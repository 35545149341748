import React from "react"
import { Button } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import { ButtonProps } from "antd/lib/button/button"
import { Link } from "react-router-dom"
import { ArrowLeftFarIcon, Flex } from "@conduktor/ui-library"

type OnClickProp = Pick<ButtonProps, "onClick">

export const BackTo: React.FC<{ to: string }> = ({ to, children }) => (
  <Link to={to}>
    <Flex gap={2} css={{ fontSize: "$2", py: "$3" }}>
      <ArrowLeftFarIcon fr /> {children}
    </Flex>
  </Link>
)

export const BackToLink: React.FC<OnClickProp> = ({ onClick, children }) => (
  <Button type="link" icon={<LeftOutlined />} className="p-0" onClick={onClick}>
    {children}
  </Button>
)

export const BackToSubscription: React.FC<OnClickProp> = ({ onClick }) => (
  <BackToLink onClick={onClick}>Back to Subscription</BackToLink>
)
