import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { map } from "lodash"
import { Col, Row, Tag } from "antd"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import {
  ChargeBeeSubscription,
  IncreaseLicnesesFormValues,
  InvoiceEstimate,
  Subscription,
} from "src/domain/desktop"
import { amount } from "src/utils"
interface MyPros {
  values: IncreaseLicnesesFormValues
  currentInvoiceEstimate: InvoiceEstimate
  currency?: string
  chargeBeeSubscription: ChargeBeeSubscription
}

const SubscriptionInfoTabPane: React.FC<MyPros> = ({
  values,
  currentInvoiceEstimate,
  currency,
  chargeBeeSubscription,
}) => {
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  return (
    <Fragment>
      <label>Subscription status will remain as </label>
      <Tag
        color={
          chargeBeeSubscription.status === "active"
            ? "green"
            : chargeBeeSubscription.status === "future"
            ? "purple"
            : "blue"
        }
      >
        <span className="capitalize">{chargeBeeSubscription.status}</span>
      </Tag>
      <h4>Subscription changes: </h4>
      {map(currentInvoiceEstimate.line_items, (item, index) => (
        <Row key={index}>
          <Col span={7} className="text-right p-5 capitalize">
            {item.entity_type}
          </Col>
          <Col span={17} className="p-5">
            <b>
              {item.description} ({currency} {amount(item.unit_amount)}) X{" "}
              {(subscription?.quantity || 0) + (values.quantity || 0)}
              <Tag>{values?.quantity} Units added</Tag>
            </b>
          </Col>
        </Row>
      ))}
      {map(currentInvoiceEstimate.discounts, (item, index) => (
        <Row key={index}>
          <Col span={7} className="text-right p-5 capitalize">
            Coupon
          </Col>
          <Col span={17} className="p-5">
            <b>{item.description}</b>
          </Col>
        </Row>
      ))}
    </Fragment>
  )
}
export default SubscriptionInfoTabPane
