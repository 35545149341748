import React from "react"
import { useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { Card, Statistic, Table } from "antd"
import { ColumnType } from "antd/lib/table"
import {
  selectAdminDashboardLoading,
  selectLicensesCount,
} from "src/store/desktop/adminDashboard/selector"
import { LicensesCount } from "src/domain/desktop"

const Licenses: React.FC = () => {
  const width: number = useWindowWidth()
  const loading: boolean = useSelector(selectAdminDashboardLoading)
  const licenseCount: LicensesCount = useSelector(selectLicensesCount)

  return (
    <div className="admin-dashboard-licenses">
      <Card loading={loading} className="card" bordered={false}>
        <h1 className="card-title">Licenses</h1>
        <Table
          columns={licensesDetailsTableColumns}
          dataSource={[licenseCount]}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            pageSize: 20,
          }}
          rowKey={(object) => [licenseCount].indexOf(object)}
          scroll={width < 800 ? { x: "max-content" } : undefined}
          size="small"
        />
      </Card>
    </div>
  )
}

const licensesDetailsTableColumns: ColumnType<LicensesCount>[] = [
  {
    title: "Available",
    dataIndex: "total",
    align: "center",
    render: (_text, record) => {
      return <Statistic value={record.total} />
    },
  },
  {
    title: "Assigned",
    dataIndex: "used",
    align: "center",
    render: (_text, record) => {
      return <Statistic value={record.used} />
    },
  },
  {
    title: "Not Assigned",
    dataIndex: "remaining",
    align: "center",
    render: (_text, record) => {
      return <Statistic value={record.remaining} />
    },
  },
]
export default Licenses
