import { all, put, select, takeLatest } from "redux-saga/effects"
import {
  deleteMachineApi,
  getAuth0UserApi,
  getLicensesApi,
  getOrCreateUserApi,
  updateConduktorUserApi,
} from "src/services"
import { Action, ActionType, GetUserApiPayload } from "src/domain/desktop"
import { parseError } from "src/utils"
import {
  fetchAuth0User,
  setApiStatus,
  setAuth0User,
  setConduktorUser,
  setIsBillingUser,
  setIsLicensed,
  setLicensedUser,
  setMessage,
  updateUserDataFailure,
  updateUserDataSuccess,
} from "./actions"
import {
  selectAuth0User,
  selectConduktorUser,
  selectIsBillingUser,
  selectLicensedUser,
} from "./selector"

import { AppState } from "../config/rootStore"
import {
  getLicenseMembersRequest,
  initLicensedMembers,
} from "../desktop/licenseMembers/actions"
import {
  Auth0User,
  ConduktorUser,
  Message,
  MessageType,
  User,
} from "../../domain/system"
import {
  UpdatableConduktorUser,
  UserRoleType,
} from "../../domain/desktop/common"
import { ClientError } from "../../services/ClientError"

function* fetchDesktopUserDataSaga({ payload: forceUpdate }: Action<boolean>) {
  const state: AppState = yield select()
  const auth0User: Auth0User = selectAuth0User(state)
  const conduktorUser: ConduktorUser = selectConduktorUser(state)
  const licensedUser: User = selectLicensedUser(state)
  const isBillingUser: boolean = selectIsBillingUser(state)
  const isNotAdmin = conduktorUser?.roleId !== UserRoleType.ADMIN

  if (
    ((Object.keys(conduktorUser).length < 1 ||
      Object.keys(licensedUser).length < 1) &&
      !auth0User.appMetadata) ||
    forceUpdate
  ) {
    yield put(
      setApiStatus({
        loading: true,
      })
    )

    if (!auth0User?.email) {
      return
    }

    if (forceUpdate) {
      const data: ConduktorUser = yield getOrCreateUserApi({
        params: {
          email: auth0User.email,
        },
      })
      yield put(setConduktorUser(data))
    }

    if (!conduktorUser.roleId || isNotAdmin) {
      yield put(setIsBillingUser(true))
    }

    if (!isBillingUser || isNotAdmin) {
      try {
        const data: User = yield getLicensesApi()
        yield put(setIsLicensed(true))
        yield put(setLicensedUser(data))
        yield put(fetchAuth0User())
        if (data.removed === undefined) {
          yield put(getLicenseMembersRequest())
        } else {
          yield put(initLicensedMembers())
        }
      } catch (err) {
        yield put(setIsLicensed(false))
        yield put(setLicensedUser({}))
        yield put(fetchAuth0User())
      }
    }

    yield put(
      setApiStatus({
        loading: false,
      })
    )
  }
}

function* fetchConduktorUserSaga() {
  const state: AppState = yield select()
  const auth0User: Auth0User = selectAuth0User(state)

  if (!auth0User?.email) {
    return
  }
  const payload: GetUserApiPayload = {
    params: {
      email: auth0User.email,
    },
  }
  const data: ConduktorUser = yield getOrCreateUserApi(payload)
  yield put(setConduktorUser(data))
}

function* fetchAuth0UserSaga() {
  try {
    const data: Auth0User[] = yield getAuth0UserApi()
    if (data.length && data.length > 0) {
      const auth0User = data[0]
      yield put(setAuth0User(auth0User))
    }
  } catch (err) {
    console.error("Fetch User Data", err)
    const message: Message = {
      type: MessageType.ERROR,
      title: "Fetch User Data",
      message: parseError(err as ClientError),
    }
    yield put(setMessage(message))
  }
}

function* removeDeviceSaga({ payload }: Action<string>) {
  try {
    const data: Auth0User = yield deleteMachineApi(payload)
    yield put(setAuth0User(data))
  } catch (err) {
    const message: Message = {
      type: MessageType.ERROR,
      title: "Remove Device",
      message: parseError(err as ClientError),
    }
    yield put(setMessage(message))
  }
}

function* updateUserDataSaga({ payload }: Action<UpdatableConduktorUser>) {
  try {
    yield updateConduktorUserApi(payload)

    yield put(updateUserDataSuccess(payload))
  } catch (err) {
    yield put(updateUserDataFailure(parseError(err as ClientError)))
  }
}

export default function* systemSagas() {
  yield all([
    takeLatest(ActionType.REMOVE_DEVICE, removeDeviceSaga),
    takeLatest(
      ActionType.FETCH_ALL_DESKTOP_USER_DATA,
      fetchDesktopUserDataSaga
    ),
    takeLatest(ActionType.FETCH_CONDUKTOR_USER, fetchConduktorUserSaga),
    takeLatest(ActionType.FETCH_AUTH0_USER, fetchAuth0UserSaga),
    takeLatest(ActionType.UPDATE_USER_DATA_REQUEST, updateUserDataSaga),
  ])
}
