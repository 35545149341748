import { useSelector } from "react-redux"
import { PlusOutlined, SyncOutlined } from "@ant-design/icons"
import { Tag } from "antd"
import { ColumnType } from "antd/lib/table"
import { Link } from "react-router-dom"
import UserWithAvatar from "src/components/UserWithAvatar"
import { selectConduktorUser } from "src/store/system/selector"
import { isValidUser } from "src/domain/user"
import { Log } from "src/domain/desktop"
import { isSubscriptionExpired } from "src/domain/desktop/subscription"
import { ConduktorUser } from "../../../../../../../domain/system"
import { UserRoleType } from "../../../../../../../domain/desktop/common"
import { formatDate } from "../../../../../../../utils"

const LogsColumns = () => {
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const isAdmin = isValidUser(conduktorUser, [UserRoleType.ADMIN])

  const columns: ColumnType<Log>[] = [
    {
      title: "Subscription",
      key: "chargeBeeSubscriptionId",
      width: 250,
      render: (_text, record) => {
        return (
          <span>
            {record.subscription.deleted ||
            (isSubscriptionExpired(record.subscription) && !isAdmin) ? (
              <span>{record.subscription.chargeBeeSubscriptionId}</span>
            ) : (
              <Link
                to={`/subscriptions/licenses-management/${record.subscription.chargeBeeSubscriptionId}`}
              >
                {record.subscription.chargeBeeSubscriptionId}
              </Link>
            )}
            {isSubscriptionExpired(record.subscription) && (
              <Tag color="volcano">Expired</Tag>
            )}
            <div className="hint-text">
              {(record.subscription.planName || "???")
                .replaceAll("Conduktor", "")
                .replaceAll("Plan", "")}
            </div>
          </span>
        )
      },
    },
    {
      title: "Actor",
      render: (_text, record) => {
        return (
          <>
            {record.user ? (
              <UserWithAvatar
                pictureUrl={record.user.pictureUrl}
                name={record.user.fullName}
                email={record.user.email}
                isAdmin={record.user.roleId === UserRoleType.ADMIN}
              />
            ) : (
              <span>
                <SyncOutlined className="m-r-5" />
                {record.action.toLowerCase() === "revoked" && (
                  <span>Auto-unassigned</span>
                )}
                {record.action.toLowerCase() === "added" && (
                  <span>Auto-assigned</span>
                )}
              </span>
            )}
          </>
        )
      },
    },
    {
      title: "Action",
      width: 100,
      align: "center",
      render: (_text, record) => {
        return (
          <span>
            {record.action === "revoked" && (
              <Tag color="red">{record.action}</Tag>
            )}
            {record.action === "updated" && (
              <Tag color="red">{record.action}</Tag>
            )}
            {record.action === "added" && <PlusOutlined />}
          </span>
        )
      },
    },
    {
      title: "License",
      render: (_text, record) => {
        return <span>{record.license?.email}</span>
      },
    },
    {
      title: "At",
      width: 150,
      render: (_text, record) => {
        return <span>{formatDate(record.created)}</span>
      },
    },
    {
      title: "Deleted",
      width: 100,
      render: (_text, record) => {
        return (
          <span>
            {record.subscription.deleted && <Tag color="red">Yes</Tag>}
            {!record.subscription.deleted && <Tag color="green">No</Tag>}
          </span>
        )
      },
    },
  ]
  return columns
}

export default LogsColumns
