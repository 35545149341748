import { Button, Tooltip } from "antd"
import { CSVLink } from "react-csv"
import { DownloadOutlined } from "@ant-design/icons"
import { isEmpty } from "lodash"
import React, { useCallback } from "react"
import { formatDate } from "../../../../utils"
import { Subscription } from "../../../../domain/desktop"
import { isSubscriptionStarted } from "src/domain/desktop/subscription"
import { User } from "../../../../domain/system"
import { useSegmentContext } from "src/core/analytics/segment"

interface Props {
  className?: string
  isAdminUser: boolean
  members: User[]
  subscription?: Subscription
}

const partialMember = (user: User) => ({
  name: user.name,
  email: user.email,
  "Assigned on": formatDate(user.created),
})

const fullMember = (user: User) => ({
  ...partialMember(user),
  "Last activity":
    user.conduktorUser && formatDate(user.conduktorUser.lastUpdatedAt),
})

export const ExportCSVButton: React.FC<Props> = ({
  className,
  isAdminUser,
  members,
  subscription,
}) => {
  const { track } = useSegmentContext()

  const onClick = useCallback(() => {
    track("cdk.admin.subscriptions.members.Exported")
  }, [track])

  return (
    <Tooltip className={className} title={"Export members to .csv"}>
      <CSVLink
        onClick={onClick}
        className="display-inline-block"
        data={members.map(isAdminUser ? fullMember : partialMember)}
        filename={"members_" + subscription?.chargeBeeSubscriptionId + ".csv"}
      >
        <Button
          disabled={isEmpty(members) || !isSubscriptionStarted(subscription)}
          className="m-t-0"
          type="link"
        >
          <DownloadOutlined />
        </Button>
      </CSVLink>
    </Tooltip>
  )
}
