import { useDispatch, useSelector } from "react-redux"
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Tooltip } from "antd"
import { ColumnType } from "antd/lib/table"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import copy from "copy-to-clipboard"
import { truncate, truncateMiddle } from "src/utils"
import { Subscription } from "src/domain/desktop"
import { deleteLicenseTokenRequest } from "src/store/desktop/subscriptionDetails/actions"
import { setMessage } from "src/store/system/actions"
import { MessageType, User } from "../../../../../../../domain/system"
import { LicenseOffline } from "../../../../../../../domain/desktop/common"

export const useLicenseOfflineTableColumns = () => {
  const dispatch = useDispatch()
  const subscription: Subscription | undefined = useSelector(selectSubscription)

  const handleCopyToClipBoard = (payload?: User | string) => {
    let token: string | undefined
    if (typeof payload === "string") {
      token = payload
    } else if (
      payload &&
      payload.licensesOffline &&
      payload.licensesOffline[0]
    ) {
      token = payload.licensesOffline[0].token
    }
    if (!token) return
    const res = copy(token)
    if (res) {
      dispatch(
        setMessage({
          type: MessageType.INFO,
          message: `${truncate(token, 20)} Copied to clipboard`,
        })
      )
    }
  }

  const handleRemoveTokenFromLicense = (licenseOffline?: LicenseOffline) => {
    if (subscription?.id && licenseOffline?.id) {
      dispatch(
        deleteLicenseTokenRequest({
          subscriptionId: subscription?.id,
          id: licenseOffline.id,
        })
      )
    }
  }

  const columns: ColumnType<LicenseOffline>[] = [
    {
      key: "device",
      title: "Device",
      width: 200,
      render: (_text, record) => {
        return <div className="text-left">{record.name || "(unnamed)"}</div>
      },
      align: "right",
    },
    {
      key: "token",
      title: "Token",
      width: 210,
      render: (_text, record) => (
        <>
          <div
            className="display-inline-block text-left"
            style={{ width: 170 }}
          >
            {truncateMiddle(record.token, 15)}
          </div>
          <span
            className="cursor-pointer"
            onClick={() => handleCopyToClipBoard(record.token)}
          >
            <Tooltip
              placement="left"
              title="Click to copy this offline token for the user"
            >
              <Button icon={<CopyOutlined />} size="small" />
            </Tooltip>
          </span>
          <Popconfirm
            title="Are you sure you want to remove this token?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleRemoveTokenFromLicense(record)}
          >
            <Tooltip placement="right" title="Delete!">
              <Button icon={<DeleteOutlined />} size="small" />
            </Tooltip>
          </Popconfirm>
        </>
      ),
    },
  ]
  return columns
}
