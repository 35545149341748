import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, Col, Row } from "antd"
import TeamRow from "./components/TeamRow"
import TeamModal from "src/components/Modals/TeamModal"
import { selectConduktorUser } from "src/store/system/selector"
import {
  selectSubscription,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsError,
  selectSubscriptionDetailsLoading,
  selectSubscriptionLicensedUsers,
  selectSubscriptionTeams,
} from "src/store/desktop/subscriptionDetails/selector"
import { setMessage } from "src/store/system/actions"
import {
  getLicensedUsersRequest,
  getSubscriptionDetailsRequest,
  getSubscriptionRequest,
} from "src/store/desktop/subscriptionDetails/actions"
import { isValidSubscription } from "src/domain/desktop/subscription"
import { ActionType, IActionType, Subscription } from "src/domain/desktop"
import { RouteKey } from "src/router/routes"
import { BackToSubscription } from "../../../components/BackToLink"
import { useSubscriptionPageView } from "src/core/analytics/hooks"
import { ConduktorUser, MessageType, User } from "../../../domain/system"
import { Team, UserRoleType } from "../../../domain/desktop/common"

interface RouteParams {
  id?: string
}
const UserGroupsPage: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const { id }: RouteParams = useParams()
  const error: string | undefined = useSelector(selectSubscriptionDetailsError)
  const loading: boolean = useSelector(selectSubscriptionDetailsLoading)
  const mode: IActionType | undefined = useSelector(
    selectSubscriptionDetailsApiMode
  )
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const teams: Team[] = useSelector(selectSubscriptionTeams) || []
  const licensedUsers: User[] =
    useSelector(selectSubscriptionLicensedUsers) || []

  const isValid = (accessLevel?: UserRoleType[]) =>
    isValidSubscription({ subscription, accessLevel, conduktorUser })

  const [teamModalVisible, setTeamModalVisible] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(undefined)

  useSubscriptionPageView(RouteKey.USER_GROUPS, subscription)

  useEffect(() => {
    if (
      id &&
      !loading &&
      mode !== ActionType.GET_SUBSCRIPTION_REQUEST &&
      !subscription?.id
    ) {
      dispatch(getSubscriptionRequest(id))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (error && !loading) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: error,
        })
      )
    }
  }, [error])

  useEffect(() => {
    if (loading) return
    if (mode === ActionType.GET_SUBSCRIPTION_REQUEST && !teams.length) {
      if (error) {
        history.push("/subscriptions") /* redirect or 404 */
      } else {
        dispatch(getSubscriptionDetailsRequest(subscription?.id))
      }
    }
    if (subscription?.id) {
      if (
        mode === ActionType.GET_SUBSCRIPTION_DETAILS_REQUEST &&
        !licensedUsers.length
      ) {
        dispatch(getLicensedUsersRequest(subscription.id))
      }
    }
  }, [loading, subscription])

  const onHandleBack = () => {
    history.goBack()
  }

  const onHandleAddNewTeam = () => {
    setSelectedTeam(undefined)
    setTeamModalVisible(true)
  }

  const handleManageTeam = (team: Team) => {
    setSelectedTeam(team)
    setTeamModalVisible(true)
  }

  return (
    <Card className="card user-groups" bordered={false}>
      <Row>
        <Col xl={11} lg={12} md={14} sm={24} xs={24}>
          <BackToSubscription onClick={onHandleBack} />
        </Col>
        <Col xl={13} lg={12} md={10} sm={24} xs={24} className="text-right">
          {isValid([
            UserRoleType.BILLING_USER,
            UserRoleType.SUBSCRIPTION_ADMIN,
          ]) && (
            <Button type="primary" onClick={onHandleAddNewTeam}>
              Create a group
            </Button>
          )}
        </Col>
      </Row>
      {teams.map((team, index) => (
        <TeamRow team={team} key={index} onManage={handleManageTeam} />
      ))}
      <TeamModal
        visible={teamModalVisible}
        teamData={selectedTeam}
        onClose={() => {
          setTeamModalVisible(false)
        }}
      />
    </Card>
  )
}

export default UserGroupsPage
