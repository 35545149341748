import React, { FC, useState } from "react"
import {
  TopicControlMembersPermissions,
  TopicPermission,
} from "src/domain/desktop/topicPermission"
import { TopicMembersPermissionsEdition } from "./TopicMembersPermissionsEdition"
import { TopicMembersDisplay } from "./TopicMembersDisplay"
import { TopicMembersPermissionsPanel } from "./TopicMembersPermissions.styled"
import { isEmpty } from "src/utils"
import { TopicPermissions } from "./TopicPermissions"
import { AngleDownFarIcon, Text } from "@conduktor/ui-library"
import { TopicsControlPanel } from "./TopicControl.styled"
import { ButtonIcon } from "../../../../components/Icon/ButtonIcon"

interface TopicMembersPermissionsProps {
  membersPermissions: TopicControlMembersPermissions
  onChange: (membersPermissions: TopicControlMembersPermissions) => void
}

export const TopicMembersPermissions: FC<TopicMembersPermissionsProps> = ({
  membersPermissions,
  onChange,
}) => {
  const [selectedPermissions, setSelectedPermissions] = useState<
    TopicPermission[]
  >(membersPermissions.permissions)
  const [isOpen, setOpen] = useState<boolean>(
    isEmpty(membersPermissions.members)
  )
  const [isEditing, setIsEditing] = useState<boolean>(
    isEmpty(membersPermissions.members)
  )

  const changeIsEditing = (value: boolean) => {
    setIsEditing(value)
    setOpen(value)
  }

  return (
    <TopicMembersPermissionsPanel>
      <TopicsControlPanel>
        <ButtonIcon reversed={isOpen}>
          <AngleDownFarIcon fr onClick={() => setOpen(!isOpen)} />
        </ButtonIcon>
        <Text>Assigned To:</Text>
        {isEditing ? (
          <TopicMembersPermissionsEdition
            members={membersPermissions.members}
            permissions={selectedPermissions}
            onCancel={() => {
              onChange(membersPermissions)
              setSelectedPermissions(membersPermissions.permissions)
              changeIsEditing(false)
            }}
            onChange={(value) => {
              onChange({ members: value, permissions: selectedPermissions })
              changeIsEditing(false)
            }}
          />
        ) : (
          <TopicMembersDisplay
            members={membersPermissions.members}
            onEdit={() => changeIsEditing(true)}
            onRemove={() =>
              onChange({
                members: [],
                permissions: [],
              })
            }
          />
        )}
      </TopicsControlPanel>
      {isOpen && (
        <TopicPermissions
          disabled={!isEditing}
          permissions={selectedPermissions}
          onChange={setSelectedPermissions}
        />
      )}
    </TopicMembersPermissionsPanel>
  )
}
