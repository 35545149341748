import { Box, XmarkFarIcon } from "@conduktor/ui-library"
import React from "react"

interface Props {
  disabled: boolean
  onClick: () => void
}

export const RemoveMemberButton = ({ disabled, onClick }: Props) => {
  return (
    <Box title={"Remove"} css={{ cursor: "pointer" }}>
      <XmarkFarIcon fr onClick={() => !disabled && onClick()} />
    </Box>
  )
}
