import { takeLatest, put, all } from "redux-saga/effects"
import { getAdminDashboardSubscriptionsDetailsApi } from "src/services"
import {
  ActionType,
  AdminDashboard,
  DomainStatusWithCount,
  LicenseActivityLog,
  LicensesCount,
  SubscriptionActiveExpiredCountWithPlanName,
  SubscriptionAddonsCount,
} from "src/domain/desktop"
import { parseError } from "src/utils"
import {
  getAdminDashboardDataFailure,
  getAdminDashboardDataSuccess,
} from "./actions"
import { ClientError } from "../../../services/ClientError"

function* getAdminDashboardDataSaga() {
  try {
    const data: {
      domainStatusWithCountDTO: DomainStatusWithCount
      licenseActivityLogDTOList: Array<LicenseActivityLog>
      licensesCountDTO: LicensesCount
      subscriptionActiveExpiredCountWithPlanNameDTOList: Array<SubscriptionActiveExpiredCountWithPlanName>
      subscriptionAddonsCountDTO: SubscriptionAddonsCount
    } = yield getAdminDashboardSubscriptionsDetailsApi()

    const adminDashboardData: AdminDashboard = {
      subscriptionActiveExpiredCountWithPlanNameList:
        data.subscriptionActiveExpiredCountWithPlanNameDTOList,
      subscriptionAddonsCount: data.subscriptionAddonsCountDTO,
      domainStatusWithCount: data.domainStatusWithCountDTO,
      licensesCount: data.licensesCountDTO,
      licenseActivityLogsList: data.licenseActivityLogDTOList,
    }
    yield put(getAdminDashboardDataSuccess(adminDashboardData))
  } catch (err) {
    yield put(getAdminDashboardDataFailure(parseError(err as ClientError)))
  }
}

export default function* adminDashboardSagas() {
  yield all([
    takeLatest(
      ActionType.GET_ADMIN_DASHBOARD_DATA_REQUEST,
      getAdminDashboardDataSaga
    ),
  ])
}
