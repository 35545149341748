import {
  Button,
  Flex,
  Pill,
  Skeleton,
  XmarkFarIcon,
} from "@conduktor/ui-library"
import { Select } from "antd"
import { isEmpty, orEmpty } from "src/utils"
import React, { FC, MouseEvent, useState } from "react"
import { useSelector } from "react-redux"
import { CustomTagProps } from "rc-select/lib/interface/generator"
import { selectAccessControlLoaded } from "src/store/desktop/accessControl/selector"
import {
  selectSubscriptionLicensedUsers,
  selectSubscriptionTeams,
  selectSubscriptionTeamsById,
} from "src/store/desktop/subscriptionDetails/selector"
import { allUsersGroupId, allUsersGroupName } from "src/domain/desktop"
import { Team, TeamsById } from "src/domain/desktop/common"
import {
  isUserMember,
  TopicControlMember,
  TopicPermission,
} from "src/domain/desktop/topicPermission"
import { User } from "src/domain/system"
import { SearchSelect } from "src/components/EditableCell/Select/SearchSelect"
import {
  createGroupId,
  createUserId,
  groupName,
  parseUserOrGroupId,
} from "../UserOrGroupId"
import { MemberIcon } from "../MemberIcon"

interface TopicMembersProps {
  members: TopicControlMember[]
  permissions: TopicPermission[]
  onChange: (value: TopicControlMember[]) => void
  onCancel: () => void
}

export const TopicMembersPermissionsEdition: FC<TopicMembersProps> = ({
  members,
  permissions,
  onChange,
  onCancel,
}) => {
  const [selectedMembers, setSelectedMembers] = useState<string[]>(
    members.map((member) => {
      if (isUserMember(member)) {
        return createUserId(member.userEmail)
      }
      return createGroupId(member.groupName)
    })
  )

  const licencedUsers = useSelector(selectSubscriptionLicensedUsers)
  const teams = useSelector(selectSubscriptionTeams)
  const teamsById = useSelector(selectSubscriptionTeamsById)
  const loaded = useSelector(selectAccessControlLoaded)

  if (!loaded) {
    return <Skeleton />
  }

  const unmanagedUsers = orEmpty(licencedUsers)
  const unmanagedGroups = orEmpty(teams)
  const hasAllUsersPseudoGroup = false

  const confirmChange = async () => {
    const members: TopicControlMember[] = selectedMembers
      .map(parseUserOrGroupId)
      .filter(Boolean)
      .map((userOrGroupId) => {
        const { type, id } = userOrGroupId!
        return type === "group" ? { groupName: id } : { userEmail: id }
      })
    onChange(members)
  }

  return (
    <>
      <SearchSelect
        allowClear
        mode="multiple"
        placeholder="Search for Users or Groups"
        value={selectedMembers}
        onChange={setSelectedMembers}
        tagRender={tagRender(teamsById)}
        style={{ flex: 1 }}
      >
        {!hasAllUsersPseudoGroup && createAllUsersGroupOption()}
        {unmanagedGroups?.map((group) => createGroupOption(group))}
        {unmanagedUsers?.map((user) => createUserOption(user))}
      </SearchSelect>

      <Button
        pointer
        variant={"secondaryOutline"}
        size={"small"}
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        pointer
        variant={"primary"}
        size={"small"}
        onClick={confirmChange}
        disabled={isEmpty(selectedMembers) || isEmpty(permissions)}
      >
        Confirm
      </Button>
    </>
  )
}

function createAllUsersGroupOption() {
  return (
    <Select.Option value={createGroupId(allUsersGroupId)} title={"All"}>
      <Flex align={"center"} gap={2}>
        <MemberIcon type={"group"} />
        <b>{allUsersGroupName}</b>
      </Flex>
    </Select.Option>
  )
}

function createGroupOption(group: Team) {
  return (
    <Select.Option
      value={createGroupId(String(group.id))}
      title={group.name}
      key={group.id}
    >
      <Flex align={"center"} gap={2}>
        <MemberIcon type={"group"} />
        <b>{group.name}</b>
      </Flex>
    </Select.Option>
  )
}

function createUserOption(user: User) {
  return (
    <Select.Option
      key={user.id}
      value={createUserId(user.email!)}
      title={user.email}
    >
      <Flex align={"center"} gap={2}>
        <MemberIcon type={"user"} /> {user.email}
      </Flex>
    </Select.Option>
  )
}

function tagRender(teamsById: TeamsById) {
  return function MemberTag(props: CustomTagProps) {
    const { value, onClose } = props
    const userOrGroup = parseUserOrGroupId(value as string)
    const memberId = userOrGroup?.id

    if (!memberId || !userOrGroup?.type) {
      return (
        <Pill color={"tomato"} css={{ mr: "$1" }}>
          unknown
        </Pill>
      )
    }

    const isGroup = userOrGroup.type === "group"

    return (
      <Pill
        color={isGroup ? "grass" : "slate"}
        css={{ mr: "$1" }}
        onMouseDown={(event: MouseEvent<HTMLSpanElement>) => {
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <MemberIcon type={userOrGroup.type} />
        {isGroup ? groupName(teamsById, memberId) : memberId}
        <XmarkFarIcon
          fr
          css={{ cursor: "pointer" }}
          onClick={() => onClose()}
        />
      </Pill>
    )
  }
}
