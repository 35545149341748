import { useDispatch, useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { Button, Table, Tag } from "antd"
import { ColumnType } from "antd/lib/table"
import {
  selectSubscription,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoading,
  selectSubscriptionInvoices,
} from "src/store/desktop/subscriptionDetails/selector"
import { setMessage } from "src/store/system/actions"
import { getSubscriptionDownloadInvoiceApi } from "src/services"
import { amount, formatDate } from "src/utils"
import {
  ActionType,
  Invoice,
  InvoiceStatusInfo,
  Subscription,
} from "src/domain/desktop"
import { useSegmentContext } from "src/core/analytics/segment"

const InvoicesModalTable = () => {
  const dispatch = useDispatch()
  const { track } = useSegmentContext()
  const width: number = useWindowWidth()
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const apiMode = useSelector(selectSubscriptionDetailsApiMode)
  const loading: boolean =
    useSelector(selectSubscriptionDetailsLoading) &&
    apiMode === ActionType.GET_INVOICES_REQUEST
  const invoices = useSelector(selectSubscriptionInvoices)

  const handleDownload = async (invoiceId?: string) => {
    if (invoiceId && subscription?.id) {
      try {
        const data: {
          downloadLink?: string
          error?: boolean
          message?: string
        } = await getSubscriptionDownloadInvoiceApi({
          invoiceId,
          id: subscription.id,
        })
        if (data?.error) {
          dispatch(setMessage({ message: data.message }))
        } else {
          window.open(data.downloadLink, "_blank")
          track("cdk.admin.subscriptions.invoices.Downloaded")
        }
      } catch (error) {
        dispatch(setMessage({ message: `Something went wrong!` }))
      }
    }
  }

  const invoicesColumns: ColumnType<Invoice>[] = [
    {
      key: "invoice",
      title: "Invoice",
      render: (_text, record) => {
        return <span>{record.id}</span>
      },
    },
    {
      key: "status",
      title: "Status",
      render: (_text, record) => {
        return (
          <span>
            <Tag color={InvoiceStatusInfo[record.status].color}>
              {InvoiceStatusInfo[record.status].text}
            </Tag>
          </span>
        )
      },
      align: "center",
    },
    {
      key: "amountTotal",
      title: "Amount",
      render: (_text, record) => {
        return (
          <span>
            {amount(record.total)} {record.currencyCode}
          </span>
        )
      },
      align: "center",
    },
    {
      key: "issuedOn",
      title: "Issued on",
      render: (_text, record) => {
        return <span>{formatDate(record.date)}</span>
      },
      align: "center",
    },
    {
      key: "payedOn",
      title: "Paid on",
      render: (_text, record) => {
        return <span>{formatDate(record.paidAt)}</span>
      },
      align: "center",
    },
    {
      key: "download",
      title: "",
      render: (_text, record) => {
        return (
          <Button
            type="link"
            size="small"
            onClick={() => handleDownload(record.id)}
          >
            Download
          </Button>
        )
      },
      align: "center",
    },
  ]

  return (
    <Table
      rowKey="id"
      size="small"
      className="m-t-10"
      pagination={{ hideOnSinglePage: true }}
      loading={loading}
      columns={invoicesColumns}
      dataSource={invoices}
      scroll={width < 900 ? { x: "max-content" } : undefined}
    />
  )
}
export default InvoicesModalTable
