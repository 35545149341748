import {
  AddDomainApiPayload,
  AddDomainEmailApiPayload,
  DeleteDomainApiPayload,
  DeleteDomainEmailApiPayload,
  GetItemsApiPayload,
  UpdateDomainStatusApiPayload,
} from "src/domain/desktop"
import { desktopClient } from "../axiosClients"

export const getDomainsApi = async (payload: GetItemsApiPayload) => {
  const { data } = await desktopClient.get("domains", payload)
  return data
}

export const updateDomainStatusApi = async (
  payload: UpdateDomainStatusApiPayload
) => {
  const { data } = await desktopClient.put(`domains`, payload)
  return data
}

export const addDomainApi = async (payload: AddDomainApiPayload) => {
  const { data } = await desktopClient.post(`domains`, payload)
  return data
}

export const deleteDomainApi = async ({
  subscriptionId,
  domainId,
}: DeleteDomainApiPayload) => {
  const { data } = await desktopClient.delete(
    `domains/${subscriptionId}/${domainId}`
  )
  return data
}

export const addDomainEmailApi = async (payload: AddDomainEmailApiPayload) => {
  const { data } = await desktopClient.post(`domain-email`, payload)
  return data
}

export const deleteDomainEmailApi = async ({
  subscriptionId,
  domainEmailId,
}: DeleteDomainEmailApiPayload) => {
  const { data } = await desktopClient.delete(
    `domain-email/${subscriptionId}/${domainEmailId}`
  )
  return data
}
