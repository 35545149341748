import TagManager from "react-gtm-module"

const gtmId = process.env.REACT_APP_GTM_ID

export const initGTM = () => {
  if (gtmId) {
    TagManager.initialize({
      gtmId: gtmId,
    })
  }
}
