import React from "react"
import { Button, Col, Row } from "antd"
import { length, momentFromNow } from "src/utils"
import "./styles.less"
import { Team } from "../../../../../domain/desktop/common"

interface ITeamRowProps {
  team: Team
  loading?: boolean
  onManage?: Function
}

const TeamRow: React.FC<ITeamRowProps> = ({ team, onManage }) => {
  return (
    <Row key="reRender" className="team-row m-t-10" gutter={24}>
      <Col xl={10} lg={9} md={8} sm={24} xs={24}>
        <div className="circle-dot" style={{ backgroundColor: team.color }} />
        <b style={{ fontSize: 18, marginLeft: 10 }}>{team.name}</b>
        <p className="m-b-2">
          Created by <b>{team.createdBy?.fullName}</b>,{" "}
          {momentFromNow(team.created)}.
        </p>
        {team.teamMembers ? (
          <p className="hint-text">
            {length(team.teamMembers) < 2 ? (
              <span>1 member: {team.teamMembers[0].license.name}</span>
            ) : (
              <span>
                {length(team.teamMembers)} members:{" "}
                {team.teamMembers
                  .slice(0, 3)
                  .map((member) => member.license.name)
                  .concat("...")
                  .join(", ")}
              </span>
            )}
          </p>
        ) : (
          <p className="hint-text">No member yet</p>
        )}
      </Col>
      {onManage && (
        <Col xl={4} lg={6} md={8} sm={24} xs={24} className="text-center p-t-5">
          <Button
            onClick={() => {
              onManage(team)
            }}
          >
            Manage
          </Button>
        </Col>
      )}
    </Row>
  )
}

export default TeamRow
