import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Input, Modal } from "antd"
import BillingUsersModalFooter from "./Footer"
import {
  selectSubscription,
  selectSubscriptionBillingUsers,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoaded,
  selectSubscriptionDetailsLoading,
} from "src/store/desktop/subscriptionDetails/selector"
import { selectConduktorUser } from "src/store/system/selector"
import { createBillingUserRequest } from "src/store/desktop/subscriptionDetails/actions"
import { setMessage } from "src/store/system/actions"
import { orEmpty } from "src/utils"
import {
  ActionType,
  BillingUsersModalFormValues,
  BillingUsersModalProps,
  IActionType,
  Subscription,
} from "src/domain/desktop"
import { ConduktorUser, MessageType, User } from "../../../domain/system"
import { isValidPayloadForAddingOwnerToSubscription } from "./responseValidation"
import { useSegmentContext } from "src/core/analytics/segment"

const BillingUsersModal: React.FC<BillingUsersModalProps> = ({
  visible,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { track } = useSegmentContext()
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const billingUsers = orEmpty(useSelector(selectSubscriptionBillingUsers))
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const apiMode: IActionType | undefined = useSelector(
    selectSubscriptionDetailsApiMode
  )
  const loading: boolean =
    useSelector(selectSubscriptionDetailsLoading) &&
    apiMode === ActionType.CREATE_BILLING_USER_REQUEST
  const loaded: boolean | undefined =
    useSelector(selectSubscriptionDetailsLoaded) &&
    apiMode === ActionType.CREATE_BILLING_USER_REQUEST
  const [values, setValues] = useState<BillingUsersModalFormValues>({})

  useEffect(() => {
    if (visible) {
      setValues({})
    }
  }, [dispatch, visible])

  useEffect(() => {
    if (!loading && loaded) {
      dispatch(
        setMessage({
          type: MessageType.SUCCESS,
          message: `Added successfully!`,
        })
      )
      onClose()
    }
  }, [loading, loaded, dispatch, onClose])

  const handleChange = (prop: keyof BillingUsersModalFormValues) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setValues({
      ...values,
      [prop]: value,
    })
  }
  const handleAddOwner = async () => {
    if (!subscription?.id) return
    const validationResponse = isValidPayloadForAddingOwnerToSubscription({
      user: values,
      conduktorUser,
      currentOwners: billingUsers,
    })
    if (validationResponse.isValidated) {
      const data: User = {
        fullName: values.name,
        email: values.email,
      }
      dispatch(
        createBillingUserRequest({
          id: subscription.id,
          data,
        })
      )
      track("cdk.admin.subscriptions.owner.Added")
    } else {
      dispatch(
        setMessage({
          message: validationResponse.errorMessage,
        })
      )
    }
  }
  return (
    <div className="add-admin-of-subscription">
      <Modal
        title="Owners"
        visible={visible}
        width={"50%"}
        maskClosable={false}
        closable={false}
        footer={
          <BillingUsersModalFooter
            loading={loading}
            onAddOwner={handleAddOwner}
            onClose={() => onClose()}
          />
        }
      >
        <p>
          You can add Owner to manage this subscription. They will not be able
          to add other Owners to the subscription.
        </p>
        <Input
          value={values.name}
          className="m-t-10"
          addonBefore="Name"
          placeholder="John Doe"
          onChange={handleChange("name")}
        />
        <Input
          value={values.email}
          className="m-t-15"
          addonBefore="Email"
          placeholder="john@mycompany.com"
          onChange={handleChange("email")}
        />
      </Modal>
    </div>
  )
}
export default BillingUsersModal
