import React, { Fragment, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { AccountBookOutlined, TeamOutlined } from "@ant-design/icons"
import { Button } from "antd"
import {
  selectSubscription,
  selectSubscriptionLicenseManagers,
  selectSubscriptionTeams,
} from "src/store/desktop/subscriptionDetails/selector"
import { selectConduktorUser } from "src/store/system/selector"
import { showModal } from "src/store/system/actions"
import { isValidSubscription } from "src/domain/desktop/subscription"
import { Subscription } from "src/domain/desktop"
import { isSubscriptionExpired } from "src/domain/desktop/subscription"
import { ConduktorUser } from "../../../../../../domain/system"
import {
  ModalType,
  UserRoleType,
} from "../../../../../../domain/desktop/common"
import { useSegmentContext } from "src/core/analytics/segment"

const ActionsSection: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { track } = useSegmentContext()
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const isExpired: boolean = isSubscriptionExpired(subscription)
  const licenseManagersCount: number =
    useSelector(selectSubscriptionLicenseManagers)?.length || 0
  const teamsCount: number = useSelector(selectSubscriptionTeams)?.length || 0
  const isValid = (accessLevel?: UserRoleType[]) => {
    return isValidSubscription({ subscription, accessLevel, conduktorUser })
  }
  const handleRedirectToManageTeamPage = () => {
    history.push(
      `/subscriptions/licenses-management/${subscription?.chargeBeeSubscriptionId}/user-groups`
    )
  }
  const showInvoicesModal = useCallback(() => {
    track("cdk.admin.subscriptions.invoices.Viewed")
    dispatch(showModal(ModalType.INVOCE))
  }, [dispatch, track])

  return (
    <Fragment>
      {isValid([UserRoleType.BILLING_USER]) && (
        <Button
          className="m-b-2 m-r-5"
          type="primary"
          onClick={showInvoicesModal}
        >
          <AccountBookOutlined />
          View Invoices
        </Button>
      )}
      <Button
        disabled={isExpired}
        className="m-b-2 m-r-5"
        type="primary"
        onClick={handleRedirectToManageTeamPage}
      >
        <TeamOutlined />
        User Groups ({teamsCount})
      </Button>
      {isValid([
        UserRoleType.BILLING_USER,
        UserRoleType.SUBSCRIPTION_ADMIN,
      ]) && (
        <Button
          disabled={isExpired}
          className="m-b-2"
          type="primary"
          onClick={() => dispatch(showModal(ModalType.LICENSE_MANAGER))}
        >
          Team Managers ({licenseManagersCount})
        </Button>
      )}
    </Fragment>
  )
}
export default ActionsSection
