import { useDispatch, useSelector } from "react-redux"
import { Flex } from "@conduktor/ui-library"
import {
  AccessControlGroupDescription,
  AccessControlGroupName,
  AccessControlMemberIcon,
  AccessControlMemberPanel,
} from "./AccessControl.styled"
import { RoleSelect } from "./RoleSelect"
import {
  AccessControlMemberApiPayload,
  AccessControlMemberId,
  allUsersGroupId,
  RemoveAccessControlMemberApiPayload,
  RoleName,
} from "src/domain/desktop"
import { selectAccessControlGroups } from "src/store/desktop/accessControl/selector"
import {
  removeAccessControlMemberSuccess,
  updateAccessControlMemberSuccess,
} from "src/store/desktop/accessControl/actions"
import { useSubscriptionQuery } from "src/pages/hooks/useSubscriptionQuery"
import { membersCountDescription, selectMostSpecificRole } from "./utils"
import React, { useState } from "react"
import {
  removeAccessControlGroupApi,
  upsertAccessControlGroupApi,
} from "src/services"
import { setErrorMessage, setSuccessMessage } from "src/store/system/actions"
import { RemoveMemberButton } from "./RemoveMemberButton"
import { selectSubscriptionTeamsById } from "src/store/desktop/subscriptionDetails/selector"
import { TeamsById } from "src/domain/desktop/common"
import { selectSelectedCluster } from "src/store/desktop/clusters/selector"
import { groupName } from "../UserOrGroupId"
import { MemberIcon } from "../MemberIcon"

export const ListGroups = () => {
  const teamsById = useSelector(selectSubscriptionTeamsById)
  const groups = useSelector(selectAccessControlGroups)

  const { [allUsersGroupId]: allUsersGroupRoles, ...allButAllUsers } = groups

  return (
    <>
      {Object.entries(allButAllUsers).map(([id, roles]) => (
        <AccessControlGroup
          key={id}
          id={id}
          roles={roles}
          teamsById={teamsById}
        />
      ))}
      {allUsersGroupRoles && (
        <AccessControlGroup
          id={allUsersGroupId}
          roles={allUsersGroupRoles}
          teamsById={teamsById}
        />
      )}
    </>
  )
}

interface GroupProps {
  id: AccessControlMemberId
  roles: RoleName[]
  teamsById: TeamsById
}

const AccessControlGroup = ({ id, roles, teamsById }: GroupProps) => {
  const dispatch = useDispatch()
  const { subscription } = useSubscriptionQuery()
  const cluster = useSelector(selectSelectedCluster)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const isAllUsersGroup = id === allUsersGroupId

  const update = async (role: RoleName) => {
    try {
      const updating: AccessControlMemberApiPayload = {
        id: id,
        clusterId: cluster!.clusterId!,
        subscriptionId: subscription!.id,
        roles: [role],
        type: "group",
      }
      setIsUpdating(true)
      await upsertAccessControlGroupApi(updating)
      dispatch(updateAccessControlMemberSuccess(updating))
      dispatch(setSuccessMessage(`Access control assigned`))
    } catch (e) {
      dispatch(setErrorMessage(`Error while assigning this access control`))
    } finally {
      setIsUpdating(false)
    }
  }

  const remove = async () => {
    try {
      const removal: RemoveAccessControlMemberApiPayload = {
        id: id,
        clusterId: cluster!.clusterId!,
        subscriptionId: subscription!.id,
        type: "group",
      }
      setIsUpdating(true)
      await removeAccessControlGroupApi(removal)
      dispatch(removeAccessControlMemberSuccess(removal))
      dispatch(setSuccessMessage(`Access control removed`))
    } catch (e) {
      setIsUpdating(false)
      dispatch(setErrorMessage(`Error while removing this access control`))
    }
  }

  return (
    <AccessControlMemberPanel allUsersGroup={isAllUsersGroup}>
      <Flex align={"center"} gap={3}>
        <AccessControlMemberIcon
          variant={isAllUsersGroup ? "allUsers" : "group"}
        >
          <MemberIcon type={"group"} />
        </AccessControlMemberIcon>
        <Flex direction={"column"}>
          <AccessControlGroupName>
            {groupName(teamsById, id)}
          </AccessControlGroupName>
          <AccessControlGroupDescription>
            {membersCountDescription(
              isAllUsersGroup,
              teamsById[Number(id)]?.teamMembers
            )}
          </AccessControlGroupDescription>
        </Flex>
      </Flex>

      <Flex align={"center"} gap={5}>
        <RoleSelect
          disabled={isUpdating}
          disableOwner={isAllUsersGroup}
          value={selectMostSpecificRole(roles)}
          onChange={update}
          options={cluster!.roles}
        />
        <RemoveMemberButton disabled={isUpdating} onClick={remove} />
      </Flex>
    </AccessControlMemberPanel>
  )
}
