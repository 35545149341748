import React from "react"
import { Box, Flex, styled, Text } from "@conduktor/ui-library"
import {
  LayoutBody,
  LayoutContainer,
  LayoutContent as LayoutContentDefault,
  LayoutWrapper,
} from "./layouts.styles"
import { Header } from "../components/Header/Header"
import { SidebarDesktop } from "../components/SideBar/SidebarDesktop"

export const DesktopLayout: React.FC = ({ children }) => (
  <EmptyLayout>
    <LayoutWrapper gridColumns>
      <SidebarDesktop />
      <LayoutBody>
        <Header />
        <LayoutContainer>{children}</LayoutContainer>
      </LayoutBody>
    </LayoutWrapper>
  </EmptyLayout>
)

type LayoutHeaderProps = {
  title: string
  subTitle?: string
}

export const LayoutHeader: React.FC<LayoutHeaderProps> = ({
  title,
  subTitle,
  children,
}) => (
  <LayoutHeaderContainer>
    <Flex direction={"column"}>
      <Text variant={"h1"}>{title}</Text>
      {subTitle && <Text css={{ color: "$neutral11" }}>{subTitle}</Text>}
    </Flex>
    <Box css={{ pt: "$2" }}>{children}</Box>
  </LayoutHeaderContainer>
)

const EmptyLayout = styled("div", {
  backgroundColor: "$neutral2",
})

const LayoutHeaderContainer = styled(Flex, {
  gap: "$3",
  justifyContent: "space-between",
  alignItems: "flex-start",
})

export const LayoutContent = styled(LayoutContentDefault, {
  display: "flex",
  flexDirection: "column",
  marginTop: "$6",
  gap: "$3",
})
