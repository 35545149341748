import React from "react"
import { useSelector } from "react-redux"
import { Button, Col, Popconfirm, Row } from "antd"
import {
  selectSubscription,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoading,
} from "src/store/desktop/subscriptionDetails/selector"

import {
  CloseCircleOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons"
import { selectConduktorUser } from "src/store/system/selector"
import { ActionType, Subscription, IActionType } from "src/domain/desktop"
import { ConduktorUser } from "../../../../domain/system"
import { Team, UserRoleType } from "../../../../domain/desktop/common"
import { isValidSubscription } from "../../../../domain/desktop/subscription"
interface MyProps {
  teamData?: Team
  onDelete: Function
  onAdd: Function
  onUpdate: Function
  onClose: Function
}

const TeamModalFooter: React.FC<MyProps> = ({
  teamData,
  onDelete,
  onClose,
  onAdd,
  onUpdate,
}) => {
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const subscription: Subscription | undefined = useSelector(selectSubscription)

  const apiMode: IActionType | undefined = useSelector(
    selectSubscriptionDetailsApiMode
  )
  const loading: boolean =
    useSelector(selectSubscriptionDetailsLoading) &&
    (apiMode === ActionType.CREATE_TEAM_REQUEST ||
      apiMode === ActionType.DELETE_TEAM_REQUEST ||
      apiMode === ActionType.UPDATE_TEAM_REQUEST)

  const isValid = (accessLevel?: UserRoleType[]) => {
    return isValidSubscription({ subscription, accessLevel, conduktorUser })
  }

  return (
    <Row>
      <Col flex="100px">
        {teamData &&
          isValid([
            UserRoleType.BILLING_USER,
            UserRoleType.SUBSCRIPTION_ADMIN,
          ]) && (
            <Popconfirm
              disabled={loading}
              title="Are you sure you want to remove this group?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => onDelete()}
            >
              <Button
                danger
                disabled={loading}
                loading={loading && apiMode === ActionType.DELETE_TEAM_REQUEST}
              >
                <UsergroupDeleteOutlined />
                Delete Group
              </Button>
            </Popconfirm>
          )}
      </Col>
      <Col flex="auto">
        {!teamData && (
          <Button
            disabled={loading}
            loading={loading && apiMode === ActionType.CREATE_TEAM_REQUEST}
            type="primary"
            onClick={() => onAdd()}
          >
            <UsergroupAddOutlined />
            Add Team
          </Button>
        )}
        {teamData && (
          <Button
            disabled={loading}
            loading={loading && apiMode === ActionType.UPDATE_TEAM_REQUEST}
            type="primary"
            onClick={() => onUpdate()}
          >
            <UserSwitchOutlined />
            Update
          </Button>
        )}
        <Button disabled={loading} className="m-l-5" onClick={() => onClose()}>
          <CloseCircleOutlined />
          Close
        </Button>
      </Col>
    </Row>
  )
}

export default TeamModalFooter
