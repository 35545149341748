import { applyMiddleware, compose, createStore, Store } from "redux"
import createSagaMiddleware from "redux-saga"
import { persistStore, persistReducer, Persistor } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { createBrowserHistory } from "history"
import { routerMiddleware } from "connected-react-router"
import rootReducer from "./rootReducer"
import rootSaga from "./rootSaga"
import { sentryReduxEnhancer } from "../../core/reporting/sentry"

const sagaMiddleware = createSagaMiddleware()

export const history = createBrowserHistory()

const rootReducerInstance = rootReducer(history)

export type AppState = ReturnType<typeof rootReducerInstance>
export interface ConfigureStore {
  store: Store
  persistor: Persistor
}

export const configureStore = async () => {
  const persistConfig = {
    key: "root",
    storage,
    whitelist: [],
  }

  const persistedReducer = persistReducer(persistConfig, rootReducerInstance)

  const store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(sagaMiddleware, routerMiddleware(history)),
      sentryReduxEnhancer
    )
  )

  sagaMiddleware.run(rootSaga)
  const persistor = persistStore(store)
  return {
    store,
    persistor,
  }
}
