import {
  AddLicensesApiPayload,
  AddLicensesByCSVApiPayload,
  DeleteSubscriptionItemApiPayload,
  GetItemsApiPayload,
  UpdateLicenseApiPayload,
} from "src/domain/desktop"
import { desktopClient } from "../axiosClients"

export const getLicensesAllApi = async (payload: GetItemsApiPayload) => {
  const { data } = await desktopClient.get("licenses/all-licenses", payload)
  return data
}

export const getLicensesApi = async (payload?: number) => {
  const { data } = await desktopClient.get(
    `licenses${payload !== undefined ? "/" + payload : ""}`
  )
  return data
}

export const getLicensesRemovedApi = async (payload?: number) => {
  const { data } = await desktopClient.get(
    `licenses/removed${payload !== undefined ? "/" + payload : ""}`
  )
  return data
}

export const addLicensesApi = async (payload?: AddLicensesApiPayload) => {
  const { data } = await desktopClient.post(`licenses`, payload)
  return data
}

export const addLicensesByCSVApi = async (
  payload: AddLicensesByCSVApiPayload
) => {
  await desktopClient.post(
    `subscriptions/${payload.subscriptionId}/create-licenses`,
    payload.csvContent
  )
}

export const updateLicenseApi = async (payload: UpdateLicenseApiPayload) => {
  const { data } = await desktopClient.put(`licenses`, payload)
  return data
}

export const generateLicensesTokenApi = async (
  payload?: AddLicensesApiPayload
) => {
  const { data } = await desktopClient.post(`licenses/generate-token`, payload)
  return data
}

export const deleteLicenseApi = async ({
  subscriptionId,
  id,
}: DeleteSubscriptionItemApiPayload): Promise<void> => {
  await desktopClient.delete(`licenses/${subscriptionId}/${id}`)
}

export const deleteMachineApi = async (payload: string) => {
  const { data } = await desktopClient.delete(
    `licenses/delete-machine/${payload}`
  )
  return data
}

export const getLicenseMembersApi = async () => {
  const { data } = await desktopClient.get(`licenses/members`)
  return data
}

export const deleteLicenseTokenApi = async ({
  subscriptionId,
  id,
}: DeleteSubscriptionItemApiPayload) => {
  const { data } = await desktopClient.delete(
    `licenses/delete-token/${subscriptionId}/${id}`
  )
  return data
}

export const getLicenseLogsApi = async (payload: GetItemsApiPayload) => {
  const { data } = await desktopClient.get("licenses-logs", payload)
  return data
}
