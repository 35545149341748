import { Action, ActionType, ApiStatus, Auth0Stats } from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import {
  failureReducer,
  initReducer,
  requestReducer,
  successReducer,
} from "../../../reducers"

export type LastSignupsReducerType = ApiStatus & {
  stats?: Auth0Stats
}

export const defaultState: LastSignupsReducerType = {
  loading: false,
  loaded: false,
  error: undefined,
  stats: undefined,
}

function getLastSignupsSuccessReducer(
  state: LastSignupsReducerType,
  { payload }: Action<Auth0Stats>
): LastSignupsReducerType {
  return {
    ...successReducer(state),
    stats: payload,
  }
}

export const lastSignupsReducer = createReducer<LastSignupsReducerType>(
  defaultState,
  {
    [ActionType.INIT_STORE]: initReducer(defaultState),
    [ActionType.GET_AUTH0_STATS_REQUEST]: requestReducer,
    [ActionType.GET_AUTH0_STATS_SUCCESS]: getLastSignupsSuccessReducer,
    [ActionType.GET_AUTH0_STATS_FAILURE]: failureReducer,
  }
)
