import { all, put, takeLatest } from "redux-saga/effects"

import { parseError } from "src/utils"
import { ClientError } from "src/services/ClientError"
import { getClusterAccessControlApi } from "src/services"

import {
  AccessControl,
  Action,
  ActionType,
  GetAccessControlApiPayload,
} from "src/domain/desktop"
import {
  getAccessControlFailure,
  getAccessControlSuccess,
} from "../accessControl/actions"

function* getAccessControlSaga({
  payload,
}: Action<GetAccessControlApiPayload>) {
  try {
    const data: AccessControl = yield getClusterAccessControlApi(payload)
    yield put(getAccessControlSuccess(data))
  } catch (err) {
    yield put(getAccessControlFailure(parseError(err as ClientError)))
  }
}

export default function* accessControlSagas() {
  yield all([
    takeLatest(ActionType.GET_ACCESS_CONTROL_REQUEST, getAccessControlSaga),
  ])
}
