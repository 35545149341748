import React, { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CreditCardOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Tooltip } from "antd"
import {
  selectSubscription,
  selectSubscriptionChangePaymentUrl,
  selectSubscriptionLicensedUsers,
} from "src/store/desktop/subscriptionDetails/selector"
import { selectConduktorUser } from "src/store/system/selector"
import { showModal } from "src/store/system/actions"
import { isValidSubscription } from "src/domain/desktop/subscription"
import { Subscription } from "src/domain/desktop"
import { isSubscriptionExpired } from "src/domain/desktop/subscription"
import { ConduktorUser, User } from "../../../../../../domain/system"
import {
  ModalType,
  UserRoleType,
} from "../../../../../../domain/desktop/common"

const MembersSection: React.FC = () => {
  const dispatch = useDispatch()

  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const licencedUsers: User[] =
    useSelector(selectSubscriptionLicensedUsers) || []
  const availableLicenses: number =
    (subscription?.quantity || 0) - licencedUsers.length

  const changePaymentMethodURL: string | undefined = useSelector(
    selectSubscriptionChangePaymentUrl
  )
  const isValid = (accessLevel?: UserRoleType[]) =>
    isValidSubscription({ subscription, accessLevel, conduktorUser })

  const isExpired: boolean = isSubscriptionExpired(subscription)
  const handleMnagePaymentMethod = () => {
    window.open(changePaymentMethodURL, "_blank")
  }
  return (
    <Fragment>
      <div>
        Members:
        <span className="sub-title m-l-5">
          {(subscription?.quantity || 0) - availableLicenses}
          {(subscription?.quantity || 0) < 99999
            ? " / " + subscription?.quantity
            : ""}
        </span>
        {isValid([UserRoleType.BILLING_USER]) &&
          subscription &&
          subscription.hasPurchaseAllowed &&
          subscription.quantity < 99999 && (
            <Button
              disabled={isExpired}
              className="m-b-2 m-l-10"
              type="primary"
              ghost
              icon={<PlusOutlined />}
              size="small"
              onClick={() => dispatch(showModal(ModalType.INCRESE_LICENSE))}
            >
              Purchase more licenses
            </Button>
          )}
        {isValid([UserRoleType.BILLING_USER]) && changePaymentMethodURL && (
          <Tooltip title="Manage your payment methods">
            <Button
              className="m-l-10"
              disabled={isExpired}
              type="primary"
              ghost
              size="small"
              icon={<CreditCardOutlined />}
              onClick={handleMnagePaymentMethod}
            />
          </Tooltip>
        )}
      </div>
      <div>
        Plan: <span className="sub-title">{subscription?.planName}</span>
      </div>
      {(subscription?.hasOffline || subscription?.hasUnlimited) && (
        <div className="float-left">Addons:</div>
      )}
      {subscription?.hasOffline && (
        <span className="sub-title m-l-5">Offline Feature</span>
      )}
      {subscription?.hasUnlimited && (
        <span className="sub-title m-l-5">Unlimited Feature</span>
      )}
    </Fragment>
  )
}
export default MembersSection
