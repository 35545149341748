import React, { useEffect, useState } from "react"
import { Alert, Card, Row, Space } from "antd"
import { useSubscriptionQuery } from "../../hooks/useSubscriptionQuery"
import { useQuery } from "../../../router/useQuery"
import { cancelSubscriptionApi } from "../../../services"
import { ActionStatus } from "../../../utils"
import { BackToSubscription } from "../../../components/BackToLink"
import { AppConfig } from "../../../core/config"

const SubscriptionTriggerCancelPage: React.FC = () => {
  const query = useQuery()
  const submissionGuid = query.get("submissionGuid")
  const { subscription, backToSubscription } = useSubscriptionQuery()
  const [status, setStatus] = useState<ActionStatus>("pending")

  useEffect(() => {
    if (submissionGuid && subscription?.id) {
      cancelSubscriptionApi({ subscriptionId: subscription.id })
        .then(() => setStatus("success"))
        .catch(() => setStatus("failure"))
    }
  }, [submissionGuid, subscription?.id])

  return (
    <Card className={"card"} bordered={false}>
      <Row className="m-b-10">
        <BackToSubscription onClick={backToSubscription} />
      </Row>
      {status === "pending" && (
        <Alert
          type={"info"}
          message={"We are canceling your subscription..."}
        />
      )}
      {status === "success" && (
        <Alert
          type={"success"}
          message={"Your subscription has been canceled successfully!"}
          description={
            <Space direction="vertical">
              <span>
                You can continue to use Conduktor until the end-of-term of your
                subscription.
              </span>
              <span>
                A small delay could be required to consider this cancellation in
                the subscription management.
              </span>
            </Space>
          }
        />
      )}
      {status === "failure" && (
        <Alert
          type={"error"}
          message={`Sorry, your subscription cannot be canceled!`}
          description={`Please contact the support at ${AppConfig.supportEmail}`}
        />
      )}
    </Card>
  )
}

export default SubscriptionTriggerCancelPage
