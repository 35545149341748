import {
  Action,
  ActionType,
  ApiStatus,
  OAuthUser,
  SentEmail,
  UserLog,
} from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import {
  failureReducer,
  initReducer,
  requestReducer,
  successReducer,
} from "../../../reducers"

export type FetchedOAuthUser = {
  user?: OAuthUser
  emails?: SentEmail[]
  logs?: UserLog[]
  refreshTokens?: string[]
}

export type OAuthUserReducerType = ApiStatus & FetchedOAuthUser

export const defaultState: OAuthUserReducerType = {
  loading: false,
  loaded: false,
  error: undefined,
  type: undefined,
  user: undefined,
  emails: undefined,
  logs: undefined,
  refreshTokens: undefined,
}

function resetOAuthUserStateReducer(): OAuthUserReducerType {
  return defaultState
}

function getOAuthUserSuccessReducer(
  state: OAuthUserReducerType,
  { payload }: Action<FetchedOAuthUser>
): OAuthUserReducerType {
  return {
    ...successReducer(state),
    ...payload,
  }
}

function setOAuthUserLoadingReducer(
  state: OAuthUserReducerType
): OAuthUserReducerType {
  return {
    ...state,
    loading: true,
  }
}

export const usersReducer = createReducer<OAuthUserReducerType>(defaultState, {
  [ActionType.INIT_STORE]: initReducer(defaultState),
  [ActionType.GET_OAUTH_USER_REQUEST]: requestReducer,
  [ActionType.GET_OAUTH_USER_SUCCESS]: getOAuthUserSuccessReducer,
  [ActionType.GET_OAUTH_USER_FAILURE]: failureReducer,
  [ActionType.RESET_OAUTH_USER_STATE]: resetOAuthUserStateReducer,
  [ActionType.SET_OAUTH_USER_MAX_ACTIVATIONS]: setOAuthUserLoadingReducer,
  [ActionType.CLEAR_OAUTH_USER_FP_AND_TOKENS]: setOAuthUserLoadingReducer,
})
