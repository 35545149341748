import { takeLatest, put, all } from "redux-saga/effects"
import { getFeauresApi, getPlansApi, updatePlanApi } from "src/services"
import { Action, ActionType, Feature, Plan } from "src/domain/desktop"
import { parseError } from "src/utils"
import {
  getFeaturesFailure,
  getFeaturesSuccess,
  getPlansFailure,
  getPlansSuccess,
  updatePlanFailure,
  updatePlanSuccess,
} from "./actions"
import { ClientError } from "../../../services/ClientError"

const plansSequence = ["free", "trialing", "professional", "enterprise"]

function* getPlansSaga() {
  try {
    const { plans }: { plans: Plan[] } = yield getPlansApi()
    plans.sort((a, b) => {
      return plansSequence.indexOf(a.name) > plansSequence.indexOf(b.name)
        ? 1
        : plansSequence.indexOf(a.name) < plansSequence.indexOf(b.name)
        ? -1
        : 0
    })
    const { features }: { features: Feature[] } = yield getFeauresApi()
    features.sort((a, b) =>
      a.sequence > b.sequence ? 1 : b.sequence > a.sequence ? -1 : 0
    )

    yield put(
      getPlansSuccess({
        plans,
        features,
      })
    )
  } catch (err) {
    yield put(getPlansFailure(parseError(err as ClientError)))
  }
}

function* getFeaturesSaga() {
  try {
    const { features }: { features: Feature[] } = yield getFeauresApi()
    features.sort((a, b) =>
      a.sequence > b.sequence ? 1 : b.sequence > a.sequence ? -1 : 0
    )

    yield put(getFeaturesSuccess(features))
  } catch (err) {
    yield put(getFeaturesFailure(parseError(err as ClientError)))
  }
}

function* updatePlanSaga({ payload }: Action<Plan>) {
  try {
    yield updatePlanApi(payload)
    const { plans }: { plans: Plan[] } = yield getPlansApi()
    plans.sort((a, b) => {
      return plansSequence.indexOf(a.name) > plansSequence.indexOf(b.name)
        ? 1
        : plansSequence.indexOf(a.name) < plansSequence.indexOf(b.name)
        ? -1
        : 0
    })
    yield put(updatePlanSuccess(plans))
  } catch (err) {
    yield put(updatePlanFailure(parseError(err as ClientError)))
  }
}
export default function* plansSagas() {
  yield all([
    takeLatest(ActionType.GET_PLANS_REQUEST, getPlansSaga),
    takeLatest(ActionType.GET_FEATURES_REQUEST, getFeaturesSaga),
    takeLatest(ActionType.UPDATE_PLAN_REQUEST, updatePlanSaga),
  ])
}
