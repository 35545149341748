import { FC } from "react"
import { Text } from "@conduktor/ui-library"

export const MainTitle: FC = ({ children }) => (
  <Text variant={"h2"}>{children}</Text>
)
export const MainDescription: FC = ({ children }) => (
  <Text css={{ color: "$neutral9" }}>{children}</Text>
)
export const SectionTitle: FC = ({ children }) => (
  <Text variant={"h5"}>{children}</Text>
)
export const SectionDescription: FC = ({ children }) => (
  <Text css={{ color: "$neutral9", fontSize: "$2" }}>{children}</Text>
)
