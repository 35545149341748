import { Flex, styled } from "@conduktor/ui-library"

export const TopicMembersPermissionsPanel = styled(Flex, {
  flexDirection: "column",
  gap: "$2",
  justifyContent: "space-between",
  padding: "$3",
  border: "1px solid $neutral6",
  borderRadius: "$2",
})
