import React, { Fragment } from "react"
import { Table } from "antd"
import { InvoiceEstimate } from "src/domain/desktop"
import { amount } from "src/utils"
import ChargesColumns from "./ChargesColumns"
interface MyPros {
  loading: boolean
  currency?: string
  nextInvoiceEstimate: InvoiceEstimate
}

const RenewalChargesTabPane: React.FC<MyPros> = ({
  nextInvoiceEstimate,
  loading,
  currency,
}) => {
  return (
    <Fragment>
      <label>
        Summary of charges that will be applicable upon Subscription renewal:
      </label>
      <Table
        pagination={false}
        className="m-t-5"
        loading={loading}
        columns={ChargesColumns(currency)}
        dataSource={nextInvoiceEstimate.line_items}
        rowKey="id"
      />
      <h3 className="text-right m-t-10 m-r-10">
        <b>
          Subtotal: {currency} {amount(nextInvoiceEstimate.sub_total)}
        </b>
      </h3>
      {nextInvoiceEstimate.discounts && (
        <div>
          {nextInvoiceEstimate.discounts.map((item, index) => (
            <h4 key={index} className="text-right m-t-10 m-r-10">
              {item.description} ({currency} {amount(item.amount)})
            </h4>
          ))}
        </div>
      )}
      {nextInvoiceEstimate.taxes && (
        <div>
          {nextInvoiceEstimate.taxes.map((item, index) => (
            <h4 key={index} className="text-right m-t-10 m-r-10">
              {item.description} {currency} {amount(item.amount)}
            </h4>
          ))}
        </div>
      )}
      <h3 className="text-right m-t-10 m-r-10">
        <b>
          Total: {currency} {amount(nextInvoiceEstimate.total)}
        </b>
      </h3>
    </Fragment>
  )
}
export default RenewalChargesTabPane
