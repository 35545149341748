import { takeLatest, put, select, all } from "redux-saga/effects"
import { getDomainsApi, updateDomainStatusApi } from "src/services"
import {
  Action,
  ActionType,
  Domain,
  GetItemsApiPayload,
  UpdateDomainStatusApiPayload,
} from "src/domain/desktop"
import { TablePaginationConfig } from "antd"
import { parseError } from "src/utils"
import {
  getDomainsSuccess,
  getDomainsRequest,
  getDomainsFailure,
  updateDomainStatusSuccess,
  updateDomainStatusFailure,
} from "./actions"
import { AppState } from "../../../config/rootStore"
import { selectDomainsFilters, selectDomainsPagination } from "./selector"
import { ClientError } from "src/services/ClientError"

function* getDomainsSaga() {
  try {
    const state: AppState = yield select()

    const pagination: TablePaginationConfig = selectDomainsPagination(state)
    const filters = selectDomainsFilters(state)
    const payload: GetItemsApiPayload = {
      params: {
        page: pagination.current,
        results: pagination.pageSize,
        "status[]":
          filters && filters.status ? filters.status.toString() : undefined,
      },
    }

    const data: {
      totalCount: number
      subscriptionDomains: Domain[]
    } = yield getDomainsApi(payload)

    yield put(getDomainsSuccess(data))
  } catch (err) {
    yield put(getDomainsFailure(parseError(err as ClientError)))
  }
}

function* setDomainsTableConfigSaga() {
  yield put(getDomainsRequest())
}

function* updateDomainStatusSaga({
  payload,
}: Action<UpdateDomainStatusApiPayload>) {
  try {
    yield updateDomainStatusApi(payload)
    yield put(updateDomainStatusSuccess())
  } catch (err) {
    yield put(updateDomainStatusFailure(parseError(err as ClientError)))
  }
}

export default function* DomainsSagas() {
  yield all([
    takeLatest(ActionType.GET_DOMAINS_REQUEST, getDomainsSaga),
    takeLatest(ActionType.SET_DOMAINS_TABLE_CONFIG, setDomainsTableConfigSaga),
    takeLatest(ActionType.UPDATE_DOMAIN_STATUS_REQUEST, updateDomainStatusSaga),
    takeLatest(ActionType.UPDATE_DOMAIN_STATUS_SUCCESS, getDomainsSaga),
  ])
}
