import { ActionType, Feature, Plan } from "src/domain/desktop"

export const getPlansRequest = () => ({
  type: ActionType.GET_PLANS_REQUEST,
})

export const getPlansSuccess = (payload: {
  plans: Array<Plan>
  features: Array<Feature>
}) => ({
  type: ActionType.GET_PLANS_SUCCESS,
  payload,
})

export const getPlansFailure = (payload: string) => ({
  type: ActionType.GET_PLANS_FAILURE,
  payload,
})

export const getFeaturesRequest = () => ({
  type: ActionType.GET_FEATURES_REQUEST,
})

export const getFeaturesSuccess = (payload: Array<Feature>) => ({
  type: ActionType.GET_FEATURES_SUCCESS,
  payload,
})

export const getFeaturesFailure = (payload: string) => ({
  type: ActionType.GET_FEATURES_FAILURE,
  payload,
})

export const updatePlanRequest = (payload: Plan) => ({
  type: ActionType.UPDATE_PLAN_REQUEST,
  payload,
})

export const updatePlanSuccess = (payload: Plan[]) => ({
  type: ActionType.UPDATE_PLAN_SUCCESS,
  payload,
})

export const updatePlanFailure = (payload: string) => ({
  type: ActionType.UPDATE_PLAN_FAILURE,
  payload,
})
