import React, { useState } from "react"
import { useDispatch } from "react-redux"
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
} from "antd"
import SeedDataModal from "./components/SeedDataModal"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import {
  GetChargebeeSubscriptionApiResponse,
  SeedData,
} from "src/domain/desktop"
import { RouteKey } from "src/router/routes"
import {
  createFullSubscriptionApi,
  getChargebeeSubscriptionApi,
} from "src/services"
import { setMessage } from "src/store/system/actions"
import { usePageView } from "src/core/analytics/hooks"
import { MessageType } from "../../../../domain/system"

const initalSeedData: SeedData = {
  company: "",
  billing_user: "",
  technical_users: [],
  subscription: {
    type: "professional",
    plan_name: "",
    quantity: 1,
    chargebee_subscription_id: "",
  },
  licenses: [],
}

const MigrationPage = () => {
  usePageView(RouteKey.ADMIN_MIGRATION)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [seedData, setSeedData] = useState<SeedData>(initalSeedData)
  const [subscriptionStatus, setSubscriptionStatus] = useState(true)
  const [licenseManagers, setLicenseManagers] = useState("")
  const [licenses, setLicenses] = useState("")
  const [seedDataModalVisible, setSeedDataModalVisible] = useState(false)

  const handleFetchChargebeeSubscription = async (
    chargebeeSubscriptionId: string
  ) => {
    if (!chargebeeSubscriptionId) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: "Please input `chargebee subscription id`!",
        })
      )
      return
    }
    setLoading(true)
    try {
      const {
        customer,
        subscription,
        plan,
      }: GetChargebeeSubscriptionApiResponse = await getChargebeeSubscriptionApi(
        chargebeeSubscriptionId
      )
      setSeedData({
        ...seedData,
        billing_user: customer.email || "",
        company: customer.company || "",
        subscription: {
          ...seedData.subscription,
          type:
            plan.meta_data && plan.meta_data.subscription_type
              ? plan.meta_data.subscription_type
              : "enterprise",
          quantity: subscription.plan_quantity,
          subscription_date: subscription.current_term_start
            ? new Date(subscription.current_term_start * 1000)
                .toISOString()
                .substring(0, 10)
            : undefined,
          renewal_date: subscription.next_billing_at
            ? new Date(subscription.next_billing_at * 1000)
                .toISOString()
                .substring(0, 10)
            : undefined,
          plan_name: plan.invoice_name || "",
        },
      })
    } catch (error) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: "Something went wrong!",
        })
      )
    }
    setLoading(false)
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      await createFullSubscriptionApi(seedData)
      setSeedDataModalVisible(false)
      setSeedData(initalSeedData)
      setLicenses("")
      setLicenseManagers("")
      dispatch(
        setMessage({
          type: MessageType.SUCCESS,
          message: "Created successfully!",
        })
      )
    } catch {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: "Something went wrong!",
        })
      )
    }
    setLoading(false)
  }

  const handleChangeSeedData = (prop: keyof SeedData) => (
    event: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent
  ) => {
    const value = event.target.value || event.target.checked
    setSeedData({
      ...seedData,
      [prop]: value,
    })
  }

  const handleValidation = () => {
    if (!seedData.company || seedData.company === "") {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: "Please enter company name",
        })
      )
      return false
    }
    if (!seedData.billing_user || seedData.billing_user === "") {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: "Please enter valid billing user email",
        })
      )
      return false
    }
    if (
      !seedData.subscription.plan_name ||
      seedData.subscription.plan_name === ""
    ) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: "Please enter valid plan name",
        })
      )
      return false
    }
    if (
      !seedData.subscription.chargebee_subscription_id ||
      seedData.subscription.chargebee_subscription_id === ""
    ) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: "Please enter chargebee subscription id",
        })
      )
      return false
    }
    if (subscriptionStatus) {
      if (
        !seedData.subscription.renewal_date ||
        seedData.subscription.renewal_date === ""
      ) {
        dispatch(
          setMessage({
            type: MessageType.ERROR,
            message: "Please set renewal date of subscription",
          })
        )
        return false
      }
    }
    if (!subscriptionStatus) {
      if (
        !seedData.subscription.expiry_date ||
        seedData.subscription.expiry_date === ""
      ) {
        dispatch(
          setMessage({
            type: MessageType.ERROR,
            message: "Please set expiry date of subscription",
          })
        )
        return false
      }
    }

    setSeedData({
      ...seedData,
      technical_users: licenseManagers
        ? licenseManagers.split(",").map((email) => {
            return email.trim()
          })
        : [],
      licenses: licenses
        ? licenses.split(",").map(function (email) {
            return email.trim()
          })
        : [],
    })

    setSeedDataModalVisible(true)
    return true
  }

  return (
    <div className="migration">
      <Card
        className="card"
        title="Chargebee Subscription ID to migrate"
        bordered={false}
      >
        <Row>
          <Col lg={8} md={12} sm={24}>
            <Input.Search
              value={seedData.subscription.chargebee_subscription_id}
              className="m-t-5"
              placeholder="Chargebee subscription id"
              enterButton
              loading={loading}
              onSearch={handleFetchChargebeeSubscription}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSeedData({
                  ...seedData,
                  subscription: {
                    ...seedData.subscription,
                    chargebee_subscription_id: e.target.value,
                  },
                })
              }}
            />
          </Col>
        </Row>
      </Card>

      <Card className="card" title="Billing User Information" bordered={false}>
        <Row gutter={24}>
          <Col className="m-b-12" xl={12} lg={12} md={12} sm={24} xs={24}>
            <label>Company name</label>
            <Input
              value={seedData.company}
              className="m-t-5"
              placeholder="Enter company name"
              onChange={handleChangeSeedData("company")}
            />
          </Col>
          <Col className="m-b-12" xl={12} lg={12} md={12} sm={24} xs={24}>
            <label>Email</label>
            <Input
              value={seedData.billing_user}
              className="m-t-5"
              type="email"
              defaultValue=""
              placeholder="Enter billing user email"
              onChange={handleChangeSeedData("billing_user")}
            />
          </Col>
        </Row>
      </Card>
      <Card className="card" title="Subscription Information" bordered={false}>
        <Row gutter={24}>
          <Col className="m-b-12" xl={12} lg={12} md={12} sm={24} xs={24}>
            <Row>
              <Col xs={15} sm={20} md={16} lg={18} xl={20}>
                <label>Type</label>
                <Select
                  value={seedData.subscription.type}
                  className="m-t-5"
                  style={{ width: "100%" }}
                  onChange={(type: string) => {
                    setSeedData({
                      ...seedData,
                      subscription: {
                        ...seedData.subscription,
                        type,
                      },
                    })
                  }}
                >
                  <Select.Option value="professional">
                    Professional
                  </Select.Option>
                  <Select.Option value="enterprise">Enterprise</Select.Option>
                </Select>
              </Col>
              <Col xs={9} sm={4} md={8} lg={6} xl={4}>
                <label className="p-l-10">Status</label>
                <br />
                <Switch
                  checked={subscriptionStatus}
                  className="m-10"
                  checkedChildren={"Active"}
                  unCheckedChildren={"Expired"}
                  defaultChecked
                  onChange={(e: boolean) => setSubscriptionStatus(e)}
                />
              </Col>
            </Row>
          </Col>
          <Col className="m-b-12" xl={6} lg={6} md={6} sm={12} xs={12}>
            <label>Quantity</label>
            <InputNumber
              value={seedData.subscription.quantity}
              className="m-t-5"
              style={{ width: "100%" }}
              min={1}
              placeholder="Enter quantity"
              onChange={(quantity: number) => {
                setSeedData({
                  ...seedData,
                  subscription: {
                    ...seedData.subscription,
                    quantity,
                  },
                })
              }}
            />
          </Col>
          <Col className="m-b-12" xl={6} lg={6} md={6} sm={12} xs={12}>
            <label>Plan Name</label>
            <Input
              value={seedData.subscription.plan_name}
              className="m-t-5"
              placeholder="Please enter plan name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSeedData({
                  ...seedData,
                  subscription: {
                    ...seedData.subscription,
                    plan_name: event.target.value,
                  },
                })
              }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="m-b-12" xl={12} lg={12} md={12} sm={24} xs={24}>
            <label>Subscription Date</label>
            <Input
              value={seedData.subscription.subscription_date}
              className="m-t-5"
              placeholder="YYYY-MM-DD"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSeedData({
                  ...seedData,
                  subscription: {
                    ...seedData.subscription,
                    subscription_date: event.target.value,
                  },
                })
              }}
            />
          </Col>
          <Col className="m-b-12" xl={12} lg={12} md={12} sm={24} xs={24}>
            {subscriptionStatus ? (
              <label>Renewal Date</label>
            ) : (
              <label>Expiry Date</label>
            )}
            <br />
            {subscriptionStatus ? (
              <Input
                value={seedData.subscription.renewal_date}
                className="m-t-5"
                placeholder="YYYY-MM-DD"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSeedData({
                    ...seedData,
                    subscription: {
                      ...seedData.subscription,
                      renewal_date: event.target.value,
                    },
                  })
                }}
              />
            ) : (
              <Input
                value={seedData.subscription.expiry_date}
                className="m-t-5"
                placeholder="YYYY-MM-DD"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSeedData({
                    ...seedData,
                    subscription: {
                      ...seedData.subscription,
                      expiry_date: event.target.value,
                    },
                  })
                }}
              />
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="m-b-12" xl={12} lg={12} md={12} sm={24} xs={24}>
            <label>License Managers</label>
            <Input.TextArea
              value={licenseManagers}
              placeholder="Enter license managers list with comma separated"
              allowClear
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setLicenseManagers(e.target.value)
              }}
            />
          </Col>
          <Col className="m-b-12" xl={12} lg={12} md={12} sm={24} xs={24}>
            <label>Licenses</label>
            <Input.TextArea
              value={licenses}
              placeholder="Enter licenses with comma separated"
              allowClear
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setLicenses(e.target.value)
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox
              checked={seedData.send_emails}
              onChange={handleChangeSeedData("send_emails")}
            >
              Send Emails (to billing & managers)
            </Checkbox>
          </Col>
        </Row>
        <Button
          className="m-t-15 float-right"
          type="primary"
          onClick={handleValidation}
        >
          Save
        </Button>
      </Card>
      <SeedDataModal
        onClose={() => setSeedDataModalVisible(false)}
        onSave={handleSave}
        seedData={seedData}
        subscriptionStatus={subscriptionStatus}
        visible={seedDataModalVisible}
      />
    </div>
  )
}

export default MigrationPage
