import * as React from "react"
import { Switch } from "react-router-dom"
import { routesDesktop } from "./routesDesktop"
import { isValidRoute } from "src/domain/user"
import { SentryRoute } from "../../core/reporting/sentry"
import NotFound from "../../pages/NotFound"
import { useDispatch, useSelector } from "react-redux"
import { selectConduktorUser } from "../../store/system/selector"
import { ConduktorUser } from "../../domain/system"
import { useEffect } from "react"
import { fetchAllDesktopUserData } from "../../store/system/actions"
import { DesktopLayout } from "../../layouts/Layouts"

export const DesktopRouter = () => {
  const dispatch = useDispatch()
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)

  useEffect(() => {
    dispatch(fetchAllDesktopUserData(false))
  }, [dispatch])

  return (
    <DesktopLayout>
      <Switch>
        {routesDesktop
          .filter((route) => isValidRoute(route, conduktorUser))
          .map((route, index) => (
            <SentryRoute
              path={route.path}
              exact
              key={index}
              component={route.component}
            />
          ))}
        <SentryRoute component={NotFound} />
      </Switch>
    </DesktopLayout>
  )
}
