import {
  DropdownContent,
  DropdownTrigger,
  Flex,
  styled,
  Text,
} from "@conduktor/ui-library"
import { Link } from "react-router-dom"

export const SidebarAside = styled("aside", {
  backgroundColor: "$background",
  borderRight: "1px solid $neutral6",
  position: "sticky",
  height: "100vh",
  top: 0,
  boxSizing: "content-box",
  display: "flex",
  flexDirection: "column",
  width: "16.25rem",

  variants: {
    collapsed: {
      true: {
        width: "4rem",
      },
    },
  },
})

export const SidebarHeader = styled(Flex, {
  padding: "0.75rem",
  alignItems: "center",
  gap: "$2",
  height: "$8",
  boxShadow: "inset 0 -1px 0 0 $colors$neutralA6",
})

export const SidebarHeaderImage = styled("img", {
  objectFit: "cover",
  width: "2.5rem",
  height: "2.5rem",
})

export const disabledStyles = {
  color: "$neutral8",
  pointerEvents: "none",
  cursor: "default",

  "&:hover": {
    backgroundColor: "initial",
  },
}

export const SidebarLink = styled(Link, {
  textDecoration: "none",
  color: "$neutral12",

  "&:hover": {
    color: "initial",
  },

  variants: {
    disabled: {
      true: { ...disabledStyles },
    },
  },
})

export const SidebarExternalLink = styled("a", {
  textDecoration: "none",
  color: "$neutral12",

  "&:hover": {
    color: "initial",
  },

  variants: {
    disabled: {
      true: { ...disabledStyles },
    },
  },
})

export const SidebarListItem = styled("span", {
  padding: "0 .75rem",
  cursor: "pointer",
  borderRadius: "$1",
  display: "flex",
  alignItems: "center",
  fontSize: "$2",
  lineHeight: "$button",
  gap: ".75rem",

  "&:hover": {
    backgroundColor: "$neutral3",
  },

  variants: {
    status: {
      active: {
        color: "white",
        backgroundColor: "$primary9",

        "&:hover": {
          backgroundColor: "$primary9",
        },
      },
      disabled: { ...disabledStyles },
    },
  },
})

export const SidebarListItemIcon = styled("span", {
  fontSize: "$3",
  py: ".75rem",
})

export const SidebarWrapper = styled("div", {
  p: "$4 .75rem .75rem",
  display: "flex",
  flexDirection: "column",
  minHeight: "calc(100vh - 12.375rem)",
  height: "100%",
  gap: "$5",
})

export const SidebarSectionTitle = styled(Text, {
  paddingLeft: ".75rem",
  color: "$neutral9",
})

export const SidebarDropdownContent = styled(DropdownContent, {
  width: "20rem",
})

export const SidebarDropdownTrigger = styled(DropdownTrigger, {
  all: "unset",
  boxSizing: "border-box",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  p: ".75rem",
  cursor: "pointer",
  transition: "background 100ms ease-in-out",
  "& > svg": {
    transition: "color 100ms ease-in-out",
    color: "$neutral9",
  },

  "@hover": {
    "&:hover": {
      background: "$neutral1",

      "& > svg": {
        color: "$neutral12",
      },
    },
  },
})
