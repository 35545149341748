import { RouteConfig } from "src/router/routes"
import { ConduktorUser } from "./system"
import { UserRoleType } from "./desktop/common"

export const getMemberNameByAccessLevel = (accessLevel?: number) => {
  switch (accessLevel) {
    case UserRoleType.BILLING_USER:
      return "Owner"
    case UserRoleType.SUBSCRIPTION_ADMIN:
      return "Admin"
    case UserRoleType.TECHNICAL_MANAGER:
      return "Team Manager"
    default:
      return "Member"
  }
}

export const isValidUser = (
  conduktorUser?: ConduktorUser,
  roles?: UserRoleType[]
): boolean => {
  if (!conduktorUser?.roleId) return false
  if (roles === undefined) return true
  if (conduktorUser.roleId === UserRoleType.ADMIN) {
    return true
  }
  return roles.includes(conduktorUser.roleId)
}

export const isValidRoute = (
  route: RouteConfig,
  conduktorUser: ConduktorUser
) => {
  const isAdmin: boolean = isValidUser(conduktorUser, [UserRoleType.ADMIN])
  if ((route.forAdmin && !isAdmin) || (route.forAdmin === false && isAdmin))
    return false
  if (route.forConduktorUser && conduktorUser.id === undefined) return false
  return true
}
