import { AppState } from "@auth0/auth0-react/dist/auth0-provider"
import { history } from "../store"
import { AppConfig } from "./config"
import { LogoutOptions } from "@auth0/auth0-spa-js"

const sessionKey = "conduktor-freshtoken"

export const getAuthToken = () => localStorage.getItem(sessionKey) || ""
export const clearAuthToken = () => localStorage.removeItem(sessionKey)
export const setAuthToken = (token: string) =>
  localStorage.setItem(sessionKey, token)

export const onRedirectCallback = (appState: AppState) => {
  //queued so /callback has time to mount
  setTimeout(() => history.replace(appState?.returnTo || "/"), 0)
}

export const performLogout = (logout: (options?: LogoutOptions) => void) => {
  clearAuthToken()
  logout({ returnTo: AppConfig.auth0.logoutReturnTo })
}
