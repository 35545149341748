import { FC } from "react"

import { SidebarAside } from "./sidebar.styles"
import { selectSidebarCollapsed } from "../../store/system/selector"
import { useSelector } from "react-redux"

export const Sidebar: FC = ({ children }) => {
  const collapsed = useSelector(selectSidebarCollapsed)

  return <SidebarAside collapsed={collapsed}>{children}</SidebarAside>
}
