import {
  PredefinedRoleName,
  PredefinedRolesName,
  PredefinedRolesPermissionsLevel,
  RoleName,
} from "src/domain/desktop"
import { TeamMember } from "../../../../domain/desktop/common"
import { length } from "../../../../utils"

export function selectMostSpecificRole(roles: RoleName[]): RoleName {
  const anyCustomRole = roles.find(
    (role) => !PredefinedRolesName.includes(role as PredefinedRoleName)
  )
  if (anyCustomRole) {
    return anyCustomRole
  }
  return roles.sort(
    (a, b) =>
      PredefinedRolesPermissionsLevel[b as PredefinedRoleName] -
      PredefinedRolesPermissionsLevel[a as PredefinedRoleName]
  )[0]
}

export function membersCountDescription(
  isAllUsersGroup: boolean,
  teamMembers?: TeamMember[]
) {
  if (isAllUsersGroup) {
    return `(contains all users)`
  }
  const count = length(teamMembers)
  if (count < 2) {
    return `(${count} member)`
  }
  return `(${count} members)`
}
