import { Button, Flex, Skeleton, Text } from "@conduktor/ui-library"
import {
  AccessControlAddPanel,
  AccessControlEmptyState,
} from "src/pages/desktop/ClusterAccessControl/accessControl/AccessControl.styled"
import { SearchSelect } from "src/components/EditableCell/Select/SearchSelect"
import { Select } from "antd"
import { isEmpty, isObjectEmpty, orEmpty } from "src/utils"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  selectAccessControlGroups,
  selectAccessControlLoaded,
  selectAccessControlUsers,
} from "src/store/desktop/accessControl/selector"
import {
  selectSubscriptionLicensedUsers,
  selectSubscriptionTeams,
} from "src/store/desktop/subscriptionDetails/selector"
import { useSubscriptionQuery } from "src/pages/hooks/useSubscriptionQuery"
import { updateAccessControlMemberSuccess } from "src/store/desktop/accessControl/actions"
import {
  AccessControlMemberApiPayload,
  allUsersGroupId,
  allUsersGroupName,
} from "src/domain/desktop"
import {
  upsertAccessControlGroupApi,
  upsertAccessControlUserApi,
} from "src/services"
import { setErrorMessage, setSuccessMessage } from "src/store/system/actions"
import { Team } from "src/domain/desktop/common"
import { User } from "src/domain/system"
import { membersCountDescription } from "./utils"
import { selectSelectedCluster } from "src/store/desktop/clusters/selector"
import {
  createGroupId,
  createUserId,
  parseUserOrGroupId,
} from "../UserOrGroupId"
import { MemberIcon } from "../MemberIcon"

export const AddMember = () => {
  const dispatch = useDispatch()
  const { subscription } = useSubscriptionQuery()
  const [selectedMember, setSelectedMember] = useState<string>()
  const [isCreating, setIsCreating] = useState<boolean>(false)

  const licencedUsers = useSelector(selectSubscriptionLicensedUsers)
  const teams = useSelector(selectSubscriptionTeams)
  const loaded = useSelector(selectAccessControlLoaded)
  const users = useSelector(selectAccessControlUsers)
  const groups = useSelector(selectAccessControlGroups)
  const cluster = useSelector(selectSelectedCluster)

  if (!loaded) {
    return <Skeleton />
  }

  const unmanagedUsers = orEmpty(licencedUsers).filter(
    (user) => user.email && !Boolean(users[user.email])
  )
  const unmanagedGroups = orEmpty(teams).filter(
    (team) => team.id && !Boolean(groups[team.id])
  )
  const hasAllUsersPseudoGroup = !isEmpty(groups[allUsersGroupId])

  const createAccessControl = async () => {
    try {
      const userOrGroup = parseUserOrGroupId(selectedMember)

      if (!userOrGroup) {
        return
      }

      const updating: AccessControlMemberApiPayload = {
        id: userOrGroup.id,
        clusterId: cluster!.clusterId!,
        subscriptionId: subscription!.id,
        roles: ["Viewer"],
        type: userOrGroup.type,
      }

      setIsCreating(true)
      if (userOrGroup.type === "group") {
        await upsertAccessControlGroupApi(updating)
      } else {
        await upsertAccessControlUserApi(updating)
      }
      dispatch(updateAccessControlMemberSuccess(updating))
      dispatch(setSuccessMessage(`Access control assigned`))
      setSelectedMember(undefined)
    } catch (e) {
      dispatch(setErrorMessage(`Error while assigning this access control`))
    } finally {
      setIsCreating(false)
    }
  }

  return (
    <AccessControlAddPanel>
      <Text css={{ fontWeight: "$semi", fontSize: "$1" }}>
        Add Users or Groups
      </Text>
      <Flex gap={3}>
        <SearchSelect
          size={"large"}
          allowClear
          disabled={isCreating}
          value={selectedMember}
          onChange={setSelectedMember}
          style={{ width: "100%" }}
          placeholder="Search for Users or Groups"
        >
          {!hasAllUsersPseudoGroup && createAllUsersGroupOption()}
          {unmanagedGroups?.map((group) => createGroupOption(group))}
          {unmanagedUsers?.map((user) => createUserOption(user))}
        </SearchSelect>

        <Button
          variant={"primary"}
          loading={isCreating}
          onClick={createAccessControl}
        >
          Add
        </Button>
      </Flex>

      {isObjectEmpty(users) && isObjectEmpty(groups) && (
        <AccessControlEmptyState>
          <Text>No one has access.</Text>
          <Text>
            Add users or turn off Access Control to grant access to the Cluster.
          </Text>
        </AccessControlEmptyState>
      )}
    </AccessControlAddPanel>
  )
}

function createAllUsersGroupOption() {
  return (
    <Select.Option value={createGroupId(allUsersGroupId)} title={"All"}>
      <Flex align={"center"} gap={2}>
        <MemberIcon type={"group"} />
        <b>{allUsersGroupName}</b>
        {membersCountDescription(true)}
      </Flex>
    </Select.Option>
  )
}

function createGroupOption(group: Team) {
  return (
    <Select.Option
      value={createGroupId(String(group.id))}
      title={group.name}
      key={group.id}
    >
      <Flex align={"center"} gap={2}>
        <MemberIcon type={"group"} />
        <b>{group.name}</b>
        {membersCountDescription(false, group.teamMembers)}
      </Flex>
    </Select.Option>
  )
}

function createUserOption(user: User) {
  return (
    <Select.Option
      key={user.id}
      value={createUserId(user.email!)}
      title={user.email}
    >
      <Flex align={"center"} gap={2}>
        <MemberIcon type={"user"} /> {user.email}
      </Flex>
    </Select.Option>
  )
}
