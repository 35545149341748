import DeviceList from "./components/DeviceList"
import { RouteKey } from "src/router/routes"
import { usePageView } from "src/core/analytics/hooks"

const DevicesPage = () => {
  usePageView(RouteKey.DEVICES)

  return (
    <div className="devices m-t-20">
      <p className="hint-text">
        Here are the devices you&#39;ve logged in with Conduktor Desktop.
        <br />
        If you have changed recently and Conduktor won&#39;t let you in, you can
        remove your old devices here.
      </p>
      <DeviceList />
    </div>
  )
}

export default DevicesPage
