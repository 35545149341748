import { Plan } from "src/domain/desktop"
import { techClient } from "../axiosClients"

export const getPlansApi = async () => {
  const { data } = await techClient.get(`plans`)
  return data
}

export const getFeauresApi = async () => {
  const { data } = await techClient.get(`features`)
  return data
}

export const updatePlanApi = async (payload: Plan) => {
  const { data } = await techClient.put(`plans/${payload.name}`, payload)
  return data
}
