export interface ParsedFeatures {
  Acls?: Feature[]
  Brokers?: Feature[]
  Clusters?: Feature[]
  "Consumer Groups"?: Feature[]
  Consumers?: Feature[]
  Global?: Feature[]
  "Kafka Connect"?: Feature[]
  Ksql?: Feature[]
  Kstreams?: Feature[]
  Monitoring?: Feature[]
  Producers?: Feature[]
  SchemaRegistry?: Feature[]
  Topics?: Feature[]
  Zookeeper?: Feature[]
}

export type Group = keyof ParsedFeatures
// "Acls" | "Brokers" | "Clusters" |
// "Consumer Groups" | "Consumers" |
// "Global" | "Kafka Connect" | "Ksql" |
// "Kstreams" | "Monitoring" | "Producers" |
// "SchemaRegistry" | "Topics" |  "Zookeeper";
export interface Plan {
  name: string
  features: Record<string, string | number | undefined>
}

export interface Feature {
  allowedInRbac?: boolean
  code: string
  description?: string
  group?: Group
  name?: string
  sequence: number
  type?: string
}
export interface Plans {
  plans: Array<Plan>
  features: Array<Feature>
}

export enum PlansActionType {
  GET_PLANS_REQUEST = "PLANS/GET_PLANS_REQUEST",
  GET_PLANS_SUCCESS = "PLANS/GET_PLANS_SUCCESS",
  GET_PLANS_FAILURE = "PLANS/GET_PLANS_FAILURE",
  UPDATE_PLAN_REQUEST = "PLANS/UPDATE_PLAN_REQUEST",
  UPDATE_PLAN_SUCCESS = "PLANS/UPDATE_PLAN_SUCCESS",
  UPDATE_PLAN_FAILURE = "PLANS/UPDATE_PLAN_FAILURE",
  GET_FEATURES_REQUEST = "PLANS/GET_FEATURES_REQUEST",
  GET_FEATURES_SUCCESS = "PLANS/GET_FEATURES_SUCCESS",
  GET_FEATURES_FAILURE = "PLANS/GET_FEATURES_FAILURE",
}
