import React from "react"
import { Tabs } from "antd"
import ImmediateChargesTabPane from "./ImmediateChargesTabPane"
import SubscriptionInfoTabPane from "./SubscriptionInfoTabPane"
import RenewalChargesTabPane from "./RenewalChargesTabPane"
import {
  ChargeBeeSubscription,
  IncreaseLicnesesFormValues,
  InvoiceEstimate,
  LineItem,
} from "src/domain/desktop"

const { TabPane } = Tabs
interface MyPros {
  values: IncreaseLicnesesFormValues
  currentInvoiceEstimate: InvoiceEstimate
  loading: boolean
  amountDue: number
  currency?: string
  nextInvoiceEstimate: InvoiceEstimate
  chargeBeeSubscription: ChargeBeeSubscription
  currentInvoiceLineItems: LineItem[]
  subTotal: number
  totalAmount: number
}

const IncreaseLicensesModalBodyTabs: React.FC<MyPros> = ({
  values,
  currentInvoiceEstimate,
  loading,
  amountDue,
  currency,
  nextInvoiceEstimate,
  chargeBeeSubscription,
  currentInvoiceLineItems,
  subTotal,
  totalAmount,
}) => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane key="1" tab="Subscription Info">
        <SubscriptionInfoTabPane
          key="1"
          values={values}
          currentInvoiceEstimate={currentInvoiceEstimate}
          currency={currency}
          chargeBeeSubscription={chargeBeeSubscription}
        />
      </TabPane>
      <TabPane key="2" tab="Immediate Charges" force-render>
        <ImmediateChargesTabPane
          currentInvoiceEstimate={currentInvoiceEstimate}
          loading={loading}
          amountDue={amountDue}
          currency={currency}
          currentInvoiceLineItems={currentInvoiceLineItems}
          subTotal={subTotal}
          totalAmount={totalAmount}
        />
      </TabPane>
      <TabPane key="3" tab="Renewal Charges" force-render>
        <RenewalChargesTabPane
          key="3"
          nextInvoiceEstimate={nextInvoiceEstimate}
          loading={loading}
          currency={currency}
        />
      </TabPane>
    </Tabs>
  )
}

export default IncreaseLicensesModalBodyTabs
