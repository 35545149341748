import React, { useState } from "react"
import {
  Permission,
  PredefinedRoleName,
  PredefinedRolesName,
  Role,
  RoleName,
} from "../../../../domain/desktop"
import { useDispatch, useSelector } from "react-redux"
import { selectSubscription } from "../../../../store/desktop/subscriptionDetails/selector"
import { upsertRoleRequest } from "../../../../store/desktop/subscriptions/actions"
import { difference, union } from "lodash"
import { upsertSubscriptionRoleApi } from "../../../../services"
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../../../store/system/actions"
import {
  Checkbox,
  CheckFarIcon,
  Flex,
  MinusFarIcon,
} from "@conduktor/ui-library"
import { CenteredSpinner } from "../../../../components/Spinner"

export type GroupLine = {
  key: string
  name: string
  isGroup: true
  children?: PermLine[]
  value: GroupValue
}

export type PermLine = {
  key: string
  permission: Permission
  isGroup: false
  value: PermValue
}

type GroupCount = {
  count: number
  max: number
}

export type Line = GroupLine | PermLine
export type PermValue = Record<RoleName, boolean>
export type GroupValue = Record<RoleName, GroupCount>

interface Props {
  role: Role
  line: Line
}

type PermStatus = "checked" | "unchecked" | "partial"

export const RoleCell: React.FC<Props> = ({ line, role }) => {
  const dispatch = useDispatch()
  const subscription = useSelector(selectSubscription)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const updateAsPerm = async (checked: boolean) => {
    const permLine = (line as PermLine).permission
    return updateRolePermissions(checked, permLine.codes)
  }

  const updateAsPermGroup = (checked: boolean) => {
    const perms = (line as GroupLine).children || []
    const codes = perms.flatMap((child) => child.permission.codes)
    return updateRolePermissions(checked, codes)
  }

  const updateRolePermissions = async (checked: boolean, codes: string[]) => {
    if (!subscription) {
      return
    }
    setIsUpdating(true)
    const newRole = {
      ...role,
      permissions: checked
        ? union(role.permissions, codes)
        : difference(role.permissions, codes),
    }
    try {
      dispatch(upsertRoleRequest(subscription.id, newRole))
      await upsertSubscriptionRoleApi({
        subscriptionId: subscription.id,
        role: newRole,
      })
      dispatch(setSuccessMessage(`Role "${role.name}" updated`))
    } catch (e) {
      // revert in case of error
      dispatch(upsertRoleRequest(subscription.id, role))
      dispatch(setErrorMessage(`Role "${role.name}" not updated`))
    } finally {
      setIsUpdating(false)
    }
  }

  if (isUpdating) {
    return <CenteredSpinner size={"small"} />
  }

  if (PredefinedRolesName.includes(role.name as PredefinedRoleName)) {
    if (line.isGroup) {
      return <PermCell status={groupStatus(line.value[role.name])} />
    }
    return <PermCell status={line.value[role.name] ? "checked" : "unchecked"} />
  }

  if (line.isGroup) {
    return (
      <PermCell
        status={groupStatus(line.value[role.name])}
        onChange={updateAsPermGroup}
      />
    )
  }
  return (
    <PermCell
      status={line.value[role.name] ? "checked" : "unchecked"}
      onChange={updateAsPerm}
    />
  )
}

const groupStatus = (value: { count: number; max: number }): PermStatus => {
  if (value.count === 0) {
    return "unchecked"
  }
  if (value.count < value.max) {
    return "partial"
  }
  return "checked"
}

const PermCell: React.FC<{
  status: PermStatus
  onChange?: (status: boolean) => void
}> = ({ status, onChange }) => {
  return (
    <Flex justify={"center"}>
      <Checkbox
        disabled={!Boolean(onChange)}
        onClick={() => onChange && onChange(status !== "checked")}
        checked={status !== "unchecked"}
        icon={status === "checked" ? <CheckFarIcon fr /> : <MinusFarIcon fr />}
      />
    </Flex>
  )
}
