import moment from "moment"

export const MINUTES_PER_DAY = 24 * 60

export const formatDate = (
  date: number | string | Date | undefined,
  format = "YYYY-MM-DD"
) => {
  if (!date) return ""
  return moment(date).format(format)
}

export const minToDays = (value?: number) => {
  if (value) {
    return Math.floor(value / MINUTES_PER_DAY)
  }
  return 0
}

export const momentFromNow = (value?: number | string | Date) => {
  if (!value) {
    return ""
  }
  return moment(value).fromNow()
}

export const daysFromNow = (value?: number | string | Date) => {
  if (!value) {
    return ""
  }
  return moment().diff(moment(value), "days")
}

export const truncate = (value?: string, length?: number, sep = "...") => {
  if (value && length) {
    return value.substr(0, length - 1) + (value.length > length ? sep : "")
  }
  return "..."
}

export const truncateMiddle = (
  value?: string,
  length?: number,
  sep = "..."
) => {
  if (value && length) {
    if (value.length <= length) {
      return value
    } else {
      return (
        value.substr(0, length / 2) +
        sep +
        value.substr(value.length - length / 2)
      )
    }
  }
  return "..."
}

export const amount = (data?: number | string) => {
  if (data) {
    return (+data / 100).toFixed(2)
  }
  return "-"
}

export const fingerprintUserPreview = (f: string) =>
  truncate(f.split(".")[0], 8, "")
export const fingerprintMachinePreview = (f: string) =>
  truncate(f.split(".")[1], 8, "")

export const slugify = (value = "") => {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;"
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------"
  const p = new RegExp(a.split("").join("|"), "g")

  return value
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}
