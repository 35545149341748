import { LayoutHeader } from "../../layouts/layouts.styles"
import React from "react"
import { Flex } from "@conduktor/ui-library"

export const Header: React.FC = () => {
  return (
    <LayoutHeader>
      <Flex align="center" css={{ color: "grey", background: "#af8", padding: 5 }}>
        Good news! We’re phasing out Desktop to bring you something even better! Contact
        <a
          target="_blank"
          rel="noreferrer"
          href="mailto:support@conduktor.io"
        >
          &nbsp;our support team&nbsp;
        </a>
        urgently for details.
      </Flex>
    </LayoutHeader>
  )
}
