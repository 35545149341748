import {
  Flex,
  PencilIcon,
  Pill,
  Text,
  TrashcanIcon,
} from "@conduktor/ui-library"
import React, { FC } from "react"
import { useSelector } from "react-redux"
import { ButtonIcon } from "src/components/Icon/ButtonIcon"
import {
  isUserMember,
  TopicControlMember,
} from "src/domain/desktop/topicPermission"
import { TeamsById } from "src/domain/desktop/common"
import { selectSubscriptionTeamsById } from "src/store/desktop/subscriptionDetails/selector"
import { groupName } from "../UserOrGroupId"
import { emailLogin, isEmpty } from "src/utils"
import { MemberIcon } from "../MemberIcon"

interface TopicMembersDisplayProps {
  onEdit: () => void
  onRemove: () => void
  members: TopicControlMember[]
}

export const TopicMembersDisplay: FC<TopicMembersDisplayProps> = ({
  onEdit,
  onRemove,
  members,
}) => {
  const teamsById = useSelector(selectSubscriptionTeamsById)
  const [first, second, third, ...others] = members

  return (
    <>
      <Flex gap={1} align={"center"} css={{ flex: 1 }}>
        {[first, second, third].filter(Boolean).map(tagRender(teamsById))}
        {!isEmpty(others) && (
          <Text css={{ color: "$neutral9" }}>+{others.length}</Text>
        )}
      </Flex>

      <Flex gap={3}>
        <ButtonIcon title={"Edit"} onClick={onEdit}>
          <PencilIcon fr />
        </ButtonIcon>
        <ButtonIcon title={"Remove"} onClick={onRemove}>
          <TrashcanIcon fr />
        </ButtonIcon>
      </Flex>
    </>
  )
}

function tagRender(teamsById: TeamsById) {
  return function MemberTag(member: TopicControlMember) {
    if (isUserMember(member)) {
      return (
        <Pill key={member.userEmail} color={"slate"} css={{ mr: "$1" }}>
          <MemberIcon type={"user"} /> {emailLogin(member.userEmail)}
        </Pill>
      )
    }

    return (
      <Pill key={member.groupName} color={"grass"} css={{ mr: "$1" }}>
        <MemberIcon type={"group"} /> {groupName(teamsById, member.groupName)}
      </Pill>
    )
  }
}
