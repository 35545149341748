import { Select } from "@conduktor/ui-library"
import { RoleName } from "src/domain/desktop"

interface Props {
  disabled?: boolean
  disableOwner?: boolean
  value: RoleName
  onChange: (value: RoleName) => void
  options?: RoleName[]
}

export const RoleSelect = ({
  disabled,
  disableOwner,
  value,
  onChange,
  options = [],
}: Props) => {
  return (
    <Select
      disabled={disabled}
      value={value}
      css={{ width: "12em", backgroundColor: "white" }}
      onChange={(e) => onChange(e.target.value)}
    >
      {options?.map((role) => (
        <option
          key={role}
          value={role}
          disabled={disableOwner && role === "Owner"}
        >
          {role}
        </option>
      ))}
    </Select>
  )
}
