import { FunctionComponent } from "react"

export interface SidebarMenuSubItem {
  name: string
  key: string
  path?: string
  icon: JSX.Element
}

export interface SidebarMenuItem extends SidebarMenuSubItem {
  forBillingUser?: boolean
  forBillingUserOnly?: boolean
  isMembersMenu?: boolean
}

export interface RouteConfig {
  key: RouteKey
  path: string
  title?: string | ((params: Record<string, string>) => string)
  forAdmin?: boolean
  forConduktorUser?: boolean
  exact?: boolean
  component: FunctionComponent
}

export enum RouteKey {
  NOT_FOUND = "NOT_FOUND",
  HOME = "home",
  CLUSTERS = "clusters",
  CLUSTERS_SHORTCUT = "clusters.shortcut",
  CLUSTER_CONTROL = "cluster",
  TOPIC_CONTROL_CREATION = "topicControl.create",
  TOPIC_CONTROL_UPDATE = "topicControl.update",
  DEVICES = "devices",
  SUBSCRIPTIONS = "subscriptions",
  SUBSCRIPTION_CANCEL = "cancelSubscription",
  SUBSCRIPTION_CANCEL_TRIGGER = "cancelTriggerSubscription",
  MEMBERS = "members",
  LICENSES = "licenses",
  LICENSE_MANAGEMENT = "license_management",
  USER_GROUPS = "user_groups",
  ADMIN = "admin",
  ADMIN_ADMINS = "admin.admins",
  ADMIN_AUTO_ASSIGN_DOMAINS = "admin.autoAssignDomains",
  ADMIN_DOMAINS = "admin.domains",
  ADMIN_LAST_SIGNUPS = "admin.lastSignups",
  ADMIN_LOGS = "admin.logs",
  ADMIN_MIGRATION = "admin.migration",
  ADMIN_PLANS_CONFIG = "admin.plans_configuration",
  ADMIN_TRANSFERT_LICENSES = "admin.transfer_licenses",
  ADMIN_USERS = "admin.users",
}
