import { Tooltip, TooltipContent, TooltipTrigger } from "@conduktor/ui-library"
import React, { FC } from "react"

import { SidebarListItem, SidebarListItemIcon } from "./sidebar.styles"
import { SidebarItemProps } from "./types"
import { useSelector } from "react-redux"
import { selectSidebarCollapsed } from "../../store/system/selector"

export const SidebarItem: FC<SidebarItemProps> = ({
  active,
  icon,
  title,
  disabled,
  onClick,
  children,
}) => {
  const collapsed = useSelector(selectSidebarCollapsed)

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <SidebarListItem
          status={active ? "active" : disabled ? "disabled" : undefined}
          onClick={onClick}
        >
          <SidebarListItemIcon>{icon}</SidebarListItemIcon>
          {!collapsed && children}
        </SidebarListItem>
      </TooltipTrigger>
      {collapsed && <TooltipContent side="right">{title}</TooltipContent>}
    </Tooltip>
  )
}
