import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import { Input } from "antd"
import {
  AddLicenseModalFormValues,
  AddLicenseModalLicenseData,
  Subscription,
} from "src/domain/desktop"

const { TextArea } = Input
interface MyProps {
  licenseData?: AddLicenseModalLicenseData
  values: AddLicenseModalFormValues
  onChange: Function
}

const AddLicenseModalBody: React.FC<MyProps> = ({
  licenseData,
  values,
  onChange,
}) => {
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  return (
    <Fragment>
      <Input
        value={values.name}
        className="m-t-10"
        addonBefore="Name"
        disabled={!!licenseData?.id}
        placeholder="John Doe"
        onChange={onChange("name")}
      />
      <Input
        value={values.email}
        className="m-t-15"
        addonBefore="Email"
        disabled={!!licenseData?.id}
        placeholder="john@mycompany.com"
        onChange={onChange("email")}
      />
      {subscription?.hasOffline &&
        subscription.hasUnlimited &&
        !!licenseData?.id && (
          <Input
            value={values.offlineName}
            className="m-t-15"
            addonBefore="Machine Name"
            placeholder="My Offline Computer"
            onChange={onChange("offlineName")}
          />
        )}
      {subscription?.hasOffline && !!licenseData?.id && (
        <TextArea
          value={values.offlineCode}
          rows={18}
          className="m-t-15"
          placeholder="Paste the Conduktor code generated by this user to add it as offline member"
          onChange={onChange("offlineCode")}
        />
      )}
    </Fragment>
  )
}

export default AddLicenseModalBody
