import {
  Action,
  ActionType,
  ApiStatus,
  Domain,
  Invoice,
  RemovedUserCount,
  Subscription,
  SubscriptionDetails,
} from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import {
  failureReducer,
  initReducer,
  requestReducer,
  successReducer,
} from "../../reducers"
import { User } from "../../../domain/system"
import { Team, UserRoleType } from "../../../domain/desktop/common"

export type SubscriptionDetailsReducerType = ApiStatus & SubscriptionDetails

export const defaultState: SubscriptionDetailsReducerType = {
  loading: false,
  error: undefined,
  type: undefined,
  loaded: false,

  subscription: undefined,
  billingUsers: [],
  licenseManagers: [],
  teams: [],
  domainList: [],
  adminsOfSubscription: [],
  changePaymentMethodURL: "",
  licensedUsers: [],
  removedUsers: [],
  removedUserCounts: [],
  invoices: [],
  selectedUser: undefined,
}

function clearSubscriptionStateReducer(): SubscriptionDetailsReducerType {
  return defaultState
}

function getSubscriptionDetailsSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<SubscriptionDetails>
) {
  return {
    ...state,
    ...payload,
    loading: false,
  }
}

function setSubscriptionSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Subscription>
) {
  return {
    ...state,
    subscription: payload,
    loading: false,
  }
}

function getLicensedUsersSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Array<User>>
) {
  return {
    ...state,
    licensedUsers: payload,
    loading: false,
  }
}

function getRemovedUsersSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Array<User>>
) {
  return {
    ...state,
    removedUsers: payload,
    loading: false,
  }
}

function getRemovedUserCountsSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Array<RemovedUserCount>>
) {
  return {
    ...state,
    removedUserCounts: payload,
    loading: false,
  }
}

function getInvoicesSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Array<Invoice>>
) {
  return {
    ...state,
    invoices: payload,
    loading: false,
  }
}

function createLicenseManagerSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Array<User>>
) {
  return {
    ...state,
    licenseManagers: payload,
    loading: false,
    loaded: true,
  }
}

function createBillingUserSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Array<User>>
) {
  return {
    ...state,
    billingUsers: payload,
    loading: false,
    loaded: true,
  }
}

function setTeamsSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Array<Team>>
) {
  return {
    ...state,
    loading: false,
    error: undefined,
    loaded: true,
    teams: payload,
  }
}

function deleteBillingUserSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Array<User>>
) {
  return {
    ...state,
    loading: false,
    error: undefined,
    loaded: true,
    billingUsers: payload,
  }
}

function deleteAdminOfSubscriptionSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Array<User>>
) {
  return {
    ...state,
    loading: false,
    error: undefined,
    loaded: true,
    adminsOfSubscription: payload,
  }
}

function deleteLicenseManagerSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Array<User>>
) {
  return {
    ...state,
    loading: false,
    error: undefined,
    loaded: true,
    licenseManagers: payload,
  }
}

function deleteLicensedUserSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Array<User>>
) {
  return {
    ...state,
    loading: false,
    error: undefined,
    loaded: true,
    licensedUsers: payload,
  }
}

function updateUserSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<User>
) {
  if (payload.accessLevel === UserRoleType.BILLING_USER && state.billingUsers) {
    const index = state.billingUsers?.findIndex((e) => e.id === payload.id)
    const billingUsers = [...state.billingUsers]
    if (index > -1) {
      billingUsers[index] = payload
    }
    return {
      ...state,
      loading: false,
      error: undefined,
      loaded: true,
      billingUsers,
    }
  }
  if (
    payload.accessLevel === UserRoleType.SUBSCRIPTION_ADMIN &&
    state.adminsOfSubscription
  ) {
    const index = state.adminsOfSubscription?.findIndex(
      (e) => e.id === payload.id
    )
    const adminsOfSubscription = [...state.adminsOfSubscription]
    if (index > -1) {
      adminsOfSubscription[index] = payload
    }
    return {
      ...state,
      loading: false,
      error: undefined,
      loaded: true,
      adminsOfSubscription,
    }
  }
  if (
    payload.accessLevel === UserRoleType.TECHNICAL_MANAGER &&
    state.licenseManagers
  ) {
    const index = state.licenseManagers?.findIndex((e) => e.id === payload.id)
    const licenseManagers = [...state.licenseManagers]
    if (index > -1) {
      licenseManagers[index] = payload
    }
    return {
      ...state,
      loading: false,
      error: undefined,
      loaded: true,
      licenseManagers,
    }
  }
  return {
    ...state,
  }
}

function setDomainsSuccessReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<Array<Domain>>
) {
  return {
    ...state,
    loading: false,
    error: undefined,
    loaded: true,
    domainList: payload,
  }
}

function selectUserReducer(
  state: SubscriptionDetailsReducerType,
  { payload }: Action<User>
) {
  return {
    ...state,
    selectedUser: payload,
  }
}

export const subscriptionDetailsReducer = createReducer<SubscriptionDetailsReducerType>(
  defaultState,
  {
    [ActionType.INIT_STORE]: initReducer(defaultState),
    [ActionType.GET_SUBSCRIPTION_DETAILS_REQUEST]: requestReducer,
    [ActionType.GET_SUBSCRIPTION_DETAILS_SUCCESS]: getSubscriptionDetailsSuccessReducer,
    [ActionType.GET_SUBSCRIPTION_DETAILS_FAILURE]: failureReducer,
    [ActionType.GET_SUBSCRIPTION_REQUEST]: requestReducer,
    [ActionType.GET_SUBSCRIPTION_SUCCESS]: setSubscriptionSuccessReducer,
    [ActionType.GET_SUBSCRIPTION_FAILURE]: failureReducer,
    [ActionType.CLEAR_SUBSCRIPTION_STATE_REQUEST]: clearSubscriptionStateReducer,
    [ActionType.UPDATE_SUBSCRIPTION_REQUEST]: requestReducer,
    [ActionType.UPDATE_SUBSCRIPTION_SUCCESS]: setSubscriptionSuccessReducer,
    [ActionType.UPDATE_SUBSCRIPTION_FAILURE]: failureReducer,
    [ActionType.GET_LICENCED_USERS_REQUEST]: requestReducer,
    [ActionType.GET_LICENCED_USERS_SUCCESS]: getLicensedUsersSuccessReducer,
    [ActionType.GET_LICENCED_USERS_FAILURE]: failureReducer,
    [ActionType.GET_REMOVED_USERS_REQUEST]: requestReducer,
    [ActionType.GET_REMOVED_USERS_SUCCESS]: getRemovedUsersSuccessReducer,
    [ActionType.GET_REMOVED_USERS_FAILURE]: failureReducer,
    [ActionType.GET_REMOVED_USER_COUNTS_REQUEST]: requestReducer,
    [ActionType.GET_REMOVED_USER_COUNTS_SUCCESS]: getRemovedUserCountsSuccessReducer,
    [ActionType.GET_REMOVED_USER_COUNTS_FAILURE]: failureReducer,
    [ActionType.GET_INVOICES_REQUEST]: requestReducer,
    [ActionType.GET_INVOICES_SUCCESS]: getInvoicesSuccessReducer,
    [ActionType.GET_INVOICES_FAILURE]: failureReducer,
    [ActionType.CREATE_LICENSE_MANAGER_REQUEST]: requestReducer,
    [ActionType.CREATE_LICENSE_MANAGER_SUCCESS]: createLicenseManagerSuccessReducer,
    [ActionType.CREATE_LICENSE_MANAGER_FAILURE]: failureReducer,
    [ActionType.CREATE_BILLING_USER_REQUEST]: requestReducer,
    [ActionType.CREATE_BILLING_USER_SUCCESS]: createBillingUserSuccessReducer,
    [ActionType.CREATE_BILLING_USER_FAILURE]: failureReducer,
    [ActionType.ADD_LICENSE_REQUEST]: requestReducer,
    [ActionType.ADD_LICENSE_SUCCESS]: successReducer,
    [ActionType.ADD_LICENSE_FAILURE]: failureReducer,
    [ActionType.ADD_LICENSES_CSV_REQUEST]: requestReducer,
    [ActionType.ADD_LICENSES_CSV_SUCCESS]: successReducer,
    [ActionType.ADD_LICENSES_CSV_FAILURE]: failureReducer,
    [ActionType.GENERATE_LICENSE_TOKEN_REQUEST]: requestReducer,
    [ActionType.GENERATE_LICENSE_TOKEN_SUCCESS]: successReducer,
    [ActionType.GENERATE_LICENSE_TOKEN_FAILURE]: failureReducer,
    [ActionType.DELETE_LICENSE_TOKEN_REQUEST]: requestReducer,
    [ActionType.DELETE_LICENSE_TOKEN_SUCCESS]: successReducer,
    [ActionType.DELETE_LICENSE_TOKEN_FAILURE]: failureReducer,
    [ActionType.CREATE_TEAM_REQUEST]: requestReducer,
    [ActionType.CREATE_TEAM_SUCCESS]: setTeamsSuccessReducer,
    [ActionType.CREATE_TEAM_FAILURE]: failureReducer,
    [ActionType.DELETE_TEAM_REQUEST]: requestReducer,
    [ActionType.DELETE_TEAM_SUCCESS]: setTeamsSuccessReducer,
    [ActionType.DELETE_TEAM_FAILURE]: failureReducer,
    [ActionType.UPDATE_TEAM_REQUEST]: requestReducer,
    [ActionType.UPDATE_TEAM_SUCCESS]: setTeamsSuccessReducer,
    [ActionType.UPDATE_TEAM_FAILURE]: failureReducer,
    [ActionType.DELETE_BILLING_USER_REQUEST]: requestReducer,
    [ActionType.DELETE_BILLING_USER_SUCCESS]: deleteBillingUserSuccessReducer,
    [ActionType.DELETE_BILLING_USER_FAILURE]: failureReducer,
    [ActionType.DELETE_ADMIN_OF_SUBSCRIPTION_REQUEST]: requestReducer,
    [ActionType.DELETE_ADMIN_OF_SUBSCRIPTION_SUCCESS]: deleteAdminOfSubscriptionSuccessReducer,
    [ActionType.DELETE_ADMIN_OF_SUBSCRIPTION_FAILURE]: failureReducer,
    [ActionType.DELETE_LICENSE_MANAGER_REQUEST]: requestReducer,
    [ActionType.DELETE_LICENSE_MANAGER_SUCCESS]: deleteLicenseManagerSuccessReducer,
    [ActionType.DELETE_LICENSE_MANAGER_FAILURE]: failureReducer,
    [ActionType.DELETE_LICENSED_USER_REQUEST]: requestReducer,
    [ActionType.DELETE_LICENSED_USER_SUCCESS]: deleteLicensedUserSuccessReducer,
    [ActionType.DELETE_LICENSED_USER_FAILURE]: failureReducer,
    [ActionType.UPDATE_LICENSE_REQUEST]: requestReducer,
    [ActionType.UPDATE_LICENSE_SUCCESS]: successReducer,
    [ActionType.UPDATE_LICENSE_FAILURE]: failureReducer,
    [ActionType.UPDATE_USER_REQUEST]: requestReducer,
    [ActionType.UPDATE_USER_SUCCESS]: updateUserSuccessReducer,
    [ActionType.UPDATE_USER_FAILURE]: failureReducer,
    [ActionType.ADD_SUBSCRIPTION_DOMAIN_REQUEST]: requestReducer,
    [ActionType.ADD_SUBSCRIPTION_DOMAIN_SUCCESS]: setDomainsSuccessReducer,
    [ActionType.ADD_SUBSCRIPTION_DOMAIN_FAILURE]: failureReducer,
    [ActionType.DELETE_SUBSCRIPTION_DOMAIN_REQUEST]: requestReducer,
    [ActionType.DELETE_SUBSCRIPTION_DOMAIN_SUCCESS]: setDomainsSuccessReducer,
    [ActionType.DELETE_SUBSCRIPTION_DOMAIN_FAILURE]: failureReducer,
    [ActionType.ADD_SUBSCRIPTION_DOMAIN_EMAIL_REQUEST]: requestReducer,
    [ActionType.ADD_SUBSCRIPTION_DOMAIN_EMAIL_SUCCESS]: setDomainsSuccessReducer,
    [ActionType.ADD_SUBSCRIPTION_DOMAIN_EMAIL_FAILURE]: failureReducer,
    [ActionType.DELETE_SUBSCRIPTION_DOMAIN_EMAIL_REQUEST]: requestReducer,
    [ActionType.DELETE_SUBSCRIPTION_DOMAIN_EMAIL_SUCCESS]: setDomainsSuccessReducer,
    [ActionType.DELETE_SUBSCRIPTION_DOMAIN_EMAIL_FAILURE]: failureReducer,
    [ActionType.SELECT_USER]: selectUserReducer,
  }
)
