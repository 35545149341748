import {
  ActionType,
  Action,
  ApiStatus,
  Plans,
  Plan,
  Feature,
} from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import { failureReducer, initReducer, requestReducer } from "../../reducers"

export type PlansReducerType = ApiStatus & Plans

export const defaultState: PlansReducerType = {
  loading: false,
  error: undefined,

  plans: [],
  features: [],
}

function getPlansSuccessReducer(
  state: PlansReducerType,
  {
    payload,
  }: Action<{
    plans: Array<Plan>
    features: Array<Feature>
  }>
) {
  return {
    ...state,
    loading: false,
    loaded: true,
    plans: payload.plans,
    features: payload.features,
  }
}

function getFeaturesSuccessReducer(
  state: PlansReducerType,
  { payload }: Action<Array<Feature>>
) {
  return {
    ...state,
    loading: false,
    loaded: true,
    features: payload,
  }
}

function updatePlanSuccessReducer(
  state: PlansReducerType,
  { payload }: Action<Plan[]>
) {
  return {
    ...state,
    loading: false,
    loaded: true,
    plans: payload,
  }
}

export const plansReducer = createReducer<PlansReducerType>(defaultState, {
  [ActionType.INIT_STORE]: initReducer(defaultState),
  [ActionType.GET_PLANS_REQUEST]: requestReducer,
  [ActionType.GET_PLANS_SUCCESS]: getPlansSuccessReducer,
  [ActionType.GET_PLANS_FAILURE]: failureReducer,
  [ActionType.UPDATE_PLAN_REQUEST]: requestReducer,
  [ActionType.UPDATE_PLAN_SUCCESS]: updatePlanSuccessReducer,
  [ActionType.UPDATE_PLAN_FAILURE]: failureReducer,
  [ActionType.GET_FEATURES_REQUEST]: requestReducer,
  [ActionType.GET_FEATURES_SUCCESS]: getFeaturesSuccessReducer,
  [ActionType.GET_FEATURES_FAILURE]: failureReducer,
})
