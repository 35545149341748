import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import { AppConfig } from "src/core/config"
import { Subscription } from "src/domain/desktop"
interface MyProps {
  limitExceeded?: boolean
  isSubscriptionRenewalIsLessThan6Months: boolean
}

const IncreaseLicensesModalBodyContactMessage: React.FC<MyProps> = ({
  limitExceeded,
  isSubscriptionRenewalIsLessThan6Months,
}) => {
  const subscription: Subscription | undefined = useSelector(selectSubscription)

  return (
    <Fragment>
      {limitExceeded && (
        <label>
          Conduktor Desktop Professional is made for Freelancers and Small
          Organisations, and limited to 10 named users. Let&#39;s evaluate how
          Conduktor Desktop Enterprise could bring you more value: please
          contact us at{" "}
          <a
            href={`${AppConfig.website.contact.email}?subject=Licenses (subscription ${subscription?.chargeBeeSubscriptionId})`}
            target="_blank"
            rel="noreferrer"
          >
            {AppConfig.website.contact.email}
          </a>
          .
        </label>
      )}
      {isSubscriptionRenewalIsLessThan6Months && (
        <label>
          Your subscription will renew in less than 6 months. Please
          <a
            href={`mailto:${AppConfig.website.contact.email}?subject=Licenses (subscription ${subscription?.chargeBeeSubscriptionId})`}
            target="_blank"
            rel="noreferrer"
          >
            contact us
          </a>
          in order to add more licenses.
        </label>
      )}
    </Fragment>
  )
}
export default IncreaseLicensesModalBodyContactMessage
