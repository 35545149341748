import {
  AddTeamApiPayload,
  LicenseData,
  Subscription,
  TeamModalFormValues,
  UpdateTeamApiPayload,
} from "src/domain/desktop"
import { Team, TeamMember } from "../../../../domain/desktop/common"

export const sortLicensedUsers = (teamData?: Team) => (
  licenseA: LicenseData,
  licenseB: LicenseData
) => {
  if (teamData?.teamMembers) {
    if (
      teamData.teamMembers.find((teamMemberNode) => {
        return teamMemberNode.license.email === licenseA.email
      }) !== undefined &&
      teamData.teamMembers.find((teamMemberNode) => {
        return teamMemberNode.license.email === licenseB.email
      }) === undefined
    ) {
      return -1
    }
    if (
      teamData.teamMembers.find((teamMemberNode) => {
        return teamMemberNode.license.email === licenseB.email
      }) !== undefined &&
      teamData.teamMembers.find((teamMemberNode) => {
        return teamMemberNode.license.email === licenseA.email
      }) === undefined
    ) {
      return 1
    }
  }
  return 0
}

export const isUserAlreadyMemberOfGroup = (
  license: LicenseData,
  teamData?: Team
) => {
  if (teamData?.teamMembers === undefined) {
    return false
  }
  if (
    teamData?.teamMembers &&
    teamData.teamMembers.find((teamMember) => {
      return teamMember.license.email === license.email
    }) !== undefined
  ) {
    return true
  }
  return false
}

export const isUserAddedToGroup = (
  license: LicenseData,
  idsToBeRemoved: number[],
  membersToBeAdded: TeamMember[]
) => {
  if (!license?.id) return false
  if (idsToBeRemoved.indexOf(license.id) > -1) return false
  if (
    membersToBeAdded.find((member) => {
      return member.license.email === license.email
    }) !== undefined
  ) {
    return true
  }
  return false
}

export const getUpdateTeamPayload = (
  values: TeamModalFormValues,
  membersToBeAdded: TeamMember[],
  idsToBeRemoved: number[],
  subscription?: Subscription
) => {
  if (values.name && values.color && subscription) {
    const payload: UpdateTeamApiPayload = {
      team: {
        ...values,
        subscription: {
          id: subscription.id,
        },
        teamMembers: membersToBeAdded,
      },
      teamMembersToBeRevoked: idsToBeRemoved,
    }
    return payload
  }
  return null
}

export const getAddTeamPayload = (
  values: TeamModalFormValues,
  membersToBeAdded: TeamMember[],
  subscription?: Subscription
) => {
  if (values.name && values.color && subscription) {
    const payload: AddTeamApiPayload = {
      ...values,
      subscription: {
        id: subscription.id,
      },
      teamMembers: membersToBeAdded,
    }
    return payload
  }
  return null
}
