import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setMessage } from "src/store/system/actions"
import { RouteKey } from "src/router/routes"
import { UserSearchInput } from "./components/UserSearchInput"
import { Card, Col, Row } from "antd"
import {
  selectOAuthUserError,
  selectOAuthUserLoading,
} from "src/store/desktop/admin/users/selector"
import { UserSubscription } from "./components/UserSubscription"
import { UserDetails } from "./components/UserDetails"
import { useQuery } from "../../../../router/useQuery"
import { getOAuthUserRequest } from "../../../../store/desktop/admin/users/actions"
import { usePageView } from "src/core/analytics/hooks"
import { MessageType } from "../../../../domain/system"

const UsersPage = () => {
  usePageView(RouteKey.ADMIN_USERS)
  const dispatch = useDispatch()
  const query = useQuery()
  const loading: boolean = useSelector(selectOAuthUserLoading)
  const error: string | undefined = useSelector(selectOAuthUserError)
  const searchEmail = query.get("email")

  useEffect(() => {
    if (error && !loading) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: error,
        })
      )
    }
  }, [dispatch, loading, error])

  useEffect(() => {
    searchEmail && dispatch(getOAuthUserRequest(searchEmail))
  }, [dispatch, searchEmail])

  return (
    <Card bordered={false}>
      <UserSearchInput email={searchEmail} />
      <Card
        loading={loading}
        className="card"
        bordered={false}
        type={"inner"}
        bodyStyle={{ padding: 0 }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <UserSubscription />
          </Col>
          <Col span={12}>
            <UserDetails />
          </Col>
        </Row>
      </Card>
    </Card>
  )
}

export default UsersPage
