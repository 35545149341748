import React, { useEffect } from "react"
import { Redirect, Switch } from "react-router"
import { useAuth0 } from "@auth0/auth0-react"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"

import LogIn from "src/pages/auth/LogIn"
import Callback from "src/pages/auth/Callback"

import { RouterPrivate } from "./RouterPrivate"
import { selectAuth0User, selectForceLogout } from "src/store/system/selector"
import { setAuth0User } from "src/store/system/actions"
import { getAuthToken, performLogout, setAuthToken } from "src/core/auth"
import { SentryRoute } from "../core/reporting/sentry"
import { Auth0User } from "../domain/system"
import { CenteredSpinner } from "../components/Spinner"
import { RouterNotAuthenticated } from "./RouterNotAuthenticated"

export const RouterMain: React.FC = () => {
  const dispatch = useDispatch()

  const {
    isAuthenticated,
    isLoading,
    user,
    logout,
    getIdTokenClaims,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0<Auth0User>()
  const auth0User: Auth0User = useSelector(selectAuth0User)
  const forceLogout: boolean | undefined = useSelector(selectForceLogout)

  useEffect(() => {
    if (isLoading || !user || auth0User.email) return

    getAccessTokenSilently()
      .then(() => getIdTokenClaims())
      .then((claims) => setAuthToken(claims.__raw))
      .then(() => dispatch(setAuth0User(user)))
      .catch((originalError) => {
        console.log("Login user error", originalError)
      })
  }, [
    dispatch,
    getAccessTokenSilently,
    getIdTokenClaims,
    user,
    auth0User,
    isLoading,
  ])

  useEffect(() => {
    if (forceLogout && logout) {
      performLogout(logout)
    }
  }, [forceLogout])

  if (
    isLoading ||
    (isAuthenticated && isEmpty(auth0User)) ||
    (isAuthenticated && isEmpty(getAuthToken()))
  ) {
    return <CenteredSpinner />
  }

  return (
    <Switch>
      <SentryRoute path="/logout" exact>
        {() => {
          performLogout(logout)
          return null
        }}
      </SentryRoute>

      <SentryRoute path="/login" exact>
        <LogIn onLogin={loginWithRedirect} />
      </SentryRoute>

      <SentryRoute path="/callback">
        <Callback />
      </SentryRoute>

      {!isAuthenticated && <RouterNotAuthenticated />}

      {/* avoid to render it conditionally to avoid multiple renderings */}
      <RouterPrivate />

      {/* should not be reached */}
      <Redirect to="/" />
    </Switch>
  )
}
