import { all, put, takeLatest } from "redux-saga/effects"
import { getAuth0StatsApi } from "src/services"
import { ActionType, Auth0Stats } from "src/domain/desktop"
import { parseError } from "src/utils"
import { getAuth0StatsFailure, getAuth0StatsSuccess } from "./actions"
import { ClientError } from "../../../../services/ClientError"

function* getAuth0StatsSaga() {
  try {
    const stats: Auth0Stats = yield getAuth0StatsApi()

    yield put(getAuth0StatsSuccess(stats))
  } catch (err) {
    yield put(getAuth0StatsFailure(parseError(err as ClientError)))
  }
}

export default function* lastSignUpsSagas() {
  yield all([takeLatest(ActionType.GET_AUTH0_STATS_REQUEST, getAuth0StatsSaga)])
}
