import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Row, Tooltip } from "antd"
import { SyncOutlined } from "@ant-design/icons"
import Subscriptions from "./components/Subscriptions"
import Licenses from "./components/Licenses"
import Activity from "./components/Activity"
import {
  selectAdminDashboardError,
  selectAdminDashboardLoading,
  selectLicenseActivityLogsList,
} from "src/store/desktop/adminDashboard/selector"
import { getAdminDashboardDataRequest } from "src/store/desktop/adminDashboard/actions"
import { setMessage } from "src/store/system/actions"
import { LicenseActivityLog } from "src/domain/desktop"
import { RouteKey } from "src/router/routes"
import { usePageView } from "src/core/analytics/hooks"
import { MessageType } from "../../../domain/system"

const AdminDashboardPage = () => {
  usePageView(RouteKey.HOME)
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectAdminDashboardLoading)
  const error: string | undefined = useSelector(selectAdminDashboardError)

  const activityLogs: LicenseActivityLog[] = useSelector(
    selectLicenseActivityLogsList
  )

  useEffect(() => {
    if (!activityLogs || !activityLogs.length) {
      dispatch(getAdminDashboardDataRequest())
    }
  }, [dispatch, activityLogs])

  useEffect(() => {
    if (error && !loading) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: error,
        })
      )
    }
  }, [dispatch, error, loading])

  const getDashboardData = () => {
    dispatch(getAdminDashboardDataRequest())
  }

  return (
    <div className="admin-dashboard" style={{ minHeight: "101vh" }}>
      <Row className="m-b-10">
        <Col span={24}>
          <Tooltip placement="bottom" title="Refresh">
            <Button
              type="primary"
              shape="circle"
              size="large"
              className="float-right"
              onClick={() => {
                getDashboardData()
              }}
            >
              <SyncOutlined spin={loading} />
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <Subscriptions />
      <Licenses />
      <Activity />
    </div>
  )
}
export default AdminDashboardPage
