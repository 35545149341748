import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import UserWithAvatar from "src/components/UserWithAvatar"
import { Card, Table } from "antd"
import { ColumnType } from "antd/lib/table"
import {
  selectAllLicenseMembers,
  selectLicenseMembersError,
  selectLicenseMembersLoading,
} from "src/store/desktop/licenseMembers/selector"
import { setMessage } from "src/store/system/actions"
import { getLicenseMembersRequest } from "src/store/desktop/licenseMembers/actions"
import { RouteKey } from "src/router/routes"
import { useWindowWidth } from "@react-hook/window-size"
import { usePageView } from "src/core/analytics/hooks"
import { MessageType, User } from "../../../domain/system"
import { getMemberNameByAccessLevel } from "../../../domain/user"

const MembersPage = () => {
  usePageView(RouteKey.MEMBERS)
  const width: number = useWindowWidth()
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectLicenseMembersLoading)
  const error: string | undefined = useSelector(selectLicenseMembersError)
  const allMembers: Array<User> = useSelector(selectAllLicenseMembers)

  useEffect(() => {
    if (!allMembers.length && !loading) {
      dispatch(getLicenseMembersRequest())
    }
  }, [dispatch, allMembers, loading])

  useEffect(() => {
    if (error && !loading) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: error,
        })
      )
    }
  }, [dispatch, error, loading])

  const membersTableColumns: ColumnType<User>[] = [
    {
      title: "Name",
      render: (_text, record) => {
        return (
          <UserWithAvatar
            teams={record.teams}
            pictureUrl={record.pictureUrl}
            name={record.name || record.fullName}
            email={record.email}
          />
        )
      },
    },
    {
      title: "Role",
      align: "center",
      width: 150,
      render: (_text, record) => {
        return <span>{getMemberNameByAccessLevel(record.accessLevel)}</span>
      },
    },
  ]

  return (
    <div className="members">
      <Card className="card" bordered={false}>
        <Table
          columns={membersTableColumns}
          dataSource={allMembers}
          loading={loading}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            pageSize: 20,
          }}
          rowKey={(user) =>
            user.accessLevel ? `user-${user.id}` : `${user.email}`
          }
          size="small"
          scroll={width < 700 ? { x: "max-content" } : undefined}
        />
      </Card>
    </div>
  )
}

export default MembersPage
