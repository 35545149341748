import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import TeamModalFooter from "./components/Footer"
import TeamModalBody from "./components/Body"
import {
  selectSubscription,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoaded,
  selectSubscriptionDetailsLoading,
  selectSubscriptionLicensedUsers,
} from "src/store/desktop/subscriptionDetails/selector"
import {
  createTeamRequest,
  deleteTeamRequest,
  updateTeamRequest,
} from "src/store/desktop/subscriptionDetails/actions"
import { setMessage } from "src/store/system/actions"
import {
  ActionType,
  DeleteSubscriptionItemApiPayload,
  TeamModalProps,
  LicenseData,
  TeamModalFormValues,
  Subscription,
} from "src/domain/desktop"
import {
  getAddTeamPayload,
  getUpdateTeamPayload,
  sortLicensedUsers,
} from "./utils"
import "./styles.less"
import { MessageType } from "../../../domain/system"
import { Team, TeamMember } from "../../../domain/desktop/common"

const defaultTeamData: Team = {
  color: "#000000",
}

const TeamModal: React.FC<TeamModalProps> = ({
  visible,
  onClose,
  teamData,
}) => {
  const dispatch = useDispatch()
  const subscription: Subscription | undefined = useSelector(selectSubscription)

  const licencedUsers = (
    useSelector(selectSubscriptionLicensedUsers) || []
  ).sort(sortLicensedUsers(teamData))

  const apiMode = useSelector(selectSubscriptionDetailsApiMode)
  const loading: boolean =
    useSelector(selectSubscriptionDetailsLoading) &&
    (apiMode === ActionType.CREATE_TEAM_REQUEST ||
      apiMode === ActionType.DELETE_TEAM_REQUEST ||
      apiMode === ActionType.UPDATE_TEAM_REQUEST)
  const loaded =
    useSelector(selectSubscriptionDetailsLoaded) &&
    (apiMode === ActionType.CREATE_TEAM_REQUEST ||
      apiMode === ActionType.DELETE_TEAM_REQUEST ||
      apiMode === ActionType.UPDATE_TEAM_REQUEST)

  const [values, setValues] = useState<TeamModalFormValues>(defaultTeamData)
  const [searchQuery, setSearchQuery] = useState("")
  const [membersToBeAdded, setMembersToBeAdded] = useState<Array<TeamMember>>(
    []
  )
  const [idsToBeRemoved, setIdsToBeRemoved] = useState<Array<number>>([])

  const licensedUsersFiltered = licencedUsers.filter(
    (user) =>
      (user.name && user.name.toLocaleLowerCase().includes(searchQuery)) ||
      (user.email && user.email.toLocaleLowerCase().includes(searchQuery))
  )

  const arrayPushPopNewTeamMembers = (
    object: TeamMember,
    checkBoxStatus: boolean
  ) => {
    return new Promise((resolve) => {
      if (checkBoxStatus) {
        if (
          idsToBeRemoved.find((id) => {
            return id === object.license.id
          }) !== undefined
        ) {
          resolve(0)
          return
        }
        for (let i = 0; i < membersToBeAdded.length; i++) {
          if (
            membersToBeAdded[i].license &&
            membersToBeAdded[i].license.email === object.license.email
          ) {
            resolve(0)
            return
          }
        }
        setMembersToBeAdded([...membersToBeAdded, object])
        membersToBeAdded.push(object)
      } else {
        for (let i = 0; i < membersToBeAdded.length; i++) {
          if (
            membersToBeAdded[i].license &&
            membersToBeAdded[i].license.email === object.license.email
          ) {
            setMembersToBeAdded(membersToBeAdded.filter((e, j) => j !== i))
            resolve(0)
            return
          }
        }
      }
      resolve(0)
    })
  }

  const arrayPushPopMembersToBeRemoved = (
    object: TeamMember,
    checkBoxStatus: boolean
  ) => {
    return new Promise((resolve) => {
      if (teamData) {
        if (checkBoxStatus) {
          for (let i = 0; i < idsToBeRemoved.length; i++) {
            if (idsToBeRemoved[i] === object.license.id) {
              setIdsToBeRemoved(idsToBeRemoved.filter((e, j) => j !== i))
              resolve(0)
              return
            }
          }
        } else {
          if (
            membersToBeAdded.find((teamMember) => {
              return teamMember.license.id === object.license.id
            }) !== undefined
          ) {
            resolve(0)
            return
          }
          if (
            idsToBeRemoved.find((id) => {
              return id === object.license.id
            }) !== undefined
          ) {
            resolve(0)
            return
          }
          if (object.license.id) {
            setIdsToBeRemoved([...idsToBeRemoved, object.license.id])
          }
        }
      }
      resolve(0)
    })
  }

  const clearData = () => {
    setValues(defaultTeamData)
    setIdsToBeRemoved([])
    setMembersToBeAdded([])
  }

  useEffect(() => {
    if (visible) {
      setValues(teamData || defaultTeamData)
    }
  }, [dispatch, teamData, visible])

  useEffect(() => {
    if (!loading && loaded) {
      dispatch(
        setMessage({
          type: MessageType.SUCCESS,
          message:
            apiMode === ActionType.CREATE_TEAM_REQUEST
              ? `Added successfully!`
              : apiMode === ActionType.DELETE_TEAM_REQUEST
              ? `Deleted successfully!`
              : `Updated successfully!`,
        })
      )
      clearData()
      onClose()
    }
  }, [loading, loaded])

  const handleSearch = (e: string) => {
    setSearchQuery(e)
  }

  const handleDelete = () => {
    if (subscription?.id && teamData?.id) {
      const payload: DeleteSubscriptionItemApiPayload = {
        subscriptionId: subscription.id,
        id: teamData.id,
      }
      dispatch(deleteTeamRequest(payload))
    }
  }

  const handleAdd = () => {
    const payload = getAddTeamPayload(values, membersToBeAdded, subscription)
    dispatch(
      payload
        ? createTeamRequest(payload)
        : setMessage({
            message: `Please enter all fields`,
          })
    )
  }

  const handleUpdate = () => {
    const payload = getUpdateTeamPayload(
      values,
      membersToBeAdded,
      idsToBeRemoved,
      subscription
    )
    dispatch(
      payload
        ? updateTeamRequest(payload)
        : setMessage({
            message: `Please enter all fields`,
          })
    )
  }

  const handleChange = (prop: keyof TeamModalFormValues) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setValues({
      ...values,
      [prop]: value,
    })
  }

  const handleCheckBoxChange = (license: LicenseData) => (
    e: CheckboxChangeEvent
  ) => {
    if (license) {
      const currentState = e.target.checked
      if (currentState) {
        arrayPushPopNewTeamMembers({ license }, currentState).then(() => {
          arrayPushPopMembersToBeRemoved({ license }, currentState)
        })
      } else {
        arrayPushPopMembersToBeRemoved({ license }, currentState).then(() => {
          arrayPushPopNewTeamMembers({ license }, currentState)
        })
      }
    }
  }

  return (
    <div className="teams-of-subscription">
      <Modal
        title="Manage Groups"
        className="p-t-0 teams-of-subscription-model"
        visible={visible}
        width={"50%"}
        maskClosable={false}
        closable={false}
        footer={
          <TeamModalFooter
            teamData={teamData}
            onAdd={handleAdd}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            onClose={() => {
              setIdsToBeRemoved([])
              setMembersToBeAdded([])
              onClose()
            }}
          />
        }
      >
        {visible && (
          <TeamModalBody
            values={values}
            teamData={teamData}
            licensedUsersFiltered={licensedUsersFiltered}
            idsToBeRemoved={idsToBeRemoved}
            membersToBeAdded={membersToBeAdded}
            onChange={handleChange}
            onSearch={handleSearch}
            onChangeCheckbox={handleCheckBoxChange}
          />
        )}
      </Modal>
    </div>
  )
}

export default TeamModal
