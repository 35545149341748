import React from "react"
import { matchPath } from "react-router"
import { RouteKey, RouteConfig, SidebarMenuItem } from "src/router/routes"
import AdminDashboardPage from "src/pages/desktop/AdminDashboard"
import HomePage from "src/pages/desktop/Home"
import MembersPage from "src/pages/desktop/Members"
import SubscriptionDetailsPage from "src/pages/desktop/SubscriptionDetails"
import SubscriptionsPage from "src/pages/desktop/Subscriptions"
import UserGroups from "src/pages/desktop/UserGroups"
import LicensesPage from "src/pages/desktop/Licenses"
import DevicesPage from "src/pages/desktop/Devices"
import TransferLicensesPage from "src/pages/desktop/admin/TransferLicenses"
import AutoAssignDomainsPage from "src/pages/desktop/admin/AutoAssignDomains"
import MigrationPage from "src/pages/desktop/admin/Migration"
import PlansConfigurationPage from "src/pages/desktop/admin/PlansConfiguration"
import LogsPage from "src/pages/desktop/admin/Logs"
import ClustersPage from "src/pages/desktop/Clusters"
import UsersPage from "src/pages/desktop/admin/Users"
import { ClusterAccessControl } from "src/pages/desktop/ClusterAccessControl"
import { DomainDetailsPage } from "src/pages/desktop/admin/DomainDetails"
import { LastSignupsPage } from "src/pages/desktop/admin/LastSignupsPage"
import { AdminsPage } from "src/pages/desktop/admin/Admins"
import SubscriptionCancelPage from "src/pages/desktop/SubscriptionCancel"
import SubscriptionTriggerCancelPage from "src/pages/desktop/SubscriptionCancel/trigger"
import { AccessTopicControl } from "src/pages/desktop/ClusterAccessControl/topics/TopicControl"
import {
  ArrowsRotateFarIcon,
  ArrowUpFromSquareFarIcon,
  BookOpenFarIcon,
  BuildingsFarIcon,
  CircleUserFarIcon,
  HouseBlankFarIcon,
  LaptopMobileFarIcon,
  OctagonExclamationFarIcon,
  PeopleIcon,
  ServerFarIcon,
  SquarePollHorizontalFarIcon,
  StarFarIcon,
  TableLayoutFarIcon,
  UserGearFarIcon,
  UserPlusFarIcon,
} from "@conduktor/ui-library"
import { ClustersPageShortcut } from "../../pages/desktop/Clusters/ClustersPageShortcut"

export const routesDesktop: RouteConfig[] = [
  {
    key: RouteKey.HOME,
    path: "/",
    title: "Home",
    forAdmin: true,
    component: () => <AdminDashboardPage />,
  },
  {
    key: RouteKey.HOME,
    path: "/",
    title: "Home",
    forAdmin: false,
    component: () => <HomePage />,
  },
  {
    key: RouteKey.SUBSCRIPTIONS,
    path: "/subscriptions",
    title: "Subscriptions",
    forConduktorUser: true,
    component: () => <SubscriptionsPage />,
  },
  {
    key: RouteKey.USER_GROUPS,
    path: "/subscriptions/licenses-management/:id/user-groups",
    title: "User Groups",
    forConduktorUser: true,
    component: () => <UserGroups />,
  },
  {
    key: RouteKey.CLUSTERS,
    path: "/subscriptions/licenses-management/:id/clusters",
    title: "Clusters Permissions",
    forConduktorUser: true,
    component: () => <ClustersPage />,
  },
  {
    key: RouteKey.CLUSTERS_SHORTCUT,
    path: "/clusters",
    title: "Clusters Permissions Shortcut",
    forConduktorUser: true,
    component: () => <ClustersPageShortcut />,
  },
  {
    key: RouteKey.CLUSTER_CONTROL,
    path: "/subscriptions/licenses-management/:id/clusters/:clusterId",
    title: (params) => `Access Control - ${params.clusterId}`,
    forConduktorUser: true,
    component: () => <ClusterAccessControl />,
  },
  {
    key: RouteKey.TOPIC_CONTROL_CREATION,
    path: "/subscriptions/licenses-management/:id/clusters/:clusterId/topics",
    title: (params) => `Topic Permissions - ${params.clusterId}`,
    forConduktorUser: true,
    component: () => <AccessTopicControl />,
  },
  {
    key: RouteKey.TOPIC_CONTROL_UPDATE,
    path:
      "/subscriptions/licenses-management/:id/clusters/:clusterId/topics/:topic",
    title: (params) => `Topic Permissions - ${params.clusterId}`,
    forConduktorUser: true,
    component: () => <AccessTopicControl />,
  },
  {
    key: RouteKey.SUBSCRIPTION_CANCEL,
    path: "/subscriptions/licenses-management/:id/cancel",
    title: `Cancel Subscription`,
    forConduktorUser: true,
    component: () => <SubscriptionCancelPage />,
  },
  {
    key: RouteKey.SUBSCRIPTION_CANCEL_TRIGGER,
    path: "/subscriptions/licenses-management/:id/cancel/trigger",
    title: `Cancel Subscription`,
    forConduktorUser: true,
    component: () => <SubscriptionTriggerCancelPage />,
  },
  {
    key: RouteKey.LICENSE_MANAGEMENT,
    path: "/subscriptions/licenses-management/:id",
    title: "License Management",
    forConduktorUser: true,
    component: () => <SubscriptionDetailsPage />,
  },
  {
    key: RouteKey.LICENSES,
    path: "/licenses",
    title: RouteKey.MEMBERS,
    forConduktorUser: true,
    component: () => <LicensesPage />,
  },

  {
    key: RouteKey.MEMBERS,
    path: "/members",
    title: "My Team",
    component: () => <MembersPage />,
  },
  {
    key: RouteKey.DEVICES,
    path: "/devices",
    title: "Devices",
    component: () => <DevicesPage />,
  },

  {
    key: RouteKey.ADMIN_TRANSFERT_LICENSES,
    path: "/transfer-licenses",
    title: "Transfer Licenses",
    forAdmin: true,
    component: () => <TransferLicensesPage />,
  },
  {
    key: RouteKey.ADMIN_AUTO_ASSIGN_DOMAINS,
    path: "/auto-assign-domains",
    title: "Auto Assign Domains",
    forAdmin: true,
    component: () => <AutoAssignDomainsPage />,
  },
  {
    key: RouteKey.ADMIN_LAST_SIGNUPS,
    path: "/last-signups",
    title: "Last Signups",
    forAdmin: true,
    component: () => <LastSignupsPage />,
  },
  {
    key: RouteKey.ADMIN_DOMAINS,
    path: "/domains",
    title: "Domains",
    forAdmin: true,
    component: () => <DomainDetailsPage />,
  },
  {
    key: RouteKey.ADMIN_USERS,
    path: "/users",
    title: "Users",
    forAdmin: true,
    component: () => <UsersPage />,
  },
  {
    key: RouteKey.ADMIN_MIGRATION,
    path: "/migration",
    title: "Migration",
    forAdmin: true,
    component: () => <MigrationPage />,
  },
  {
    key: RouteKey.ADMIN_ADMINS,
    path: "/admins",
    title: "Administrators",
    forAdmin: true,
    component: () => <AdminsPage />,
  },
  {
    key: RouteKey.ADMIN_PLANS_CONFIG,
    path: "/plans-configuration",
    title: "Plans",
    forAdmin: true,
    component: () => <PlansConfigurationPage />,
  },
  {
    key: RouteKey.ADMIN_LOGS,
    path: "/logs",
    title: "Logs",
    forAdmin: true,
    component: () => <LogsPage />,
  },
]

export const menuItems: SidebarMenuItem[] = [
  {
    name: "Home",
    key: RouteKey.HOME,
    icon: <HouseBlankFarIcon fr />,
  },
  {
    name: "Subscriptions",
    key: RouteKey.SUBSCRIPTIONS,
    icon: <SquarePollHorizontalFarIcon fr />,
    forBillingUser: true,
  },
  {
    name: "My Clusters",
    key: RouteKey.CLUSTERS_SHORTCUT,
    icon: <ServerFarIcon fr />,
    forBillingUserOnly: true,
  },
  {
    name: "My Team",
    key: RouteKey.MEMBERS,
    icon: <PeopleIcon fr />,
    isMembersMenu: true,
  },
  {
    name: "All Members",
    key: RouteKey.LICENSES,
    icon: <CircleUserFarIcon fr />,
    forBillingUser: true,
  },
  {
    name: "Devices",
    key: RouteKey.DEVICES,
    icon: <LaptopMobileFarIcon fr />,
  },
]

export const menuAdminItems: SidebarMenuItem[] = [
  {
    name: "Admins",
    key: RouteKey.ADMIN_ADMINS,
    icon: <StarFarIcon fr />,
  },
  {
    name: "Plans Configuration",
    key: RouteKey.ADMIN_PLANS_CONFIG,
    icon: <TableLayoutFarIcon fr />,
  },
  {
    name: "Auto-Assign Domains",
    key: RouteKey.ADMIN_AUTO_ASSIGN_DOMAINS,
    icon: <OctagonExclamationFarIcon fr />,
  },
  {
    name: "Last Signups",
    key: RouteKey.ADMIN_LAST_SIGNUPS,
    icon: <UserPlusFarIcon fr />,
  },
  {
    name: "Domains",
    key: RouteKey.ADMIN_DOMAINS,
    icon: <BuildingsFarIcon fr />,
  },
  {
    name: "Users",
    key: RouteKey.ADMIN_USERS,
    icon: <UserGearFarIcon fr />,
  },
  {
    name: "Logs",
    key: RouteKey.ADMIN_LOGS,
    icon: <BookOpenFarIcon fr />,
  },
  {
    name: "Migration",
    key: RouteKey.ADMIN_MIGRATION,
    icon: <ArrowUpFromSquareFarIcon fr />,
  },
  {
    name: "Transfer Licenses",
    key: RouteKey.ADMIN_TRANSFERT_LICENSES,
    icon: <ArrowsRotateFarIcon fr />,
  },
]

export const routeOfPath = (pathname: string): RouteConfig | undefined =>
  routesDesktop.find((r) => matchPath(pathname, { path: r.path, exact: true }))
