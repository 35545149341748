import React, { useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import {
  ArrowRightFromBracketFarIcon,
  DiscordFabIcon,
  Dropdown,
  DropdownSeparator,
  EllipsisVerticalFarIcon,
  Flex,
} from "@conduktor/ui-library"

import {
  AvatarDropdownItem,
  AvatarEmail,
  AvatarImage,
  AvatarName,
} from "./avatarMenu.styles"
import { setForceLogout } from "../../store/system/actions"
import { useDispatch } from "react-redux"
import {
  SidebarDropdownContent,
  SidebarDropdownTrigger,
} from "./sidebar.styles"

interface Props {
  collapsed: boolean
}

export const AvatarMenu: React.FC<Props> = ({ collapsed }) => {
  const { user } = useAuth0()
  const dispatch = useDispatch()
  const [showDropdown, setShowDropdown] = useState(false)

  const triggerLogout = () => {
    dispatch(setForceLogout(true))
  }

  return (
    <Dropdown
      open={showDropdown}
      onOpenChange={() => setShowDropdown(!showDropdown)}
    >
      <SidebarDropdownTrigger
        css={{
          background: "$neutral1",
          boxShadow: "inset 0 1px 0 0 $colors$neutral6",
        }}
      >
        <Flex gap={2} align="center">
          <>
            <AvatarImage src={user?.picture} alt="Avatar" />
            {!collapsed && (
              <Flex direction="column">
                <AvatarName>{user?.name}</AvatarName>
                <AvatarEmail>{user?.email}</AvatarEmail>
              </Flex>
            )}
          </>
        </Flex>
        {!collapsed && <EllipsisVerticalFarIcon fr />}
      </SidebarDropdownTrigger>

      <SidebarDropdownContent align="start" alignOffset={12}>
        <AvatarDropdownItem
          onSelect={() =>
            window.open(
              "https://discord.gg/PrPZjHs28K",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <DiscordFabIcon fr />
          Join our Discord community
        </AvatarDropdownItem>

        <DropdownSeparator />

        <AvatarDropdownItem color="error" onClick={triggerLogout}>
          <ArrowRightFromBracketFarIcon fr /> Sign out
        </AvatarDropdownItem>
      </SidebarDropdownContent>
    </Dropdown>
  )
}
