import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, Col, Popconfirm, Row, Tooltip } from "antd"
import { CloseOutlined, LaptopOutlined } from "@ant-design/icons"
import {
  selectAuth0User,
  selectLicensedUser,
  selectSystemLoading,
} from "src/store/system/selector"
import { removeDevice } from "src/store/system/actions"
import {
  daysFromNow,
  fingerprintMachinePreview,
  fingerprintUserPreview,
  formatDate,
  isObjectEmpty,
  momentFromNow,
} from "src/utils"
import { Auth0User, User } from "../../../../../domain/system"
import { Fingerprint } from "../../../../../domain/desktop/common"

const DeviceList: React.FC = () => {
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectSystemLoading)
  const licensedUser: User = useSelector(selectLicensedUser)
  const auth0User: Auth0User = useSelector(selectAuth0User)
  const devices: Array<Fingerprint> =
    !isObjectEmpty(licensedUser) &&
    auth0User?.appMetadata &&
    auth0User.appMetadata.fingerprints &&
    auth0User.appMetadata.fingerprints.length
      ? auth0User.appMetadata.fingerprints
      : []

  const handleRemoveDevice = (device: Fingerprint) => {
    if (device.fingerprint) {
      dispatch(removeDevice(device.fingerprint))
    }
  }

  return (
    <>
      {devices.map((device, index) => (
        <Card
          key={index}
          className="card"
          title=""
          extra={
            <Popconfirm
              disabled={daysFromNow(device.first_usage) < 30}
              title="Remove this device?"
              okText="Yes"
              cancelText="No"
              placement="left"
              onConfirm={() => handleRemoveDevice(device)}
            >
              <Tooltip
                placement="left"
                title={
                  daysFromNow(device.first_usage) < 30 && (
                    <span>
                      Cannot remove, this device was recently used. You need to
                      wait at least a month to remove it.
                    </span>
                  )
                }
              >
                <Button
                  shape="circle"
                  icon={<CloseOutlined />}
                  loading={loading}
                  disabled={loading || daysFromNow(device.first_usage) < 30}
                />
              </Tooltip>
            </Popconfirm>
          }
        >
          <Row>
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="text-center">
              <LaptopOutlined style={{ fontSize: 72 }} />
              <br />
              {device.fingerprint && (
                <>
                  ID: {fingerprintUserPreview(device.fingerprint)}.
                  {fingerprintMachinePreview(device.fingerprint)}
                </>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className=" p-15">
              <div>
                OS:
                <span className="sub-title m-l-5"> {device.os || "-"}</span>
              </div>
              <div>
                Browser:
                <span className="sub-title m-l-5">
                  {" "}
                  {device.userAgent || "-"}
                </span>
              </div>
              <div>
                IP Address:
                <span className="sub-title m-l-5"> {device.ip || "-"}</span>
              </div>
            </Col>
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="p-15">
              <div>
                Location:
                <span className="sub-title m-l-5">
                  {" "}
                  {device.location || "-"}
                </span>
              </div>
              <div>
                <Tooltip
                  placement="bottom"
                  title={formatDate(device.first_usage)}
                >
                  Login:
                  <span className="sub-title m-l-5">
                    {momentFromNow(device.first_usage)}
                  </span>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  placement="bottom"
                  title={formatDate(device.last_usage)}
                >
                  Last seen:
                  <span className="sub-title m-l-5">
                    {momentFromNow(device.last_usage)}
                  </span>
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Card>
      ))}
    </>
  )
}

export default DeviceList
