import { ColumnType } from "antd/lib/table"
import React from "react"
import UserWithAvatar from "../../../../components/UserWithAvatar"
import { formatDate, or0 } from "../../../../utils"
import { Button, Popconfirm, Space } from "antd"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { ConduktorUser } from "../../../../domain/system"
import { getMemberNameByAccessLevel } from "../../../../domain/user"

interface Handlers {
  handleEdit: (user: ConduktorUser) => void
  handleDelete: (user: ConduktorUser) => void
}

export const useColumns = ({ handleEdit, handleDelete }: Handlers) => {
  const columns: ColumnType<ConduktorUser>[] = [
    {
      title: "Name",
      render: (_text, record) => {
        return (
          <UserWithAvatar
            pictureUrl={record.pictureUrl}
            name={record.fullName}
          />
        )
      },
      sorter: (a, b) => or0(a.fullName?.localeCompare(b.fullName!)),
    },
    {
      title: "Email",
      dataIndex: "email",
      showSorterTooltip: false,
      sorter: (a, b) => or0(a.email?.localeCompare(b.email!)),
    },
    {
      title: "Role",
      align: "center",
      render: (_text, record) => {
        return <span>{getMemberNameByAccessLevel(record.roleId)}</span>
      },
      sorter: (a, b) => or0(a.roleId) - or0(b.roleId),
    },
    {
      title: "Created At",
      align: "center",
      render: (_text, record) => {
        return <span>{formatDate(record.created)}</span>
      },
      sorter: (a, b) => or0(a.created) - or0(b.created),
    },
    {
      title: "Last Update",
      align: "center",
      render: (_text, record) => {
        return <span>{formatDate(record.modified)}</span>
      },
      sorter: (a, b) => or0(a.modified) - or0(b.modified),
    },
    {
      title: "Id",
      dataIndex: "id",
      sorter: (a, b) => or0(a.id) - or0(b.id),
    },
    {
      key: "action",
      align: "left",
      title: "Actions",
      render: (_text, record) => {
        return (
          <Space direction={"vertical"} size={0}>
            <Button type="link" onClick={() => handleEdit(record)}>
              <EditOutlined className="editable-cell-icon-pencil" />
              Edit
            </Button>
            <Popconfirm
              title="Remove admin access to this user?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(record)}
            >
              <Button type="link">
                <DeleteOutlined />
                Delete
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]
  return columns
}
