import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { Card, Col, Row, Statistic, Switch } from "antd"
import Table from "antd/lib/table"
import { getDomainUsersRequest } from "src/store/desktop/admin/domainDetails/actions"
import { useDomainDetailsColumns } from "./columns"
import {
  selectDomainDetailsLoading,
  selectDomainDetailsUsers,
} from "src/store/desktop/admin/domainDetails/selector"
import { DomainSearchInput } from "../DomainSearchInput"

interface Props {
  domainName: string | null
}

export const DomainUsersTable: React.FC<Props> = ({ domainName }) => {
  const width: number = useWindowWidth()
  const dispatch = useDispatch()
  const columns = useDomainDetailsColumns()
  const loading = useSelector(selectDomainDetailsLoading)
  const domainUsersList = useSelector(selectDomainDetailsUsers)
  const [showExpired, setShowExpired] = useState<boolean>(false)

  useEffect(() => {
    domainName && dispatch(getDomainUsersRequest(domainName))
  }, [dispatch, domainName])

  const users = showExpired
    ? domainUsersList?.users
    : domainUsersList?.users.filter((u) => !u.subscriptionExpired)

  return (
    <Card loading={loading} className="card" bordered={false}>
      <Row className="m-b-15">
        <Col className="float-left">
          <DomainSearchInput domainName={domainName} />
        </Col>
        <Col>
          <Switch
            checked={showExpired}
            className="m-y-5 m-x-10"
            checkedChildren="Expired"
            unCheckedChildren="Expired"
            onChange={setShowExpired}
          />
        </Col>
      </Row>
      {domainUsersList && (
        <>
          <Row>
            <Col span={2}>
              <Statistic title="Total" value={domainUsersList?.total} />
            </Col>
            <Col span={2}>
              <Statistic
                title="Active (< 1mo)"
                value={domainUsersList?.active}
              />
            </Col>
          </Row>
          <Table
            loading={loading}
            columns={columns}
            dataSource={users}
            size="small"
            rowKey="email"
            scroll={width < 800 ? { x: "max-content" } : undefined}
          />
        </>
      )}
    </Card>
  )
}
