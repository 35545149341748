import { TablePaginationConfig } from "antd"
import { RootState, Subscription } from "src/domain/desktop"
import { SubscriptionsReducerType } from "./reducer"

const selectSubscriptionsState = (state: RootState): SubscriptionsReducerType =>
  state.subscriptions

export const selectSubscriptionsLoaded = (state: RootState): boolean =>
  Boolean(selectSubscriptionsState(state).loaded)

export const selectSubscriptionsLoading = (state: RootState): boolean =>
  selectSubscriptionsState(state).loading

export const selectSubscriptionsError = (
  state: RootState
): string | undefined => selectSubscriptionsState(state).error

export const selectSubscriptionsList = (state: RootState): Subscription[] =>
  selectSubscriptionsState(state).items

export const selectSubscriptionsPagination = (
  state: RootState
): TablePaginationConfig => selectSubscriptionsState(state).pagination

export const selectSubscriptionsSearchQuery = (
  state: RootState
): string | undefined => selectSubscriptionsState(state).searchQuery

export const selectShowExpiredSubscriptions = (state: RootState): boolean =>
  selectSubscriptionsState(state).showExpiredSubscriptions
