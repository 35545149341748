import React, { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { Auth0User } from "../domain/system"
import { CenteredSpinner } from "../components/Spinner"
import { Route } from "react-router-dom"

export const RouterNotAuthenticated: React.FC = () => {
  return (
    <Route>
      <RedirectWithState />
    </Route>
  )
}

export const RedirectWithState: React.FC = () => {
  const { loginWithRedirect } = useAuth0<Auth0User>()

  useEffect(() => {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    })
      .then((res) => console.log("Login redirect succeed", res))
      .catch((err) => console.log("Login redirect failed", err))
  }, [loginWithRedirect])

  return <CenteredSpinner />
}
