import { Route } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { createBrowserHistory } from "history"
import { AppConfig } from "../config"

export const SentryRoute = Sentry.withSentryRouting(Route)
export const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

export const initSentry = () => {
  const history = createBrowserHistory()
  Sentry.init({
    dsn:
      "https://10e6a9afe4bc4d3d98cf906720e25707@o1131735.ingest.sentry.io/6247152",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.5,
    environment: AppConfig.environment,
    enabled: false,
  })
}
