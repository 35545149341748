import { TablePaginationConfig as Pagination } from "antd/lib/table"
import { ActionType, Action, ApiStatus } from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import { failureReducer, initReducer, requestReducer } from "../../reducers"
import { License, Licenses } from "../../../domain/desktop/common"

export type LicensesReducerType = ApiStatus & Licenses

export const defaultState: LicensesReducerType = {
  loading: false,
  error: undefined,
  type: undefined,

  items: [],

  pagination: {
    current: 1,
    pageSizeOptions: ["10", "20", "40", "100"],
    pageSize: 40,
    showSizeChanger: true,
    total: 0,
  },

  searchQuery: "",
}

function getLicensesSuccessReducer(
  state: LicensesReducerType,
  { payload }: Action<{ licenses: License[]; totalCount: number }>
) {
  return {
    ...state,
    items: payload.licenses,
    pagination: {
      ...state.pagination,
      total: payload.totalCount,
    },
    loading: false,
  }
}

function setLicensesPagination(
  state: LicensesReducerType,
  { payload }: Action<Pagination>
) {
  return {
    ...state,
    pagination: payload,
  }
}

function setLicensesSearchQuery(
  state: LicensesReducerType,
  { payload }: Action<string>
) {
  return {
    ...state,
    pagination: {
      ...state.pagination,
      current: 1,
    },
    searchQuery: payload,
  }
}

export const licensesReducer = createReducer<LicensesReducerType>(
  defaultState,
  {
    [ActionType.INIT_STORE]: initReducer(defaultState),
    [ActionType.GET_LICENSES_REQUEST]: requestReducer,
    [ActionType.GET_LICENSES_SUCCESS]: getLicensesSuccessReducer,
    [ActionType.GET_LICENSES_FAILURE]: failureReducer,
    [ActionType.SET_LICENSES_PAGINATION]: setLicensesPagination,
    [ActionType.SET_LICENSES_SEARCH_QUERY]: setLicensesSearchQuery,
  }
)
