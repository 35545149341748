import {
  Action,
  ActionType,
  ApiStatus,
  DomainUser,
  DomainUsers,
} from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import {
  failureReducer,
  initReducer,
  requestReducer,
  successReducer,
} from "../../../reducers"

export type DomainDetailsReducerType = ApiStatus & {
  domainUsers?: DomainUsers
}

export const defaultState: DomainDetailsReducerType = {
  loading: false,
  loaded: false,
  error: undefined,
  type: undefined,
  domainUsers: undefined,
}

function getDomainUsersSuccessReducer(
  state: DomainDetailsReducerType,
  { payload }: Action<DomainUsers>
): DomainDetailsReducerType {
  return {
    ...successReducer(state),
    domainUsers: payload,
  }
}

function deleteDomainUserReducer(
  state: DomainDetailsReducerType,
  { payload }: Action<DomainUser>
): DomainDetailsReducerType {
  if (!state.domainUsers) {
    return state
  }
  return {
    ...state,
    domainUsers: {
      ...state.domainUsers,
      total: Math.max(0, state.domainUsers.total - 1),
      users: state.domainUsers.users.filter((u) => u.email !== payload.email),
    },
  }
}

export const domainDetailsReducer = createReducer<DomainDetailsReducerType>(
  defaultState,
  {
    [ActionType.INIT_STORE]: initReducer(defaultState),
    [ActionType.GET_DOMAIN_USERS_REQUEST]: requestReducer,
    [ActionType.GET_DOMAIN_USERS_SUCCESS]: getDomainUsersSuccessReducer,
    [ActionType.GET_DOMAIN_USERS_FAILURE]: failureReducer,
    [ActionType.DELETE_DOMAIN_USER_SUCCESS]: deleteDomainUserReducer,
  }
)
