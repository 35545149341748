import React from "react"
import { Input } from "antd"
import { Cluster } from "src/domain/desktop"
import { Flex, Text } from "@conduktor/ui-library"

interface Props {
  cluster: Cluster
  onChange: (
    prop: keyof Cluster
  ) => (event: React.ChangeEvent<{ value: string }>) => void
}

const ClusterModalBody: React.FC<Props> = ({ cluster, onChange }) => {
  const isNewRecord = !Boolean(cluster.createdAt)

  return (
    <Flex direction={"column"} gap={3} css={{ mt: "$2" }}>
      <Flex direction={"column"} gap={1}>
        <Text variant={"label"}>Cluster ID</Text>
        <Input
          value={cluster.clusterId}
          disabled={!isNewRecord}
          placeholder="Enter your Apache Kafka Cluster ID"
          onChange={onChange("clusterId")}
        />
      </Flex>
      <Flex direction={"column"} gap={1}>
        <Text variant={"label"}>Cluster Name</Text>
        <Input
          value={cluster.name}
          placeholder="My Cluster"
          onChange={onChange("name")}
        />
      </Flex>
      <Flex direction={"column"} gap={1}>
        <Text variant={"label"}>Description</Text>
        <Input value={cluster.description} onChange={onChange("description")} />
      </Flex>
    </Flex>
  )
}

export default ClusterModalBody
