import * as React from "react"
import { SpinnerDots, SpinnerDotsProps } from "@conduktor/ui-library"
import { Centered } from "./Centered"

export const CenteredSpinner = (props: SpinnerDotsProps) => {
  return (
    <Centered>
      <SpinnerDots size={"large"} {...props} />
    </Centered>
  )
}
