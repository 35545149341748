import React, { FC } from "react"
import { Empty } from "antd"
import { Box, Button, PlusFarIcon, styled, Text } from "@conduktor/ui-library"
import { Link } from "react-router-dom"
import { useRoutePath } from "../useRoutePath.hook"
import { RouteKey } from "../../../../router/routes"

export const EmptyState: FC = () => {
  const path = useRoutePath(RouteKey.TOPIC_CONTROL_CREATION)

  return (
    <StyledEmptyState>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 60,
        }}
        description={<Text>No Topic Permissions have been defined</Text>}
      >
        <Link to={path}>
          <Button variant="primary">
            <PlusFarIcon fr /> Define topic permissions
          </Button>
        </Link>
      </Empty>
    </StyledEmptyState>
  )
}

export const StyledEmptyState = styled(Box, {
  backgroundColor: "$neutral1",
  border: "1px solid $neutral6",
  borderRadius: "$2",
})
