import { ColumnType } from "antd/lib/table"
import { LineItem } from "src/domain/desktop"
import { amount, formatDate } from "src/utils"

const ChargesColumns = (currency?: string) => {
  const columns: ColumnType<LineItem>[] = [
    {
      title: "Description",
      render: (_text, record) => {
        return (
          <span>
            {record.description}
            <br />
            <span className="hint-text">
              ({currency} {amount(record.unit_amount)} X {record.quantity}){" "}
              {formatDate((record.date_from || 0) * 1000)} to{" "}
              {formatDate((record.date_to || 0) * 1000)}
            </span>
          </span>
        )
      },
      className: "p-t-5 p-b-5",
    },
    {
      title: "Amount",
      render: (_text, record) => {
        return (
          <span>
            {" "}
            {currency} {amount(record.amount)}
          </span>
        )
      },
      className: "p-t-5 p-b-5 text-right",
    },
  ]

  return columns
}
export default ChargesColumns
