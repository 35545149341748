import React, { useEffect, useState } from "react"
import { Alert, Collapse, Modal, Row, Spin } from "antd"
import { RoleName, RoleUsage } from "../../../../domain/desktop"
import { isClientError } from "../../../../utils"
import { getSubscriptionRoleMembersApi } from "../../../../services"

export interface UsageModalConfig {
  role?: RoleName
  subscriptionId?: number
  deleteRoleOnSubmit?: boolean
  isVisible: boolean
}

interface Props {
  config: UsageModalConfig
  onClose: () => void
  onSubmit: (config: UsageModalConfig) => Promise<void>
}

export const RoleUsageModal: React.FC<Props> = ({
  config,
  onSubmit,
  onClose,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isConfirmLoading, setIsConfirmationLoading] = useState(false)
  const [usage, setUsage] = useState<RoleUsage>()
  const clustersName = Object.keys(usage || {})
  const isUsed = clustersName.length > 0

  useEffect(() => {
    if (config.role && config.subscriptionId) {
      getSubscriptionRoleMembersApi({
        subscriptionId: config.subscriptionId,
        role: config.role,
      }).then(setUsage)
    }
    return () => setUsage(undefined)
  }, [config.role, config.subscriptionId])

  const submit = () => {
    setIsConfirmationLoading(true)
    setErrorMessage(undefined)
    onSubmit(config)
      .then(() => {
        setIsConfirmationLoading(false)
        onClose()
      })
      .catch((e) => {
        console.log("Error", e)
        setIsConfirmationLoading(false)
        setErrorMessage(
          isClientError(e)
            ? "The role seems invalid, please retry"
            : "Sorry, an unexpected error occurred"
        )
      })
  }

  return (
    <Modal
      title="Role usage"
      okButtonProps={{
        hidden: !usage || !config.deleteRoleOnSubmit,
        danger: true,
      }}
      okText={"Delete this role"}
      visible={config.isVisible}
      onOk={submit}
      confirmLoading={isConfirmLoading}
      onCancel={onClose}
    >
      {!usage && (
        <Row justify={"center"}>
          <Spin size="large" />
        </Row>
      )}
      {usage && !isUsed && <p>This role is not used yet</p>}
      {usage && isUsed && (
        <Collapse>
          {clustersName.map((clusterName) => (
            <Collapse.Panel
              header={`Cluster: ${clusterName}`}
              key={clusterName}
            >
              {usage![clusterName].users.join(", ")}
            </Collapse.Panel>
          ))}
        </Collapse>
      )}
      {errorMessage && <Alert type="error" message={errorMessage} />}
    </Modal>
  )
}
