import { OAuthUser, Subscription, SubscriptionType } from "./index"
import moment from "moment"
import { UserRoleType } from "./common"
import { ConduktorUser } from "../system"
import { isValidUser } from "../user"

const reactivableTypes = [SubscriptionType.FREE, SubscriptionType.TRIAL]

type ReactivableStatus = "impossible" | "possible"
export function getReactivableStatus(user: OAuthUser): ReactivableStatus {
  if (
    user.subscriptionExpired ||
    reactivableTypes.includes(user.subscriptionType)
  ) {
    return "possible"
  }
  return "impossible"
}

export function isCancelable(subscription?: Subscription): boolean {
  return (
    subscription?.type === SubscriptionType.PROFESSIONAL &&
    !isSubscriptionExpired(subscription) &&
    !isSubscriptionCancellingScheduled(subscription)
  )
}

export const isSubscriptionStarted = (subscription?: Subscription) => {
  return (
    subscription && moment(subscription.subscriptionDate).isBefore(moment())
  )
}
export const isSubscriptionCancellingScheduled = (
  subscription?: Subscription
): boolean => {
  return (
    Boolean(subscription) &&
    !Boolean(subscription?.renewalDate) &&
    moment(subscription?.expiryDate).isAfter(moment())
  )
}
export const isSubscriptionExpired = (subscription?: Subscription) => {
  if (subscription?.expiryDate) {
    return moment(subscription.expiryDate).isBefore(moment())
  }
  if (subscription?.renewalDate) {
    return moment(subscription.renewalDate).isBefore(moment())
  }
  return true
}
export const isSubscriptionRenewalIsLessThan6MonthsMethod = (
  subscription: Subscription
) => {
  if (
    subscription &&
    subscription.type === "enterprise" &&
    subscription.renewalDate
  ) {
    if (moment(subscription.renewalDate).diff(moment(), "months", true) < 6) {
      return true
    }
  }
  return false
}
export const isValidSubscription = ({
  subscription,
  accessLevel,
  conduktorUser,
}: {
  subscription?: Subscription
  accessLevel?: UserRoleType[]
  conduktorUser?: ConduktorUser
}) => {
  if (!subscription?.chargeBeeSubscriptionId) return false
  if (!accessLevel || isValidUser(conduktorUser, [UserRoleType.ADMIN]))
    return true
  if (
    !subscription.accessLevel ||
    !accessLevel.includes(subscription.accessLevel)
  ) {
    return false
  }
  return true
}
