import { AccessControlMembers, RootState } from "src/domain/desktop"
import { AccessControlReducerType } from "./reducer"

const selectAccessControlState = (state: RootState): AccessControlReducerType =>
  state.accessControl

export const selectAccessControlLoading = (state: RootState): boolean =>
  selectAccessControlState(state).loading

export const selectAccessControlError = (
  state: RootState
): string | undefined => selectAccessControlState(state).error

export const selectAccessControlLoaded = (
  state: RootState
): boolean | undefined => selectAccessControlState(state).loaded

export const selectAccessControlUsers = (
  state: RootState
): AccessControlMembers => selectAccessControlState(state).users

export const selectAccessControlGroups = (
  state: RootState
): AccessControlMembers => selectAccessControlState(state).groups
