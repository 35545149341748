import {
  RootState,
  Subscription,
  RemovedUserCount,
  Domain,
  Invoice,
  IActionType,
} from "src/domain/desktop"
import { SubscriptionDetailsReducerType } from "./reducer"
import { User } from "src/domain/system"
import { Team, TeamsById } from "src/domain/desktop/common"
import { orEmpty } from "src/utils"

const selectSubscriptionDetailsState = (
  state: RootState
): SubscriptionDetailsReducerType => state.subscriptionDetails

export const selectSubscriptionDetailsLoading = (state: RootState): boolean =>
  selectSubscriptionDetailsState(state).loading

export const selectSubscriptionDetailsLoaded = (
  state: RootState
): boolean | undefined => selectSubscriptionDetailsState(state).loaded

export const selectSubscriptionDetailsError = (
  state: RootState
): string | undefined => selectSubscriptionDetailsState(state).error

export const selectSubscriptionDetailsApiMode = (
  state: RootState
): IActionType | undefined => selectSubscriptionDetailsState(state).type

export const selectSubscription = (
  state: RootState
): Subscription | undefined =>
  selectSubscriptionDetailsState(state).subscription

export const selectSubscriptionLicenseManagers = (
  state: RootState
): User[] | undefined => selectSubscriptionDetailsState(state).licenseManagers

export const selectSubscriptionTeams = (state: RootState): Team[] | undefined =>
  selectSubscriptionDetailsState(state).teams

export const selectSubscriptionTeamsById = (state: RootState): TeamsById => {
  return orEmpty(selectSubscriptionTeams(state)).reduce((byId, team) => {
    byId[team.id!] = team
    return byId
  }, {} as TeamsById)
}

export const selectSubscriptionAdmins = (
  state: RootState
): Array<User> | undefined =>
  selectSubscriptionDetailsState(state).adminsOfSubscription

export const selectSubscriptionBillingUsers = (
  state: RootState
): Array<User> | undefined => selectSubscriptionDetailsState(state).billingUsers

export const selectSubscriptionChangePaymentUrl = (
  state: RootState
): string | undefined =>
  selectSubscriptionDetailsState(state).changePaymentMethodURL

export const selectSubscriptionDomainList = (
  state: RootState
): Array<Domain> | undefined => selectSubscriptionDetailsState(state).domainList

export const selectSubscriptionLicensedUsers = (
  state: RootState
): Array<User> | undefined =>
  selectSubscriptionDetailsState(state).licensedUsers

export const selectSubscriptionRemovedUsers = (
  state: RootState
): Array<User> | undefined => selectSubscriptionDetailsState(state).removedUsers

export const selectSubscriptionRemovedUserCounts = (
  state: RootState
): Array<RemovedUserCount> | undefined =>
  selectSubscriptionDetailsState(state).removedUserCounts

export const selectSubscriptionInvoices = (
  state: RootState
): Array<Invoice> | undefined => selectSubscriptionDetailsState(state).invoices

export const selectSelectedUser = (state: RootState): User | undefined =>
  selectSubscriptionDetailsState(state).selectedUser

export const selectSubscriptionUsers = (state: RootState): User[] => {
  const billingUsers = selectSubscriptionBillingUsers(state) || []
  const admins = selectSubscriptionAdmins(state) || []
  const licenseManagers = selectSubscriptionLicenseManagers(state) || []
  const licenseMembers = selectSubscriptionLicensedUsers(state) || []

  return [...billingUsers, ...admins, ...licenseManagers, ...licenseMembers]
}
