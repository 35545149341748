export enum SystemActionType {
  SET_AUTH0_USER = "SYSTEM/SET_AUTH0_USER",
  SET_IS_BILLING_USER = "SYSTEM/SET_IS_BILLING_USER",
  SET_CONDUKTOR_USER = "SYSTEM/SET_CONDUKTOR_USER",
  SET_LICENCED_USER = "SYSTEM/SET_LICENCED_USER",
  SET_IS_LICENCED = "SYSTEM/SET_IS_LICENCED",
  SET_API_STATE = "SYSTEM/SET_API_STATE",
  SET_MESSAGE = "SYSTEM/SET_MESSAGE",
  SET_FORCE_LOGOUT = "SYSTEM/SET_FORCE_LOGOUT",
  FETCH_ALL_DESKTOP_USER_DATA = "SYSTEM/FETCH_ALL_DESKTOP_USER_DATA",
  FETCH_CONDUKTOR_USER = "SYSTEM/FETCH_CONDUKTOR_USER",
  FETCH_AUTH0_USER = "SYSTEM/FETCH_AUTH0_USER",
  UPDATE_USER_DATA_REQUEST = "SYSTEM/UPDATE_USER_DATA_REQUEST",
  UPDATE_USER_DATA_SUCCESS = "SYSTEM/UPDATE_USER_DATA_SUCCESS",
  UPDATE_USER_DATA_FAILURE = "SYSTEM/UPDATE_USER_DATA_FAILURE",
  UPDATE_USER_DATA_CLEAR = "SYSTEM/UPDATE_USER_DATA_CLEAR",
  REMOVE_DEVICE = "REMOVE_DEVICE",
  SHOW_MODAL = "SHOW_MODAL",
  HIDE_MODAL = "HIDE_MODAL",
  HIDE_ALL_MODALS = "HIDE_ALL_MODALS",
  SIDEBAR_TOGGLE_COLLAPSE = "SIDEBAR_TOGGLE_COLLAPSE",
}
