import { Card, Statistic, Table } from "antd"
import React from "react"
import { useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { ColumnType } from "antd/lib/table"
import {
  selectAdminDashboardLoading,
  selectLicenseActivityLogsList,
} from "src/store/desktop/adminDashboard/selector"
import { LicenseActivityLog } from "src/domain/desktop"

const Activity: React.FC = () => {
  const width: number = useWindowWidth()
  const loading: boolean = useSelector(selectAdminDashboardLoading)
  const licenseActivityLogsList: LicenseActivityLog[] = useSelector(
    selectLicenseActivityLogsList
  )

  return (
    <div className="admin-dashboard-activity">
      <Card loading={loading} className="card" bordered={false}>
        <h1 className="card-title">Activity</h1>
        <Table
          columns={activityLogsTableColumn}
          dataSource={licenseActivityLogsList}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            pageSize: 20,
          }}
          rowKey="date"
          scroll={width < 800 ? { x: "max-content" } : undefined}
          size="small"
        />
      </Card>
    </div>
  )
}

const activityLogsTableColumn: ColumnType<LicenseActivityLog>[] = [
  {
    title: "Action date",
    dataIndex: "date",
    align: "center",
  },
  {
    title: "Added",
    align: "center",
    render: (_text, record) => {
      return <Statistic value={record.added} />
    },
  },
  {
    title: "Updated",
    align: "center",
    render: (_text, record) => {
      return <Statistic value={record.updated} />
    },
  },
  {
    title: "Revoked",
    align: "center",
    render: (_text, record) => {
      return <Statistic value={record.revoked} />
    },
  },
]
export default Activity
