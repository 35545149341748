import {
  Action,
  ActionType,
  ApiStatus,
  LicenseMembers,
} from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import { failureReducer, initReducer, requestReducer } from "../../reducers"

export type LicenseMembersReducerType = ApiStatus & LicenseMembers

export const defaultState: LicenseMembersReducerType = {
  loading: false,
  error: undefined,
  type: undefined,

  admins: [],
  billingUsers: [],
  licenseMembers: [],
  licenseManagers: [],
}

function getLicenseMembersSuccessReducer(
  state: LicenseMembersReducerType,
  { payload }: Action<LicenseMembers>
) {
  return {
    ...state,
    loading: false,
    error: false,
    // force overriding, as the payload hasn't the field when no user has such "profile"
    billingUsers: [],
    licenseMembers: [],
    admins: [],
    licenseManagers: [],
    //
    ...payload,
  }
}

function initStateReducer() {
  return {
    ...defaultState,
  }
}

export const licenseMembersReducer = createReducer<LicenseMembersReducerType>(
  defaultState,
  {
    [ActionType.INIT_STORE]: initReducer(defaultState),
    [ActionType.GET_LICENSE_MEMBERS_REQUEST]: requestReducer,
    [ActionType.GET_LICENSE_MEMBERS_SUCCESS]: getLicenseMembersSuccessReducer,
    [ActionType.GET_LICENSE_MEMBERS_FAILURE]: failureReducer,
    [ActionType.INIT_LICENSED_MEMBERS]: initStateReducer,
  }
)
