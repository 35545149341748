import { History } from "history"
import { connectRouter } from "connected-react-router"

import { combineReducers } from "redux"
import { systemReducer } from "src/store/system/reducer"
import { adminDashboardReducer } from "src/store/desktop/adminDashboard/reducer"
import { subscriptionsReducer } from "src/store/desktop/subscriptions/reducer"
import { licensesReducer } from "src/store/desktop/licenses/reducer"
import { subscriptionDetailsReducer } from "src/store/desktop/subscriptionDetails/reducer"
import { plansReducer } from "src/store/desktop/plans/reducer"
import { licenseMembersReducer } from "src/store/desktop/licenseMembers/reducer"
import { domainsReducer } from "src/store/desktop/admin/domains/reducer"
import { logsReducer } from "src/store/desktop/logs/reducer"
import { clustersReducer } from "src/store/desktop/clusters/reducer"
import { usersReducer } from "src/store/desktop/admin/users/reducer"
import { domainDetailsReducer } from "../desktop/admin/domainDetails/reducer"
import { lastSignupsReducer } from "../desktop/admin/lastSignups/reducer"
import { adminsReducer } from "../desktop/admin/admins/reducer"
import { accessControlReducer } from "../desktop/accessControl/reducer"
import { topicsControlReducer } from "../desktop/topicsControl/reducer"

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    system: systemReducer,
    adminDashboard: adminDashboardReducer,
    subscriptions: subscriptionsReducer,
    licenses: licensesReducer,
    subscriptionDetails: subscriptionDetailsReducer,
    plans: plansReducer,
    licenseMembers: licenseMembersReducer,
    users: usersReducer,
    domainDetails: domainDetailsReducer,
    domains: domainsReducer,
    logs: logsReducer,
    clusters: clustersReducer,
    lastSignups: lastSignupsReducer,
    admins: adminsReducer,
    accessControl: accessControlReducer,
    topicsControl: topicsControlReducer,
  })

export default rootReducer
