export interface SubscriptionActiveExpiredCountWithPlanName {
  activeCount?: number
  expiredCount?: number
  planName?: string
  total?: number
}

export interface SubscriptionAddonsCount {
  offlineCount?: number
  reAssignmentCount?: number
  unlimitedCount?: number
}

export interface DomainStatusWithCount {
  approvedCount?: number
  pendingCount?: number
  rejectedCount?: number
}

export interface LicensesCount {
  remaining?: number
  total?: number
  used?: number
}

export interface LicenseActivityLog {
  added?: number
  date?: string
  revoked?: number
  updated?: number
}

export interface AdminDashboard {
  subscriptionActiveExpiredCountWithPlanNameList: Array<SubscriptionActiveExpiredCountWithPlanName>
  subscriptionAddonsCount: SubscriptionAddonsCount
  domainStatusWithCount: DomainStatusWithCount
  licensesCount: LicensesCount
  licenseActivityLogsList: Array<LicenseActivityLog>
}

export interface Auth0Stats {
  lastCreated: { email: string; createdAt: string }[]
  lastUpdated: { email: string; lastUpdatedAt: string }[]
}

export enum AdminDashboardActionType {
  GET_ADMIN_DASHBOARD_DATA_REQUEST = "ADMIN_DASHBOARD/GET_ADMIN_DASHBOARD_DATA_REQUEST",
  GET_ADMIN_DASHBOARD_DATA_SUCCESS = "ADMIN_DASHBOARD/GET_ADMIN_DASHBOARD_DATA_SUCCESS",
  GET_ADMIN_DASHBOARD_DATA_FAILURE = "ADMIN_DASHBOARD/GET_ADMIN_DASHBOARD_DATA_FAILURE",
  GET_AUTH0_STATS_REQUEST = "ADMIN_DASHBOARD/GET_AUTH0_STATS_REQUEST",
  GET_AUTH0_STATS_SUCCESS = "ADMIN_DASHBOARD/GET_AUTH0_STATS_SUCCESS",
  GET_AUTH0_STATS_FAILURE = "ADMIN_DASHBOARD/GET_AUTH0_STATS_FAILURE",
}
