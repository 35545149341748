import { RootState, ApiStatus } from "src/domain/desktop"
import { SystemReducerType } from "./reducer"
import { isEmpty } from "lodash"
import { Auth0User, ConduktorUser, Message, User } from "../../domain/system"

const selectSystemState = (state: RootState): SystemReducerType => state.system

export const selectSystemLoading = (state: RootState): boolean =>
  selectSystemState(state).loading

export const selectSystemError = (state: RootState): string | undefined =>
  selectSystemState(state).error

export const selectAuth0User = (state: RootState): Auth0User =>
  selectSystemState(state).auth0User

export const selectConduktorUser = (state: RootState): ConduktorUser =>
  selectSystemState(state).conduktorUser

export const selectIsDefinedConduktorUser = (state: RootState): boolean => {
  const user = selectSystemState(state).conduktorUser
  return !isEmpty(user.firstName) && !isEmpty(user.lastName)
}

export const selectConduktorUserUpdateStatus = (state: RootState): ApiStatus =>
  selectSystemState(state).updateUserDataStatus

export const selectLicensedUser = (state: RootState): User =>
  selectSystemState(state).licensedUser

export const selectIsBillingUser = (state: RootState): boolean =>
  selectSystemState(state).isBillingUser

export const selectIsLicensed = (state: RootState): boolean =>
  selectSystemState(state).isLicensed

export const selectMessage = (state: RootState): Message | undefined =>
  selectSystemState(state).message

export const selectForceLogout = (state: RootState): boolean | undefined =>
  selectSystemState(state).forceLogout

export const selectSidebarCollapsed = (state: RootState): boolean =>
  selectSystemState(state).sidebar.collapsed

export const selectModals = (state: RootState): Record<string, boolean> =>
  selectSystemState(state).modals
