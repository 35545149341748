import { SubscriptionType } from "./subscriptionsTypes"

export type UserId = string

export interface SentEmail {
  source: string
  timestamp: string
  event: string
  subject: string
  deliveryStatus: string
  template: string
  userVariables: {}
  tags: string[]
}

export interface UserLog {
  at: string
  clientId: string
  clientName: string
  eventType: { name: string; description: string }
}

export interface FingerprintV2 {
  alg: string
  count: number
  fingerprint: string
  firstUsage: string
  lastUsage: string
}

export interface OAuthUser {
  email: string
  picture: string
  subscriptionType: SubscriptionType
  chargebeeSubscriptionId: string | null
  subscriptionExpiry: string
  subscriptionExpired: boolean
  updatedAt: string
  createdAt: string
  userId: UserId
  lastAuthDate: string
  lastRetrialDate: string
  identityProviders: string[]
  emailVerified: boolean
  conduktorVersion: string
  lastAuthProtocol: string
  maxActivations: number
  fingerprints: FingerprintV2[]
  hubSpotContactId: string
}

export interface UserEmailPayload {
  email: string
}

export interface SetMaxActivationPayload {
  email: string
  max: number
}

export enum UsersActionType {
  GET_OAUTH_USER_REQUEST = "USERS/GET_OAUTH_USER_REQUEST",
  GET_OAUTH_USER_SUCCESS = "USERS/GET_OAUTH_USER_SUCCESS",
  GET_OAUTH_USER_FAILURE = "USERS/GET_OAUTH_USER_FAILURE",
  DELETE_OAUTH_USER_REQUEST = "USERS/DELETE_OAUTH_USER_REQUEST",
  RESET_OAUTH_USER_STATE = "USERS/RESET_OAUTH_USER_STATE",
  SEND_OAUTH_USER_RESET_PASSWORD_EMAIL = "USERS/SEND_OAUTH_USER_RESET_PASSWORD_EMAIL",
  SEND_OAUTH_USER_VERIFICATION_EMAIL = "USERS/SEND_OAUTH_USER_VERIFICATION_EMAIL",
  SET_OAUTH_USER_MAX_ACTIVATIONS = "USERS/SET_OAUTH_USER_MAX_ACTIVATIONS",
  CLEAR_OAUTH_USER_FP_AND_TOKENS = "USERS/CLEAR_OAUTH_USER_FP_AND_TOKENS",
  SEND_USER_REACTIVATE_TRIAL = "USERS/SEND_USER_REACTIVATE_TRIAL",
}
