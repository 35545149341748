import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"
import { CenteredSpinner } from "src/components/Spinner"
import { useSegmentContext } from "src/core/analytics/segment"
import { Auth0User } from "src/domain/system"

const CallbackPage = () => {
  const { track } = useSegmentContext()
  const { user } = useAuth0<Auth0User>()

  useEffect(() => {
    if (user) {
      track("cdk.admin.user.LoggedIn", { email: user?.email })
    }
  }, [user, track])

  return <CenteredSpinner />
}
export default CallbackPage
