import { TablePaginationConfig as Pagination } from "antd"
import {
  Action,
  ActionType,
  CreateRolePayload,
  DeleteRolePayload,
  Role,
  RoleName,
  Subscription,
  UpdateRolePayload,
} from "src/domain/desktop"

export const getSubscriptionsRequest = (isAdmin?: boolean) => ({
  type: ActionType.GET_SUBSCRIPTIONS_REQUEST,
  payload: isAdmin,
})

export const getSubscriptionsSuccess = (payload: {
  subscriptions: Subscription[]
  totalCount: number
}) => ({
  type: ActionType.GET_SUBSCRIPTIONS_SUCCESS,
  payload,
})

export const getSubscriptionsFailure = (payload: string) => ({
  type: ActionType.GET_SUBSCRIPTIONS_FAILURE,
  payload,
})

export const setSubscriptionsPagination = (payload: Pagination) => ({
  type: ActionType.SET_SUBSCRIPTIONS_PAGINATION,
  payload,
})

export const setSubscriptionsSearchQuery = (payload: string) => ({
  type: ActionType.SET_SUBSCRIPTIONS_SEARCH_QUERY,
  payload,
})

export const setShowExpiredSubscriptions = (payload: boolean) => ({
  type: ActionType.SET_SHOW_SHOWEXPIRED_SUBSCRIPTIONS,
  payload,
})

export const deleteRoleRequest = (
  subscriptionId: number,
  role: RoleName
): Action<DeleteRolePayload> => ({
  type: ActionType.DELETE_ROLE_REQUEST,
  payload: { subscriptionId, role },
})

export const createRoleRequest = (
  subscriptionId: number,
  role: Role
): Action<CreateRolePayload> => ({
  type: ActionType.CREATE_ROLE_REQUEST,
  payload: { subscriptionId, role },
})

export const upsertRoleRequest = (
  subscriptionId: number,
  role: Role,
  newName?: string
): Action<UpdateRolePayload> => ({
  type: ActionType.UPDATE_ROLE_REQUEST,
  payload: { subscriptionId, role, newName },
})
