import { all, put, takeLatest } from "redux-saga/effects"
import {
  clearOAuthUserFingerprintsApi,
  clearOAuthUserRefreshTokensApi,
  deleteOAuthUserApi,
  getOAuthUserApi,
  getOAuthUserEmailsApi,
  getOAuthUserLogsApi,
  getOAuthUserRefreshTokenApi,
  sendOAuthUserResetPasswordEmailApi,
  sendOAuthUserVerificationEmailApi,
  sendUserReactivateTrialApi,
  setOAuthUserMaxActivationsApi,
} from "src/services"
import {
  Action,
  ActionType,
  OAuthUser,
  SentEmail,
  SetMaxActivationPayload,
  UserEmailPayload,
  UserLog,
} from "src/domain/desktop"
import { parseError } from "src/utils"
import {
  getOAuthUserFailure,
  getOAuthUserRequest,
  getOAuthUserSuccess,
  resetOAuthUserState,
} from "./actions"
import { setMessage } from "../../../system/actions"
import { MessageType } from "../../../../domain/system"
import { ClientError } from "../../../../services/ClientError"

function* getOAuthUserSaga({ payload }: Action<UserEmailPayload>) {
  try {
    const user: OAuthUser = yield getOAuthUserApi(payload)
    const emails: SentEmail[] = yield getOAuthUserEmailsApi(payload)
    const logs: UserLog[] = yield getOAuthUserLogsApi(payload)
    const refreshTokens: string[] = yield getOAuthUserRefreshTokenApi(payload)

    yield put(getOAuthUserSuccess({ user, emails, logs, refreshTokens }))
  } catch (err) {
    yield put(getOAuthUserFailure(parseError(err as ClientError)))
  }
}

function* deleteOAuthUserSaga({ payload }: Action<UserEmailPayload>) {
  try {
    yield deleteOAuthUserApi(payload)

    yield put(resetOAuthUserState())
    yield put(
      setMessage({
        type: MessageType.SUCCESS,
        message: "The user has been removed",
      })
    )
  } catch (err) {
    yield put(
      setMessage({
        type: MessageType.ERROR,
        message: err?.message,
        title: "The user can't be removed",
      })
    )
  }
}

function* sendOAuthUserResetPasswordEmailSaga({
  payload,
}: Action<UserEmailPayload>) {
  try {
    yield sendOAuthUserResetPasswordEmailApi(payload)
    yield put(
      setMessage({
        type: MessageType.SUCCESS,
        message: "The email has been sent",
      })
    )
  } catch (err) {
    yield put(
      setMessage({
        type: MessageType.ERROR,
        message: err?.message,
        title: "The email can't be sent",
      })
    )
  }
}

function* sendOAuthUserVerificationEmailSaga({
  payload,
}: Action<UserEmailPayload>) {
  try {
    yield sendOAuthUserVerificationEmailApi(payload)
    yield put(
      setMessage({
        type: MessageType.SUCCESS,
        message: "The verification email has been sent",
      })
    )
  } catch (err) {
    yield put(
      setMessage({
        type: MessageType.ERROR,
        message: err?.message,
        title: "The verification email can't be sent",
      })
    )
  }
}

function* setOAuthUserMaxActivationsSaga({
  payload,
}: Action<SetMaxActivationPayload>) {
  try {
    yield setOAuthUserMaxActivationsApi(payload)
    yield put(
      setMessage({
        type: MessageType.SUCCESS,
        message: "The max activations has been updated",
      })
    )
  } catch (err) {
    yield put(
      setMessage({
        type: MessageType.ERROR,
        message: err?.message,
        title: "The max activations can't be updated",
      })
    )
  }
  yield put(getOAuthUserRequest(payload.email))
}

function* sendUserReactivateTrialSaga({ payload }: Action<UserEmailPayload>) {
  try {
    yield sendUserReactivateTrialApi(payload)
    yield put(
      setMessage({
        type: MessageType.SUCCESS,
        message: "The user has been updated",
      })
    )
  } catch (err) {
    yield put(
      setMessage({
        type: MessageType.ERROR,
        message: err?.message,
        title: "The user can't be updated",
      })
    )
  }
  yield put(getOAuthUserRequest(payload.email))
}

function* clearOAuthUserFingerprintsAndTokensSaga({
  payload,
}: Action<SetMaxActivationPayload>) {
  // fingerprints
  try {
    yield clearOAuthUserFingerprintsApi(payload)
    yield put(
      setMessage({
        type: MessageType.SUCCESS,
        message: "Fingerprints have been cleared",
      })
    )
  } catch (err) {
    yield put(
      setMessage({
        type: MessageType.ERROR,
        message: err?.message,
        title: "Fingerprints can't be cleared",
      })
    )
  }
  // refresh-tokens
  try {
    yield clearOAuthUserRefreshTokensApi(payload)
    yield put(
      setMessage({
        type: MessageType.SUCCESS,
        message: "Refresh Tokens have been cleared",
      })
    )
  } catch (err) {
    yield put(
      setMessage({
        type: MessageType.ERROR,
        message: err?.message,
        title: "Refresh Tokens can't be cleared",
      })
    )
  }
  yield put(getOAuthUserRequest(payload.email))
}

export default function* DomainsSagas() {
  yield all([
    takeLatest(ActionType.GET_OAUTH_USER_REQUEST, getOAuthUserSaga),
    takeLatest(ActionType.DELETE_OAUTH_USER_REQUEST, deleteOAuthUserSaga),
    takeLatest(
      ActionType.SEND_USER_REACTIVATE_TRIAL,
      sendUserReactivateTrialSaga
    ),
    takeLatest(
      ActionType.CLEAR_OAUTH_USER_FP_AND_TOKENS,
      clearOAuthUserFingerprintsAndTokensSaga
    ),
    takeLatest(
      ActionType.SEND_OAUTH_USER_RESET_PASSWORD_EMAIL,
      sendOAuthUserResetPasswordEmailSaga
    ),
    takeLatest(
      ActionType.SEND_OAUTH_USER_VERIFICATION_EMAIL,
      sendOAuthUserVerificationEmailSaga
    ),
    takeLatest(
      ActionType.SET_OAUTH_USER_MAX_ACTIVATIONS,
      setOAuthUserMaxActivationsSaga
    ),
  ])
}
