import React from "react"
import { Button, Modal } from "antd"
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { SeedData } from "src/domain/desktop"

interface ISeedDataModalProps {
  visible: boolean
  onClose: Function
  seedData?: SeedData
  subscriptionStatus?: boolean
  loading?: boolean
  onSave?: Function
}

const SeedDataModal: React.FC<ISeedDataModalProps> = ({
  visible,
  onClose,
  seedData,
  subscriptionStatus,
  onSave,
  loading,
}) => {
  const handleClose = () => {
    onClose()
  }

  const handleSave = () => {
    if (onSave) {
      onSave()
    } else {
      onClose()
    }
  }

  return (
    <Modal
      title="Subscription"
      className="p-t-0"
      visible={visible}
      width={"50%"}
      maskClosable={false}
      closable={false}
      footer={
        <>
          <Button disabled={loading} className="m-l-0" onClick={handleSave}>
            <PlusOutlined />
            Save
          </Button>
          <Button disabled={loading} className="m-l-0" onClick={handleClose}>
            <CloseCircleOutlined />
            Close
          </Button>
        </>
      }
    >
      {seedData ? (
        <>
          <h3>
            This data will be saved to database, please varify before processing
          </h3>
          <h3>
            <b>Billing User</b>
          </h3>
          <h4>
            Company: <span className="sub-title">{seedData.company}</span>
          </h4>
          <h4>
            Email: <span className="sub-title">{seedData.billing_user}</span>
          </h4>
          <h3 className="m-t-10">
            <b>Subscription</b>
          </h3>
          <h4>
            Type:{" "}
            <span className="sub-title">{seedData.subscription.type}</span>
          </h4>
          <h4>
            ChargeBee Id:{" "}
            <span className="sub-title">
              {seedData.subscription.chargebee_subscription_id}
            </span>
          </h4>
          <h4>
            No. of Licenses:{" "}
            <span className="sub-title">{seedData.subscription.quantity}</span>
          </h4>
          <h4>
            Status:{" "}
            <span className="sub-title">
              {subscriptionStatus ? "active" : "expired"}
            </span>
          </h4>
          <h4>
            Subscription date:{" "}
            <span className="sub-title">
              {seedData.subscription.subscription_date}
            </span>
          </h4>
          <h4>
            Renewal date:{" "}
            <span className="sub-title">
              {seedData.subscription.renewal_date}
            </span>
          </h4>
          <h4>
            Expiry date:{" "}
            <span className="sub-title">
              {seedData.subscription.expiry_date}
            </span>
          </h4>
          <h3 className="m-t-10">
            <b>License Manager</b>
          </h3>
          {seedData.technical_users?.map((licenseManager, index) => (
            <h4 key={`licenseManager-${index}`}>
              <span className="sub-title">{licenseManager}</span>
            </h4>
          ))}
          <h3 className="m-t-10">
            <b>Licenses</b>
          </h3>
          {seedData.licenses?.map((license, index) => (
            <h4 key={`license-${index}`}>
              <span className="sub-title">{license}</span>
            </h4>
          ))}
        </>
      ) : (
        <h3>No Data</h3>
      )}
    </Modal>
  )
}

export default SeedDataModal
