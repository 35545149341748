import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { Card } from "antd"
import Table from "antd/lib/table"
import {
  selectLastSignupsLoading,
  selectLastSignupsStats,
} from "../../../../store/desktop/admin/lastSignups/selector"
import { useColumns } from "./components/columns"
import { getAuth0StatsRequest } from "../../../../store/desktop/admin/lastSignups/actions"
import { emailDomain } from "../../../../utils"
import { RouteKey } from "src/router/routes"
import { usePageView } from "src/core/analytics/hooks"

export const LastSignupsPage: React.FC = () => {
  usePageView(RouteKey.ADMIN_LAST_SIGNUPS)
  const width: number = useWindowWidth()
  const dispatch = useDispatch()
  const columns = useColumns()

  const loading: boolean = useSelector(selectLastSignupsLoading)
  const stats = useSelector(selectLastSignupsStats)

  useEffect(() => {
    dispatch(getAuth0StatsRequest())
  }, [dispatch])

  const lastCreated = stats?.lastCreated.map((item) => ({
    ...item,
    domain: emailDomain(item.email),
  }))

  return (
    <Card loading={loading} className="card" bordered={false}>
      <Table
        loading={loading}
        pagination={{
          pageSizeOptions: ["20", "50", "100"],
          hideOnSinglePage: true,
          showSizeChanger: true,
        }}
        columns={columns}
        dataSource={lastCreated}
        size="small"
        rowKey="email"
        scroll={width < 800 ? { x: "max-content" } : undefined}
      />
    </Card>
  )
}
