import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { Button, Card, Table } from "antd"
import { ColumnType } from "antd/lib/table"
import UserWithAvatar from "src/components/UserWithAvatar"
import {
  selectSubscription,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoading,
  selectSubscriptionRemovedUsers,
  selectSubscriptionUsers,
} from "src/store/desktop/subscriptionDetails/selector"
import { ActionType, IActionType, Subscription } from "src/domain/desktop"
import { formatDate } from "src/utils"
import { UserAddOutlined } from "@ant-design/icons"
import { getLicensePayload } from "../../../../../components/Modals/AddLicenseModal/utils"
import { addLicenseRequest } from "../../../../../store/desktop/subscriptionDetails/actions"
import { setMessage } from "../../../../../store/system/actions"
import { User } from "../../../../../domain/system"
import { useSegmentContext } from "src/core/analytics/segment"

const RemovedUsers: React.FC = () => {
  const width: number = useWindowWidth()
  const mode: IActionType | undefined = useSelector(
    selectSubscriptionDetailsApiMode
  )
  const loading: boolean =
    useSelector(selectSubscriptionDetailsLoading) &&
    mode === ActionType.GET_REMOVED_USERS_REQUEST
  const removedUsers: Array<User> =
    useSelector(selectSubscriptionRemovedUsers) || []

  return (
    <div className="removed-users">
      <Card className="card" bordered={false}>
        <h1 className="card-title">Removed Members</h1>
        <Table
          size="small"
          rowKey="id"
          loading={loading}
          columns={removedUsersColumns}
          dataSource={removedUsers}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            pageSize: 20,
          }}
          scroll={width < 1100 ? { x: "max-content" } : undefined}
        />
      </Card>
    </div>
  )
}

export default RemovedUsers

const removedUsersColumns: ColumnType<User>[] = [
  {
    title: "Name",
    dataIndex: "name",
    align: "left",
    render: (_text, record) => {
      return (
        <>
          <UserWithAvatar
            pictureUrl={record.pictureUrl}
            name={record.name || "Unknown"}
            email={record.email || "Unknown"}
          />
        </>
      )
    },
  },
  {
    title: "Added on",
    width: 150,
    align: "center",
    render: (_text, record) => {
      return <span>{formatDate(record.created)}</span>
    },
  },
  {
    title: "Reactivation",
    width: 150,
    align: "center",
    render: (_text, record) => {
      const dispatch = useDispatch()
      const { track } = useSegmentContext()
      const allMembers: Array<User> = useSelector(selectSubscriptionUsers)
      const subscription: Subscription | undefined = useSelector(
        selectSubscription
      )
      if (allMembers.find((member) => member.email === record.email)) {
        return (
          <Button
            disabled
            title={"This user is already in the active members list"}
          >
            <UserAddOutlined />
            Reactivate
          </Button>
        )
      }

      const handleAddLicense = () => {
        const payload = getLicensePayload(record, subscription)
        dispatch(
          payload
            ? addLicenseRequest(payload)
            : setMessage({ message: "Please enter valid email" })
        )
        track("cdk.admin.subscriptions.members.Reactivated")
      }

      return (
        <Button type="primary" onClick={() => handleAddLicense()}>
          <UserAddOutlined />
          Reactivate
        </Button>
      )
    },
  },
  {
    title: "Removed on",
    width: 150,
    align: "center",
    render: (_text, record) => {
      return <span>{formatDate(record.removed)}</span>
    },
  },
]
