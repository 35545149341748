import { Button } from "@conduktor/ui-library"
import React from "react"
import { useFormContext } from "react-hook-form"

export const SubmitButton: React.FC<typeof Button.defaultProps> = ({
  children,
  loading,
  ...props
}) => {
  const {
    formState: { isValidating, isSubmitting },
  } = useFormContext()

  return (
    <Button
      pointer
      type={"submit"}
      variant={"primary"}
      {...props}
      loading={isValidating || isSubmitting || loading}
    >
      {children}
    </Button>
  )
}
