import { ClientError } from "../services/ClientError"

export const parseError = (err: ClientError) => {
  const msg = "Something went wrong!"
  if (err && err.response) {
    const { data } = err.response
    if (typeof data === "string") {
      return data || msg
    } else if (typeof data?.message === "string") {
      return data.message || msg
    }
    return err.status || msg
  } else {
    return msg
  }
}

export const isClientError = (err: ClientError) => {
  const status = err.response?.status || 0
  return status >= 400 && status < 500
}
