import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Input, Modal } from "antd"
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons"
import {
  selectSubscription,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoaded,
  selectSubscriptionDetailsLoading,
} from "src/store/desktop/subscriptionDetails/selector"
import { createLicenceManagerRequest } from "src/store/desktop/subscriptionDetails/actions"
import { ActionType, IActionType, Subscription } from "src/domain/desktop"
import { setMessage } from "src/store/system/actions"
import { MessageType, User } from "../../../domain/system"
interface IFormValues {
  name?: string
  email?: string
}
interface ILicenseManagersModalProps {
  visible: boolean
  onClose: Function
}

const LicenseManagersModal: React.FC<ILicenseManagersModalProps> = ({
  visible,
  onClose,
}) => {
  const dispatch = useDispatch()
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const apiMode: IActionType | undefined = useSelector(
    selectSubscriptionDetailsApiMode
  )
  const loading: boolean =
    useSelector(selectSubscriptionDetailsLoading) &&
    apiMode === ActionType.CREATE_LICENSE_MANAGER_REQUEST
  const loaded: boolean | undefined =
    useSelector(selectSubscriptionDetailsLoaded) &&
    apiMode === ActionType.CREATE_LICENSE_MANAGER_REQUEST

  const [values, setValues] = useState<IFormValues>({})

  useEffect(() => {
    if (visible) {
      setValues({})
    }
  }, [dispatch, visible])

  useEffect(() => {
    if (!loading && loaded) {
      dispatch(
        setMessage({
          type: MessageType.SUCCESS,
          message: `Added successfully!`,
        })
      )
      onClose()
    }
  }, [loading, loaded, dispatch, onClose])

  const handleChange = (prop: keyof IFormValues) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setValues({
      ...values,
      [prop]: value,
    })
  }

  const handleClose = () => {
    onClose()
  }

  const handleAddTechnicalManager = async () => {
    if (subscription?.id) {
      const data: User = {
        fullName: values.name,
        email: values.email,
      }
      dispatch(
        createLicenceManagerRequest({
          id: subscription.id,
          data,
        })
      )
    }
  }

  return (
    <div className="add-technical-manager">
      <Modal
        title="License Managers"
        visible={visible}
        width={"50%"}
        maskClosable={false}
        closable={false}
        footer={
          <>
            <Button onClick={handleClose}>
              <CloseCircleOutlined />
              Close
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              onClick={handleAddTechnicalManager}
            >
              <PlusOutlined />
              Add as Team Manager
            </Button>
          </>
        }
      >
        <p>
          You can add Team Managers to manage the members on your behalf. They
          will be able to see and alter only the members of this subscription.
        </p>
        <Input
          value={values.name}
          className="m-t-10"
          addonBefore="Name"
          placeholder="John Doe"
          onChange={handleChange("name")}
        />
        <Input
          value={values.email}
          className="m-t-15"
          addonBefore="Email"
          placeholder="john@mycompany.com"
          onChange={handleChange("email")}
        />
      </Modal>
    </div>
  )
}

export default LicenseManagersModal
