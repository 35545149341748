import React from "react"
import { ColumnType } from "antd/lib/table"
import { formatDate, orBlank } from "src/utils"
import { useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Button, EllipsisFarIcon, Flex, Text } from "@conduktor/ui-library"
import { Cluster } from "src/domain/desktop"
import { showModal } from "src/store/system/actions"
import { ModalType } from "src/domain/desktop/common"
import { selectCluster } from "src/store/desktop/clusters/actions"
import { EllipsisText } from "src/styles/overflow"

export const useClustersColumns = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleEditCluster = (record: Cluster) => {
    dispatch(selectCluster(record))
    dispatch(showModal(ModalType.CLUSTER))
  }

  const columns: ColumnType<Cluster>[] = [
    {
      title: "Name",
      align: "left",
      showSorterTooltip: false,
      sorter: (a, b) => orBlank(a.name).localeCompare(orBlank(b.name)),
      render: (_text, record) => {
        return (
          <span>
            <Text css={{ color: "$neutral12" }}>{record.name}</Text>
            <div className="hint-text">{record.description}</div>
          </span>
        )
      },
    },
    {
      title: "Cluster ID",
      dataIndex: "clusterId",
      width: 200,
      render: (text) => {
        return (
          <Flex css={{ maxWidth: 180 }}>
            <EllipsisText>{text}</EllipsisText>
          </Flex>
        )
      },
    },
    {
      title: "Created",
      align: "center",
      width: 140,
      render: (_text, record) => {
        return (
          <span>
            {formatDate(record.createdAt)}
            <div className="hint-text">by {record.createdBy?.fullName}</div>
          </span>
        )
      },
    },
    {
      title: "Last Update",
      align: "center",
      width: 140,
      render: (_text, record) => {
        return (
          <span>
            {formatDate(record.updatedAt)}
            <div className="hint-text">by {record.updatedBy?.fullName}</div>
          </span>
        )
      },
    },
    {
      key: "action",
      title: "",
      align: "center",
      width: 220,
      render: (_text, record) => {
        return (
          <Flex
            align={"center"}
            direction={"column"}
            css={{ float: "right", "@bp3": { flexDirection: "row" } }}
          >
            <Link to={`${history.location.pathname}/${record.clusterId}`}>
              <Button variant={"secondaryOutline"}>Manage access</Button>
            </Link>
            <Button
              variant={"secondaryTransparent"}
              onClick={() => handleEditCluster(record)}
            >
              <EllipsisFarIcon fr />
            </Button>
          </Flex>
        )
      },
    },
  ]
  return columns
}
