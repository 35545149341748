import React, { useState } from "react"
import { Alert, Form, Input, Modal } from "antd"
import { isClientError, orBlank } from "../../../../utils"
import { ConduktorUser } from "../../../../domain/system"

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

interface FormValues {
  email: string
  fullName: string
  pictureUrl: string
}

export interface ModalConfig {
  user?: ConduktorUser
  isUpdate?: boolean
  isVisible: boolean
}

interface Props {
  config: ModalConfig
  onClose: () => void
  onSubmit: (user: FormValues, config: ModalConfig) => Promise<void>
}

export const AdminModal: React.FC<Props> = ({ config, onSubmit, onClose }) => {
  const [form] = Form.useForm<FormValues>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isConfirmLoading, setIsConfirmationLoading] = useState(false)

  const create = (values: FormValues) => {
    setIsConfirmationLoading(true)
    setErrorMessage(undefined)
    onSubmit(
      {
        fullName: values.fullName,
        email: values.email,
        pictureUrl: values.pictureUrl,
      },
      config
    )
      .then(() => {
        setIsConfirmationLoading(false)
        onClose()
      })
      .catch((e) => {
        setIsConfirmationLoading(false)
        setErrorMessage(
          isClientError(e)
            ? "The user seems invalid, please retry with other settings"
            : "Sorry, an unexpected error occurred"
        )
      })
  }

  return (
    <Modal
      title="Administrator"
      visible={config.isVisible}
      onOk={form.submit}
      confirmLoading={isConfirmLoading}
      onCancel={onClose}
    >
      {config.isVisible && (
        <Form {...layout} form={form} onFinish={create}>
          <Form.Item
            name="fullName"
            label="Full name"
            rules={[{ required: true }]}
            initialValue={orBlank(config.user?.fullName)}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ type: "email" }, { required: true }]}
            initialValue={orBlank(config.user?.email)}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="pictureUrl"
            label="Picture"
            rules={[{ required: false }]}
            initialValue={orBlank(config.user?.pictureUrl)}
          >
            <Input />
          </Form.Item>
          {errorMessage && <Alert type="error" message={errorMessage} />}
        </Form>
      )}
    </Modal>
  )
}
