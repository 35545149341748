import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import { selectConduktorUser } from "src/store/system/selector"
import { getSubscriptionRequest } from "src/store/desktop/subscriptionDetails/actions"
import { setMessage } from "src/store/system/actions"
import { getSubscriptionsRequest } from "src/store/desktop/subscriptions/actions"
import {
  estimateLicensesApi,
  generateQuoteLicensesApi,
  increaseLicensesApi,
} from "src/services"
import { Modal } from "antd"
import { isEmpty } from "lodash"
import IncreaseLicensesModalFooter from "./components/Footer"
import IncreaseLicensesModalBody from "./components/Body"
import {
  ChargeBeeSubscription,
  EstimateChange,
  IncreaseLicensesApiPayload,
  IncreaseLicensesModalProps,
  IncreaseLicnesesFormValues,
  InvoiceEstimate,
  LineItem,
  Subscription,
} from "src/domain/desktop"
import { AppConfig } from "src/core/config"
import { estimateLicenseChanges } from "./estimateLicenseChanges"
import { or0 } from "../../../utils"
import { isSubscriptionRenewalIsLessThan6MonthsMethod } from "src/domain/desktop/subscription"
import { ConduktorUser, MessageType } from "../../../domain/system"
import { UserRoleType } from "../../../domain/desktop/common"
import { isValidUser } from "../../../domain/user"

const IncreaseLicensesModal: React.FC<IncreaseLicensesModalProps> = ({
  visible,
  onClose,
}) => {
  const dispatch = useDispatch()

  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const subscription: Subscription | undefined = useSelector(selectSubscription)

  const [values, setValues] = useState<IncreaseLicnesesFormValues>({})
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [
    currentInvoiceEstimate,
    setCurrentInvoiceEstimate,
  ] = useState<InvoiceEstimate>({})
  const [
    nextInvoiceEstimate,
    setNextInvoiceEstimate,
  ] = useState<InvoiceEstimate>({})

  const [
    estimateCurrentChange,
    setEstimateCurrentChange,
  ] = useState<EstimateChange>({})
  const [
    isCustomerHaveValidCardDetails,
    setIsCustomerHaveValidCardDetails,
  ] = useState<boolean>(true)
  const [limitExceeded, setLimitExceeded] = useState<boolean>(false)
  const [
    isSubscriptionRenewalIsLessThan6Months,
    setIsSubscriptionRenewalIsLessThan6Months,
  ] = useState<boolean>(false)
  const [termsOk, setTermOk] = useState<boolean>(false)
  const [notAReseller, setNotAReseller] = useState<boolean>(false)
  const [amountDue, setAmountDue] = useState(0)
  const [
    chargeBeeSubscription,
    setChargeBeeSubscription,
  ] = useState<ChargeBeeSubscription>({})
  const [invoicePending, setInvoicePending] = useState<boolean>(false)
  const [subTotal, setSubTotal] = useState<number>(0)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [currentInvoiceLineItems, setCurrentInvoiceLineItems] = useState<
    LineItem[]
  >([])
  const [hostedPageURL, setHostedPageURL] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const handleChange = (prop: keyof IncreaseLicnesesFormValues) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowDetails(false)
    setLimitExceeded(false)
    const value = Math.abs(+event.target.value)

    setValues({
      ...values,
      [prop]: value,
    })
  }

  const handleSetTermOk = (e: boolean) => {
    setTermOk(e)
  }

  const handleSetNotAReseller = (e: boolean) => {
    setNotAReseller(e)
  }

  const handleBuyLicenses = async () => {
    if (!subscription || !values.quantity) return
    setLoading(true)
    try {
      const payload: IncreaseLicensesApiPayload = {
        subscriptionId: subscription?.id,
        quantity: +values.quantity,
      }
      const data: {
        error?: Object
        message?: string
      } = await increaseLicensesApi(payload)
      if (data.error) {
        dispatch(
          setMessage({
            message: data.message,
          })
        )
      } else if (isValidUser(conduktorUser, [UserRoleType.ADMIN])) {
        dispatch(getSubscriptionRequest(subscription.chargeBeeSubscriptionId))
      } else {
        dispatch(getSubscriptionsRequest())
      }
      dispatch(
        setMessage({
          type: MessageType.SUCCESS,
          message: "Licenses added successfully",
        })
      )
    } catch (error) {
      dispatch(
        setMessage({
          message: "Error adding licenses, pleas try another time",
        })
      )
    }
    setLoading(false)
  }

  const handleUpdatePaymentDetails = () => {
    onClose()
    window.open(hostedPageURL, "_blank")
    reset()
  }

  const handleGenerateQuote = async () => {
    if (values.quantity && subscription) {
      setLoading(true)
      try {
        const payload: IncreaseLicensesApiPayload = {
          subscriptionId: subscription?.id,
          quantity: +values.quantity + subscription.quantity,
        }
        const data: {
          downloadLink?: string
          error?: Object
          message?: string
        } = await generateQuoteLicensesApi(payload)
        if (data.error) {
          dispatch(
            setMessage({
              message: data.message,
            })
          )
        } else {
          window.open(data.downloadLink, "_blank")
        }
      } catch (error) {
        dispatch(
          setMessage({
            message: `Error generating quote. Please contact us. ${AppConfig.website.contact.email}`,
          })
        )
      }
      setLoading(false)
    } else {
      dispatch(
        setMessage({
          message: `Please enter a valid quantity`,
        })
      )
    }
  }

  const handleGetLicensesEstimate = async () => {
    if (!values.quantity || !subscription) {
      dispatch(
        setMessage({
          message: `Please enter a valid quantity`,
        })
      )
      return
    }

    try {
      setLoading(true)
      setLimitExceeded(false)

      const payload: IncreaseLicensesApiPayload = {
        subscriptionId: subscription?.id,
        quantity: +values.quantity + subscription.quantity,
      }
      /*if (subscription.type === "professional" && payload.quantity > 10) {
        setLoading(false)
        setLimitExceeded(true)
        return
      }
      if (isSubscriptionRenewalIsLessThan6MonthsMethod(subscription)) {
        setLoading(false)
        setIsSubscriptionRenewalIsLessThan6Months(true)
        return
      }*/

      const estimate = await estimateLicensesApi(payload)
      const invoice =
        estimate.estimateCurrentChange.invoice_estimate ||
        estimate.estimateCurrentChange.next_invoice_estimate ||
        {}
      const invoiceEstimate = estimateLicenseChanges(estimate, invoice)

      setCurrentInvoiceEstimate(invoice)
      setSubTotal(or0(invoiceEstimate.sub_total))
      setTotalAmount(or0(invoiceEstimate.total))
      setAmountDue(or0(invoiceEstimate.amount_due))
      setCurrentInvoiceLineItems(invoiceEstimate.line_items)
      setEstimateCurrentChange(estimate.estimateCurrentChange)
      setNextInvoiceEstimate(
        estimate.estimateRenewal.next_invoice_estimate ||
          estimate.estimateRenewal.invoice_estimate ||
          {}
      )
      setChargeBeeSubscription(
        estimate.estimateCurrentChange?.subscription_estimate || {}
      )

      if (estimate.updatePaymentMethodURL) {
        setIsCustomerHaveValidCardDetails(false)
        setHostedPageURL(estimate.updatePaymentMethodURL)
      }
      if (!isEmpty(currentInvoiceEstimate.line_items)) {
        setInvoicePending(
          or0(currentInvoiceEstimate.line_items![0].quantity) > values.quantity
        )
      }

      setShowDetails(true)
    } catch (error) {
      console.log(error)
      dispatch(
        setMessage({
          message: `Error getting estimation. Please contact us. ${AppConfig.website.contact.email}`,
        })
      )
    } finally {
      setLoading(false)
    }
  }

  const reset = () => {
    setShowDetails(false)
    setLimitExceeded(false)
    setIsSubscriptionRenewalIsLessThan6Months(false)
    setEstimateCurrentChange({})
    setCurrentInvoiceEstimate({})
    setSubTotal(0)
    setTotalAmount(0)
    setAmountDue(0)
    setNextInvoiceEstimate({})
    setCurrentInvoiceLineItems([])
    setIsCustomerHaveValidCardDetails(true)
    setHostedPageURL("")
  }

  return (
    <div className="addLicense">
      <Modal
        title="Purchase more licenses"
        className="p-t-0"
        visible={visible}
        width="660px"
        maskClosable={false}
        closable={false}
        footer={
          <IncreaseLicensesModalFooter
            onBuyLicense={handleBuyLicenses}
            onUpdatePaymentDetails={handleUpdatePaymentDetails}
            onGenerateQuote={handleGenerateQuote}
            onClose={() => onClose()}
            values={values}
            showDetails={showDetails}
            currentInvoiceEstimate={currentInvoiceEstimate}
            isCustomerHaveValidCardDetails={isCustomerHaveValidCardDetails}
            limitExceeded={limitExceeded}
            isSubscriptionRenewalIsLessThan6Months={
              isSubscriptionRenewalIsLessThan6Months
            }
            loading={loading}
            termsOk={termsOk}
            notAReseller={notAReseller}
            amountDue={amountDue}
            currency={chargeBeeSubscription?.currency_code}
          />
        }
      >
        <IncreaseLicensesModalBody
          values={values}
          showDetails={showDetails}
          currentInvoiceEstimate={currentInvoiceEstimate}
          limitExceeded={limitExceeded}
          isSubscriptionRenewalIsLessThan6Months={
            isSubscriptionRenewalIsLessThan6Months
          }
          loading={loading}
          termsOk={termsOk}
          notAReseller={notAReseller}
          amountDue={amountDue}
          currency={chargeBeeSubscription?.currency_code}
          estimateCurrentChange={estimateCurrentChange}
          nextInvoiceEstimate={nextInvoiceEstimate}
          chargeBeeSubscription={chargeBeeSubscription}
          invoicePending={invoicePending}
          currentInvoiceLineItems={currentInvoiceLineItems}
          subTotal={subTotal}
          totalAmount={totalAmount}
          onChange={handleChange}
          onGetLicensesEstimate={handleGetLicensesEstimate}
          onSetTermOk={handleSetTermOk}
          onSetNotAReseller={handleSetNotAReseller}
        />
      </Modal>
    </div>
  )
}

export default IncreaseLicensesModal
