import { map, sumBy } from "lodash"
import {
  EstimateLicensesApiResult,
  InvoiceEstimate,
} from "../../../domain/desktop"
import { or0, orEmpty } from "../../../utils"

export const estimateLicenseChanges = (
  estimate: EstimateLicensesApiResult,
  invoice: InvoiceEstimate
): Required<InvoiceEstimate> => {
  const initialEstimate: Required<InvoiceEstimate> = {
    taxes: [],
    discounts: [],
    line_items: orEmpty(invoice.line_items),
    sub_total: or0(invoice.sub_total),
    total: or0(invoice.total),
    amount_due: or0(invoice.amount_due),
  }

  if (!estimate.unBilledCharges?.estimate) {
    return initialEstimate
  }

  return estimate.unBilledCharges.estimate.invoice_estimates.reduce(
    (newEstimate, invoice) => {
      newEstimate.discounts = [
        ...newEstimate.discounts!,
        ...map(invoice.discounts, (discountObjectParent) => {
          if (invoice.discounts) {
            invoice.discounts.forEach((discountObjectChild) => {
              if (
                discountObjectParent.entity_id === discountObjectChild.entity_id
              ) {
                discountObjectParent.amount =
                  or0(discountObjectParent.amount) +
                  or0(discountObjectChild.amount)
              }
            })
          }
          return discountObjectParent
        }),
      ]
      newEstimate.taxes = [
        ...newEstimate.taxes!,
        ...map(invoice.taxes, (taxesObjectParent) => {
          if (invoice.taxes) {
            invoice.taxes.forEach((taxesObjectChild) => {
              if (taxesObjectParent.name === taxesObjectChild.name) {
                taxesObjectParent.amount =
                  or0(taxesObjectParent.amount) + or0(taxesObjectChild.amount)
              }
            })
          }
          return taxesObjectParent
        }),
      ]
      newEstimate.line_items = [
        ...newEstimate.line_items!,
        ...orEmpty(invoice.line_items),
      ]
      newEstimate.sub_total =
        newEstimate.sub_total! + sumBy(invoice.line_items, "amount")
      newEstimate.total = newEstimate.total! + or0(invoice.total)
      newEstimate.amount_due = newEstimate.amount_due! + or0(invoice.amount_due)
      return newEstimate
    },
    initialEstimate
  ) as Required<InvoiceEstimate>
}
