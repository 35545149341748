import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setMessage } from "src/store/system/actions"
import { Button, InputNumber, Spin } from "antd"
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons"
import "./styles.less"

interface IEditableNumberProps {
  value?: number
  loading?: boolean
  disabled?: boolean
  onChange?: Function
}

const EditableNumber: React.FC<IEditableNumberProps> = ({
  value,
  loading = false,
  disabled,
  onChange,
}) => {
  const dispatch = useDispatch()
  const [newValue, setNewValue] = useState<number | undefined>(0)
  const [editable, setEditable] = useState(false)

  useEffect(() => {
    setNewValue(value)
  }, [value])

  const handleChange = (change: number) => {
    setNewValue(change)
  }

  const handleSave = () => {
    if ((newValue || 0) > -1) {
      setEditable(false)
      if (onChange) {
        onChange(newValue)
      }
    } else {
      dispatch(
        setMessage({
          message: `Please enter name`,
        })
      )
    }
  }

  const handleCancel = () => {
    setEditable(false)
    setNewValue(value)
  }

  const handleEdit = () => {
    setNewValue(value)
    setEditable(true)
  }

  return (
    <div className="editable-number">
      {editable ? (
        <div className="editable-cell-input-wrapper p-t-5">
          <InputNumber
            value={newValue}
            className="m-l-5"
            size="small"
            type="number"
            min={0}
            onChange={handleChange}
          />
          <CheckOutlined
            className="editable-cell-icon-check"
            onClick={handleSave}
          />
          <CloseOutlined
            className="editable-cell-icon-cross"
            onClick={handleCancel}
          />
        </div>
      ) : (
        <div className="editable-cell-text-wrapper p-t-5">
          <Spin spinning={loading}>
            <div className="spin-content sub-title">
              <span>{newValue}</span>
              <Button
                size="small"
                type="link"
                disabled={disabled}
                className="editable-cell-icon-pencil"
                onClick={handleEdit}
              >
                <EditOutlined type="edit" />
              </Button>
            </div>
          </Spin>
        </div>
      )}
    </div>
  )
}
export default EditableNumber
