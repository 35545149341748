import { Link } from "react-router-dom"
import "./styles.less"
import { usePageView } from "src/core/analytics/hooks"
import { RouteKey } from "src/router/routes"

const NotFoundPage = () => {
  usePageView(RouteKey.NOT_FOUND)

  return (
    <div className="not-found">
      <h1 className="main-title">404 - Page not found</h1>
      <br />
      <Link className="ant-btn" to="/">
        Go home
      </Link>
    </div>
  )
}
export default NotFoundPage
