import { Flex, styled, Text } from "@conduktor/ui-library"

export const TopicsControlPanel = styled(Flex, {
  gap: "$2",
  alignItems: "center",
  justifyContent: "space-between",

  [`& ${Text}`]: {
    fontWeight: "$semi",
    fontSize: "$1",
  },
})

export const AddMembersPermissions = styled(Flex, {
  alignItems: "center",
  color: "$primary11",
  gap: "$2",
  fontSize: "$1",
  cursor: "pointer",

  [`&:hover`]: {
    color: "$primary10",
  },
})
