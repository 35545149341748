import {
  AccessControl,
  AccessControlMemberApiPayload,
  createAction,
  GetAccessControlApiPayload,
  RemoveAccessControlMemberApiPayload,
} from "src/domain/desktop"
import { AccessControlActionType } from "../../../domain/desktop/accessControlTypes"

// get members
export const getAccessControlRequest = createAction<GetAccessControlApiPayload>(
  AccessControlActionType.GET_ACCESS_CONTROL_REQUEST
)
export const getAccessControlSuccess = createAction<AccessControl>(
  AccessControlActionType.GET_ACCESS_CONTROL_SUCCESS
)
export const getAccessControlFailure = createAction<string>(
  AccessControlActionType.GET_ACCESS_CONTROL_FAILURE
)

// update one member
export const updateAccessControlMemberSuccess = createAction<AccessControlMemberApiPayload>(
  AccessControlActionType.UPDATE_ACCESS_CONTROL_MEMBER_SUCCESS
)

// remove one member
export const removeAccessControlMemberSuccess = createAction<RemoveAccessControlMemberApiPayload>(
  AccessControlActionType.REMOVE_ACCESS_CONTROL_MEMBER_SUCCESS
)
