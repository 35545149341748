import { ActionType, AdminDashboard } from "src/domain/desktop"

export const getAdminDashboardDataRequest = () => ({
  type: ActionType.GET_ADMIN_DASHBOARD_DATA_REQUEST,
})

export const getAdminDashboardDataSuccess = (payload: AdminDashboard) => ({
  type: ActionType.GET_ADMIN_DASHBOARD_DATA_SUCCESS,
  payload,
})

export const getAdminDashboardDataFailure = (payload: string) => ({
  type: ActionType.GET_ADMIN_DASHBOARD_DATA_FAILURE,
  payload,
})
