import {
  Auth0Stats,
  DeleteSubscriptionItemApiPayload,
  DomainPayload,
  DomainUsers,
  GetAdminSubscriptionsApiPayload,
  MoveLicensesApiPayload,
  OAuthUser,
  SeedData,
  SentEmail,
  SetMaxActivationPayload,
  Subscription,
  UserEmailPayload,
  UserLog,
} from "src/domain/desktop"
import { desktopClient, usersClient } from "../axiosClients"
import {
  ConduktorUserIdPayload,
  ConduktorUserUpdate,
} from "../../domain/desktop/admin/adminsTypes"
import { Auth0User, ConduktorUser, User } from "../../domain/system"

export const getAdminDashboardSubscriptionsDetailsApi = async () => {
  const { data } = await desktopClient.get(
    "admin/dashboard/subscriptions/details"
  )
  return data
}

export const getAdminSubscriptionsApi = async (
  payload: GetAdminSubscriptionsApiPayload
) => {
  const { data } = await desktopClient.get("admin/subscriptions", payload)
  return data
}

export const updateAdminSubscriptionApi = async (payload: Subscription) => {
  const { data } = await desktopClient.put("admin/subscriptions", payload)
  return data
}

export const getAdminSubscriptionDetailsApi = async (paylaod: string) => {
  const { data } = await desktopClient.get(`admin/${paylaod}`)
  return data
}

export const getAdminLicensesRemovedCountApi = async (payload?: number) => {
  const { data } = await desktopClient.get(
    `admin/licenses-removed-count${payload !== undefined ? "/" + payload : ""}`
  )
  return data
}

export const createAdminBillingUserOfSubscriptoinApi = async ({
  id,
  data: payload,
}: {
  id: number
  data: User
}) => {
  const { data } = await desktopClient.post(
    `admin/billing-user-of-subscription/${id}`,
    payload
  )
  return data
}

export const deleteBillingUserApi = async ({
  subscriptionId,
  id,
}: DeleteSubscriptionItemApiPayload) => {
  const { data } = await desktopClient.delete(
    `admin/billing-user-of-subscription/${subscriptionId}/${id}`
  )
  return data
}

export const moveLicensesApi = async (payload: MoveLicensesApiPayload) => {
  const { data } = await desktopClient.post(`admin/move-licenses`, payload)
  return data
}

export const getChargebeeSubscriptionApi = async (payload: number | string) => {
  const { data } = await desktopClient.get(
    `admin/chargebee-subscription/${payload}`
  )
  return data
}

export const createFullSubscriptionApi = async (payload: SeedData) => {
  const { data } = await desktopClient.post(
    `admin/create-full-subscription`,
    payload
  )
  return data
}

export const getAdminUsersApi = async (): Promise<ConduktorUser[]> => {
  const { data } = await desktopClient.get(`admin/users`)
  return data
}
export const deleteAdminUserApi = async (
  payload: ConduktorUserIdPayload
): Promise<void> => {
  await desktopClient.delete(`admin/users/${payload.id}`)
}
export const createAdminUserApi = async (
  payload: ConduktorUserUpdate
): Promise<void> => {
  await desktopClient.post(`admin/users`, payload)
}
export const updateAdminUserApi = async (
  id: number,
  payload: ConduktorUserUpdate
): Promise<void> => {
  await desktopClient.put(`admin/users/${id}`, payload)
}

export const sendUserReactivateTrialApi = async ({
  email,
}: UserEmailPayload): Promise<void> => {
  await desktopClient.post(
    `admin/trial/reactivate?email=${encodeURIComponent(email)}`
  )
}

// USERS API

export const getOAuthUserApi = async ({
  email,
}: UserEmailPayload): Promise<OAuthUser> => {
  const { data } = await usersClient.get(
    `users/extended?email=${encodeURIComponent(email)}`
  )
  return data
}

export const getOAuthUserEmailsApi = async ({
  email,
}: UserEmailPayload): Promise<SentEmail[]> => {
  try {
    const { data } = await usersClient.get(
      `users/emails?email=${encodeURIComponent(email)}`
    )
    return data
  } catch (e) {
    return []
  }
}

export const getOAuthUserLogsApi = async ({
  email,
}: UserEmailPayload): Promise<UserLog[]> => {
  const { data } = await usersClient.get(
    `users/logs?email=${encodeURIComponent(email)}`
  )
  return data
}

export const getOAuthUserRefreshTokenApi = async ({
  email,
}: UserEmailPayload): Promise<string[]> => {
  try {
    const { data } = await usersClient.get(
      `users/refresh-tokens?email=${encodeURIComponent(email)}`
    )
    return data
  } catch (e) {
    return []
  }
}

export const deleteOAuthUserApi = async ({
  email,
}: UserEmailPayload): Promise<void> => {
  await usersClient.delete(`users?email=${encodeURIComponent(email)}`)
}

export const sendOAuthUserResetPasswordEmailApi = async ({
  email,
}: UserEmailPayload): Promise<void> => {
  try {
    await usersClient.get(
      `users/reset-password?email=${encodeURIComponent(email)}`
    )
  } catch (e) {
    if (e.response?.data?.msg) {
      throw new Error(e.response.data.msg)
    }
    throw e
  }
}

export const sendOAuthUserVerificationEmailApi = async ({
  email,
}: UserEmailPayload): Promise<void> => {
  await usersClient.get(
    `users/send-verification-email?email=${encodeURIComponent(email)}`
  )
}

export const clearOAuthUserFingerprintsApi = async ({
  email,
}: UserEmailPayload): Promise<void> => {
  await usersClient.post(`users/clear-fingerprints`, {
    emails: [email],
  })
}

export const clearOAuthUserRefreshTokensApi = async ({
  email,
}: UserEmailPayload): Promise<void> => {
  await usersClient.post(`users/clear-refresh-tokens`, {
    emails: [email],
  })
}

export const setOAuthUserMaxActivationsApi = async ({
  email,
  max,
}: SetMaxActivationPayload): Promise<void> => {
  await usersClient.post(`users/set-max-activations`, {
    emails: [email],
    maxActivations: max,
  })
}

export const getDomainUsersApi = async ({
  domainName,
}: DomainPayload): Promise<DomainUsers> => {
  const { data } = await usersClient.get(
    `auth0/domain/${encodeURIComponent(domainName)}`
  )
  return data
}

export const searchForAuth0users = async (
  query: string
): Promise<Auth0User[]> => {
  const q = encodeURIComponent(query)
  const { data } = await usersClient.get(
    `auth0/users?query=email:${q} OR given_name:${q} OR family_name:${q}`
  )
  return data
}

export const getAuth0StatsApi = async (): Promise<Auth0Stats> => {
  const { data } = await usersClient.get(`auth0/stats`)
  return data
}
