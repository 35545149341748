import { useWindowWidth } from "@react-hook/window-size"
import React from "react"
import { useSelector } from "react-redux"
import { Card, Spin, Table } from "antd"
import { ColumnType } from "antd/lib/table"
import { selectAuth0User } from "src/store/system/selector"
import { formatDate } from "src/utils"
import { Auth0User } from "../../../../../domain/system"

const AuthUserInfo: React.FC = () => {
  const width: number = useWindowWidth()
  const auth0User = useSelector(selectAuth0User)
  const isLicenseExpired =
    formatDate(auth0User.appMetadata?.subscription_expiry) <
    formatDate(new Date())

  const auth0UsersColumns: ColumnType<Auth0User>[] = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      ellipsis: true,
    },
    {
      title: "Plan",
      render: () => {
        return <span>{auth0User.appMetadata?.subscription_type || "Free"}</span>
      },
    },
  ]

  if (
    auth0User.appMetadata &&
    auth0User.appMetadata.subscription_type &&
    auth0User.appMetadata.subscription_type !== "free" &&
    !isLicenseExpired
  ) {
    auth0UsersColumns.push({
      title: "Expiry",
      render: () => {
        return <span>{auth0User.appMetadata?.subscription_expiry}</span>
      },
    })
  }

  if (!auth0User.email) {
    return <Spin tip="Loading..." />
  }

  return (
    <div className="subscriber-info">
      <Card className="card" title="Your Subscriptions" bordered={false}>
        <Table
          pagination={false}
          columns={auth0UsersColumns}
          dataSource={[auth0User]}
          rowKey="email"
          scroll={width < 1285 ? { x: "max-content" } : undefined}
        />
      </Card>
    </div>
  )
}

export default AuthUserInfo
