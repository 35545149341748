import { Action, ActionType, ApiStatus } from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import { initReducer, requestReducer, successReducer } from "src/store/reducers"
import {
  hasSameTarget,
  TopicsControl,
  TopicControlTarget,
  TopicControl,
} from "src/domain/desktop/topicPermission"
import { orEmpty, replace } from "../../../utils"

export type TopicsControlReducerType = ApiStatus & { control?: TopicsControl }

export const defaultState: TopicsControlReducerType = {
  loading: false,
  loaded: false,
  error: undefined,
  type: undefined,

  control: undefined,
}

function getTopicsControlSuccessReducer(
  state: TopicsControlReducerType,
  { payload }: Action<TopicsControl>
): TopicsControlReducerType {
  return successReducer({
    ...state,
    control: payload,
  })
}

function removeTopicControlReducer(
  state: TopicsControlReducerType,
  { payload }: Action<TopicControlTarget>
): TopicsControlReducerType {
  const sameTarget = hasSameTarget(payload.topicClusterId, payload.topicName)
  return {
    ...state,
    control: state.control?.filter((topic) => !sameTarget(topic)),
  }
}

function upsertTopicControlReducer(
  state: TopicsControlReducerType,
  { payload }: Action<TopicControl>
): TopicsControlReducerType {
  const sameTarget = hasSameTarget(
    payload.target.topicClusterId,
    payload.target.topicName
  )
  const currentsControl = orEmpty(state.control)
  const index = currentsControl.findIndex(sameTarget)

  if (index === -1) {
    return {
      ...state,
      control: [...currentsControl, payload],
    }
  }

  return {
    ...state,
    control: replace(index, payload, currentsControl),
  }
}

export const topicsControlReducer = createReducer<TopicsControlReducerType>(
  defaultState,
  {
    [ActionType.INIT_STORE]: initReducer(defaultState),
    [ActionType.GET_TOPICS_CONTROL_REQUEST]: requestReducer,
    [ActionType.GET_TOPICS_CONTROL_SUCCESS]: getTopicsControlSuccessReducer,
    [ActionType.REMOVE_TOPIC_CONTROL]: removeTopicControlReducer,
    [ActionType.UPSERT_TOPIC_CONTROL]: upsertTopicControlReducer,
  }
)
