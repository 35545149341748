import { takeLatest, put, select, all } from "redux-saga/effects"
import { getLicenseLogsApi } from "src/services"
import { ActionType, GetItemsApiPayload, Log } from "src/domain/desktop"
import { TablePaginationConfig } from "antd"
import { parseError } from "src/utils"
import { getLogsSuccess, getLogsFailure, getLogsRequest } from "./actions"
import { AppState } from "../../config/rootStore"
import { selectLogsPagination, selectLogsSearchQuery } from "./selector"
import { ClientError } from "../../../services/ClientError"

function* getLogsSaga() {
  try {
    const state: AppState = yield select()

    const searchQuery: string | undefined = selectLogsSearchQuery(state)

    const pagination: TablePaginationConfig = selectLogsPagination(state)

    const payload: GetItemsApiPayload = {
      params: {
        page: pagination.current,
        results: pagination.pageSize,
        searchQuery: searchQuery ? searchQuery : undefined,
      },
    }

    const data: {
      totalCount: number
      licenseLoggingList: Log[]
    } = yield getLicenseLogsApi(payload)

    yield put(getLogsSuccess(data))
  } catch (err) {
    yield put(getLogsFailure(parseError(err as ClientError)))
  }
}

function* setLogsPaginationSaga() {
  yield put(getLogsRequest())
}

export default function* licensesSagas() {
  yield all([
    takeLatest(ActionType.GET_LOGS_REQUEST, getLogsSaga),
    takeLatest(ActionType.SET_LOGS_PAGINATION, setLogsPaginationSaga),
    takeLatest(ActionType.SET_LOGS_SEARCH_QUERY, setLogsPaginationSaga),
  ])
}
