import { styled } from "@conduktor/ui-library"

export const ellipsis = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
}

export const EllipsisText = styled("span", {
  ...ellipsis,
})
