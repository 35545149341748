import { useSegmentContext } from "./segment"
import { useEffect, useState } from "react"
import { RouteKey } from "src/router/routes"
import { Subscription } from "../../domain/desktop"

export const usePageView = (key: RouteKey) => {
  const { page } = useSegmentContext()

  useEffect(() => {
    page(key)
  }, [key, page])
}

export const useSubscriptionPageView = (
  key: RouteKey,
  subscription?: Subscription
) => {
  const [isSent, setIsSent] = useState<boolean>(false)
  const { page } = useSegmentContext()

  useEffect(() => {
    if (subscription && !isSent) {
      page(key, {
        subscriptionId: subscription.id,
        chargeBeeId: subscription.chargeBeeSubscriptionId,
      })
      setIsSent(true)
    }
  }, [key, page, subscription, isSent])
}
