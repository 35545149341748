import { all, put, takeLatest } from "redux-saga/effects"
import { deleteOAuthUserApi, getDomainUsersApi } from "src/services"
import {
  Action,
  ActionType,
  DomainPayload,
  DomainUser,
  DomainUsers,
} from "src/domain/desktop"
import { parseError } from "src/utils"
import {
  deleteDomainUserSuccess,
  getDomainUsersFailure,
  getDomainUsersSuccess,
} from "./actions"
import { setMessage } from "../../../system/actions"
import { MessageType } from "../../../../domain/system"
import { ClientError } from "../../../../services/ClientError"

function* getDomainUsersSaga({ payload }: Action<DomainPayload>) {
  try {
    const users: DomainUsers = yield getDomainUsersApi(payload)

    yield put(getDomainUsersSuccess(users))
  } catch (err) {
    yield put(getDomainUsersFailure(parseError(err as ClientError)))
  }
}

function* deleteDomainUserSaga({ payload }: Action<DomainUser>) {
  try {
    yield deleteOAuthUserApi({ email: payload.email })
    yield put(deleteDomainUserSuccess(payload))
    yield put(
      setMessage({
        type: MessageType.SUCCESS,
        message: "The user has been removed",
      })
    )
  } catch (err) {
    yield put(
      setMessage({
        type: MessageType.ERROR,
        message: "The user can't be removed",
      })
    )
  }
}

export default function* DomainsSagas() {
  yield all([
    takeLatest(ActionType.GET_DOMAIN_USERS_REQUEST, getDomainUsersSaga),
    takeLatest(ActionType.DELETE_DOMAIN_USER_REQUEST, deleteDomainUserSaga),
  ])
}
