import { useDispatch, useSelector } from "react-redux"
import {
  selectSubscription,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsError,
  selectSubscriptionDetailsLoading,
} from "../../store/desktop/subscriptionDetails/selector"
import { Subscription } from "../../domain/desktop"
import { useEffect } from "react"
import {
  clearSubscriptionStateRequest,
  getSubscriptionRequest,
} from "../../store/desktop/subscriptionDetails/actions"
import { setMessage } from "../../store/system/actions"
import { useHistory, useParams } from "react-router-dom"
import { MessageType } from "../../domain/system"

interface RouteParams {
  id?: string
}

export const useSubscriptionQuery = () => {
  const dispatch = useDispatch()
  const { id }: RouteParams = useParams()
  const history = useHistory()
  const error = useSelector(selectSubscriptionDetailsError)
  const loading = useSelector(selectSubscriptionDetailsLoading)
  const mode = useSelector(selectSubscriptionDetailsApiMode)
  const subscription: Subscription | undefined = useSelector(selectSubscription)

  useEffect(() => {
    if (id && !loading && subscription?.chargeBeeSubscriptionId !== id) {
      dispatch(clearSubscriptionStateRequest())
      dispatch(getSubscriptionRequest(id))
    }
  }, [dispatch, id, loading, subscription])

  useEffect(() => {
    if (error && !loading) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: error,
        })
      )
    }
  }, [error])

  useEffect(() => {
    if (id && error) {
      history.push("/subscriptions") /* redirect or 404 */
    }
  }, [error, id])

  const backToSubscription = () =>
    history.push(
      `/subscriptions/licenses-management/${subscription?.chargeBeeSubscriptionId}`
    )

  return {
    chargeBeeId: id,
    subscription,
    error,
    loading,
    mode,
    backToSubscription,
  }
}
