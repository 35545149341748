import { FC, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useDispatch } from "react-redux"

import { Button } from "antd"
import { LoginOutlined } from "@ant-design/icons"
import "./styles.less"

import { history } from "src/store"
import { initStore } from "src/store/system/actions"

interface Props {
  onLogin: () => void
  title?: string
  autoRedirect?: boolean
}

const DEFAULT_TITLE = "Account & Subscription Management"

const LogInPage: FC<Props> = ({ autoRedirect, onLogin, title }) => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth0()

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/")
    } else if (autoRedirect) {
      onLogin()
    } else {
      dispatch(initStore())
    }
  }, [dispatch, isAuthenticated, autoRedirect, onLogin])

  return (
    <div className={"login"}>
      <div className="login-div">
        <div className="logo">
          <img
            className="logo-image"
            src="/assets/logo/logo-black.svg"
            alt="conduktor"
          />
        </div>
        <br />
        <br />
        <label className="main-title">{title || DEFAULT_TITLE}</label>
        <br />
        <Button className="login-button" type="primary" onClick={onLogin}>
          <LoginOutlined />
          Login
        </Button>
      </div>
    </div>
  )
}
export default LogInPage
