import debounce from "awesome-debounce-promise"
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Box,
  Button,
  Flex,
  PlusFarIcon,
  SearchIcon,
  Text,
  TextInput,
  TrashcanIcon,
} from "@conduktor/ui-library"
import { Link } from "react-router-dom"
import {
  SectionDescription,
  SectionTitle,
} from "src/components/Typography/Typography"
import { Section, SectionPanel, SectionText } from "../Section.styled"
import { EmptyState } from "./EmptyState"
import {
  getTopicsControlRequest,
  removeTopicControl,
} from "src/store/desktop/topicsControl/actions"
import { useSubscriptionQuery } from "src/pages/hooks/useSubscriptionQuery"
import { selectSelectedCluster } from "src/store/desktop/clusters/selector"
import {
  selectTopicsControl,
  selectTopicsControlLoading,
} from "src/store/desktop/topicsControl/selector"
import { CenteredSpinner } from "src/components/Spinner"
import { isEmpty } from "src/utils"
import { TopicControl, TopicsControl } from "src/domain/desktop/topicPermission"
import { TopicsControlTopicItem, TopicsControlTopicList } from "./Topics.styled"
import { RouteKey } from "src/router/routes"
import { useRoutePath } from "../useRoutePath.hook"
import { ButtonIcon } from "src/components/Icon/ButtonIcon"
import { removeClusterTopicControlApi } from "src/services"
import { setErrorMessage, setSuccessMessage } from "src/store/system/actions"
import { ConfirmDialog } from "src/components/Dialog/ConfirmDialog"

export const Topics: FC = () => {
  const dispatch = useDispatch()
  const { subscription } = useSubscriptionQuery()
  const cluster = useSelector(selectSelectedCluster)
  const loading = useSelector(selectTopicsControlLoading)
  const topicsControl = useSelector(selectTopicsControl)
  const [searchText, setSearchText] = useState<string>("")

  useEffect(() => {
    if (!subscription?.id || !cluster?.clusterId) {
      return
    }
    dispatch(
      getTopicsControlRequest({
        subscriptionId: subscription.id,
        clusterId: cluster.clusterId,
      })
    )
  }, [dispatch, subscription, cluster])

  return (
    <Section>
      <SectionText small>
        <SectionTitle>Topic permissions</SectionTitle>
        <SectionDescription>
          Define Topic Patterns to grant permissions to specific user groups.
        </SectionDescription>
      </SectionText>
      <SectionPanel>
        {loading ? (
          <CenteredSpinner />
        ) : isEmpty(topicsControl) ? (
          <EmptyState />
        ) : (
          <Flex gap={3} direction={"column"}>
            <TopicsControlTools onSearchChange={setSearchText} />
            <TopicsControlList
              topicsControl={topicsControl!}
              searchText={searchText}
            />
          </Flex>
        )}
      </SectionPanel>
    </Section>
  )
}

export const TopicsControlTools: FC<{
  onSearchChange: (value: string) => void
}> = ({ onSearchChange }) => {
  const path = useRoutePath(RouteKey.TOPIC_CONTROL_CREATION)
  const searchChanged = useMemo(
    () =>
      debounce(
        (e: ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value),
        400
      ),
    [onSearchChange]
  )

  return (
    <Flex gap={2} justify={"space-between"}>
      <Box css={{ maxWidth: "25em" }}>
        <TextInput
          onChange={searchChanged}
          placeholder={"Search Topic Patterns"}
          icon={<SearchIcon fr />}
        />
      </Box>
      <Link to={path}>
        <Button variant={"primary"}>
          <PlusFarIcon fr /> Define topic permissions
        </Button>
      </Link>
    </Flex>
  )
}

export const TopicsControlList: FC<{
  topicsControl: TopicsControl
  searchText: string
}> = ({ topicsControl, searchText }) => {
  const matchSearch = ({ target }: TopicControl) =>
    !searchText || target.topicName.includes(searchText)

  const filteredControls = topicsControl.filter(matchSearch)
  return (
    <TopicsControlTopicList>
      {isEmpty(filteredControls) ? (
        <Flex justify={"center"}>
          <SectionDescription>No matches</SectionDescription>
        </Flex>
      ) : (
        filteredControls.map((control) => (
          <TopicsControlListItem
            key={control.target.topicName}
            topicControl={control}
          />
        ))
      )}
    </TopicsControlTopicList>
  )
}

export const TopicsControlListItem: FC<{ topicControl: TopicControl }> = ({
  topicControl,
}) => {
  const dispatch = useDispatch()
  const { subscription } = useSubscriptionQuery()
  const path = useRoutePath(RouteKey.TOPIC_CONTROL_CREATION)
  const [isRemoving, setRemoving] = useState<boolean>(false)
  const [isRemoveDialogOpen, setRemoveDialogOpen] = useState<boolean>(false)
  const updatePath = `${path}/${topicControl.target.topicName}`

  const remove = async () => {
    try {
      setRemoving(true)
      await removeClusterTopicControlApi({
        subscriptionId: subscription!.id,
        target: topicControl.target,
      })
      dispatch(removeTopicControl(topicControl.target))
      setRemoveDialogOpen(false)
      dispatch(
        setSuccessMessage(
          `Topic control "${topicControl.target.topicName}" removed`
        )
      )
    } catch (e) {
      setRemoving(false)
      dispatch(setErrorMessage(`Error while removing this topic control`))
    }
  }

  return (
    <TopicsControlTopicItem>
      <Text>{topicControl.target.topicName}</Text>
      <Flex gap={3} align={"center"}>
        <Link to={updatePath}>
          <Button variant={"secondaryOutline"} disabled={isRemoving}>
            Manage topic
          </Button>
        </Link>
        <ConfirmDialog
          title={"Delete Topic permissions?"}
          acceptLabel={"Delete topic permissions"}
          acceptVariant={"danger"}
          isAccepting={isRemoving}
          isOpen={isRemoveDialogOpen}
          onAccept={remove}
          onOpenChange={setRemoveDialogOpen}
          trigger={
            <ButtonIcon>
              <TrashcanIcon fr />
            </ButtonIcon>
          }
        >
          <Text>Are you sure you want to delete these topic permissions?</Text>
          <Text css={{ color: "$error11" }}>This action cannot be undone.</Text>
        </ConfirmDialog>
      </Flex>
    </TopicsControlTopicItem>
  )
}
