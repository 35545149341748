import React from "react"
import { DomainUsersTable } from "./components/table"
import { useQuery } from "src/router/useQuery"
import { RouteKey } from "src/router/routes"
import { usePageView } from "src/core/analytics/hooks"

export const DomainDetailsPage = () => {
  usePageView(RouteKey.ADMIN_DOMAINS)
  const query = useQuery()

  return <DomainUsersTable domainName={query.get("name")} />
}
