import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Popconfirm, Row, Table } from "antd"
import { Cluster } from "src/domain/desktop"
import {
  selectCheckedClusters,
  selectClustersList,
} from "src/store/desktop/clusters/selector"
import {
  checkClusters,
  deleteClustersRequest,
} from "src/store/desktop/clusters/actions"
import { useClustersColumns } from "./ClustersColumns"
import { DeleteOutlined } from "@ant-design/icons"
import { Button } from "@conduktor/ui-library"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import { isEmpty } from "src/utils"
import "./clustersTab.less"
import { AddClusterModal } from "../../../../components/Modals/ClusterModal"

const b = "clustersTab"

export const ClustersTab: React.FC = () => {
  const dispatch = useDispatch()

  const clusters: Cluster[] = useSelector(selectClustersList) || []
  const checkedClusters: Cluster[] = useSelector(selectCheckedClusters)
  const subscription = useSelector(selectSubscription)
  const columns = useClustersColumns()

  const handleSelectionChange = (
    _selectedRowKeys: React.Key[],
    selectedRows: Cluster[]
  ) => {
    dispatch(checkClusters(selectedRows))
  }

  const handleDeleteClusters = () => {
    if (!isEmpty(checkedClusters) && subscription?.id) {
      dispatch(deleteClustersRequest(subscription?.id))
    }
  }

  return (
    <div className={b}>
      <h1 className="card-title">
        <Row gutter={10}>
          <Col>
            <AddClusterModal />
          </Col>
          {!isEmpty(checkedClusters) && (
            <Col>
              <Popconfirm
                title="Are you sure you want to remove these cluster(s)?"
                okText="Yes"
                cancelText="No"
                onConfirm={handleDeleteClusters}
              >
                <Button variant="danger">
                  <DeleteOutlined /> Delete ({checkedClusters.length})
                </Button>
              </Popconfirm>
            </Col>
          )}
        </Row>
      </h1>
      <Table
        size={"small"}
        columns={columns}
        dataSource={clusters}
        rowKey="clusterId"
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize: 20,
        }}
        rowSelection={{
          selectedRowKeys: checkedClusters.map((e) => e.clusterId || ""),
          onChange: handleSelectionChange,
        }}
        locale={{ emptyText: "No cluster created yet" }}
      />
    </div>
  )
}
