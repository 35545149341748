import {
  AddLicenseModalFormValues,
  AddLicensesApiPayload,
  Subscription,
  UpdateLicenseApiPayload,
} from "src/domain/desktop"
import { isValidEmail } from "src/utils"

export const getLicenseTokenPayload = (
  values: AddLicenseModalFormValues,
  subscription?: Subscription
) => {
  if (values.offlineCode && values.id) {
    const payload: UpdateLicenseApiPayload = {
      id: values.id,
      name: values.name,
      email: values.email,
      subscription: {
        id: subscription?.id,
      },
      licensesOffline: [
        {
          name: values.offlineName || null,
          code: values.offlineCode,
        },
      ],
    }
    return payload
  }
  return null
}

export const getLicensePayload = (
  values: AddLicenseModalFormValues,
  subscription?: Subscription
) => {
  if (values?.name && values.email && isValidEmail(values.email)) {
    const payload: AddLicensesApiPayload = {
      name: values.name,
      email: values.email,
      subscription: {
        id: subscription?.id,
      },
    }
    if (subscription?.hasOffline) {
      payload.licensesOffline = [
        {
          name: values.offlineName || null,
          code: values.offlineCode,
        },
      ]
    }
    return payload
  }
  return null
}
