import { PermissionGroup } from "src/domain/desktop"

export const brokersOperations: string[] = [
  "brokers.decommission",
  "brokers.rebalance",
  "brokers.restart",
  "brokers.rollingrestart",
]
export const produceOperations: string[] = ["producers", "topics.importdata"]
export const topicsOperations: string[] = [
  "topics.reassignment",
  "topics.partitions.update",
  "topics.replicationfactor.update",
  "topics.leader.election",
  "topics.partitions.cleanup",
]
export const kafkaconnectOperations: string[] = [
  "kafkaconnect.connectors.state.update",
  "kafkaconnect.connectors.resetoffsets",
  "kafkaconnect.connectors.resettopics",
]
export const allOperations: string[] = [
  ...brokersOperations,
  ...produceOperations,
  ...topicsOperations,
  ...kafkaconnectOperations,
]

export const permissionGroups: PermissionGroup[] = [
  {
    group: "Security (ACLs)",
    permissions: [
      {
        name: "Read",
        codes: ["acls"],
      },
      {
        name: "Create",
        codes: ["acls.create"],
      },
      {
        name: "Delete",
        codes: ["acls.delete"],
      },
    ],
  },
  {
    group: "Brokers",
    permissions: [
      {
        name: "Read",
        codes: ["brokers.view"],
      },
      {
        name: "Read Size",
        codes: ["brokers.size.read"],
      },
      {
        name: "Read Config",
        codes: ["brokers.config.read"],
      },
      {
        name: "Update Config",
        codes: ["brokers.config.update"],
      },
      {
        name: "Operations",
        codes: brokersOperations,
      },
    ],
  },
  {
    group: "Consume Topics",
    permissions: [
      {
        name: "Enabled",
        codes: ["consumers"],
      },
      {
        name: "Export data",
        codes: ["consumers.export"],
      },
    ],
  },
  {
    group: "Produce into Topics",
    permissions: [
      {
        name: "Enabled",
        codes: produceOperations,
      },
    ],
  },
  {
    group: "Schema Registry",
    permissions: [
      {
        name: "Read",
        codes: ["schemaregistry"],
      },
      {
        name: "Create Subjects",
        codes: ["schemaregistry.subjects.create"],
      },
      {
        name: "Update Subjects",
        codes: [
          "schemaregistry.subjects.update",
          "schemaregistry.subjects.compatibility.update",
        ],
      },
      {
        name: "Delete Subjects/Schemas",
        codes: [
          "schemaregistry.subjects.delete",
          "schemaregistry.subjects.schema.delete",
        ],
      },
      {
        name: "Change Global Compatibility",
        codes: ["schemaregistry.compatibility.update"],
      },
    ],
  },
  {
    group: "Topics",
    permissions: [
      {
        name: "Read",
        codes: ["topics"],
      },
      {
        name: "Read Size",
        codes: ["topics.size.read"],
      },
      {
        name: "Create",
        codes: ["topics.create"],
      },
      {
        name: "Update",
        codes: ["topics.config.update"],
      },
      {
        name: "Delete",
        codes: ["topics.delete"],
      },
      {
        name: "Operations",
        codes: topicsOperations,
      },
    ],
  },
  {
    group: "Consumer Groups",
    permissions: [
      {
        name: "Read",
        codes: ["consumergroups"],
      },
      {
        name: "Update",
        codes: [
          "consumergroups.offsets.removetopics",
          "consumergroups.members.remove",
        ],
      },
      {
        name: "Delete",
        codes: ["consumergroups.delete"],
      },
      {
        name: "Reset Offsets",
        codes: ["consumergroups.offsets.update"],
      },
    ],
  },
  {
    group: "Kafka Connect",
    permissions: [
      {
        name: "Enabled",
        codes: ["kafkaconnect"],
      },
      {
        name: "Create",
        codes: ["kafkaconnect.connectors.create"],
      },
      {
        name: "Update",
        codes: ["kafkaconnect.connectors.update"],
      },
      {
        name: "Delete",
        codes: ["kafkaconnect.connectors.delete"],
      },
      {
        name: "Operations",
        codes: kafkaconnectOperations,
      },
    ],
  },
  {
    group: "Kafka Streams",
    permissions: [
      {
        name: "Enabled",
        codes: [
          "kstreams",
          "kstreams.create.byurl",
          "kstreams.create.manual",
          "kstreams.delete",
        ],
      },
    ],
  },
  {
    group: "ksqlDB",
    permissions: [
      {
        name: "Enabled",
        codes: ["ksql"],
      },
      {
        name: "Create",
        codes: ["ksql.queries.metadata"],
      },
      {
        name: "Query",
        codes: ["ksql.queries", "ksql.queries.push", "ksql.queries.pull"],
      },
      {
        name: "Delete",
        codes: ["ksql.collections.remove", "ksql.queries.remove"],
      },
    ],
  },
  {
    group: "Monitoring",
    permissions: [
      {
        name: "Enabled",
        codes: ["monitoring"],
      },
    ],
  },
  {
    group: "Zookeeper",
    permissions: [
      {
        name: "Enabled",
        codes: ["zookeeper.access"],
      },
    ],
  },
  {
    group: "Global",
    permissions: [
      {
        name: "Allow Users to Connect",
        codes: ["clusters.connect"],
      },
    ],
  },
]
