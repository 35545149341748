import React, { useState } from "react"
import { Alert, Button, Card, Col, Input, Row } from "antd"
import { MoveLicensesApiPayload } from "src/domain/desktop"
import { RouteKey } from "src/router/routes"
import { moveLicensesApi } from "src/services"
import { Flex } from "../../../../components/Flex"
import { usePageView } from "src/core/analytics/hooks"

const TransferLicensesPage = () => {
  usePageView(RouteKey.ADMIN_TRANSFERT_LICENSES)
  const [payload, setPayload] = useState<MoveLicensesApiPayload>({})
  const [loading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState<{
    message: string
    isError?: boolean
  }>()

  const isValid =
    payload.from && payload.from !== "" && payload.to && payload.to !== ""

  const handleChange = (prop: keyof MoveLicensesApiPayload) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setPayload({
      ...payload,
      [prop]: value,
    })
  }

  const handleMigrateLicenses = async () => {
    setFeedback(undefined)
    if (isValid) {
      if (payload.to === payload.from) {
        setFeedback({
          message: "Source and target subscriptions are same!",
          isError: true,
        })
        return
      }
      setLoading(true)
      try {
        const data: {
          migrated?: boolean
          message?: string
        } = await moveLicensesApi(payload)
        if (data.migrated) {
          setPayload({})
          setFeedback({
            message: data.message!,
          })
        } else {
          setFeedback({
            message: data.message!,
            isError: true,
          })
        }
      } catch (e) {
        setFeedback({
          message: `Error while migrating licenses: ${
            e?.message ? e.message : "unknown cause"
          }`,
          isError: true,
        })
      }
      setLoading(false)
    } else {
      setFeedback({
        message: "Please enter all fields",
        isError: true,
      })
    }
  }

  return (
    <div className="transfer-licenses">
      <Card className="card" title="" bordered={false}>
        <Flex flexDirection={"column"} gap={20}>
          <Row gutter={20}>
            <Col span={9}>
              <label>From</label>
              <Input
                value={payload.from}
                disabled={loading}
                className="m-t-5"
                placeholder="Enter chargeBee Id"
                onChange={handleChange("from")}
              />
            </Col>
            <Col span={9}>
              <label>To</label>
              <Input
                value={payload.to}
                disabled={loading}
                className="m-t-5"
                placeholder="Enter chargeBee Id"
                onChange={handleChange("to")}
              />
            </Col>
            <Col span={6}>
              <label>Click to migrate</label>
              <br />
              <Button
                className="m-t-5"
                loading={loading}
                disabled={loading}
                onClick={handleMigrateLicenses}
              >
                Migrate
              </Button>
            </Col>
          </Row>
          {feedback && (
            <Alert
              message={feedback.message}
              type={feedback.isError ? "error" : "success"}
            />
          )}
        </Flex>
      </Card>
    </div>
  )
}

export default TransferLicensesPage
