import { AutoComplete, Col, Input, Row, SelectProps, Typography } from "antd"
import React, { useCallback, useState } from "react"
import { useHistory } from "react-router-dom"
import { debounce, uniqBy } from "lodash"
import { searchForAuth0users } from "../../../../../services"
import { Auth0User } from "../../../../../domain/system"

interface Props {
  email: string | null
}

export const UserSearchInput: React.FC<Props> = ({ email }) => {
  const history = useHistory()
  const [options, setOptions] = useState<SelectProps<object>["options"]>()
  const [loading, setLoading] = useState<boolean>(false)

  const handleSearch = (email: string) => {
    history.push(`/users?email=${encodeURIComponent(email)}`)
  }

  const renderTitle = (user: Auth0User) => (
    <span>
      <Typography.Text type={"secondary"}>
        {user.name || user.nickname}
      </Typography.Text>
      <Typography.Text italic style={{ float: "right" }}>
        {user.email}
      </Typography.Text>
    </span>
  )

  const handleSearchChange = useCallback(
    debounce((query: string) => {
      setOptions([])
      if (query?.length > 2) {
        setLoading(true)
        searchForAuth0users(`${query}*`)
          .then((users) => users.filter((user) => Boolean(user.email)))
          .then((users) =>
            setOptions(
              uniqBy(users, (user) => user.email).map((user) => ({
                value: user.email!,
                label: renderTitle(user),
              }))
            )
          )
          .then(() => setLoading(false))
      }
    }, 1000),
    []
  )

  return (
    <Row className="m-b-15">
      <Col className="float-left">
        <AutoComplete
          defaultValue={email!}
          dropdownMatchSelectWidth={500}
          style={{ width: 250 }}
          options={options}
          onChange={handleSearchChange}
          onSelect={handleSearch}
        >
          <Input.Search
            placeholder="User email"
            allowClear={true}
            className="search-box"
            loading={loading}
          />
        </AutoComplete>
      </Col>
    </Row>
  )
}
