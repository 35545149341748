import axios from "axios"
import { getAuthToken } from "src/core/auth"

// 0 is the code when the request hasn't completed successfully: cors, timeout?
const NO_REPLY_CODE = 0

const createClient = (envName: string) => {
  const url = process.env[envName]

  if (!url) {
    throw new Error(`FATAL Error, the env variable ${envName} is undefined`)
  }

  const client = axios.create({
    baseURL: url,
    timeout: 100000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })

  client.interceptors.request.use(
    async (config) => {
      const token = getAuthToken()
      if (token) {
        config.headers = {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + token,
          ...config.headers,
        }
      } else {
        delete client.defaults.headers.common.Authorization
      }
      return config
    },
    (error) => Promise.reject(error)
  )

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response?.status || NO_REPLY_CODE
      if (
        status === NO_REPLY_CODE ||
        status === 400 || // could help to spot badly formatted query
        status === 429 || // could help to track rate-limit issues
        status >= 500
      ) {
      }
      return Promise.reject(error)
    }
  )

  return client
}

export const desktopClient = createClient("REACT_APP_API_BASE_URL")
export const apiClient = createClient("REACT_APP_API_URL")
export const techClient = createClient("REACT_APP_TECHNICAL_FEATURE_BASE_URL")
export const usersClient = createClient("REACT_APP_USERS_API_BASE_URL")
export const permissionsClient = createClient(
  "REACT_APP_PERMISSIONS_API_BASE_URL"
)
