import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DesktopRouter } from "./desktop/router"
import { fetchConduktorUser } from "../store/system/actions"
import { selectConduktorUser } from "../store/system/selector"
import { isObjectEmpty } from "../utils"
import { CenteredSpinner } from "../components/Spinner"

export const RouterPrivate: React.FC = () => {
  const dispatch = useDispatch()
  const conduktorUser = useSelector(selectConduktorUser)
  const isUserLoaded = !isObjectEmpty(conduktorUser)

  useEffect(() => {
    if (!isUserLoaded) {
      dispatch(fetchConduktorUser())
    }
  }, [dispatch, isUserLoaded])

  if (!isUserLoaded) {
    return <CenteredSpinner />
  }

  return <DesktopRouter />
}
