import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  selectFeatures,
  selectPlansApiMode,
  selectPlansLoading,
} from "src/store/desktop/plans/selector"
import { updatePlanRequest } from "src/store/desktop/plans/actions"
import { Checkbox, Input, InputNumber } from "antd"
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { ActionType, Plan } from "src/domain/desktop"
import "./styles.less"
interface IEditablePlanFeatureProps {
  code: string
  plan?: Plan
  currentSelectedElementCodeWithPlanName?: string
  onEdit?: Function
}

const EditablePlanFeature: React.FC<IEditablePlanFeatureProps> = ({
  code,
  plan,
  currentSelectedElementCodeWithPlanName,
  onEdit,
}) => {
  const dispatch = useDispatch()
  const features = useSelector(selectFeatures)
  const planLoading = useSelector(selectPlansLoading)
  const isUpdate =
    useSelector(selectPlansApiMode) === ActionType.UPDATE_PLAN_REQUEST
  const loading: boolean = isUpdate && planLoading

  const typeOfField = features.find((obj) => obj.code === code)?.type

  const [newData, setNewData] = useState<Plan>()
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    const newPlan = JSON.parse(JSON.stringify(plan))
    setNewData(newPlan)
  }, [plan])

  useEffect(() => {
    if (
      currentSelectedElementCodeWithPlanName !== `${code}-${plan?.name}` &&
      isEditing
    ) {
      handleCloseEditing()
    }
  }, [currentSelectedElementCodeWithPlanName])

  const handleCheckboxClicked = (e: CheckboxChangeEvent) => {
    const value = e.target.checked ? "enabled" : "disabled"
    const newPlan = JSON.parse(JSON.stringify(plan))
    newPlan.features[code] = value
    setNewData(newPlan)
  }

  const handleInputNumberChanged = (value: number) => {
    const newPlan = JSON.parse(JSON.stringify(plan))
    newPlan.features[code] = value
    setNewData(newPlan)
  }

  const handleInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const newPlan = JSON.parse(JSON.stringify(plan))
    newPlan.features[code] = value
    setNewData(newPlan)
  }

  const handleEnableEditing = () => {
    setIsEditing(true)
    if (onEdit) {
      onEdit(plan?.name, code)
    }
  }

  const handleUpdate = () => {
    if (!newData || !plan) return
    if (JSON.stringify(newData) !== JSON.stringify(plan)) {
      dispatch(updatePlanRequest(newData))
    }
  }

  const handleCloseEditing = () => {
    const newPlan = JSON.parse(JSON.stringify(plan))
    setNewData(newPlan)
    setIsEditing(false)
  }
  return (
    <div className="editable-plan-feature">
      {!isEditing && !loading && (
        <EditOutlined className="edit-icon" onClick={handleEnableEditing} />
      )}
      {isEditing && !loading && (
        <CheckOutlined className="check-icon" onClick={handleUpdate} />
      )}
      {isEditing && !loading && (
        <CloseOutlined className="cross-icon" onClick={handleCloseEditing} />
      )}
      {plan?.features && (
        <div className="h-100">
          {loading && (
            <div className="content">
              <LoadingOutlined />
            </div>
          )}
          {!loading && newData?.features && (
            <div>
              {typeOfField === "boolean" && (
                <div className="content">
                  {!isEditing ? (
                    <div>
                      {newData.features[code] === "enabled" && (
                        <CheckOutlined className="check-icon-data"></CheckOutlined>
                      )}
                      {newData.features[code] === "disabled" && <div>-</div>}
                    </div>
                  ) : (
                    <div>
                      <Checkbox
                        checked={newData.features[code] === "enabled"}
                        onChange={handleCheckboxClicked}
                      />
                    </div>
                  )}
                </div>
              )}
              {typeOfField === "number" && (
                <div className="content">
                  {!isEditing ? (
                    <div>
                      {newData.features[code] === -1 ||
                      newData.features[code] === "*"
                        ? "unlimited"
                        : newData.features[code]}
                    </div>
                  ) : (
                    <div>
                      <InputNumber
                        value={newData.features[code] as number}
                        className="m-l-5"
                        min={-1}
                        size="small"
                        type="number"
                        onChange={handleInputNumberChanged}
                      />
                    </div>
                  )}
                </div>
              )}
              {typeOfField === "string" && (
                <div className="content">
                  {!isEditing ? (
                    <div>{newData.features[code]}</div>
                  ) : (
                    <div>
                      <Input
                        value={newData.features[code]}
                        v-model="newData.plan.features[code]"
                        className="m-l-5"
                        size="small"
                        onChange={handleInputChanged}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default EditablePlanFeature
