import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Cluster, Subscription } from "src/domain/desktop"

import "./styles.less"
import { selectSelectedCluster } from "src/store/desktop/clusters/selector"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import {
  addClusterRequest,
  selectCluster,
  updateClusterRequest,
} from "src/store/desktop/clusters/actions"
import { hideModal, showModal } from "src/store/system/actions"
import { addClusterApi, getClusterApi } from "../../../services"
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentBox,
  DialogTitle,
  DialogTrigger,
  PlusFarIcon,
} from "@conduktor/ui-library"
import { ModalType } from "../../../domain/desktop/common"
import { selectModals } from "../../../store/system/selector"
import { CenteredSpinner } from "../../Spinner"
import ClusterModalBody from "./components/Body"
import ClusterModalFooter from "./components/Footer"
import { useHistory } from "react-router-dom"

export const AddClusterModal = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const cluster: Cluster | undefined = useSelector(selectSelectedCluster)
  const visible: boolean = useSelector(selectModals)[ModalType.CLUSTER]
  const isUpdate = Boolean(cluster?.clusterId)

  const [clusterState, setClusterState] = useState<Cluster>()

  useEffect(() => {
    setClusterState(undefined)
    if (subscription?.id && cluster?.clusterId) {
      getClusterApi({
        subscriptionId: subscription.id,
        clusterId: cluster.clusterId,
      }).then((cluster) => {
        setClusterState(cluster)
      })
    } else {
      setClusterState({})
    }
  }, [subscription?.id, cluster?.clusterId])

  const onClose = () => dispatch(hideModal(ModalType.CLUSTER))

  const handleOpen = () => {
    dispatch(selectCluster(undefined))
    dispatch(showModal(ModalType.CLUSTER))
  }

  const handleChange = (prop: keyof Cluster) => (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    const value = event.target.value
    setClusterState({
      ...clusterState,
      [prop]: value,
    })
  }

  const handleCreate = async () => {
    if (clusterState?.clusterId && clusterState?.name && subscription?.id) {
      const payload = {
        subscriptionId: subscription.id,
        clusterData: clusterState,
      }
      await addClusterApi(payload)
      dispatch(addClusterRequest(payload))
      history.push(`${history.location.pathname}/${clusterState.clusterId}`)
    }
  }

  const handleUpdate = () => {
    if (clusterState?.clusterId && clusterState?.name && subscription?.id) {
      dispatch(
        updateClusterRequest({
          subscriptionId: subscription.id,
          clusterData: clusterState,
        })
      )
    }
  }

  return (
    <Dialog open={visible} onOpenChange={handleOpen}>
      <DialogTrigger asChild>
        <Button variant="primary">
          <PlusFarIcon fr /> Add New Cluster
        </Button>
      </DialogTrigger>
      {visible && (cluster || !isUpdate) && (
        <DialogContent size={"regular"}>
          <DialogContentBox>
            <DialogTitle variant={"h3"}>
              {cluster?.clusterId ? "Edit Cluster" : "Add Cluster"}
            </DialogTitle>
            {clusterState ? (
              <ClusterModalBody
                cluster={clusterState}
                onChange={handleChange}
              />
            ) : (
              <CenteredSpinner />
            )}
          </DialogContentBox>
          <ClusterModalFooter
            onAdd={handleCreate}
            onUpdate={handleUpdate}
            onClose={onClose}
          />
        </DialogContent>
      )}
    </Dialog>
  )
}
