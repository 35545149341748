import { ColumnType } from "antd/lib/table"
import { formatDate } from "src/utils"
import React from "react"
import { Link } from "react-router-dom"

export const useColumns = () => {
  const columns: ColumnType<{
    email: string
    domain?: string
    createdAt: string
  }>[] = [
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      width: 180,
      render: (email) => (
        <Link to={`/users?email=${encodeURIComponent(email)}`}>{email}</Link>
      ),
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
      },
    },
    {
      title: "Domain",
      key: "domain",
      dataIndex: "domain",
      width: 180,
      render: (domain) => {
        return domain ? (
          <Link to={`/domains?name=${domain}`}>{domain}</Link>
        ) : null
      },
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
      },
    },
    {
      title: "Created At",
      key: "createdAt",
      dataIndex: "createdAt",
      align: "center",
      width: 80,
      render: (createdAt) => formatDate(createdAt),
      sorter: {
        compare: (a, b) => a.createdAt.localeCompare(b.createdAt),
      },
    },
  ]
  return columns
}
