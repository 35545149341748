import {
  ArrowLeftFromLineFarIcon,
  ArrowRightFromLineFarIcon,
  Button,
} from "@conduktor/ui-library"
import React, { VFC } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectSidebarCollapsed } from "../../store/system/selector"
import { toggleSidebarCollapsed } from "../../store/system/actions"

export const SidebarToggleButton: VFC = () => {
  const dispatch = useDispatch()
  const collapsed = useSelector(selectSidebarCollapsed)

  return (
    <Button size="large" onClick={() => dispatch(toggleSidebarCollapsed())}>
      {collapsed ? (
        <ArrowRightFromLineFarIcon fr size="small" />
      ) : (
        <ArrowLeftFromLineFarIcon fr size="small" />
      )}
    </Button>
  )
}
