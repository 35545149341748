import React, { Fragment } from "react"
import { Table, Tag } from "antd"
import { InvoiceEstimate, LineItem } from "src/domain/desktop"
import ChargesColumns from "./ChargesColumns"
import { amount } from "src/utils"
interface MyPros {
  currentInvoiceEstimate: InvoiceEstimate
  loading: boolean
  amountDue: number
  currency?: string
  currentInvoiceLineItems: LineItem[]
  subTotal: number
  totalAmount: number
}

const ImmediateChargesTabPane: React.FC<MyPros> = ({
  currentInvoiceEstimate,
  loading,
  amountDue,
  currency,
  currentInvoiceLineItems,
  subTotal,
  totalAmount,
}) => {
  return (
    <Fragment>
      <label>
        Summary of charges and credits that will be applied upon confirmation:
      </label>
      <Table
        pagination={false}
        className="m-t-5"
        loading={loading}
        columns={ChargesColumns(currency)}
        dataSource={currentInvoiceLineItems}
        rowKey="id"
      />
      <h3 className="text-right m-t-10 m-r-10">
        <b>
          Subtotal: {currency} {amount(subTotal)}
        </b>
      </h3>
      {currentInvoiceEstimate.discounts && (
        <div>
          {currentInvoiceEstimate.discounts.map((item, index) => (
            <h4 key={index} className="text-right m-t-10 m-r-10">
              {item.description} ({currency} {item.amount})
            </h4>
          ))}
        </div>
      )}
      {currentInvoiceEstimate.taxes && (
        <div>
          {currentInvoiceEstimate.taxes.map((item, index) => (
            <h4 key={index} className="text-right m-t-10 m-r-10">
              {item.description} {currency} {amount(item.amount)}
            </h4>
          ))}
        </div>
      )}
      <h3 className="text-right m-t-10 m-r-10">
        <b>
          Total: {currency} {amount(totalAmount)}
        </b>
      </h3>
      <div className="w-100 text-right m-t-10 m-r-10">
        <Tag>
          <h3>
            Amount Due:{" "}
            <b>
              {currency} {amount(amountDue)}
            </b>
          </h3>
        </Tag>
      </div>
    </Fragment>
  )
}
export default ImmediateChargesTabPane
