import React, { useEffect, useState } from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form"

import { ConfigureStore, configureStore, history } from "./store"
import { RouterMain } from "./router/RouterMain"
import Notification from "./components/Notification"

import "./App.less"
import { ConnectedRouter } from "connected-react-router"
import { SegmentProvider } from "./core/analytics/segment"

const App: React.FC = () => {
  const [rootStore, setRootStore] = useState<ConfigureStore | undefined>()
  useEffect(() => {
    const initStore = async () => {
      const { store, persistor } = await configureStore()
      setRootStore({ store, persistor })
    }
    initStore()
  }, [])

  if (!rootStore) {
    return null
  }

  return (
    <HubspotProvider>
      <Provider store={rootStore.store}>
        <PersistGate loading={null} persistor={rootStore.persistor}>
          <ConnectedRouter history={history}>
            <SegmentProvider>
              <RouterMain />
              <Notification />
            </SegmentProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </HubspotProvider>
  )
}

export default App
