import { ColumnType } from "antd/lib/table"
import { formatDate } from "src/utils"
import { User } from "../../../../../../../../domain/system"

const LastActivityColumn: ColumnType<User> = {
  title: "⭐️ Last Activity",
  key: "lastActivityAt",
  render: (_text, record) =>
    record.conduktorUser ? (
      <span>{formatDate(record.conduktorUser.lastUpdatedAt)}</span>
    ) : (
      <span>-</span>
    ),
  align: "center",
  sorter: (a, b) => {
    if (a.conduktorUser === undefined && b.conduktorUser === undefined) {
      return -1
    }
    if (a.conduktorUser && b.conduktorUser) {
      return (
        (a?.conduktorUser.lastUpdatedAt || 0) -
        (b?.conduktorUser.lastUpdatedAt || 0)
      )
    }
    if (a.conduktorUser && b.conduktorUser === undefined) {
      return +1
    }
    if (a.conduktorUser === undefined && b.conduktorUser) {
      return -1
    }
    return 1
  },
  width: 150,
}

export default LastActivityColumn
