import { useDispatch } from "react-redux"
import { Popconfirm, Tag, Tooltip } from "antd"
import { Domain, DomainStatus } from "src/domain/desktop"
import { EditOutlined } from "@ant-design/icons"
import { updateDomainStatusRequest } from "src/store/desktop/admin/domains/actions"
import { isSubscriptionExpired } from "src/domain/desktop/subscription"

const StatusColumn = (_text: string, record: Domain) => {
  const dispatch = useDispatch()

  const handleUpdateDomainStatus = (record: Domain, approvalStatus: number) => {
    dispatch(
      updateDomainStatusRequest({
        id: record.id,
        approvalStatus,
      })
    )
  }

  return (
    <span>
      {record.approvalStatus === DomainStatus.ACCEPTED ? (
        <Popconfirm
          disabled={isSubscriptionExpired(record.subscription)}
          title="Please confirm"
          okText="Reject"
          cancelText="Pending"
          onCancel={() =>
            handleUpdateDomainStatus(record, DomainStatus.PENDING)
          }
          onConfirm={() =>
            handleUpdateDomainStatus(record, DomainStatus.REJECTED)
          }
        >
          <Tooltip
            placement="bottom"
            title={!isSubscriptionExpired(record.subscription) ? "Edit" : ""}
          >
            <Tag
              className={
                isSubscriptionExpired(record.subscription)
                  ? "cursor-not-allowed "
                  : ""
              }
              color="green"
            >
              Approved
              <EditOutlined />
            </Tag>
          </Tooltip>
        </Popconfirm>
      ) : record.approvalStatus === DomainStatus.PENDING ? (
        <Popconfirm
          disabled={isSubscriptionExpired(record.subscription)}
          title="Please confirm"
          okText="Approve"
          cancelText="Reject"
          onCancel={() =>
            handleUpdateDomainStatus(record, DomainStatus.REJECTED)
          }
          onConfirm={() =>
            handleUpdateDomainStatus(record, DomainStatus.ACCEPTED)
          }
        >
          <Tooltip
            placement="bottom"
            title={!isSubscriptionExpired(record.subscription) ? "Edit" : ""}
          >
            <Tag
              className={
                isSubscriptionExpired(record.subscription)
                  ? "cursor-not-allowed "
                  : ""
              }
              color="orange"
            >
              Pending
              <EditOutlined />
            </Tag>
          </Tooltip>
        </Popconfirm>
      ) : (
        <Popconfirm
          disabled={isSubscriptionExpired(record.subscription)}
          title="Please confirm"
          okText="Approve"
          cancelText="Pending"
          onCancel={() =>
            handleUpdateDomainStatus(record, DomainStatus.PENDING)
          }
          onConfirm={() =>
            handleUpdateDomainStatus(record, DomainStatus.ACCEPTED)
          }
        >
          <Tooltip
            placement="bottom"
            title={!isSubscriptionExpired(record.subscription) ? "Edit" : ""}
          >
            <Tag
              className={
                isSubscriptionExpired(record.subscription)
                  ? "cursor-not-allowed "
                  : ""
              }
              color="orange"
            >
              Rejected
              <EditOutlined />
            </Tag>
          </Tooltip>
        </Popconfirm>
      )}
    </span>
  )
}

export default StatusColumn
