import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { ActionType } from "src/domain/desktop"
import { RouteKey } from "src/router/routes"
import {
  getLicensedUsersRequest,
  getRemovedUserCountsRequest,
  getRemovedUsersRequest,
  getSubscriptionDetailsRequest,
} from "src/store/desktop/subscriptionDetails/actions"
import CompanyInformation from "./components/CompanyInformation"
import RemovedUserCounts from "./components/RemovedUserCounts"
import RemovedUsers from "./components/RemovedUsers"
import SubscribedUsers from "./components/SubscribedUsers"
import { useSubscriptionQuery } from "../../hooks/useSubscriptionQuery"
import "./styles.less"
import { useSubscriptionPageView } from "src/core/analytics/hooks"

const SubscriptionDetailsPage = () => {
  const dispatch = useDispatch()
  const { loading, mode, subscription } = useSubscriptionQuery()
  useSubscriptionPageView(RouteKey.LICENSE_MANAGEMENT, subscription)

  useEffect(() => {
    if (loading || !subscription?.id) return
    if (mode === ActionType.GET_SUBSCRIPTION_REQUEST) {
      dispatch(getSubscriptionDetailsRequest(subscription?.id))
    }
    if (mode === ActionType.GET_SUBSCRIPTION_DETAILS_REQUEST) {
      dispatch(getLicensedUsersRequest(subscription.id))
    }
    if (mode === ActionType.GET_LICENCED_USERS_REQUEST) {
      dispatch(getRemovedUsersRequest(subscription.id))
    }
    if (mode === ActionType.GET_REMOVED_USERS_REQUEST) {
      dispatch(getRemovedUserCountsRequest(subscription.id))
    }
  }, [loading, subscription])

  return (
    <div className="licenses">
      <CompanyInformation />
      <SubscribedUsers />
      <RemovedUsers />
      <RemovedUserCounts />
    </div>
  )
}

export default SubscriptionDetailsPage
