import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { Tag } from "antd"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import { selectConduktorUser } from "src/store/system/selector"
import { isValidSubscription } from "src/domain/desktop/subscription"
import { Subscription } from "src/domain/desktop"
import {
  isSubscriptionCancellingScheduled,
  isSubscriptionExpired,
  isSubscriptionStarted,
} from "src/domain/desktop/subscription"
import { ConduktorUser } from "../../../../../../domain/system"
import { UserRoleType } from "../../../../../../domain/desktop/common"
import { Flex } from "@conduktor/ui-library"

const DetailsSection: React.FC = () => {
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  // const subscription: Subscription = undefined as never

  const isValid = (accessLevel?: UserRoleType[]) =>
    isValidSubscription({ subscription, accessLevel, conduktorUser })

  const isLoaded = Boolean(subscription)
  const isExpired = isSubscriptionExpired(subscription)
  const isStarted = isSubscriptionStarted(subscription)
  const isCancelled = isSubscriptionCancellingScheduled(subscription)

  return (
    <Fragment>
      <Flex align={"center"}>
        <h1 className="card-title m-b-0">Subscription Details</h1>
        {isLoaded && !isStarted && (
          <Tag color="purple" className="m-l-5">
            Future
          </Tag>
        )}
        {isLoaded && isExpired && (
          <Tag color="volcano" className="m-l-5">
            Expired
          </Tag>
        )}
        {isLoaded && !isExpired && isCancelled && (
          <Tag color="error" className="m-l-5">
            Cancel scheduled
          </Tag>
        )}
        {isLoaded && isStarted && !isExpired && (
          <Tag color="green" className="m-l-5">
            Active
          </Tag>
        )}
      </Flex>
      {isValid([UserRoleType.ADMIN]) && (
        <div>
          Subscription ID:
          <span className="sub-title">
            {" "}
            {subscription?.chargeBeeSubscriptionId}
          </span>
        </div>
      )}
    </Fragment>
  )
}

export default DetailsSection
