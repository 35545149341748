import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, Col, Row } from "antd"
import InvoicesModal from "src/components/Modals/InvoicesModal"
import LicenseManagersModal from "src/components/Modals/LicenseManagersModal"
import BillingUsersModal from "src/components/Modals/BillingUsersModal"
import IncreaseLicensesModal from "src/components/Modals/IncreaseLicensesModal"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import { selectConduktorUser, selectModals } from "src/store/system/selector"
import { isValidUser } from "src/domain/user"
import { isValidSubscription } from "src/domain/desktop/subscription"
import { Subscription, SubscriptionType } from "src/domain/desktop"
import DomainModal from "src/components/Modals/DomainModal"
import { hideAllModals } from "src/store/system/actions"
import AdminSection from "./AdminSection"
import DetailsSection from "./DetailsSection"
import ActionsSection from "./ActionsSection"
import MembersSection from "./MemebersSection"
import DateSection from "./DateSection"
import AutoAssignMembersSection from "./AutoAssignMembersSection"
import { useHistory } from "react-router"
import { isSubscriptionExpired } from "src/domain/desktop/subscription"
import { ConduktorUser } from "../../../../../domain/system"
import { ModalType, UserRoleType } from "../../../../../domain/desktop/common"

const CompanyInformation: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const subscription: Subscription | undefined = useSelector(selectSubscription)

  const isAdmin: boolean = isValidUser(conduktorUser, [UserRoleType.ADMIN])
  const isValid = (accessLevel: UserRoleType[]) =>
    isValidSubscription({ subscription, accessLevel, conduktorUser })
  const isExpired: boolean = isSubscriptionExpired(subscription)
  const modalVisible: Record<string, boolean> = useSelector(selectModals)

  const modalsOnClose = useCallback(() => dispatch(hideAllModals()), [])

  const handleRedirectToManageClustersPermissions = () => {
    if (subscription) {
      history.push(
        `/subscriptions/licenses-management/${subscription.chargeBeeSubscriptionId}/clusters`
      )
    }
  }

  return (
    <div className="company-information">
      <Card loading={false} className="card" bordered={false}>
        <Row gutter={20}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <DetailsSection />
          </Col>
          <Col lg={12} md={12} sm={24} xs={24} className="text-right">
            <ActionsSection />
          </Col>
        </Row>
        <Row gutter={20} className="m-t-10">
          <Col span={12}>
            <MembersSection />
          </Col>
          <Col span={12} className="text-right">
            <DateSection />
          </Col>
        </Row>
        {(subscription?.quantity || 0) >= 20 &&
          subscription?.hasReAssignment &&
          !subscription?.hasOffline && <AutoAssignMembersSection />}
        {subscription?.type === SubscriptionType.ENTERPRISE &&
          isValid([
            UserRoleType.BILLING_USER,
            UserRoleType.SUBSCRIPTION_ADMIN,
            UserRoleType.TECHNICAL_MANAGER,
          ]) && (
            <Row gutter={20}>
              <Col lg={12} md={12} sm={24} xs={24} className="m-t-5">
                Cluster Permissions:
                <Button
                  disabled={isExpired}
                  type="primary"
                  size="small"
                  className="m-l-5"
                  onClick={handleRedirectToManageClustersPermissions}
                >
                  Manage
                </Button>
              </Col>
            </Row>
          )}
        {isAdmin && <AdminSection />}
      </Card>

      <InvoicesModal
        visible={modalVisible[ModalType.INVOCE]}
        onClose={modalsOnClose}
      />
      <LicenseManagersModal
        visible={modalVisible[ModalType.LICENSE_MANAGER]}
        onClose={modalsOnClose}
      />
      <BillingUsersModal
        visible={modalVisible[ModalType.BILLING_USERS]}
        onClose={modalsOnClose}
      />
      <IncreaseLicensesModal
        visible={modalVisible[ModalType.INCRESE_LICENSE]}
        onClose={modalsOnClose}
      />
      <DomainModal
        visible={modalVisible[ModalType.DOMAIN]}
        onClose={modalsOnClose}
      />
    </div>
  )
}

export default CompanyInformation
