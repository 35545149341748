import { styled } from "@conduktor/ui-library"

export const LayoutWrapper = styled("div", {
  minHeight: "100vh",
  display: "grid",

  variants: {
    gridColumns: {
      true: {
        gridTemplateColumns: "max-content 1fr",
      },
    },
  },
})

export const LayoutHeader = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 $6",
  width: "100%",
  height: "$8",
  backgroundColor: "$whiteA12",

  boxShadow: "inset 0 -1px 0 0 $colors$neutralA6",

  "& .menu:hover": {
    color: "$neutral12",
    cursor: "pointer",
  },
})

export const LayoutBody = styled("div", {
  width: "100%",
})

export const LayoutContainer = styled("div", {
  display: "grid",
  padding: "$4 $7 $2 $7",
  width: "100%",

  "@media screen and (min-width: 1280px)": {
    maxWidth: "1280px",
    margin: "auto",
  },
})

export const LayoutContent = styled("main", {
  backgroundColor: "$neutral2",
  overflow: "hidden",
})
