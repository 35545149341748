import React, { useEffect, useState } from "react"
import { Tabs } from "antd"
import { bem } from "../../../../../bem"
import { length } from "../../../../../utils"
import MainTable from "./MainTable"
import { isEmpty } from "lodash"
import { useSelector } from "react-redux"
import { selectSubscriptionTeams } from "../../../../../store/desktop/subscriptionDetails/selector"
import { User } from "../../../../../domain/system"

interface Props {
  searchQuery: string
  members: Array<User>
}

const b = "subscribedUsers"

export const MembersTabs: React.FC<Props> = ({ members, searchQuery }) => {
  const teams = useSelector(selectSubscriptionTeams)
  const [filteredMembers, setFilteredMembers] = useState<Array<User>>([])

  useEffect(() => {
    if (isEmpty(searchQuery)) {
      setFilteredMembers(members)
      return
    }
    const query = searchQuery.toLowerCase()
    setFilteredMembers(
      members.filter(
        (member) =>
          member.fullName?.toLowerCase().includes(query) ||
          member.email?.toLowerCase().includes(query) ||
          member.name?.toLowerCase().includes(query)
      )
    )
  }, [members, searchQuery])

  const teamsMembers = teams
    ?.map((team) => ({
      id: team.id,
      name: team.name,
      count: length(team.teamMembers),
      members: filteredMembers.filter((member) =>
        team.teamMembers?.some(({ license }) => license.email === member.email)
      ),
    }))
    .sort((a, b) => b.count - a.count)

  return (
    <Tabs defaultActiveKey="::all">
      <Tabs.TabPane
        key={"::all"}
        tab={
          <span>
            All users{" "}
            <span className={bem(b, "menuBadge")}>{length(members)}</span>
          </span>
        }
      >
        <MainTable members={filteredMembers} />
      </Tabs.TabPane>
      {teamsMembers?.map((team) => (
        <Tabs.TabPane
          key={team.id}
          tab={
            <span>
              {team.name}{" "}
              <span className={bem(b, "menuBadge")}>{team.count}</span>
            </span>
          }
        >
          <MainTable members={team.members} />
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}
