import { desktopClient } from "../axiosClients"
import { GetUserApiPayload, UpdateUserApiPayload } from "src/domain/desktop"
import { AxiosError } from "axios"
import { UpdatableConduktorUser } from "../../domain/desktop/common"

const getUserApi = async (paylaod: GetUserApiPayload) => {
  const { data } = await desktopClient.get("users", paylaod)
  return data
}

export const getOrCreateUserApi = async (paylaod: GetUserApiPayload) => {
  try {
    return await getUserApi(paylaod)
  } catch (err) {
    const error = err as AxiosError
    if (error?.response?.status !== 404) {
      throw error
    }
    await desktopClient.post("users", {
      firstName: null,
      lastName: null,
      fullName: null,
      pictureUrl: null,
      phoneNumber: null,
      country: null,
    })
    return getUserApi(paylaod)
  }
}

export const getAuth0UserApi = async () => {
  const { data } = await desktopClient.get("users/auth")
  return data
}

export const updateUserApi = async ({
  subscriptionId,
  user,
}: UpdateUserApiPayload) => {
  const { data } = await desktopClient.put(`users/${subscriptionId}`, user)
  return data
}
export const updateConduktorUserApi = async (user: UpdatableConduktorUser) => {
  await desktopClient.put(`users`, user)
}
