import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { Card, Col, Input, Row } from "antd"
import Table, { TablePaginationConfig } from "antd/lib/table"
import { selectConduktorUser } from "src/store/system/selector"
import LogsColumns from "./LogsColumns"
import {
  selectLogsList,
  selectLogsLoading,
  selectLogsPagination,
  selectLogsSearchQuery,
} from "src/store/desktop/logs/selector"
import {
  getLogsRequest,
  setLogsPagination,
  setLogsSearchQuery,
} from "src/store/desktop/logs/actions"
import { isValidUser } from "src/domain/user"
import { Log } from "src/domain/desktop"
import { ConduktorUser } from "../../../../../../domain/system"
import { UserRoleType } from "../../../../../../domain/desktop/common"

const AllLogs: React.FC = () => {
  const width: number = useWindowWidth()
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectLogsLoading)
  const logsList: Log[] = useSelector(selectLogsList)
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const isAdmin = isValidUser(conduktorUser, [UserRoleType.ADMIN])

  const searchQuery: string = useSelector(selectLogsSearchQuery) || ""
  const pagination: TablePaginationConfig = useSelector(selectLogsPagination)

  const [currentQuery, setCurrentQuery] = useState("")

  useEffect(() => {
    dispatch(getLogsRequest())
  }, [dispatch])

  useEffect(() => {
    setCurrentQuery(searchQuery)
  }, [searchQuery])

  const handleTableChange = (pagination: TablePaginationConfig) => {
    dispatch(setLogsPagination(pagination))
  }

  const handleSearch = () => {
    dispatch(setLogsSearchQuery(currentQuery))
  }

  const logsColumns = LogsColumns()

  return (
    <Card loading={loading} className="card" bordered={false}>
      {isAdmin && (
        <Row className="m-b-15">
          <Col className="float-left">
            <Input.Search
              value={currentQuery}
              loading={loading}
              placeholder="Search for ID / Name / Email"
              allowClear={true}
              className="search-box"
              onChange={(e) => {
                setCurrentQuery(e.target.value)
              }}
              onSearch={handleSearch}
            />
          </Col>
        </Row>
      )}
      <Table
        loading={loading}
        pagination={pagination}
        columns={logsColumns}
        dataSource={logsList}
        size="small"
        rowKey="id"
        scroll={width < 800 ? { x: "max-content" } : undefined}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default AllLogs
