import {
  Action,
  ActionType,
  DomainPayload,
  DomainUser,
  DomainUsers,
} from "src/domain/desktop"

export const getDomainUsersRequest = (
  domain: string
): Action<DomainPayload> => ({
  type: ActionType.GET_DOMAIN_USERS_REQUEST,
  payload: { domainName: domain },
})

export const getDomainUsersSuccess = (
  payload: DomainUsers
): Action<DomainUsers> => ({
  type: ActionType.GET_DOMAIN_USERS_SUCCESS,
  payload,
})

export const getDomainUsersFailure = (message: string): Action<string> => ({
  type: ActionType.GET_DOMAIN_USERS_FAILURE,
  payload: message,
})

export const deleteDomainUser = (user: DomainUser): Action<DomainUser> => ({
  type: ActionType.DELETE_DOMAIN_USER_REQUEST,
  payload: user,
})

export const deleteDomainUserSuccess = (
  user: DomainUser
): Action<DomainUser> => ({
  type: ActionType.DELETE_DOMAIN_USER_SUCCESS,
  payload: user,
})
