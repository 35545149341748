import moment from "moment"
import { Subscription } from "src/domain/desktop"
import { isObjectEmpty } from "src/utils"
import { AppConfig } from "src/core/config"
import { ConduktorUser, User } from "../../../../../../../../domain/system"
import { UserRoleType } from "../../../../../../../../domain/desktop/common"
import { isValidSubscription } from "../../../../../../../../domain/desktop/subscription"
import { isValidUser } from "../../../../../../../../domain/user"

export const showUpdateNameButton = (
  record: User,
  conduktorUser: ConduktorUser,
  subscription?: Subscription
) => {
  if (!record.accessLevel) {
    if (
      !isObjectEmpty(conduktorUser) &&
      conduktorUser.roleId === UserRoleType.ADMIN
    ) {
      return true
    }
    if (
      isValidSubscription({
        subscription,
        accessLevel: [UserRoleType.BILLING_USER],
        conduktorUser,
      })
    ) {
      if (record.accessLevel !== UserRoleType.BILLING_USER) {
        return true
      }
      if (!isObjectEmpty(conduktorUser) && conduktorUser.id === record.id) {
        return true
      }
    }
    if (
      isValidSubscription({
        subscription,
        accessLevel: [UserRoleType.SUBSCRIPTION_ADMIN],
        conduktorUser,
      })
    ) {
      if (
        record.accessLevel === UserRoleType.SUBSCRIPTION_ADMIN &&
        !isObjectEmpty(conduktorUser) &&
        conduktorUser.id === record.id
      ) {
        return true
      }
      if (record.accessLevel === UserRoleType.TECHNICAL_MANAGER) {
        return true
      }
    }
    if (
      isValidSubscription({
        subscription,
        accessLevel: [UserRoleType.TECHNICAL_MANAGER],
        conduktorUser,
      }) &&
      !isObjectEmpty(conduktorUser) &&
      conduktorUser.id === record.id
    ) {
      return true
    }
    return false
  }
  if (!subscription?.hasOffline || record.licensesOffline === undefined) {
    return true
  }
  return false
}

export const showDeleteButton = (
  record: User,
  conduktorUser: ConduktorUser,
  billingUsers: Array<User>,
  subscription?: Subscription
) => {
  const isAdmin = isValidUser(conduktorUser, [UserRoleType.ADMIN])
  if (record.accessLevel && record.accessLevel === UserRoleType.BILLING_USER) {
    return billingUsers.length > 1 && isAdmin
  }
  if (
    !isObjectEmpty(conduktorUser) &&
    conduktorUser.roleId === UserRoleType.ADMIN
  ) {
    return true
  }
  if (record.accessLevel) {
    if (
      isValidSubscription({
        conduktorUser,
        accessLevel: [UserRoleType.TECHNICAL_MANAGER],
        subscription,
      })
    ) {
      return false
    }
    if (
      record.accessLevel === UserRoleType.SUBSCRIPTION_ADMIN &&
      isValidSubscription({
        conduktorUser,
        accessLevel: [UserRoleType.SUBSCRIPTION_ADMIN],
        subscription,
      })
    ) {
      return false
    }
  }
  return true
}

export const isAllowedToDelete = (
  record: User,
  conduktorUser: ConduktorUser,
  subscription?: Subscription
) => {
  const isAdmin = isValidUser(conduktorUser, [UserRoleType.ADMIN])
  if (isAdmin) return true
  if (
    record.accessLevel &&
    (record.accessLevel === UserRoleType.SUBSCRIPTION_ADMIN ||
      record.accessLevel === UserRoleType.TECHNICAL_MANAGER)
  ) {
    return true
  }

  if (
    subscription?.hasReAssignment &&
    moment(record.created).add(+subscription?.reassignmentMinDelay, "minutes") <
      moment()
  ) {
    return true
  }
  return false
}

export const toolTipMessage = (
  createdDate: number,
  subscription?: Subscription
) => {
  if (subscription?.hasReAssignment) {
    const createdDateWithReAssignmentMinDelay = moment(createdDate).add(
      subscription.reassignmentMinDelay + 1.0,
      "minutes"
    )
    return (
      "You will be able to remove this member on the " +
      createdDateWithReAssignmentMinDelay.format("YYYY-MM-DD")
    )
  }
  return `You do not have the permission to revoke member. Please contact us at ${AppConfig.supportEmail}`
}
