import { Box, CSS, PropertyValue, styled } from "@conduktor/ui-library"
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area"
import React from "react"

enum ScrollBarSize {
  "small" = 7,
  "large" = 11,
}

const StyledScrollArea = styled(ScrollAreaPrimitive.Root, {
  width: "100%",
  height: "100%",
  overflow: "hidden",
})

const StyledViewport = styled(ScrollAreaPrimitive.Viewport, {
  width: "100%",
  height: "100%",
  borderRadius: "inherit",

  "& > div": {
    height: "100%",
  },
})

const StyledScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  display: "flex",
  userSelect: "none",
  touchAction: "none",

  background: "$neutralA1",
  transition: "background 160ms ease-out",
  "&:hover": { background: "$neutralA5" },

  '&[data-orientation="horizontal"]': {
    flexDirection: "column",
  },

  variants: {
    scrollBarSize: {
      small: {
        padding: 1,
        '&[data-orientation="vertical"]': { width: ScrollBarSize["small"] },
        '&[data-orientation="horizontal"]': { height: ScrollBarSize["small"] },
      },
      large: {
        padding: 2,
        '&[data-orientation="vertical"]': { width: ScrollBarSize["large"] },
        '&[data-orientation="horizontal"]': { height: ScrollBarSize["large"] },
      },
    },
  },
})

const StyledThumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: "$neutralA10",

  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    minWidth: 44,
    minHeight: 44,
  },
  variants: {
    scrollBarSize: {
      small: {
        borderRadius: ScrollBarSize["small"],
      },
      large: {
        borderRadius: ScrollBarSize["large"],
      },
    },
  },
})

export const ScrollArea = StyledScrollArea
export const ScrollAreaViewport = StyledViewport
export const ScrollAreaScrollbar = StyledScrollbar
export const ScrollAreaThumb = StyledThumb

type Props = {
  height?: PropertyValue<"height">
  scrollBarSize?: keyof typeof ScrollBarSize
  onScroll?: React.UIEventHandler<HTMLElement>
  children: JSX.Element | JSX.Element[]
  css?: CSS
}

export const Scroll = React.forwardRef<
  React.ElementRef<typeof ScrollAreaViewport>,
  Props
>(function Scroll(
  { height = "100%", scrollBarSize = "large", css, children, ...props },
  ref
) {
  return (
    <Box css={{ height, ...css }}>
      <ScrollArea scrollHideDelay={100}>
        <ScrollAreaViewport ref={ref} {...props}>
          {children}
        </ScrollAreaViewport>
        <ScrollAreaScrollbar
          orientation="vertical"
          scrollBarSize={scrollBarSize}
        >
          <ScrollAreaThumb scrollBarSize={scrollBarSize} />
        </ScrollAreaScrollbar>
      </ScrollArea>
    </Box>
  )
})
