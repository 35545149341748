import { isNil } from "lodash"

export const isObjectEmpty = (obj?: Object) => {
  return Object.keys(obj || {}).length === 0
}

export const isValidEmail = (email: string) => {
  const regExp = /\S+@\S+\.\S+/
  return regExp.test(email)
}

export const emailLogin = (email: string): string | undefined => {
  return email.split("@")[0]
}
export const emailDomain = (email: string): string | undefined => {
  return email.split("@")[1]
}

export const delay = (ms: number) =>
  new Promise((res: Function) => setTimeout(res, ms))

export const isNotDefined = <T>(value?: T) => isNil(value)

export const isDefined = <T>(value?: T) => !isNil(value)

export const or0 = (value?: number) => value || 0

export const orEmpty = <T>(value?: T[]) => value || []

export const orBlank = <T>(value?: T): T | string => value || ""

export const isEmpty = <T>(value?: T[]): boolean => {
  if (!value) return true
  if (Array.isArray(value)) return value.length === 0
  return false
}

export const length = <T>(value?: T[]): number =>
  Array.isArray(value) ? value.length : 0

export const replace = <T>(index: number, data: T, array: T[] = []): T[] => [
  ...array.slice(0, index),
  data,
  ...array.slice(index + 1),
]
export const removeIndex = <T>(index: number, array: T[] = []): T[] => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
]

export const trim = (value?: string): string => value?.trim() || ""

export const omit = <T, K extends keyof T>(key: K, obj: T): Omit<T, K> => {
  const { [key]: omitted, ...rest } = obj
  return rest
}
