import React, { Fragment } from "react"
import { Button, Checkbox, Input, Row } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import IncreaseLicensesModalBodyTabs from "./Tabs"
import IncreaseLicensesModalBodyDetails from "./Details"
import IncreaseLicensesModalBodyContactMessage from "./ContactMessage"
import { IncreaseLicensesModalBodyProps } from "src/domain/desktop"
import { AppConfig } from "src/core/config"

const IncreaseLicensesModalBody: React.FC<IncreaseLicensesModalBodyProps> = ({
  values,
  showDetails,
  limitExceeded,
  isSubscriptionRenewalIsLessThan6Months,
  loading,
  termsOk,
  notAReseller,
  amountDue,
  currency,
  estimateCurrentChange,
  nextInvoiceEstimate,
  onChange,
  onGetLicensesEstimate,
  chargeBeeSubscription,
  invoicePending,
  onSetTermOk,
  onSetNotAReseller,
  subTotal,
  currentInvoiceLineItems,
  totalAmount,
  currentInvoiceEstimate,
}) => {
  return (
    <Fragment>
      <div className="m-t-10 m-b-10">
        <label>
          How many licenses would you want to add to your subscription?{" "}
        </label>
        <Input
          value={values.quantity}
          type="number"
          min={1}
          style={{ width: "10%" }}
          placeholder="1"
          onChange={onChange("quantity")}
        />
        <Button
          className="float-right"
          type="primary"
          disabled={loading}
          loading={loading}
          onClick={() => onGetLicensesEstimate()}
        >
          Estimate
        </Button>
      </div>
      {showDetails && (
        <IncreaseLicensesModalBodyDetails
          currency={currency}
          amountDue={amountDue}
          estimateCurrentChange={estimateCurrentChange}
          nextInvoiceEstimate={nextInvoiceEstimate}
          chargeBeeSubscription={chargeBeeSubscription}
          invoicePending={invoicePending}
        />
      )}
      {showDetails && (
        <IncreaseLicensesModalBodyTabs
          values={values}
          currentInvoiceEstimate={currentInvoiceEstimate}
          loading={loading}
          amountDue={amountDue}
          currency={currency}
          nextInvoiceEstimate={nextInvoiceEstimate}
          chargeBeeSubscription={chargeBeeSubscription}
          currentInvoiceLineItems={currentInvoiceLineItems}
          subTotal={subTotal}
          totalAmount={totalAmount}
        />
      )}
      <IncreaseLicensesModalBodyContactMessage
        limitExceeded={limitExceeded}
        isSubscriptionRenewalIsLessThan6Months={
          isSubscriptionRenewalIsLessThan6Months
        }
      />
      {showDetails && (
        <div>
          <hr className="m-t-20 m-b-20" />
          <Row>
            <Checkbox
              checked={termsOk}
              onChange={(e: CheckboxChangeEvent) =>
                onSetTermOk(e.target.checked)
              }
            >
              To purchase new licenses, I agree to the{" "}
              <a
                target="_blank"
                href={`${AppConfig.website.url}terms-of-service/`}
                rel="noreferrer"
              >
                terms & conditions
              </a>
              .
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              checked={notAReseller}
              onChange={(e: CheckboxChangeEvent) => {
                onSetNotAReseller(e.target.checked)
              }}
            >
              I am not a reseller. Resellers must contact us at{" "}
              <a href={`mailto:${AppConfig.website.contact.email}`}>
                {AppConfig.website.contact.email}
              </a>
              .
            </Checkbox>
          </Row>
        </div>
      )}
    </Fragment>
  )
}

export default IncreaseLicensesModalBody
