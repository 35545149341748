import { useEffect } from "react"
import { getClusterApi } from "../../../services"
import { selectCluster } from "../../../store/desktop/clusters/actions"
import { getAccessControlRequest } from "../../../store/desktop/accessControl/actions"
import { useParams } from "react-router-dom"
import { useSubscriptionQuery } from "../../hooks/useSubscriptionQuery"
import { useDispatch, useSelector } from "react-redux"
import { selectSelectedCluster } from "../../../store/desktop/clusters/selector"

interface RouteParams {
  id: string
  clusterId: string
}

export const useSelectCluster = () => {
  const dispatch = useDispatch()
  const { clusterId }: RouteParams = useParams()
  const { subscription } = useSubscriptionQuery()
  const cluster = useSelector(selectSelectedCluster)
  const subscriptionId = subscription?.id
  const selectedClusterId = cluster?.clusterId

  useEffect(() => {
    if (!subscriptionId || !clusterId || selectedClusterId === clusterId) {
      return
    }
    if (selectedClusterId) {
      return void dispatch(selectCluster(undefined))
    }

    const request = {
      subscriptionId: subscriptionId,
      clusterId: clusterId,
    }
    getClusterApi(request)
      .then((cluster) => dispatch(selectCluster(cluster)))
      .then(() => dispatch(getAccessControlRequest(request)))
  }, [dispatch, clusterId, subscriptionId, selectedClusterId])
}
