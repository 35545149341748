import { RootState, Plan, Feature, IActionType } from "src/domain/desktop"
import { PlansReducerType } from "./reducer"

const selectPlansState = (state: RootState): PlansReducerType => state.plans

export const selectPlansLoading = (state: RootState): boolean =>
  selectPlansState(state).loading

export const selectPlansLoaded = (state: RootState): boolean | undefined =>
  selectPlansState(state).loaded

export const selectPlansApiMode = (state: RootState): IActionType | undefined =>
  selectPlansState(state).type

export const selectPlansError = (state: RootState): string | undefined =>
  selectPlansState(state).error

export const selectPlans = (state: RootState): Plan[] =>
  selectPlansState(state).plans

export const selectFeatures = (state: RootState): Feature[] =>
  selectPlansState(state).features
