import React from "react"
import { useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { Card, Col, Row, Statistic, Table } from "antd"
import { ColumnType } from "antd/lib/table"
import {
  selectAdminDashboardLoading,
  selectDomainStatusWithCount,
  selectSubscriptionActiveExpiredCountWithPlanNameList,
  selectSubscriptionAddonsCount,
} from "src/store/desktop/adminDashboard/selector"
import {
  DomainStatusWithCount,
  SubscriptionActiveExpiredCountWithPlanName,
  SubscriptionAddonsCount,
} from "src/domain/desktop"

const Subscriptions: React.FC = () => {
  const width: number = useWindowWidth()
  const loading: boolean = useSelector(selectAdminDashboardLoading)
  const subscriptionActiveExpiredCountWithPlanNameList = useSelector(
    selectSubscriptionActiveExpiredCountWithPlanNameList
  )
  const subscriptionAddonsCount = useSelector(selectSubscriptionAddonsCount)
  const domainStatusWithCount = useSelector(selectDomainStatusWithCount)

  return (
    <div className="admin-dashboard-subscriptions">
      <Card loading={loading} className="card" bordered={false}>
        <h1 className="card-title">Subscriptions</h1>
        <Table
          columns={subscriptionDetailsTableColumns}
          dataSource={subscriptionActiveExpiredCountWithPlanNameList}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            pageSize: 20,
          }}
          rowKey="planName"
          size="small"
          scroll={width < 600 ? { x: "max-content" } : undefined}
        />
        <Row className="m-t-20">
          <Col span={21}>
            <Table
              columns={subscriptionAddonsTableColumns}
              dataSource={[subscriptionAddonsCount]}
              pagination={{
                hideOnSinglePage: true,
                showSizeChanger: false,
                pageSize: 20,
              }}
              rowKey={(object) => [subscriptionAddonsCount].indexOf(object)}
              scroll={width < 800 ? { x: "max-content" } : undefined}
              size="small"
            />
          </Col>
        </Row>
      </Card>

      <Card loading={loading} className="card" bordered={false}>
        <h1 className="card-title">Domains</h1>
        <Table
          columns={subscriptionDomainsTableColumns}
          dataSource={[domainStatusWithCount]}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            pageSize: 20,
          }}
          rowKey={(object) => [domainStatusWithCount].indexOf(object)}
          scroll={width < 800 ? { x: "max-content" } : undefined}
          size="small"
        />
      </Card>
    </div>
  )
}

const subscriptionDetailsTableColumns: ColumnType<SubscriptionActiveExpiredCountWithPlanName>[] = [
  {
    title: "Plan Name",
    dataIndex: "planName",
    key: "planName",
  },
  {
    title: "Count",
    align: "center",
    key: "count",
    render: (text, record) => {
      return <Statistic value={record.activeCount} />
    },
  },
]

const subscriptionAddonsTableColumns: ColumnType<SubscriptionAddonsCount>[] = [
  {
    title: "With reassignment",
    align: "center",
    render: (text, record) => {
      return (
        <Statistic
          value={record.reAssignmentCount}
          suffix={
            <span>
              {" "}
              /{" "}
              {(record?.reAssignmentCount || 0) +
                (record?.offlineCount || 0) +
                (record?.unlimitedCount || 0)}
            </span>
          }
        />
      )
    },
  },
  {
    title: "With Offline",
    align: "center",
    render: (text, record) => {
      return (
        <Statistic
          value={record.offlineCount}
          suffix={
            <span>
              {" "}
              /{" "}
              {(record.reAssignmentCount || 0) +
                (record.offlineCount || 0) +
                (record.unlimitedCount || 0)}
            </span>
          }
        />
      )
    },
  },
  {
    title: "With Unlimited",
    align: "center",
    render: (text, record) => {
      return (
        <Statistic
          value={record.unlimitedCount}
          suffix={
            <span>
              {" "}
              /{" "}
              {(record.reAssignmentCount || 0) +
                (record.offlineCount || 0) +
                (record.unlimitedCount || 0)}
            </span>
          }
        />
      )
    },
  },
]

const subscriptionDomainsTableColumns: ColumnType<DomainStatusWithCount>[] = [
  {
    title: "Accepted",
    align: "center",
    render: (_text, record) => {
      return <Statistic value={record.approvedCount} />
    },
  },
  {
    title: "Pending",
    align: "center",
    render: (_text, record) => {
      return <Statistic value={record.pendingCount} />
    },
  },
  {
    title: "Rejected",
    align: "center",
    render: (_text, record) => {
      return <Statistic value={record.rejectedCount} />
    },
  },
]

export default Subscriptions
