import { put, takeLatest } from "redux-saga/effects"

import { parseError } from "src/utils"
import { ClientError } from "src/services/ClientError"
import { getClusterTopicsControlApi } from "src/services"

import {
  Action,
  ActionType,
  GetTopicsControlApiPayload,
} from "src/domain/desktop"
import { getTopicsControlFailure, getTopicsControlSuccess } from "./actions"
import { TopicsControl } from "src/domain/desktop/topicPermission"

function* getTopicsControlSaga({
  payload,
}: Action<GetTopicsControlApiPayload>) {
  try {
    const data: TopicsControl = yield getClusterTopicsControlApi(payload)
    yield put(getTopicsControlSuccess(data))
  } catch (err) {
    yield put(getTopicsControlFailure(parseError(err as ClientError)))
  }
}

export default function* topicsControlSagas() {
  yield takeLatest(ActionType.GET_TOPICS_CONTROL_REQUEST, getTopicsControlSaga)
}
