import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useWindowWidth } from "@react-hook/window-size"
import { Button, Card, Table } from "antd"
import { ColumnType } from "antd/lib/table"
import { selectConduktorUser } from "src/store/system/selector"
import {
  selectSubscriptionsList,
  selectSubscriptionsLoading,
} from "src/store/desktop/subscriptions/selector"
import { getSubscriptionsRequest } from "src/store/desktop/subscriptions/actions"
import { isSubscriptionExpired } from "src/domain/desktop/subscription"
import { ConduktorUser } from "../../../../../domain/system"

const SubscriberInfo: React.FC = () => {
  const width: number = useWindowWidth()
  const dispatch = useDispatch()
  const history = useHistory()
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const subscriptoins = useSelector(selectSubscriptionsList)
  const loading: boolean = useSelector(selectSubscriptionsLoading)

  useEffect(() => {
    if (!loading && (!subscriptoins || !subscriptoins.length)) {
      dispatch(getSubscriptionsRequest())
    }
  }, [dispatch])

  const expired: number = (subscriptoins || []).filter(
    (e) => !!isSubscriptionExpired(e)
  ).length
  const active: number = (subscriptoins || []).length - expired

  const handleView = () => {
    history.push("/subscriptions")
  }

  const subscribedUsersColumns: ColumnType<ConduktorUser>[] = [
    {
      title: "Name",
      dataIndex: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Active",
      align: "center",
      width: 100,
      render: () => {
        return <span style={{ fontSize: 18 }}>{active}</span>
      },
    },
    {
      title: "Expired",
      align: "center",
      width: 100,
      render: () => {
        return <span style={{ fontSize: 18 }}>{expired}</span>
      },
    },
    {
      title: "",
      align: "center",
      width: 150,
      render: () => {
        return (
          <Button type="link" onClick={handleView}>
            View
          </Button>
        )
      },
    },
  ]
  return (
    <div className="subscriber-info">
      <Card className="card" title="Your Subscriptions" bordered={false}>
        <Table
          loading={loading}
          pagination={false}
          columns={subscribedUsersColumns}
          dataSource={[conduktorUser]}
          rowKey="id"
          scroll={width < 1285 ? { x: "max-content" } : undefined}
        />
      </Card>
    </div>
  )
}

export default SubscriberInfo
