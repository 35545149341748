import { all, fork } from "redux-saga/effects"
import system from "src/store/system/saga"
import adminDashboard from "src/store/desktop/adminDashboard/saga"
import subscriptions from "src/store/desktop/subscriptions/saga"
import licenses from "src/store/desktop/licenses/saga"
import subscriptionDetails from "src/store/desktop/subscriptionDetails/saga"
import plans from "src/store/desktop/plans/saga"
import licenseMembers from "src/store/desktop/licenseMembers/saga"
import users from "src/store/desktop/admin/users/saga"
import domainDetails from "src/store/desktop/admin/domainDetails/saga"
import lastSignups from "src/store/desktop/admin/lastSignups/saga"
import domains from "src/store/desktop/admin/domains/saga"
import admins from "src/store/desktop/admin/admins/saga"
import logs from "src/store/desktop/logs/saga"
import clusters from "src/store/desktop/clusters/saga"
import accessControl from "src/store/desktop/accessControl/saga"
import topicsControl from "src/store/desktop/topicsControl/saga"

export default function* start() {
  yield all([
    fork(system),
    fork(adminDashboard),
    fork(subscriptions),
    fork(licenses),
    fork(subscriptionDetails),
    fork(plans),
    fork(licenseMembers),
    fork(users),
    fork(domainDetails),
    fork(domains),
    fork(logs),
    fork(clusters),
    fork(lastSignups),
    fork(admins),
    fork(accessControl),
    fork(topicsControl),
  ])
}
