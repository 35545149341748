import {
  AddTeamApiPayload,
  DeleteSubscriptionItemApiPayload,
  UpdateTeamApiPayload,
} from "src/domain/desktop"
import { desktopClient } from "../axiosClients"

export const addTeamApi = async (payload: AddTeamApiPayload) => {
  const { data } = await desktopClient.post(`teams`, payload)
  return data
}

export const deleteTeamApi = async ({
  subscriptionId,
  id,
}: DeleteSubscriptionItemApiPayload) => {
  const { data } = await desktopClient.delete(`teams/${subscriptionId}/${id}`)
  return data
}

export const updateTeamApi = async (payload: UpdateTeamApiPayload) => {
  const { data } = await desktopClient.post(`teams/update-team`, payload)
  return data
}
