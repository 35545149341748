import { Flex, styled, Text } from "@conduktor/ui-library"

export const TopicsControlTopicList = styled(Flex, {
  gap: "$2",
  flexDirection: "column",
})

export const TopicsControlTopicItem = styled(Flex, {
  alignItems: "center",
  justifyContent: "space-between",
  padding: "$2 $3",
  border: "1px solid $neutral6",
  borderRadius: "$2",

  [`& ${Text}`]: {
    fontSize: "$2",
    fontWeight: "$bold",
  },
})
