import { TablePaginationConfig as Pagination } from "antd/lib/table"
import { ActionType, Action, ApiStatus, Logs, Log } from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import { failureReducer, initReducer, requestReducer } from "../../reducers"

export type LogsReducerType = ApiStatus & Logs

export const defaultState: LogsReducerType = {
  loading: false,
  error: undefined,
  type: undefined,

  items: [],

  pagination: {
    current: 1,
    pageSizeOptions: ["10", "20", "40", "100"],
    pageSize: 10,
    showSizeChanger: true,
    total: 0,
  },

  searchQuery: "",
}

function getLogsSuccessReducer(
  state: LogsReducerType,
  { payload }: Action<{ licenseLoggingList: Log[]; totalCount: number }>
) {
  return {
    ...state,
    items: payload.licenseLoggingList,
    pagination: {
      ...state.pagination,
      total: payload.totalCount,
    },
    loading: false,
  }
}

function setLogsPagination(
  state: LogsReducerType,
  { payload }: Action<Pagination>
) {
  return {
    ...state,
    pagination: payload,
  }
}

function setLogsSearchQuery(
  state: LogsReducerType,
  { payload }: Action<string>
) {
  return {
    ...state,
    pagination: {
      ...state.pagination,
      current: 1,
    },
    searchQuery: payload,
  }
}

export const logsReducer = createReducer<LogsReducerType>(defaultState, {
  [ActionType.INIT_STORE]: initReducer(defaultState),
  [ActionType.GET_LOGS_REQUEST]: requestReducer,
  [ActionType.GET_LOGS_SUCCESS]: getLogsSuccessReducer,
  [ActionType.GET_LOGS_FAILURE]: failureReducer,
  [ActionType.SET_LOGS_PAGINATION]: setLogsPagination,
  [ActionType.SET_LOGS_SEARCH_QUERY]: setLogsSearchQuery,
})
