import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RouteKey } from "src/router/routes"
import {
  selectLogsError,
  selectLogsLoading,
} from "src/store/desktop/logs/selector"
import { setMessage } from "src/store/system/actions"
import AllLogs from "./components/AllLogs"

import "./styles.less"
import { usePageView } from "src/core/analytics/hooks"
import { MessageType } from "../../../../domain/system"

const LogsPage = () => {
  usePageView(RouteKey.ADMIN_LOGS)
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectLogsLoading)
  const error: string | undefined = useSelector(selectLogsError)

  useEffect(() => {
    if (error && !loading) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: error,
        })
      )
    }
  }, [dispatch, error, loading])

  return (
    <div className="logs">
      <AllLogs />
    </div>
  )
}

export default LogsPage
