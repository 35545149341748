import { useDispatch, useSelector } from "react-redux"
import { UserOutlined } from "@ant-design/icons"
import { Avatar, Tag } from "antd"
import EditableCell from "src/components/EditableCell"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import { selectConduktorUser } from "src/store/system/selector"
import { showUpdateNameButton } from "../utils"
import {
  Subscription,
  UpdateLicenseApiPayload,
  UpdateUserApiPayload,
} from "src/domain/desktop"
import {
  updateLicensedUserRequest,
  updateUserRequest,
} from "src/store/desktop/subscriptionDetails/actions"
import { ConduktorUser, User } from "../../../../../../../../domain/system"

const MembersNameColumn = (_text: string, record: User) => {
  const dispatch = useDispatch()
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const subscription: Subscription | undefined = useSelector(selectSubscription)

  const handleChangeName = (record: User) => (name: string) => {
    if (!record.id) return
    if (record.accessLevel) {
      const payload: UpdateUserApiPayload = {
        subscriptionId: subscription?.id,
        user: {
          ...record,
          fullName: name,
        },
      }
      dispatch(updateUserRequest(payload))
    } else {
      const payload: UpdateLicenseApiPayload = {
        subscription: {
          id: subscription?.id,
        },
        id: record.id,
        name,
        email: record.email?.toLocaleLowerCase(),
      }
      dispatch(updateLicensedUserRequest(payload))
    }
  }

  return (
    <div className="display-flex">
      <div className="m-r-10 display-flex" style={{ alignItems: "center" }}>
        {record.pictureUrl === undefined ? (
          <Avatar size={32} icon={<UserOutlined />} />
        ) : (
          <Avatar src={record.pictureUrl} size={32} />
        )}
      </div>
      <div className="w-100">
        <EditableCell
          canEdit={showUpdateNameButton(record, conduktorUser, subscription)}
          cellLoading={false}
          text={record.fullName || record.name}
          onChange={handleChangeName(record)}
        />
        <div className="hint-text">
          <span className="m-r-5">{record.email}</span>
          {record.teams &&
            record.teams.map((tw, index) => (
              <Tag key={index} color={tw.team.color} closable={false}>
                {tw.team.name}
              </Tag>
            ))}
        </div>
      </div>
    </div>
  )
}

export default MembersNameColumn
