import React from "react"
import { useSelector } from "react-redux"
import { Button, DialogFooter } from "@conduktor/ui-library"
import { Cluster } from "src/domain/desktop"
import {
  selectClustersLoading,
  selectSelectedCluster,
} from "src/store/desktop/clusters/selector"

interface MyProps {
  onAdd: () => void
  onUpdate: () => void
  onClose: () => void
}

const ClusterModalFooter: React.FC<MyProps> = ({
  onClose,
  onAdd,
  onUpdate,
}) => {
  const cluster: Cluster | undefined = useSelector(selectSelectedCluster)
  const loading: boolean = useSelector(selectClustersLoading)

  const isUpdate = Boolean(cluster?.clusterId)

  return (
    <DialogFooter justify="flex-end">
      <Button
        disabled={loading}
        loading={loading}
        onClick={onClose}
        type="button"
        variant="secondaryOutline"
      >
        Cancel
      </Button>
      <Button
        type="button"
        variant="primary"
        disabled={loading}
        loading={loading}
        onClick={() => (isUpdate ? onUpdate() : onAdd())}
      >
        {isUpdate ? "Edit Cluster" : "Add Cluster"}
      </Button>
    </DialogFooter>
  )
}

export default ClusterModalFooter
