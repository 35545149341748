import { Button } from "@conduktor/ui-library"
import React from "react"
import { useFormContext } from "react-hook-form"

export const FormButton: React.FC<typeof Button.defaultProps> = ({
  children,
  ...props
}) => {
  const {
    formState: { isSubmitting },
  } = useFormContext()

  return (
    <Button
      pointer
      type={"button"}
      variant={"secondaryOutline"}
      {...props}
      disabled={isSubmitting}
    >
      {children}
    </Button>
  )
}
