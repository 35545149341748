import {
  Action,
  ActionType,
  EmptyAction,
  SetMaxActivationPayload,
  UserEmailPayload,
} from "src/domain/desktop"
import { FetchedOAuthUser } from "./reducer"

export const getOAuthUserRequest = (
  email: string
): Action<UserEmailPayload> => ({
  type: ActionType.GET_OAUTH_USER_REQUEST,
  payload: { email },
})

export const getOAuthUserSuccess = (
  payload: FetchedOAuthUser
): Action<FetchedOAuthUser> => ({
  type: ActionType.GET_OAUTH_USER_SUCCESS,
  payload,
})

export const getOAuthUserFailure = (message: string): Action<string> => ({
  type: ActionType.GET_OAUTH_USER_FAILURE,
  payload: message,
})

export const deleteOAuthUser = (email: string): Action<UserEmailPayload> => ({
  type: ActionType.DELETE_OAUTH_USER_REQUEST,
  payload: { email },
})

export const resetOAuthUserState = (): EmptyAction => ({
  type: ActionType.RESET_OAUTH_USER_STATE,
})

export const sendOAuthUserResetPasswordEmail = (
  email: string
): Action<UserEmailPayload> => ({
  type: ActionType.SEND_OAUTH_USER_RESET_PASSWORD_EMAIL,
  payload: { email },
})

export const sendOAuthUserVerificationEmail = (
  email: string
): Action<UserEmailPayload> => ({
  type: ActionType.SEND_OAUTH_USER_VERIFICATION_EMAIL,
  payload: { email },
})

export const setOAuthUserMaxActivations = (
  email: string,
  max: number
): Action<SetMaxActivationPayload> => ({
  type: ActionType.SET_OAUTH_USER_MAX_ACTIVATIONS,
  payload: { email, max },
})

export const clearOAuthUserFingerprintsAndRefreshTokens = (
  email: string
): Action<UserEmailPayload> => ({
  type: ActionType.CLEAR_OAUTH_USER_FP_AND_TOKENS,
  payload: { email },
})

export const sendUserReactivateTrial = (
  email: string
): Action<UserEmailPayload> => ({
  type: ActionType.SEND_USER_REACTIVATE_TRIAL,
  payload: { email },
})
