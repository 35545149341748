import { TablePaginationConfig as Pagination } from "antd/lib/table"
export interface Company {
  id: number
  name: string
  created: number
  modified: number
}

export interface BillingUser {
  accessLevel: number
  company: Company
  created: number
  email: string
  fullName: string
  id: string
  modified: number
  pictureUrl?: string
}

export interface Subscription {
  accessLevel: number
  available: number
  billingUser: BillingUser
  chargeBeeSubscriptionId: string
  deleted: boolean
  hasAutoAssign: boolean
  hasOffline: boolean
  hasReAssignment: boolean
  hasPurchaseAllowed: boolean
  id: number
  modified: number
  planName: string
  quantity: number
  reassignmentMinDelay: number
  renewalDate: number
  revokePercentagePerMonth: number
  subscriptionDate: number
  type: string
  expiryDate?: number
  showLastActivity?: boolean
  hasUnlimited?: boolean
}

export interface Subscriptions {
  items: Array<Subscription>
  showExpiredSubscriptions: boolean
  pagination: Pagination
  searchQuery?: string
}

export enum SubscriptionType {
  ENTERPRISE = "enterprise",
  FREE = "free",
  PROFESSIONAL = "professional",
  TRIAL = "trialing",
}

export enum SubscriptionsActionType {
  GET_SUBSCRIPTIONS_REQUEST = "SUBSCRIPTIONS/GET_SUBSCRIPTIONS_REQUEST",
  GET_SUBSCRIPTIONS_SUCCESS = "SUBSCRIPTIONS/GET_SUBSCRIPTIONS_SUCCESS",
  GET_SUBSCRIPTIONS_FAILURE = "SUBSCRIPTIONS/GET_SUBSCRIPTIONS_FAILURE",
  DELETE_ROLE_REQUEST = "SUBSCRIPTIONS/DELETE_ROLE_REQUEST",
  CREATE_ROLE_REQUEST = "SUBSCRIPTIONS/CREATE_ROLE_REQUEST",
  UPDATE_ROLE_REQUEST = "SUBSCRIPTIONS/UPDATE_ROLE_REQUEST",
  SET_SUBSCRIPTIONS_PAGINATION = "SUBSCRIPTIONS/SET_SUBSCRIPTIONS_PAGINATION",
  SET_SUBSCRIPTIONS_SEARCH_QUERY = "SUBSCRIPTIONS/SET_SUBSCRIPTIONS_SEARCH_QUERY",
  SET_SHOW_SHOWEXPIRED_SUBSCRIPTIONS = "SUBSCRIPTIONS/SET_SHOW_SHOWEXPIRED_SUBSCRIPTIONS",
}
