import { RootState } from "src/domain/desktop"
import { AdminsReducerType } from "./reducer"
import { ConduktorUser } from "../../../../domain/system"

export const selectAdminsState = (state: RootState): AdminsReducerType =>
  state.admins

export const selectAdminsLoading = (state: RootState): boolean =>
  selectAdminsState(state).loading

export const selectAdminsError = (state: RootState): string | undefined =>
  selectAdminsState(state).error

export const selectAdmins = (state: RootState): ConduktorUser[] | undefined =>
  selectAdminsState(state).users
