import { Action, ActionType, ApiStatus } from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import {
  failureReducerOn,
  initReducer,
  requestReducerOn,
  resetRequestReducerOn,
  successReducer,
} from "../reducers"
import {
  Auth0User,
  ConduktorUser,
  Message,
  System,
  User,
} from "../../domain/system"
import { ModalType, UpdatableConduktorUser } from "../../domain/desktop/common"

export type SystemReducerType = ApiStatus & System

export const defaultState: SystemReducerType = {
  loading: false,
  error: undefined,

  conduktorUser: {},
  auth0User: {},
  licensedUser: {},
  isBillingUser: false,
  isLicensed: false,
  message: undefined,
  forceLogout: false,
  sidebar: { collapsed: false },
  modals: {},

  updateUserDataStatus: { loading: false },
}

function setAuth0UserReducer(
  state: SystemReducerType,
  { payload }: Action<Auth0User>
) {
  return {
    ...state,
    auth0User: payload,
    forceLogout: false,
  }
}

function setConduktorUserReducer(
  state: SystemReducerType,
  { payload }: Action<ConduktorUser>
) {
  return {
    ...state,
    conduktorUser: payload,
    forceLogout: false,
  }
}

function patchConduktorUserReducer(
  state: SystemReducerType,
  { payload }: Action<UpdatableConduktorUser>
) {
  return {
    ...state,
    updateUserDataStatus: successReducer(state),
    conduktorUser: { ...state.conduktorUser, ...payload },
  }
}

function setLicensedUserReducer(
  state: SystemReducerType,
  { payload }: Action<User>
) {
  return {
    ...state,
    licensedUser: payload,
    forceLogout: false,
  }
}

function setSetIsLicensedReducer(
  state: SystemReducerType,
  { payload }: Action<boolean>
) {
  return {
    ...state,
    isLicensed: payload,
  }
}

function setIsBillingUserReducer(
  state: SystemReducerType,
  { payload }: Action<boolean>
) {
  return {
    ...state,
    isBillingUser: payload,
  }
}

function setApiStatusReducer(
  state: SystemReducerType,
  { payload }: Action<ApiStatus>
) {
  return {
    ...state,
    ...payload,
  }
}

function setMessageReducer(
  state: SystemReducerType,
  { payload }: Action<Message>
) {
  return {
    ...state,
    message: payload,
  }
}

function setForceLogoutReducer(
  state: SystemReducerType,
  { payload }: Action<boolean>
) {
  return {
    ...state,
    forceLogout: payload,
  }
}

function sidebarToggleCollapsedReducer(state: SystemReducerType) {
  return {
    ...state,
    sidebar: { ...state.sidebar, collapsed: !state.sidebar.collapsed },
  }
}

function showModalReducer(
  state: SystemReducerType,
  { payload }: Action<ModalType>
) {
  return {
    ...state,
    modals: {
      ...state.modals,
      [payload]: true,
    },
  }
}

function hideModalReducer(
  state: SystemReducerType,
  { payload }: Action<ModalType>
) {
  return {
    ...state,
    modals: {
      ...state.modals,
      [payload]: false,
    },
  }
}

function hideAllModalsReducer(state: SystemReducerType) {
  return {
    ...state,
    modals: {},
  }
}

export const systemReducer = createReducer<SystemReducerType>(defaultState, {
  [ActionType.INIT_STORE]: initReducer(defaultState),
  [ActionType.SET_AUTH0_USER]: setAuth0UserReducer,
  [ActionType.SET_IS_BILLING_USER]: setIsBillingUserReducer,
  [ActionType.SET_CONDUKTOR_USER]: setConduktorUserReducer,
  [ActionType.SET_LICENCED_USER]: setLicensedUserReducer,
  [ActionType.SET_IS_LICENCED]: setSetIsLicensedReducer,
  [ActionType.SET_API_STATE]: setApiStatusReducer,
  [ActionType.SET_MESSAGE]: setMessageReducer,
  [ActionType.SET_FORCE_LOGOUT]: setForceLogoutReducer,
  [ActionType.SIDEBAR_TOGGLE_COLLAPSE]: sidebarToggleCollapsedReducer,
  [ActionType.SHOW_MODAL]: showModalReducer,
  [ActionType.HIDE_MODAL]: hideModalReducer,
  [ActionType.HIDE_ALL_MODALS]: hideAllModalsReducer,
  //
  [ActionType.UPDATE_USER_DATA_REQUEST]: requestReducerOn<SystemReducerType>(
    "updateUserDataStatus"
  ),
  [ActionType.UPDATE_USER_DATA_FAILURE]: failureReducerOn<SystemReducerType>(
    "updateUserDataStatus"
  ),
  [ActionType.UPDATE_USER_DATA_CLEAR]: resetRequestReducerOn<SystemReducerType>(
    "updateUserDataStatus"
  ),
  [ActionType.UPDATE_USER_DATA_SUCCESS]: patchConduktorUserReducer,
})
