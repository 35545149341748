import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import EditablePlanFeature from "src/components/EditablePlanFeature"
import { SyncOutlined } from "@ant-design/icons"
import { Button, Card, Col, Row, Tooltip } from "antd"
import { getPlansRequest } from "src/store/desktop/plans/actions"
import {
  selectFeatures,
  selectPlans,
  selectPlansLoading,
} from "src/store/desktop/plans/selector"
import { Feature, ParsedFeatures } from "src/domain/desktop"
import { RouteKey } from "src/router/routes"
import { usePageView } from "src/core/analytics/hooks"
import "./styles.less"

const PlansConfigurationPage = () => {
  usePageView(RouteKey.ADMIN_PLANS_CONFIG)
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectPlansLoading)
  const plans = useSelector(selectPlans)
  const features = useSelector(selectFeatures)

  const [tableHeaders, setTableHeaders] = useState<string[]>([])
  const [parsedFeatures, setParsedFeatures] = useState<ParsedFeatures>({})
  const [currentElementCode, setCurrentElementCode] = useState<string>("")

  useEffect(() => {
    dispatch(getPlansRequest())
  }, [dispatch])

  useEffect(() => {
    if (!loading && plans) {
      const headers = ["TECHNICAL FEATURES"]
      for (let i = 0; i < plans.length; i++) {
        headers.push(plans[i].name.toUpperCase())
      }
      setTableHeaders(headers)
    }
  }, [plans])

  useEffect(() => {
    if (!loading && features.length && features.length > 0) {
      const group = features.reduce((r: ParsedFeatures, a: Feature) => {
        if (a.group) r[a.group] = [...(r[a.group] || []), a]
        return r
      }, {})
      setParsedFeatures(group)
    }
  }, [features])

  const truncateSubFeature = (value?: string, text?: string) => {
    if (!value || !text) return ""
    if (value.trim() === text.trim()) {
      return value
    }
    return value.replaceAll(text, "")
  }

  const handleInitialize = (force: boolean) => {
    if (force) dispatch(getPlansRequest())
  }

  const handleEdit = (value?: string, planName?: string) =>
    setCurrentElementCode(`${value}-${planName}`)

  return (
    <div className="plans-configurations">
      <Row className="m-t-20">
        <Col className="float-right">
          <Tooltip placement="bottom" title="Refresh">
            <Button
              type="primary"
              shape="circle"
              size="large"
              onClick={() => handleInitialize(true)}
            >
              <SyncOutlined spin={loading} />
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <Card
        className="plans-configurations"
        bordered={false}
        loading={loading}
        bodyStyle={{
          padding: 0,
        }}
      >
        <div>
          <table>
            <tr>
              {tableHeaders.map((item, index) => (
                <th
                  key={index}
                  className={
                    item === "TECHNICAL FEATURES" ? "text-left" : "text-center"
                  }
                >
                  {item}
                </th>
              ))}
            </tr>
            {Object.entries(parsedFeatures).map(
              ([group, features], groupIndex) => (
                <>
                  <tr key={"feature" + groupIndex}>
                    <td className="feature-text">{group}</td>
                  </tr>
                  {features.map(
                    (subFeature: Feature, subFeatureIndex: number) => (
                      <tr key={groupIndex + "-subFeature-" + subFeatureIndex}>
                        <td className="sub-feature-text">
                          {truncateSubFeature(subFeature.name, group)}
                        </td>
                        {plans.map((plan, planIndex) => (
                          <td
                            key={groupIndex + "-plan-sub-feature-" + planIndex}
                            className="text-center"
                          >
                            <EditablePlanFeature
                              plan={plan}
                              code={subFeature.code}
                              currentSelectedElementCodeWithPlanName={
                                currentElementCode
                              }
                              onEdit={handleEdit}
                            />
                          </td>
                        ))}
                      </tr>
                    )
                  )}
                </>
              )
            )}
          </table>
        </div>
      </Card>
    </div>
  )
}

export default PlansConfigurationPage
