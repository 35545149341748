export type PredefinedRoleName = "Owner" | "Editor" | "Viewer"
export type RoleName = string | PredefinedRoleName
export type PermissionName = string

export interface Role {
  name: RoleName
  permissions: PermissionName[]
}

export type RoleClusterUsage = {
  users: string[]
  groups: string[]
}
export type RoleUsage = Record<
  string, // cluster name
  RoleClusterUsage
>

export const PredefinedRolesName: ReadonlyArray<PredefinedRoleName> = [
  "Owner",
  "Editor",
  "Viewer",
] as const

export const PredefinedRolesPermissionsLevel: Record<
  PredefinedRoleName,
  number
> = {
  Owner: 100,
  Editor: 50,
  Viewer: 10,
}

export type DeleteRolePayload = {
  subscriptionId: number
  role: RoleName
}

export type CreateRolePayload = {
  subscriptionId: number
  role: Role
}

export type UpdateRolePayload = CreateRolePayload & {
  newName?: string
}
