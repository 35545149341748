import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Row, Switch } from "antd"
import {
  selectSubscription,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoading,
  selectSubscriptionDomainList,
} from "src/store/desktop/subscriptionDetails/selector"
import { updateSubscriptionRequest } from "src/store/desktop/subscriptionDetails/actions"
import { ActionType, IActionType, Subscription } from "src/domain/desktop"
import { showModal } from "src/store/system/actions"
import { isSubscriptionExpired } from "src/domain/desktop/subscription"
import { ModalType } from "../../../../../../domain/desktop/common"
import { useSegmentContext } from "src/core/analytics/segment"

const AutoAssignMembersSection: React.FC = () => {
  const dispatch = useDispatch()
  const { track } = useSegmentContext()

  const subscription: Subscription | undefined = useSelector(selectSubscription)

  const domainsCount = useSelector(selectSubscriptionDomainList)?.length || 0
  const loading: boolean = useSelector(selectSubscriptionDetailsLoading)
  const api: IActionType | undefined = useSelector(
    selectSubscriptionDetailsApiMode
  )
  const switchLoading: boolean =
    loading && api === ActionType.UPDATE_SUBSCRIPTION_REQUEST

  const isExpired: boolean = isSubscriptionExpired(subscription)

  const handleSwitchAutoAssignMembers = (hasAutoAssign: boolean) => {
    if (subscription) {
      const payload = {
        subscription: {
          ...subscription,
          hasAutoAssign,
        },
        isAdminApi: false,
      }
      track("cdk.admin.subscriptions.domain.Status", {
        enabled: hasAutoAssign,
      })
      dispatch(updateSubscriptionRequest(payload))
    }
  }
  if (!subscription) return <></>
  return (
    <Row>
      <Col lg={12} md={12} sm={24} xs={24} className="text-left">
        <div className="display-inline-block">
          Auto-Assign Members:
          <Switch
            checked={subscription?.hasAutoAssign}
            defaultChecked
            loading={switchLoading}
            disabled={loading || isExpired}
            size="small"
            className="m-l-5"
            onChange={handleSwitchAutoAssignMembers}
          />
        </div>
        {subscription.hasAutoAssign && (
          <Button
            size="small"
            disabled={isExpired}
            type="link"
            onClick={() => dispatch(showModal(ModalType.DOMAIN))}
          >
            Setup Domains ({domainsCount})
          </Button>
        )}
      </Col>
      <Col lg={12} md={12} sm={24} xs={24} className="text-right"></Col>
    </Row>
  )
}
export default AutoAssignMembersSection
