import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Divider } from "antd"
import { RouteKey } from "src/router/routes"
import {
  getLicensedUsersRequest,
  getSubscriptionDetailsRequest,
} from "src/store/desktop/subscriptionDetails/actions"
import { useSubscriptionPageView } from "src/core/analytics/hooks"
import { Card, Flex, Skeleton } from "@conduktor/ui-library"
import {
  MainDescription,
  MainTitle,
} from "src/components/Typography/Typography"
import { BackTo } from "src/components/BackToLink"
import { useSubscriptionQuery } from "src/pages/hooks/useSubscriptionQuery"
import { SectionText } from "./Section.styled"
import { AccessControl } from "./accessControl/AccessControl"
import { Topics } from "./topics/Topics"
import { useSelectCluster } from "./useSelectCluster.hook"
import { selectSelectedCluster } from "../../../store/desktop/clusters/selector"
import { useRoutePath } from "./useRoutePath.hook"

export const ClusterAccessControl: React.FC = () => {
  const dispatch = useDispatch()
  const { subscription } = useSubscriptionQuery()
  const cluster = useSelector(selectSelectedCluster)
  const backPath = useRoutePath(RouteKey.CLUSTERS)
  useSubscriptionPageView(RouteKey.CLUSTER_CONTROL, subscription)
  useSelectCluster()

  useEffect(() => {
    if (subscription?.id) {
      dispatch(getLicensedUsersRequest(subscription.id))
      dispatch(getSubscriptionDetailsRequest(subscription.id))
    }
  }, [dispatch, subscription?.id])

  if (!cluster) {
    return (
      <Card>
        <BackTo to={backPath}>Manage Clusters</BackTo>
        <Flex className="m-t-20" direction={"column"} gap={2}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Flex>
      </Card>
    )
  }

  return (
    <Card>
      <BackTo to={backPath}>Manage Clusters</BackTo>
      <SectionText>
        <MainTitle>{cluster?.name}</MainTitle>
        <MainDescription>
          Configure access and set topic specific permission.
        </MainDescription>
      </SectionText>

      <Divider />
      <AccessControl />
      <Divider />
      <Topics />
    </Card>
  )
}
