import { SystemActionType } from "../system/actionType"
import { LicensesActionType } from "../desktop/licenses/actionType"
import { AdminDashboardActionType } from "src/domain/desktop/adminDashboardTypes"
import { SubscriptionsActionType } from "src/domain/desktop/subscriptionsTypes"
import { SubscriptionDetailsActionType } from "src/domain/desktop/subscriptionDetailsTypes"
import { PlansActionType } from "src/domain/desktop/admin/plansTypes"
import { LicenseMembersActionType } from "src/domain/desktop/licenseMembersTypes"
import { DomainsActionType } from "src/domain/desktop/domainsTypes"
import { LogsActionType } from "src/domain/desktop/admin/logsTypes"
import { ClustersActionType } from "src/domain/desktop/clustersTypes"
import { UsersActionType } from "src/domain/desktop/usersTypes"
import { DomainDetailsActionType } from "src/domain/desktop/admin/domainDetailsTypes"
import { AdminsActionType } from "src/domain/desktop/admin/adminsTypes"
import { AccessControlActionType } from "src/domain/desktop/accessControlTypes"
import { TopicsControlActionType } from "src/domain/desktop/topicPermission"

enum BasicType {
  INIT_STORE = "ON_INIT",
}

export type IActionType =
  | BasicType
  | SystemActionType
  | AdminDashboardActionType
  | SubscriptionsActionType
  | LicensesActionType
  | SubscriptionDetailsActionType
  | PlansActionType
  | LicenseMembersActionType
  | DomainsActionType
  | LogsActionType
  | ClustersActionType
  | UsersActionType
  | DomainDetailsActionType
  | AdminsActionType
  | AccessControlActionType
  | TopicsControlActionType

export const ActionType = {
  ...BasicType,
  ...SystemActionType,
  ...AdminDashboardActionType,
  ...SubscriptionsActionType,
  ...LicensesActionType,
  ...SubscriptionDetailsActionType,
  ...PlansActionType,
  ...LicenseMembersActionType,
  ...DomainsActionType,
  ...LogsActionType,
  ...ClustersActionType,
  ...UsersActionType,
  ...DomainDetailsActionType,
  ...AdminsActionType,
  ...AccessControlActionType,
  ...TopicsControlActionType,
}
export interface Action<T> {
  type: IActionType
  payload: T
}

export type EmptyAction = Omit<Action<void>, "payload">

export const createEmptyAction = (type: IActionType) => (): EmptyAction => ({
  type: type,
})

export const createAction = <T>(type: IActionType) => (
  payload: T
): Action<T> => ({
  type: type,
  payload: payload,
})
