import React from "react"
import { useSelector } from "react-redux"
import { Checkbox, Col, Input, Row } from "antd"
import UserWithAvatar from "src/components/UserWithAvatar"
import {
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoading,
} from "src/store/desktop/subscriptionDetails/selector"
import { ActionType, TeamModalFormValues } from "src/domain/desktop"
import { isUserAddedToGroup, isUserAlreadyMemberOfGroup } from "../utils"
import { Fragment } from "react"
import { User } from "../../../../domain/system"
import { Team, TeamMember } from "../../../../domain/desktop/common"
interface MyProps {
  values: TeamModalFormValues
  teamData?: Team
  licensedUsersFiltered: User[]
  idsToBeRemoved: number[]
  membersToBeAdded: TeamMember[]
  onChange: Function
  onSearch: Function
  onChangeCheckbox: Function
}

const TeamModalBody: React.FC<MyProps> = ({
  values,
  teamData,
  licensedUsersFiltered,
  idsToBeRemoved,
  membersToBeAdded,
  onChange,
  onSearch,
  onChangeCheckbox,
}) => {
  const apiMode = useSelector(selectSubscriptionDetailsApiMode)
  const loading: boolean =
    useSelector(selectSubscriptionDetailsLoading) &&
    (apiMode === ActionType.CREATE_TEAM_REQUEST ||
      apiMode === ActionType.DELETE_TEAM_REQUEST ||
      apiMode === ActionType.UPDATE_TEAM_REQUEST)

  return (
    <Fragment>
      <Input
        value={values.name}
        className="m-t-10"
        addonBefore="Name"
        placeholder="Team..."
        disabled={loading}
        onChange={onChange("name")}
      />
      <Input
        value={values.color}
        type="color"
        className="m-t-15"
        style={{ width: 100 }}
        addonBefore="Color"
        placeholder="Please select color"
        disabled={loading}
        onChange={onChange("color")}
      />
      <Row>
        <Col className="float-right" span={16}>
          <Input.Search
            placeholder="Search for a Member"
            allowClear={true}
            className="m-t-15 search-box"
            disabled={loading}
            onSearch={(e: string) => onSearch(e)}
          />
        </Col>
      </Row>
      <div className="license-container">
        {licensedUsersFiltered.map((license, index) => (
          <Row key={index} className="m-t-2">
            <Col span={1}>
              <Checkbox
                checked={
                  !idsToBeRemoved.includes(license.id!) &&
                  (isUserAlreadyMemberOfGroup(license, teamData) ||
                    isUserAddedToGroup(
                      license,
                      idsToBeRemoved,
                      membersToBeAdded
                    ))
                }
                disabled={loading}
                className="p-t-15 p-l-5"
                onChange={onChangeCheckbox(license)}
              />
            </Col>
            <Col span={23}>
              <UserWithAvatar
                pictureUrl={license.pictureUrl}
                name={license.name || "Unknown"}
                email={license.email || "Unknown"}
              />
            </Col>
          </Row>
        ))}
      </div>
    </Fragment>
  )
}

export default TeamModalBody
