import React, { ComponentProps } from "react"
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentBox,
  DialogFooter,
  SlideoutClose,
  SlideoutTrigger,
} from "@conduktor/ui-library"

interface ConfirmDialogProps {
  acceptLabel?: string
  acceptVariant?: ComponentProps<typeof Button>["variant"]
  isAccepting: boolean
  isOpen: boolean
  onAccept(): void
  onOpenChange: (open: boolean) => void
  trigger: React.ReactElement
  title: string
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  acceptLabel = "Ok",
  acceptVariant = "primary",
  isAccepting,
  isOpen,
  onAccept,
  onOpenChange,
  trigger,
  title,
  children,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <SlideoutTrigger asChild>{trigger}</SlideoutTrigger>
      <DialogContent size="regular" title={title}>
        <DialogContentBox>{children}</DialogContentBox>
        <DialogFooter justify="flex-end">
          <SlideoutClose asChild>
            <Button
              pointer
              type="button"
              variant="secondaryTransparent"
              disabled={isAccepting}
            >
              Cancel
            </Button>
          </SlideoutClose>
          <Button
            pointer
            type="button"
            variant={acceptVariant}
            onClick={onAccept}
            loading={isAccepting}
          >
            {acceptLabel}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
