import { TablePaginationConfig as Pagination } from "antd"
import { FilterValue, SorterResult } from "antd/lib/table/interface"
import {
  ActionType,
  Domain,
  UpdateDomainStatusApiPayload,
} from "src/domain/desktop"

export const getDomainsRequest = () => ({
  type: ActionType.GET_DOMAINS_REQUEST,
})

export const getDomainsSuccess = (payload: {
  subscriptionDomains: Domain[]
  totalCount: number
}) => ({
  type: ActionType.GET_DOMAINS_SUCCESS,
  payload,
})

export const getDomainsFailure = (payload: string) => ({
  type: ActionType.GET_DOMAINS_FAILURE,
  payload,
})

export const setDomainsTableConfig = (payload: {
  pagination: Pagination
  filters: Record<string, FilterValue | null>
  sorter: SorterResult<Domain> | SorterResult<Domain>[]
}) => ({
  type: ActionType.SET_DOMAINS_TABLE_CONFIG,
  payload,
})

export const updateDomainStatusRequest = (
  payload: UpdateDomainStatusApiPayload
) => ({
  type: ActionType.UPDATE_DOMAIN_STATUS_REQUEST,
  payload,
})

export const updateDomainStatusSuccess = () => ({
  type: ActionType.UPDATE_DOMAIN_STATUS_SUCCESS,
})

export const updateDomainStatusFailure = (payload: string) => ({
  type: ActionType.UPDATE_DOMAIN_STATUS_FAILURE,
  payload,
})
