import { TablePaginationConfig as Pagination } from "antd/lib/table"
import { Subscription } from "../index"
import { License } from "../common"
import { User } from "../../system"

export interface Log {
  action: string
  created?: string
  id: number
  license?: License
  user?: User
  subscription: Subscription
}

export interface Logs {
  items: Array<Log>
  pagination: Pagination
  searchQuery?: string
}

export enum LogsActionType {
  GET_LOGS_REQUEST = "LOGS/GET_LOGS_REQUEST",
  GET_LOGS_SUCCESS = "LOGS/GET_LOGS_SUCCESS",
  GET_LOGS_FAILURE = "LOGS/GET_LOGS_FAILURE",
  SET_LOGS_PAGINATION = "LOGS/SET_LOGS_PAGINATION",
  SET_LOGS_SEARCH_QUERY = "LOGS/SET_LOGS_SEARCH_QUERY",
}
