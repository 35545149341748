import React from "react"
import { Select } from "antd"
import { SelectProps } from "antd/lib/select"
import { FilterFunc } from "rc-select/lib/interface/generator"

const filterOption: FilterFunc<any> = (input, option) => {
  if (!option) return true
  return (option.title || option.children)
    .toLowerCase()
    .includes(input.toLowerCase())
}

export const SearchSelect: React.FC<SelectProps<any>> = ({
  children,
  ...props
}) => {
  return (
    <Select
      showSearch
      optionFilterProp="children"
      filterOption={filterOption}
      {...props}
    >
      {children}
    </Select>
  )
}
