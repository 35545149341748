import React from "react"
import {
  ArrowUpRightFromSquareFarIcon,
  Button,
  Flex,
  Text,
} from "@conduktor/ui-library"
import { AppConfig } from "../../../../core/config"
import { Alert } from "antd"

const discardKey = "cdk.portal.home.downloadApp.discarded"

export const DownloadAppAlert = () => {
  const discard = () => localStorage.setItem(discardKey, "true")
  const isDiscarded = localStorage.getItem(discardKey) === "true"

  if (isDiscarded) {
    return null
  }

  return (
    <Alert
      banner
      type={"info"}
      className={"m-b-10"}
      closable
      onClose={discard}
      message={
        <Flex justify={"space-between"} css={{ color: "$primary11" }}>
          <Flex align={"center"} gap={2}>
            <Text weight={"bold"}>Welcome to Conduktor 🎉</Text> If you
            haven&apos;t already, download the Conduktor Desktop App to get
            started!
          </Flex>
          <a href={AppConfig.website.download} target="_blank" rel="noreferrer">
            <Button variant={"primary"} size={"small"}>
              <ArrowUpRightFromSquareFarIcon fr /> Download App
            </Button>
          </a>
        </Flex>
      }
    />
  )
}
