import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  selectSubscription,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoading,
} from "src/store/desktop/subscriptionDetails/selector"
import { addLicensesByCSVRequest } from "src/store/desktop/subscriptionDetails/actions"
import {
  ActionType,
  AddLicensesByCSVModalProps,
  IActionType,
  Subscription,
} from "src/domain/desktop"
import { Alert, Button, Modal, Row, Space, Spin, Upload } from "antd"
import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons"
import {
  RcFile,
  UploadFile,
  UploadChangeParam,
} from "antd/lib/upload/interface"
import { useSegmentContext } from "src/core/analytics/segment"

export const AddLicensesByCSVModal: React.FC<AddLicensesByCSVModalProps> = ({
  visible,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { track } = useSegmentContext()

  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const apiMode: IActionType | undefined = useSelector(
    selectSubscriptionDetailsApiMode
  )
  const loading =
    useSelector(selectSubscriptionDetailsLoading) &&
    apiMode === ActionType.ADD_LICENSES_CSV_REQUEST

  const handleUpload = useCallback(
    (file: RcFile) => {
      if (!subscription) {
        return false
      }
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = () => {
        if (reader.result) {
          dispatch(
            addLicensesByCSVRequest({
              subscriptionId: subscription?.id,
              csvContent: reader.result as string,
            })
          )
        }
      }
      return false
    },
    [dispatch, subscription]
  )
  const onFileChange = useCallback(
    (info: UploadChangeParam<UploadFile>) => {
      if (info.file.status === "done") {
        track("cdk.admin.subscriptions.members.Imported")
      }
    },
    [track]
  )

  return (
    <Modal
      title={"Add members by CSV"}
      visible={visible}
      width={"50%"}
      maskClosable={false}
      closable={false}
      footer={
        <Button disabled={loading} onClick={() => onClose()}>
          <CloseCircleOutlined />
          Close
        </Button>
      }
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Upload
          disabled={loading}
          onChange={onFileChange}
          beforeUpload={handleUpload}
          showUploadList={false}
        >
          <Button disabled={loading} icon={<UploadOutlined />}>
            Select .csv file
          </Button>
        </Upload>
        {loading ? (
          <Row justify={"center"}>
            <Spin size="large" tip="Loading..." />
          </Row>
        ) : (
          <Alert
            type="info"
            message={<u>example.csv</u>}
            description={
              <Space direction="vertical">
                <span>firstname1,lastname1,user1@test.org</span>
                <span>firstname2,lastname2,user2@test.org</span>
              </Space>
            }
          />
        )}
      </Space>
    </Modal>
  )
}
