import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import { formatDate } from "src/utils"
import { Subscription } from "src/domain/desktop"
import {
  isCancelable,
  isSubscriptionStarted,
} from "src/domain/desktop/subscription"

const DateSection: React.FC = () => {
  const subscription: Subscription | undefined = useSelector(selectSubscription)

  return (
    <Fragment>
      {isSubscriptionStarted(subscription) ? (
        <div>
          Started on:{" "}
          <span className="sub-title">
            {formatDate(subscription?.subscriptionDate)}
          </span>
        </div>
      ) : (
        <div>
          Start on:{" "}
          <span className="sub-title">
            {formatDate(subscription?.subscriptionDate)}
          </span>
        </div>
      )}
      {subscription?.renewalDate && (
        <div>
          Next Billing on:
          <span className="sub-title">
            {" "}
            {formatDate(subscription?.renewalDate)}
          </span>
        </div>
      )}
      {subscription?.expiryDate && (
        <div>
          Expires on:
          <span className="sub-title">
            {" "}
            {formatDate(subscription?.expiryDate)}
          </span>
        </div>
      )}
      {isCancelable(subscription) && (
        <span>
          <Link
            className={"noStyleLink"}
            to={(location) => ({
              ...location,
              pathname: `${location.pathname}/cancel`,
            })}
          >
            Cancel your subscription
          </Link>
        </span>
      )}
    </Fragment>
  )
}
export default DateSection
