import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import AutoAssignDomainsTable from "./components/table"
import {
  selectDomainsError,
  selectDomainsLoading,
} from "src/store/desktop/admin/domains/selector"
import { setMessage } from "src/store/system/actions"
import { RouteKey } from "src/router/routes"
import { usePageView } from "src/core/analytics/hooks"
import { MessageType } from "../../../../domain/system"

const AutoAssignDomainsPage = () => {
  usePageView(RouteKey.ADMIN_AUTO_ASSIGN_DOMAINS)
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectDomainsLoading)
  const error: string | undefined = useSelector(selectDomainsError)

  useEffect(() => {
    if (error && !loading) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: error,
        })
      )
    }
  }, [dispatch, loading, error])

  return (
    <div className="domains-admin">
      <AutoAssignDomainsTable />
    </div>
  )
}

export default AutoAssignDomainsPage
