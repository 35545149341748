import React from "react"
import { Avatar, Tag, Tooltip } from "antd"
import { UserOutlined } from "@ant-design/icons"
import "./styles.less"
import { Team } from "../../domain/desktop/common"
interface UserWithAvatarProps {
  pictureUrl?: string
  name?: string
  email?: string
  company?: string
  showToolTip?: boolean
  teams?: Array<Team>
  isAdmin?: boolean
}
const UserWithAvatar: React.FC<UserWithAvatarProps> = ({
  pictureUrl,
  name,
  email,
  company,
  teams,
  isAdmin,
}) => {
  return (
    <div className="user-with-avatar">
      <div className="display-flex">
        <div className="m-r-10 display-flex" style={{ alignItems: "center" }}>
          {pictureUrl === undefined ? (
            <Avatar size={32} icon={<UserOutlined />} />
          ) : (
            <Avatar size={32} src={pictureUrl} />
          )}
        </div>
        <div className="w-100">
          {!company ? (
            <div>
              {name}
              {isAdmin && (
                <Tag color="#2db7f5" className="m-l-3">
                  Admin
                </Tag>
              )}
              <div className="hint-text m-t-4">
                {email}
                {(teams || []).map((team, index) => (
                  <Tag key={index} color={team.color} closable={false}>
                    {team.name}
                  </Tag>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <Tooltip
                title={
                  <div>
                    {email}
                    {(teams || []).map((team, index) => (
                      <Tag key={index} color={team.color} closable={false}>
                        {team.name}
                      </Tag>
                    ))}
                  </div>
                }
              >
                <a href={"mailto:" + email}>{name}</a>
              </Tooltip>
              <div className="hint-text m-t-4">{company}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default UserWithAvatar
