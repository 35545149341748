import { Action, ActionType, Auth0Stats, EmptyAction } from "src/domain/desktop"

export const getAuth0StatsRequest = (): EmptyAction => ({
  type: ActionType.GET_AUTH0_STATS_REQUEST,
})

export const getAuth0StatsSuccess = (
  payload: Auth0Stats
): Action<Auth0Stats> => ({
  type: ActionType.GET_AUTH0_STATS_SUCCESS,
  payload,
})

export const getAuth0StatsFailure = (message: string): Action<string> => ({
  type: ActionType.GET_AUTH0_STATS_FAILURE,
  payload: message,
})
