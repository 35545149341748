import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  CheckCircleTwoTone,
  InfoCircleTwoTone,
  WarningTwoTone,
} from "@ant-design/icons"
import { notification } from "antd"
import { setMessage } from "src/store/system/actions"
import { selectMessage } from "src/store/system/selector"
import { MessageType } from "../../domain/system"

const Notification = () => {
  const dispatch = useDispatch()
  const message = useSelector(selectMessage)

  useEffect(() => {
    if (message) {
      notification.open({
        message: message.title,
        description: message.message,
        icon:
          message.type === MessageType.SUCCESS ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : message.type === MessageType.INFO ? (
            <InfoCircleTwoTone />
          ) : (
            <WarningTwoTone twoToneColor="#eb2f96" />
          ),
      })
      dispatch(setMessage(undefined))
    }
  }, [message, dispatch])

  return <></>
}
export default Notification
