import { useDispatch, useSelector } from "react-redux"
import { UserDeleteOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Tooltip } from "antd"
import { isAllowedToDelete, showDeleteButton, toolTipMessage } from "../utils"
import {
  DeleteSubscriptionItemApiPayload,
  Subscription,
} from "src/domain/desktop"
import {
  selectSubscription,
  selectSubscriptionBillingUsers,
} from "src/store/desktop/subscriptionDetails/selector"
import { selectConduktorUser } from "src/store/system/selector"
import {
  deleteAdminOfSubscriptionRequest,
  deleteBillingUserRequest,
  deleteLicensedUserRequest,
  deleteLicenseManagerRequest,
} from "src/store/desktop/subscriptionDetails/actions"
import { isSubscriptionExpired } from "src/domain/desktop/subscription"
import { ConduktorUser, User } from "../../../../../../../../domain/system"
import { UserRoleType } from "../../../../../../../../domain/desktop/common"
import { useSegmentContext } from "src/core/analytics/segment"

const RevokeColumn = (_text: string, record: User) => {
  const dispatch = useDispatch()
  const { track } = useSegmentContext()
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const billingUsers: Array<User> =
    useSelector(selectSubscriptionBillingUsers) || []

  const handleRemoveLicense = (license: User) => {
    if (!license?.id || !subscription) return

    const payload: DeleteSubscriptionItemApiPayload = {
      subscriptionId: subscription?.id,
      id: license.id,
    }

    if (license.accessLevel) {
      switch (license.accessLevel) {
        case UserRoleType.BILLING_USER:
          dispatch(deleteBillingUserRequest(payload))
          break
        case UserRoleType.SUBSCRIPTION_ADMIN:
          dispatch(deleteAdminOfSubscriptionRequest(payload))
          break
        case UserRoleType.TECHNICAL_MANAGER:
          dispatch(deleteLicenseManagerRequest(payload))
          break
      }
    } else {
      dispatch(deleteLicensedUserRequest(payload))
    }
    track("cdk.admin.subscriptions.members.Revoked")
  }

  return showDeleteButton(record, conduktorUser, billingUsers, subscription) ? (
    <Popconfirm
      title="Are you sure you want to remove this member?"
      disabled={
        isSubscriptionExpired(subscription) ||
        !isAllowedToDelete(record, conduktorUser, subscription)
      }
      onConfirm={() => handleRemoveLicense(record)}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip
        placement="left"
        title={
          !isAllowedToDelete(record, conduktorUser, subscription)
            ? toolTipMessage(record.created || 0, subscription)
            : undefined
        }
      >
        <Button
          disabled={
            isSubscriptionExpired(subscription) ||
            !isAllowedToDelete(record, conduktorUser, subscription)
          }
          icon={<UserDeleteOutlined />}
          danger
          type="link"
        />
      </Tooltip>
    </Popconfirm>
  ) : (
    <span></span>
  )
}

export default RevokeColumn
