import React, { FC, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AccessControlMemberApiPayload } from "src/domain/desktop"
import { accessControlEnabled } from "src/domain/desktop/cluster"
import { upsertAccessControlGroupApi } from "src/services"
import { Skeleton, SpinnerDots, Switch, Text } from "@conduktor/ui-library"
import {
  SectionDescription,
  SectionTitle,
} from "src/components/Typography/Typography"
import { useSubscriptionQuery } from "src/pages/hooks/useSubscriptionQuery"
import { allUsersGroupId } from "src/domain/desktop/accessControlTypes"
import { updateAccessControlMemberSuccess } from "src/store/desktop/accessControl/actions"
import {
  selectAccessControlGroups,
  selectAccessControlLoaded,
} from "src/store/desktop/accessControl/selector"
import {
  AccessControlAddPanel,
  AccessControlHeader,
} from "./AccessControl.styled"
import { Section, SectionPanel, SectionText } from "../Section.styled"
import { AddMember } from "./AddMember"
import { ListUsers } from "./ListUsers"
import { ListGroups } from "./ListGroups"
import { selectSelectedCluster } from "../../../../store/desktop/clusters/selector"

export const AccessControl: FC = () => {
  const dispatch = useDispatch()
  const { subscription } = useSubscriptionQuery()
  const cluster = useSelector(selectSelectedCluster)
  const loaded = useSelector(selectAccessControlLoaded)
  const groups = useSelector(selectAccessControlGroups)

  const [isEnabling, setIsEnabling] = useState<boolean>()

  const handleAccessControlEnabledChange = async (checked: boolean) => {
    const payload: AccessControlMemberApiPayload = {
      clusterId: cluster!.clusterId!,
      subscriptionId: subscription!.id,
      type: "group",
      id: allUsersGroupId,
      roles: [checked ? "Viewer" : "Owner"],
    }

    try {
      setIsEnabling(true)
      await upsertAccessControlGroupApi(payload)
      dispatch(updateAccessControlMemberSuccess(payload))
    } finally {
      setIsEnabling(false)
    }
  }

  const isAccessControlEnabled = loaded && accessControlEnabled(groups)

  return (
    <Section>
      <SectionText small>
        <SectionTitle>Manage Cluster Access</SectionTitle>
        <SectionDescription>
          By default, clusters are accessible by anyone. Turning on access
          control allows you to limit that access and only make it accessible to
          specific users and groups.
        </SectionDescription>
      </SectionText>
      <SectionPanel>
        <AccessControlHeader expanded={isAccessControlEnabled}>
          <Text>Access Control</Text>
          {isEnabling || !loaded ? (
            <SpinnerDots size={"small"} />
          ) : (
            <Switch
              size={"small"}
              value={isAccessControlEnabled}
              onChange={handleAccessControlEnabledChange}
            />
          )}
        </AccessControlHeader>

        {isAccessControlEnabled && !loaded && (
          <AccessControlAddPanel>
            <Skeleton />
          </AccessControlAddPanel>
        )}
        {isAccessControlEnabled && loaded && (
          <>
            <AddMember />

            <ListUsers />
            <ListGroups />
          </>
        )}
      </SectionPanel>
    </Section>
  )
}
