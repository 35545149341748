import { useDispatch, useSelector } from "react-redux"
import { Button, Row, Switch } from "antd"
import EditableNumber from "src/components/EditableNumber"
import {
  selectSubscription,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoading,
} from "src/store/desktop/subscriptionDetails/selector"
import {
  updateSubscriptionReassignAllMembersRequest,
  updateSubscriptionRequest,
} from "src/store/desktop/subscriptionDetails/actions"
import { showModal } from "src/store/system/actions"
import { minToDays, MINUTES_PER_DAY } from "src/utils"
import { ActionType, IActionType, Subscription } from "src/domain/desktop"
import { isSubscriptionExpired } from "src/domain/desktop/subscription"
import { ModalType } from "../../../../../../domain/desktop/common"
import { PlayCircleFilled, PlusSquareFilled } from "@ant-design/icons"

const AdminSection = () => {
  const dispatch = useDispatch()
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const isExpired: boolean = isSubscriptionExpired(subscription)
  const loading: boolean = useSelector(selectSubscriptionDetailsLoading)
  const api: IActionType | undefined = useSelector(
    selectSubscriptionDetailsApiMode
  )
  const switchLoading: boolean =
    loading && api === ActionType.UPDATE_SUBSCRIPTION_REQUEST

  const handleReassignAllMembers = () => {
    if (subscription) {
      const payload = {
        subscription: {
          ...subscription,
        },
        isAdminApi: true,
      }
      dispatch(updateSubscriptionReassignAllMembersRequest(payload))
    }
  }
  const handleSwitchShowLastActivity = (value: boolean) => {
    handleAdminUpdate({ showLastActivity: value })
  }

  const handleSwitchHasRevokeMembers = (value: boolean) => {
    handleAdminUpdate({ hasReAssignment: value })
  }

  const handleSwitchHasPurchaseAllowed = (value: boolean) => {
    handleAdminUpdate({ hasPurchaseAllowed: value })
  }

  const handleUpdateReAssignment = (value: number) => {
    handleAdminUpdate({ reassignmentMinDelay: value * MINUTES_PER_DAY })
  }

  const handleUpdateRevokePercentagePerMonth = (value: number) => {
    handleAdminUpdate({ revokePercentagePerMonth: value })
  }

  const handleAdminUpdate = (update: Partial<Subscription>) => {
    if (subscription) {
      const payload = {
        subscription: {
          ...subscription,
          ...update,
        },
        isAdminApi: true,
      }
      dispatch(updateSubscriptionRequest(payload))
    }
  }

  return (
    <Row>
      <details>
        <summary style={{ outline: "none", cursor: "pointer", marginTop: 20 }}>
          ⭐️ Administration Settings
        </summary>
        <div className="p-t-5">
          <Button
            disabled={isExpired}
            className="m-0 p-0"
            icon={<PlusSquareFilled />}
            size="small"
            type="link"
            onClick={() => dispatch(showModal(ModalType.BILLING_USERS))}
          >
            Add an Owner
          </Button>
        </div>
        <div className="p-t-5">
          <Button
            disabled={isExpired}
            className="m-0 p-0"
            icon={<PlayCircleFilled />}
            size="small"
            type="link"
            onClick={handleReassignAllMembers}
          >
            Update all users on Auth0
          </Button>
        </div>
        <div className="display-inline-block p-t-5">
          Show last activity column:&nbsp;
          <Switch
            checked={subscription?.showLastActivity}
            defaultChecked
            loading={switchLoading}
            disabled={loading || isExpired}
            size="small"
            onChange={handleSwitchShowLastActivity}
          />
        </div>
        <br />
        <div className="display-inline-block p-t-5">
          Can revoke members:&nbsp;
          <Switch
            checked={subscription?.hasReAssignment}
            defaultChecked
            loading={switchLoading}
            disabled={loading || isExpired}
            size="small"
            onChange={handleSwitchHasRevokeMembers}
          />
        </div>
        <br />
        <div className="display-inline-block p-t-5">
          Allow Purchasing Additional Licenses:&nbsp;
          <Switch
            checked={subscription?.hasPurchaseAllowed}
            defaultChecked
            loading={switchLoading}
            disabled={loading || isExpired}
            size="small"
            onChange={handleSwitchHasPurchaseAllowed}
          />
        </div>
        {subscription?.hasReAssignment && (
          <div>
            <span className="float-left p-t-5">
              Days after ASSIGNMENT to revoke members:&nbsp;
            </span>
            <EditableNumber
              value={minToDays(subscription?.reassignmentMinDelay)}
              disabled={loading || isExpired}
              loading={loading}
              onChange={handleUpdateReAssignment}
            />
          </div>
        )}
        <div>
          <span className="float-left p-t-5">
            Acceptable % of reassignments per Month:&nbsp;
          </span>
          <EditableNumber
            value={subscription?.revokePercentagePerMonth}
            disabled={loading || isExpired}
            loading={loading}
            onChange={handleUpdateRevokePercentagePerMonth}
          />
        </div>
      </details>
    </Row>
  )
}
export default AdminSection
