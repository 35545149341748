import { Role, RoleName } from "./index"

export interface ClusterUserUpdate {
  id: string
  fullName: string
}

export interface Cluster {
  clusterId?: string
  name?: string
  description?: string
  editable?: boolean
  roles?: RoleName[]
  allUsersRoles?: RoleName[]
  createdAt?: string
  updatedAt?: string
  createdBy?: ClusterUserUpdate
  updatedBy?: ClusterUserUpdate
}

export enum Usability {
  Enabled = "enabled",
  Disabled = "disabled",
  Limit = "limit",
  Value = "value",
}

export interface PermissionValue {
  permissionType?: Usability
  value?: string
  limit?: number
}

export type ClusterTab = "clusters" | "roles"

export interface Clusters {
  items: Array<Cluster>
  checked: Array<Cluster>
  selected?: Cluster
  roles?: Role[]
}

export enum ClustersActionType {
  GET_CLUSTERS_REQUEST = "CLUSTERS/GET_CLUSTERS_REQUEST",
  GET_CLUSTERS_SUCCESS = "CLUSTERS/GET_CLUSTERS_SUCCESS",
  GET_CLUSTERS_FAILURE = "CLUSTERS/GET_CLUSTERS_FAILURE",
  GET_CLUSTERS_ROLES_REQUEST = "CLUSTERS/GET_CLUSTERS_ROLES_REQUEST",
  GET_CLUSTERS_ROLES_SUCCESS = "CLUSTERS/GET_CLUSTERS_ROLES_SUCCESS",
  GET_CLUSTERS_ROLES_FAILURE = "CLUSTERS/GET_CLUSTERS_ROLES_FAILURE",
  ADD_CLUSTER_REQUEST = "CLUSTERS/ADD_CLUSTER_REQUEST",
  ADD_CLUSTER_SUCCESS = "CLUSTERS/ADD_CLUSTER_SUCCESS",
  ADD_CLUSTER_FAILURE = "CLUSTERS/ADD_CLUSTER_FAILURE",
  UPDATE_CLUSTER_REQUEST = "CLUSTERS/UPDATE_CLUSTER_REQUEST",
  UPDATE_CLUSTER_SUCCESS = "CLUSTERS/UPDATE_CLUSTER_SUCCESS",
  UPDATE_CLUSTER_FAILURE = "CLUSTERS/UPDATE_CLUSTER_FAILURE",
  DELETE_CLUSTER_REQUEST = "CLUSTERS/DELETE_CLUSTER_REQUEST",
  DELETE_CLUSTER_SUCCESS = "CLUSTERS/DELETE_CLUSTER_SUCCESS",
  DELETE_CLUSTER_FAILURE = "CLUSTERS/DELETE_CLUSTER_FAILURE",
  DELETE_CLUSTERS_REQUEST = "CLUSTERS/DELETE_CLUSTERS_REQUEST",
  DELETE_CLUSTERS_SUCCESS = "CLUSTERS/DELETE_CLUSTERS_SUCCESS",
  DELETE_CLUSTERS_FAILURE = "CLUSTERS/DELETE_CLUSTERS_FAILURE",
  CHECK_CLUSTERS = "CLUSTERS/CHECK_CLUSTERS",
  SELECT_CLUSTER = "CLUSTERS/SELECT_CLUSTER",
  CLUSTERS_SELECT_TAB = "CLUSTERS/CLUSTERS_SELECT_TAB",
}
