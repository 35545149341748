import { Alert } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useEffect } from "react"
import { Text } from "@conduktor/ui-library"
import {
  selectSubscriptionsList,
  selectSubscriptionsLoaded,
  selectSubscriptionsLoading,
} from "src/store/desktop/subscriptions/selector"
import { getSubscriptionsRequest } from "src/store/desktop/subscriptions/actions"
import { isEmpty } from "src/utils"
import { CenteredSpinner } from "src/components/Spinner"
import { usePageView } from "src/core/analytics/hooks"
import { RouteKey } from "src/router/routes"

export const ClustersPageShortcut = () => {
  usePageView(RouteKey.CLUSTERS_SHORTCUT)
  const dispatch = useDispatch()
  const history = useHistory()
  const isLoaded: boolean = useSelector(selectSubscriptionsLoaded)
  const isLoading: boolean = useSelector(selectSubscriptionsLoading)
  const subscriptionsList = useSelector(selectSubscriptionsList)
  const hasNoSubscriptions = isEmpty(subscriptionsList)

  useEffect(() => {
    if (hasNoSubscriptions) {
      dispatch(getSubscriptionsRequest())
    }
  }, [dispatch, hasNoSubscriptions])

  useEffect(() => {
    if (!hasNoSubscriptions) {
      const id = subscriptionsList[0].chargeBeeSubscriptionId
      history.replace(`/subscriptions/licenses-management/${id}/clusters`)
    }
  }, [history, hasNoSubscriptions, subscriptionsList])

  if (isLoading || !isLoaded) {
    return <CenteredSpinner />
  }

  return (
    <Alert
      banner
      type={"info"}
      className={"m-b-10"}
      message={<Text>Sorry, you are not a manager of any subscription</Text>}
    />
  )
}
