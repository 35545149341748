import { RoleName, Subscription } from "./index"
import { TablePaginationConfig as Pagination } from "antd"
import { ConduktorUser, Email, User } from "../system"

export interface Fingerprint {
  first_usage?: string | number
  last_usage?: string | number
  fingerprint?: string
  os?: string
  userAgent?: string
  ip?: string
  location?: string
}

export interface AppMetadata {
  fingerprints?: Array<Fingerprint>
  last_auth_date?: number
  max_activations?: number
  portal_last_activity_saved?: string
  subscription_expiry?: string
  subscription_type?: string
}

export type UpdatableConduktorUser = Pick<
  ConduktorUser,
  "firstName" | "lastName" | "country" | "phoneNumber"
>

export interface TeamMember extends User {
  license: License | User
}

export interface Team {
  id?: number
  name?: string
  color?: string
  createdBy?: User
  modifiedBy?: User
  created?: number
  teamMembers?: Array<TeamMember>
}
export type TeamsById = Record<number, Team>

export interface TeamWrapper {
  id?: number
  team: Team
}

export interface LicenseOffline {
  id?: number
  expiry?: number
  token?: string
  name?: string
}

export type UserRoles = {
  email: Email
  roles: RoleName[]
}

export enum ModalType {
  ADD_USER = "add_user",
  ADD_USER_CSV = "add_user_csv",
  INVOCE = "invoice",
  LICENSE_MANAGER = "license_manager",
  BILLING_USERS = "billing_users",
  DOMAIN = "domain",
  INCRESE_LICENSE = "increase_license",
  CLUSTER = "clusters",
}

export enum UserRoleType {
  BILLING_USER = 1,
  TECHNICAL_MANAGER = 2,
  ADMIN = 3,
  SUBSCRIPTION_ADMIN = 4,
}

interface LicenseTeam {
  id: number
  created: number
  team: Team
}

export interface License extends User {
  subscription: Subscription
  chargeBeeSubscriptionId?: string
  teams?: Array<LicenseTeam>
  renewalDate?: number
  expiryDate?: number
}

export interface Licenses {
  items: Array<License>
  pagination: Pagination
  searchQuery?: string
}
