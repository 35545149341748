import { useDispatch, useSelector } from "react-redux"
import {
  selectSubscription,
  selectSubscriptionDetailsLoading,
} from "src/store/desktop/subscriptionDetails/selector"
import { deleteDomainEmailRequest } from "src/store/desktop/subscriptionDetails/actions"
import {
  DomainEmail,
  DeleteDomainEmailApiPayload,
  Subscription,
} from "src/domain/desktop"
import { Button, Popconfirm } from "antd"
import { ColumnType } from "antd/lib/table"

export const useDomainEmailsColumns = () => {
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectSubscriptionDetailsLoading)
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const handleDeleteDomainEmail = (id?: number | string) => {
    const payload: DeleteDomainEmailApiPayload = {
      subscriptionId: subscription?.id,
      domainEmailId: id,
    }
    dispatch(deleteDomainEmailRequest(payload))
  }

  const columns: ColumnType<DomainEmail>[] = [
    {
      key: "email",
      title: "Email",
      render: (_text, record) => {
        return <span>{record.email}</span>
      },
    },
    {
      key: "action-sub-table",
      title: "Action",
      width: 80,
      render: (_text, record) => {
        return (
          <Popconfirm
            disabled={loading}
            title="Are you sure you want to remove this email from the allow-list?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDeleteDomainEmail(record.id)}
          >
            <Button disabled={loading} type="link">
              Unallow
            </Button>
          </Popconfirm>
        )
      },
      align: "center",
    },
  ]
  return columns
}
