import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  selectSubscription,
  selectSubscriptionDetailsLoading,
  selectSubscriptionDomainList,
} from "src/store/desktop/subscriptionDetails/selector"
import { setMessage } from "src/store/system/actions"
import { addDomainRequest } from "src/store/desktop/subscriptionDetails/actions"
import {
  AddDomainApiPayload,
  Domain,
  DomainModalProps,
  Subscription,
} from "src/domain/desktop"
import { Button, Input, Modal, Row } from "antd"
import { CloseCircleOutlined } from "@ant-design/icons"
import DomainModalTable from "./components/Table"
import "./styles.less"
import { useSegmentContext } from "src/core/analytics/segment"

const DomainModal: React.FC<DomainModalProps> = ({ visible, onClose }) => {
  const dispatch = useDispatch()
  const { track } = useSegmentContext()
  const loading: boolean = useSelector(selectSubscriptionDetailsLoading)
  const domainList: Domain[] | undefined = useSelector(
    selectSubscriptionDomainList
  )
  const subscription: Subscription | undefined = useSelector(selectSubscription)

  const handleClose = () => {
    onClose()
  }

  const handleAddDomain = (domainName: string) => {
    if (domainName && domainName.includes(".")) {
      if (
        !domainList ||
        domainList.length < 1 ||
        domainList.find(
          (o) => o.domain === domainName || o.name === domainName
        ) === undefined
      ) {
        const payload: AddDomainApiPayload = {
          domain: domainName,
          subscription,
        }
        dispatch(addDomainRequest(payload))
        track("cdk.admin.subscriptions.domain.Linked")
      } else {
        dispatch(
          setMessage({
            message: `Domain already registered`,
          })
        )
      }
    } else {
      dispatch(
        setMessage({
          message: `Please enter a valid domain`,
        })
      )
    }
  }

  return (
    <Modal
      title="Domains linked to your subscription"
      className="domains p-t-0"
      visible={visible}
      width={"50%"}
      maskClosable={false}
      closable={false}
      footer={
        <Row>
          <Button disabled={loading} className="m-l-0" onClick={handleClose}>
            <CloseCircleOutlined />
            Close
          </Button>
        </Row>
      }
    >
      <p>
        To avoid adding members manually, you can ask to link one or multiple
        domains to your subscription. The users belonging to this domain, when
        logging in through Conduktor Desktop, will automatically be added as
        member.
      </p>
      <Input.Search
        addonBefore="Domain to link"
        placeholder="mycompany.com"
        enterButton="Link"
        loading={loading}
        onSearch={handleAddDomain}
      />
      <DomainModalTable />
    </Modal>
  )
}

export default DomainModal
