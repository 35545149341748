import { TablePaginationConfig } from "antd"
import { RootState } from "src/domain/desktop"
import { LicensesReducerType } from "./reducer"
import { License } from "../../../domain/desktop/common"

const selectLicensesState = (state: RootState): LicensesReducerType =>
  state.licenses

export const selectLicensesLoading = (state: RootState): boolean =>
  selectLicensesState(state).loading

export const selectLicensesError = (state: RootState): string | undefined =>
  selectLicensesState(state).error

export const selectLicensesList = (state: RootState): License[] =>
  selectLicensesState(state).items

export const selectLicensesPagination = (
  state: RootState
): TablePaginationConfig => selectLicensesState(state).pagination

export const selectLicensesSearchQuery = (
  state: RootState
): string | undefined => selectLicensesState(state).searchQuery
