import { createAction, GetTopicsControlApiPayload } from "src/domain/desktop"
import {
  TopicsControl,
  TopicsControlActionType,
  TopicControlTarget,
  TopicControl,
} from "src/domain/desktop/topicPermission"

// get topics members
export const getTopicsControlRequest = createAction<GetTopicsControlApiPayload>(
  TopicsControlActionType.GET_TOPICS_CONTROL_REQUEST
)
export const getTopicsControlSuccess = createAction<TopicsControl>(
  TopicsControlActionType.GET_TOPICS_CONTROL_SUCCESS
)
export const getTopicsControlFailure = createAction<string>(
  TopicsControlActionType.GET_TOPICS_CONTROL_FAILURE
)

// remove one topic
export const removeTopicControl = createAction<TopicControlTarget>(
  TopicsControlActionType.REMOVE_TOPIC_CONTROL
)

// create or update one topic
export const upsertTopicControl = createAction<TopicControl>(
  TopicsControlActionType.UPSERT_TOPIC_CONTROL
)
