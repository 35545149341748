import { RoleName } from "./rolesTypes"

export enum AccessControlActionType {
  GET_ACCESS_CONTROL_REQUEST = "ACCESS_CONTROL/GET_ACCESS_CONTROL_REQUEST",
  GET_ACCESS_CONTROL_SUCCESS = "ACCESS_CONTROL/GET_ACCESS_CONTROL_SUCCESS",
  GET_ACCESS_CONTROL_FAILURE = "ACCESS_CONTROL/GET_ACCESS_CONTROL_FAILURE",
  UPDATE_ACCESS_CONTROL_MEMBER_SUCCESS = "ACCESS_CONTROL/UPDATE_ACCESS_CONTROL_MEMBER_SUCCESS",
  REMOVE_ACCESS_CONTROL_MEMBER_SUCCESS = "ACCESS_CONTROL/REMOVE_ACCESS_CONTROL_MEMBER_SUCCESS",
}

export type AccessControlMemberId = string
export type AccessControlMembers = Record<AccessControlMemberId, RoleName[]>
export type AccessControl = {
  groups: AccessControlMembers
  users: AccessControlMembers
}

export const allUsersGroupId = "AllUsers"
export const allUsersGroupName = "All users group"
