import React, { FC, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import {
  Box,
  Card,
  Flex,
  Form,
  PlusFarIcon,
  Skeleton,
  TextInput,
  useFormField,
} from "@conduktor/ui-library"
import { Divider } from "antd"

import {
  MainDescription,
  MainTitle,
  SectionDescription,
  SectionTitle,
} from "src/components/Typography/Typography"
import { BackTo } from "src/components/BackToLink"
import { useSubscriptionPageView } from "src/core/analytics/hooks"
import { RouteKey } from "src/router/routes"
import { useSubscriptionQuery } from "src/pages/hooks/useSubscriptionQuery"
import { selectSelectedCluster } from "src/store/desktop/clusters/selector"
import { useSelectCluster } from "../useSelectCluster.hook"
import { useRoutePath } from "../useRoutePath.hook"
import { Section, SectionPanel, SectionText } from "../Section.styled"
import { TopicMembersPermissions } from "./TopicMembersPermissions"
import {
  hasSameTarget,
  isUserMember,
  TopicControlMember,
  TopicControlMembersPermissions,
} from "src/domain/desktop/topicPermission"
import {
  getTopicsControlRequest,
  removeTopicControl,
  upsertTopicControl,
} from "src/store/desktop/topicsControl/actions"
import { selectTopicsControl } from "src/store/desktop/topicsControl/selector"
import { isEmpty, orBlank, removeIndex, replace } from "src/utils"
import { AddMembersPermissions } from "./TopicControl.styled"
import {
  getLicensedUsersRequest,
  getSubscriptionDetailsRequest,
} from "src/store/desktop/subscriptionDetails/actions"
import { allUsersGroupId } from "src/domain/desktop"
import {
  removeClusterTopicControlApi,
  upsertClusterTopicControlApi,
} from "src/services"
import { setErrorMessage, setSuccessMessage } from "src/store/system/actions"
import { FormButton, SubmitButton } from "src/components/Form"
import { FormValues, schema } from "./schema"
import { selectSubscriptionTeamsById } from "src/store/desktop/subscriptionDetails/selector"
import { TeamsById } from "src/domain/desktop/common"

interface RouteParams {
  topic?: string
}

const compareMembers = (teamsById: TeamsById) => {
  return (a: TopicControlMember, b: TopicControlMember) => {
    if (isUserMember(a) && !isUserMember(b)) {
      return 1
    }
    if (!isUserMember(a) && isUserMember(b)) {
      return -1
    }
    if (isUserMember(a) && isUserMember(b)) {
      return a.userEmail.localeCompare(b.userEmail)
    }
    if (!isUserMember(a) && !isUserMember(b)) {
      if (a.groupName === allUsersGroupId) {
        return -1
      }
      if (b.groupName === allUsersGroupId) {
        return 1
      }
      const aGroupDisplayName = orBlank(teamsById[Number(a.groupName)].name)
      const bGroupDisplayName = orBlank(teamsById[Number(b.groupName)].name)
      return aGroupDisplayName.localeCompare(bGroupDisplayName)
    }
    return 0
  }
}

export const AccessTopicControl: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { topic }: RouteParams = useParams()
  const cluster = useSelector(selectSelectedCluster)
  const topicsControl = useSelector(selectTopicsControl)
  const backPath = useRoutePath(RouteKey.CLUSTER_CONTROL)
  const creationPath = useRoutePath(RouteKey.TOPIC_CONTROL_CREATION)
  const { subscription } = useSubscriptionQuery()
  const teamsById = useSelector(selectSubscriptionTeamsById)
  const [members, setMembers] = useState<TopicControlMembersPermissions[]>([])
  const { FormField } = useFormField<FormValues>()

  useSubscriptionPageView(RouteKey.TOPIC_CONTROL_CREATION, subscription)
  useSelectCluster()

  const isCreate = !topic

  useEffect(() => {
    if (subscription?.id) {
      dispatch(getLicensedUsersRequest(subscription.id))
      dispatch(getSubscriptionDetailsRequest(subscription.id))
    }
  }, [dispatch, subscription?.id])

  useEffect(() => {
    if (!topic || !topicsControl || !cluster?.clusterId) {
      return
    }
    const topicControl = topicsControl.find(
      hasSameTarget(cluster.clusterId, topic)
    )
    topicControl && setMembers(topicControl.membersPermissions)
  }, [setMembers, topicsControl, topic, cluster])

  useEffect(() => {
    if (isCreate || topicsControl || !subscription?.id || !cluster?.clusterId) {
      return
    }
    dispatch(
      getTopicsControlRequest({
        subscriptionId: subscription.id,
        clusterId: cluster.clusterId,
      })
    )
  }, [dispatch, topicsControl, subscription, cluster, isCreate])

  const addMembersPermissions = () => {
    if (!cluster?.clusterId) {
      return
    }
    setMembers([...members, { permissions: [], members: [] }])
  }

  const onMembersPermissionsChange = (
    membersPermissions: TopicControlMembersPermissions,
    index: number
  ) => {
    if (isEmpty(membersPermissions.members)) {
      setMembers(removeIndex(index, members))
    } else {
      setMembers(replace(index, membersPermissions, members))
    }
  }

  const removePreviousIsRenamed = async (newName: string) => {
    if (isCreate || !topic || topic === newName) {
      return
    }
    const to = { topicClusterId: cluster!.clusterId!, topicName: topic }
    await removeClusterTopicControlApi({
      subscriptionId: subscription!.id,
      target: to,
    })
    dispatch(removeTopicControl(to))
  }

  const save = async (newName: string) => {
    const to = { topicClusterId: cluster!.clusterId!, topicName: newName }
    await upsertClusterTopicControlApi({
      subscriptionId: subscription!.id,
      update: { to, from: members },
    })
    dispatch(
      upsertTopicControl({
        target: to,
        membersPermissions: members,
      })
    )
  }

  const onSave = async ({ topic }: FormValues) => {
    if (!subscription?.id || !cluster?.clusterId) {
      return
    }
    try {
      await save(topic)
      await removePreviousIsRenamed(topic)

      const message = `Topic control ${isCreate ? "created" : "updated"}`
      dispatch(setSuccessMessage(message))
      history.replace(`${creationPath}/${topic}`)
    } catch (e) {
      dispatch(setErrorMessage(`Error while saving this topic control`))
    }
  }

  if (!cluster) {
    return (
      <Card>
        <Flex className="m-t-20" direction={"column"} gap={2}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Flex>
      </Card>
    )
  }

  return (
    <Form
      onSubmit={onSave}
      schema={schema}
      defaultValues={{ topic: orBlank(topic) }}
    >
      <Card>
        <BackTo to={backPath}>{cluster?.name}</BackTo>
        <SectionText>
          <MainTitle>Define Topic Permissions</MainTitle>
          <MainDescription>
            Manage Topic access by setting permissions to specific topic
            patterns.
          </MainDescription>
        </SectionText>

        <Divider />

        <Section>
          <SectionText small>
            <SectionTitle>Topic pattern</SectionTitle>
            <SectionDescription>
              Define the topic name. You can also use a wildcard as a prefix or
              suffix to reference the desired topic pattern (e.g.: my-topic-*).
            </SectionDescription>
          </SectionText>
          <SectionPanel small>
            <Box css={{ maxWidth: "30em" }}>
              <FormField name="topic">
                <TextInput placeholder={"my-topic-pattern-*"} />
              </FormField>
            </Box>
          </SectionPanel>
        </Section>

        <Divider />

        <Section>
          <SectionText small>
            <SectionTitle>Permissions</SectionTitle>
            <SectionDescription>
              Define topic permissions by creating permission groups. A
              permission group can contain multiple user groups.
            </SectionDescription>
          </SectionText>
          <SectionPanel>
            <Flex direction={"column"} gap={2}>
              {members.map((membersPermissions, index) => (
                <TopicMembersPermissions
                  key={index}
                  membersPermissions={{
                    members: membersPermissions.members.sort(
                      compareMembers(teamsById)
                    ),
                    permissions: membersPermissions.permissions,
                  }}
                  onChange={(membersPermissions) =>
                    onMembersPermissionsChange(membersPermissions, index)
                  }
                />
              ))}
              <AddMembersPermissions onClick={addMembersPermissions}>
                <PlusFarIcon fr />
                <span> Add permission group</span>
              </AddMembersPermissions>
            </Flex>
          </SectionPanel>
        </Section>

        <Divider />

        <Flex gap={2} align={"center"}>
          <SubmitButton>{isCreate ? "Create" : "Update"}</SubmitButton>
          <Link to={backPath}>
            <FormButton>Cancel</FormButton>
          </Link>
        </Flex>
      </Card>
    </Form>
  )
}
