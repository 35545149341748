import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { Fragment } from "react"

interface MyProps {
  loading?: boolean
  onClose: Function
  onAddOwner: Function
}

const BillingUsersModalFooter = ({ loading, onClose, onAddOwner }: MyProps) => {
  return (
    <Fragment>
      <Button onClick={() => onClose()}>
        <CloseCircleOutlined />
        Close
      </Button>
      <Button disabled={loading} loading={loading} onClick={() => onAddOwner()}>
        <PlusOutlined />
        Add as Owner
      </Button>
    </Fragment>
  )
}

export default BillingUsersModalFooter
