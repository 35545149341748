import React, { Fragment } from "react"
import { Tag } from "antd"
import { amount, formatDate } from "src/utils"
import {
  ChargeBeeSubscription,
  EstimateChange,
  InvoiceEstimate,
} from "src/domain/desktop"
interface MyProps {
  currency?: string
  amountDue: number
  estimateCurrentChange: EstimateChange
  nextInvoiceEstimate: InvoiceEstimate
  chargeBeeSubscription: ChargeBeeSubscription
  invoicePending: boolean
}

const IncreaseLicensesModalBodyDetails: React.FC<MyProps> = ({
  currency,
  amountDue,
  estimateCurrentChange,
  nextInvoiceEstimate,
  chargeBeeSubscription,
  invoicePending,
}) => {
  return (
    <Fragment>
      <Tag color="blue" className="p-10 w-100 white-space-normal">
        An invoice for{" "}
        <b>
          {currency} {amount(amountDue)}
        </b>{" "}
        will be generated immediately. <br />
        {estimateCurrentChange?.credit_note_estimates &&
          estimateCurrentChange?.credit_note_estimates?.length > 0 && (
            <span>
              Adjustment credits worth{" "}
              <b>
                {currency}{" "}
                {amount(estimateCurrentChange.credit_note_estimates[0].total)}
              </b>
              will be created and adjusted against due invoice(s).
              <br />
            </span>
          )}
        Subsequent invoice for{" "}
        <b>
          {currency} {amount(nextInvoiceEstimate.total)}
        </b>{" "}
        will be generated upon renewal on{" "}
        {formatDate(
          (chargeBeeSubscription?.next_billing_at || 0) * 1000,
          "YYYY-MM-DD HH:MM"
        )}
      </Tag>
      <br />
      {invoicePending && (
        <Tag color="volcano" className="p-10 m-t-10 w-100">
          You have previously pending invoice added in this amount.
        </Tag>
      )}
      <hr />
    </Fragment>
  )
}
export default IncreaseLicensesModalBodyDetails
