import { styled } from "@conduktor/ui-library"

export const ButtonIcon = styled("div", {
  color: "$neutral10",
  cursor: "pointer",
  transition: "transform 0.3s ease",

  [`&:hover`]: {
    color: "$neutral12",
  },

  variants: {
    reversed: {
      true: {
        transform: "rotate(-180deg)",
      },
    },
  },
})
