import {
  AccessControl,
  AccessControlMemberApiPayload,
  DeleteSubscriptionRoleApiPayload,
  GetAccessControlApiPayload,
  GetSubscriptionRoleMembersApiPayload,
  GetTopicsControlApiPayload,
  RemoveAccessControlMemberApiPayload,
  RemoveTopicControlApiPayload,
  RenameSubscriptionRoleApiPayload,
  Role,
  RoleUsage,
  SubscriptionApiPayload,
  UpdateSubscriptionRoleApiPayload,
  UpsertTopicControlApiPayload,
} from "src/domain/desktop"
import { permissionsClient } from "../axiosClients"
import { TopicsControl } from "../../domain/desktop/topicPermission"

export const getSubscriptionRolesApi = async (
  payload: SubscriptionApiPayload
): Promise<Role[]> => {
  const { data } = await permissionsClient.get(
    `${payload.subscriptionId}/roles`
  )

  return data
}

export const getSubscriptionRoleMembersApi = async (
  payload: GetSubscriptionRoleMembersApiPayload
): Promise<RoleUsage> => {
  const { data } = await permissionsClient.get(
    `${payload.subscriptionId}/roles/${payload.role}/members`
  )
  return data
}

export const upsertSubscriptionRoleApi = async (
  payload: UpdateSubscriptionRoleApiPayload
): Promise<void> => {
  await permissionsClient.put(
    `${payload.subscriptionId}/roles/${payload.role.name}`,
    { permissions: payload.role.permissions }
  )
}

export const renameSubscriptionRoleApi = async (
  payload: RenameSubscriptionRoleApiPayload
): Promise<void> => {
  await permissionsClient.put(
    `${payload.subscriptionId}/roles/${payload.name}`,
    { name: payload.newName }
  )
}

export const deleteSubscriptionRoleApi = async (
  payload: DeleteSubscriptionRoleApiPayload
): Promise<void> => {
  await permissionsClient.delete(
    `${payload.subscriptionId}/roles/${payload.role}`
  )
}

export const getClusterAccessControlApi = async (
  payload: GetAccessControlApiPayload
): Promise<AccessControl> => {
  const { data } = await permissionsClient.get(
    `${payload.subscriptionId}/clusters/${payload.clusterId}/members`
  )
  return data
}

export const upsertAccessControlUserApi = async (
  payload: AccessControlMemberApiPayload
): Promise<void> => {
  await permissionsClient.put(
    `${payload.subscriptionId}/clusters/${
      payload.clusterId
    }/users/${encodeURIComponent(payload.id)}/roles`,
    payload.roles
  )
}

export const upsertAccessControlGroupApi = async (
  payload: AccessControlMemberApiPayload
): Promise<void> => {
  await permissionsClient.put(
    `${payload.subscriptionId}/clusters/${payload.clusterId}/groups/${payload.id}/roles`,
    payload.roles
  )
}

export const removeAccessControlUserApi = async (
  payload: RemoveAccessControlMemberApiPayload
): Promise<void> => {
  await upsertAccessControlUserApi({ ...payload, roles: [] })
}

export const removeAccessControlGroupApi = async (
  payload: RemoveAccessControlMemberApiPayload
): Promise<void> => {
  await upsertAccessControlGroupApi({ ...payload, roles: [] })
}

export const getClusterTopicsControlApi = async (
  payload: GetTopicsControlApiPayload
): Promise<TopicsControl> => {
  const {
    data,
  } = await permissionsClient.get(
    `${payload.subscriptionId}/permissions/topics`,
    { params: { clusterId: payload.clusterId } }
  )
  return data
}

export const upsertClusterTopicControlApi = async (
  payload: UpsertTopicControlApiPayload
): Promise<void> => {
  await permissionsClient.post(
    `${payload.subscriptionId}/permissions`,
    payload.update
  )
}

export const removeClusterTopicControlApi = async (
  payload: RemoveTopicControlApiPayload
): Promise<void> => {
  await permissionsClient.delete(`${payload.subscriptionId}/resources`, {
    data: payload.target,
  })
}
