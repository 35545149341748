import { TablePaginationConfig as Pagination } from "antd"
import { ActionType } from "src/domain/desktop"
import { License } from "../../../domain/desktop/common"

export const getLicensesRequest = () => ({
  type: ActionType.GET_LICENSES_REQUEST,
})

export const getLicensesSuccess = (payload: {
  licenses: License[]
  totalCount: number
}) => ({
  type: ActionType.GET_LICENSES_SUCCESS,
  payload,
})

export const getLicensesFailure = (payload: string) => ({
  type: ActionType.GET_LICENSES_FAILURE,
  payload,
})

export const setLicensesPagination = (payload: Pagination) => ({
  type: ActionType.SET_LICENSES_PAGINATION,
  payload,
})

export const setLicensesSearchQuery = (payload: string) => ({
  type: ActionType.SET_LICENSES_SEARCH_QUERY,
  payload,
})
