import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { Card } from "antd"
import Table, { TablePaginationConfig } from "antd/lib/table"
import DomainsColumns from "./columns"
import {
  selectDomainsList,
  selectDomainsLoading,
  selectDomainsPagination,
} from "src/store/desktop/admin/domains/selector"
import {
  getDomainsRequest,
  setDomainsTableConfig,
} from "src/store/desktop/admin/domains/actions"
import { Domain } from "src/domain/desktop"
import { FilterValue, SorterResult } from "antd/lib/table/interface"

const AutoAssignDomainsTable: React.FC = () => {
  const width: number = useWindowWidth()
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectDomainsLoading)
  const domainsList: Domain[] = useSelector(selectDomainsList)

  const pagination: TablePaginationConfig = useSelector(selectDomainsPagination)

  const domainsColumns = DomainsColumns()

  useEffect(() => {
    dispatch(getDomainsRequest())
  }, [dispatch])

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Domain> | SorterResult<Domain>[]
  ) => {
    dispatch(
      setDomainsTableConfig({
        pagination,
        filters,
        sorter,
      })
    )
  }

  return (
    <Card loading={loading} className="card" bordered={false}>
      <Table
        loading={loading}
        pagination={pagination}
        columns={domainsColumns}
        dataSource={domainsList}
        size="small"
        rowKey="id"
        scroll={width < 800 ? { x: "max-content" } : undefined}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default AutoAssignDomainsTable
