import React from "react"
import { useSelector } from "react-redux"
import { Button, Col, Popconfirm, Row, Space, Tooltip } from "antd"
import { CloseCircleOutlined, MailOutlined } from "@ant-design/icons"
import { isEmpty } from "lodash"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import {
  IncreaseLicensesModalFooterProps,
  Subscription,
} from "src/domain/desktop"
import { AppConfig } from "src/core/config"
import { amount } from "src/utils"

const IncreaseLicensesModalFooter: React.FC<IncreaseLicensesModalFooterProps> = ({
  onBuyLicense,
  onUpdatePaymentDetails,
  onGenerateQuote,
  onClose,
  values,
  showDetails,
  currentInvoiceEstimate,
  isCustomerHaveValidCardDetails,
  limitExceeded,
  isSubscriptionRenewalIsLessThan6Months,
  loading,
  termsOk,
  notAReseller,
  amountDue,
  currency,
}) => {
  const subscription: Subscription | undefined = useSelector(selectSubscription)

  const handleOpenSales = () => {
    window.open(
      `mailto:${AppConfig.website.contact.email}?subject=Licenses (subscription ${subscription?.chargeBeeSubscriptionId})`,
      "_blank"
    )
  }

  const isQuoteButtonDisplayed =
    (showDetails && !isEmpty(currentInvoiceEstimate.line_items)) ||
    limitExceeded ||
    isSubscriptionRenewalIsLessThan6Months

  const isPurchaseDisabled =
    loading ||
    limitExceeded ||
    !termsOk ||
    !notAReseller ||
    isSubscriptionRenewalIsLessThan6Months

  return (
    <Row>
      <Col>
        <Button onClick={handleOpenSales}>
          <MailOutlined />
          Contact Sales
        </Button>
      </Col>
      <Col flex="1">
        <Space>
          {((showDetails &&
            currentInvoiceEstimate.line_items &&
            currentInvoiceEstimate.line_items.length > 0 &&
            isCustomerHaveValidCardDetails) ||
            limitExceeded ||
            isSubscriptionRenewalIsLessThan6Months) && (
            <Popconfirm
              title={`Do you confirm purchasing ${
                values?.quantity
              } more licenses to get ${
                (values?.quantity || 0) + (subscription?.quantity || 0)
              } + total?`}
              okText="Yes"
              cancelText="No"
              onConfirm={() => onBuyLicense()}
              disabled={isPurchaseDisabled}
            >
              <Tooltip
                placement="bottom"
                title={
                  !limitExceeded && !isSubscriptionRenewalIsLessThan6Months
                    ? `Total amount will be ${amount(amountDue)} ${currency}`
                    : ""
                }
              >
                <Button
                  disabled={isPurchaseDisabled}
                  type="primary"
                  loading={loading}
                >
                  Purchase
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
          {showDetails &&
            currentInvoiceEstimate.line_items &&
            currentInvoiceEstimate.line_items.length > 0 &&
            !isCustomerHaveValidCardDetails && (
              <Button
                disabled={loading}
                type="link"
                loading={loading}
                onClick={() => onUpdatePaymentDetails()}
              >
                Add Payment Method to Purchase Licenses
              </Button>
            )}
          {isQuoteButtonDisplayed && (
            <Button
              type="primary"
              className="m-l-0"
              disabled={isPurchaseDisabled}
              loading={loading}
              onClick={() => onGenerateQuote()}
            >
              Generate Quote
            </Button>
          )}
          <Button
            className="m-l-0"
            disabled={loading}
            onClick={() => onClose()}
          >
            <CloseCircleOutlined />
            Close
          </Button>
        </Space>
      </Col>
    </Row>
  )
}

export default IncreaseLicensesModalFooter
