import { TablePaginationConfig as Pagination } from "antd/lib/table"
import { FilterValue, SorterResult } from "antd/lib/table/interface"
import { Subscription } from "./subscriptionsTypes"
import { User } from "../system"

export interface DomainEmail {
  id?: number | string
  email?: string
  editable?: boolean
}

export interface Domain {
  approvalStatus: number
  billingUser: User
  created: number
  domain: string
  name?: string
  id: number
  modified: number
  subscription: Subscription
  emails?: DomainEmail[]
}

export interface Domains {
  items: Array<Domain>
  pagination: Pagination
  filters?: Record<string, FilterValue | null>
  sorter?: SorterResult<Domain> | SorterResult<Domain>[]
}

export enum DomainStatus {
  REJECTED = 0,
  PENDING = 1,
  ACCEPTED = 2,
}

export enum DomainsActionType {
  GET_DOMAINS_REQUEST = "DOMAINS/GET_DOMAINS_REQUEST",
  GET_DOMAINS_SUCCESS = "DOMAINS/GET_DOMAINS_SUCCESS",
  GET_DOMAINS_FAILURE = "DOMAINS/GET_DOMAINS_FAILURE",
  SET_DOMAINS_TABLE_CONFIG = "DOMAINS/SET_DOMAINS_TABLE_CONFIG",
  UPDATE_DOMAIN_STATUS_REQUEST = "DOMAINS/UPDATE_DOMAIN_STATUS_REQUEST",
  UPDATE_DOMAIN_STATUS_SUCCESS = "DOMAINS/UPDATE_DOMAIN_STATUS_SUCCESS",
  UPDATE_DOMAIN_STATUS_FAILURE = "DOMAINS/UPDATE_DOMAIN_STATUS_FAILURE",
}
