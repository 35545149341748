import { useSelector } from "react-redux"
import { Tag } from "antd"
import { Link } from "react-router-dom"
import UserWithAvatar from "src/components/UserWithAvatar"
import StatusColumn from "./StatusColumn"
import { ColumnType } from "antd/lib/table"
import { Domain, DomainStatus } from "src/domain/desktop"
import { isValidUser } from "src/domain/user"
import { selectConduktorUser } from "src/store/system/selector"
import { selectDomainsFilters } from "src/store/desktop/admin/domains/selector"
import { isSubscriptionExpired } from "src/domain/desktop/subscription"
import { ConduktorUser } from "../../../../../../../domain/system"
import { UserRoleType } from "../../../../../../../domain/desktop/common"

const DomainsColumns = () => {
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const filters = useSelector(selectDomainsFilters)

  const isAdmin = isValidUser(conduktorUser, [UserRoleType.ADMIN])

  const columns: ColumnType<Domain>[] = [
    {
      title: "Domain Name",
      dataIndex: "domain",
      width: 200,
      render: (_text, record) => {
        return (
          <Link to={`/domains?name=${record.domain}`}>{record.domain}</Link>
        )
      },
    },
    {
      title: "Company Name",
      width: 200,
      render: (_text, record) => {
        return (
          <span>
            {record.billingUser && record.billingUser.company
              ? record.billingUser.company.name
              : "Unknown"}
          </span>
        )
      },
    },
    {
      title: "Subscription",
      key: "chargeBeeSubscriptionId",
      width: 250,
      render: (_text, record) => {
        return (
          <span className="cursor-pointer">
            {!isAdmin && isSubscriptionExpired(record.subscription) ? (
              <div className="link-disabled">
                {record.subscription.chargeBeeSubscriptionId}
                <Tag color="volcano">Expired</Tag>
              </div>
            ) : (
              <Link
                to={`/subscriptions/licenses-management/${record.subscription.chargeBeeSubscriptionId}`}
              >
                {record.subscription.chargeBeeSubscriptionId}
                {isSubscriptionExpired(record.subscription) && (
                  <Tag color="volcano">Expired</Tag>
                )}
              </Link>
            )}
            <div className="hint-text">
              {(record.subscription.planName || "???")
                .replaceAll("Conduktor", "")
                .replaceAll("Plan", "")}
            </div>
          </span>
        )
      },
    },
    {
      title: "User",
      dataIndex: "",
      render: (_text, record) => {
        return (
          <UserWithAvatar
            pictureUrl={record.billingUser.pictureUrl}
            name={record.billingUser.fullName}
            email={record.billingUser.email}
          />
        )
      },
    },
    {
      title: "Status",
      dataIndex: "",
      key: "status",
      filterMultiple: false,
      width: 120,
      filteredValue: filters ? filters.status : [],
      filtered: filters ? true : false,
      filters: [
        { text: "Approved", value: DomainStatus.ACCEPTED },
        { text: "Pending", value: DomainStatus.PENDING },
        { text: "Rejected", value: DomainStatus.REJECTED },
      ],
      render: StatusColumn,
    },
  ]
  return columns
}

export default DomainsColumns
