import { Flex, styled, Text } from "@conduktor/ui-library"
import { EllipsisText } from "src/styles/overflow"

export const AccessControlHeader = styled(Flex, {
  justifyContent: "space-between",
  alignItems: "center",
  gap: "$2",
  padding: "$3",
  border: "1px solid $neutral6",
  borderRadius: "$2",
  fontSize: "$3",
  fontWeight: "$bold",

  variants: {
    expanded: {
      true: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
})

export const AccessControlAddPanel = styled(Flex, {
  flexDirection: "column",
  gap: "$2",
  padding: "$3",
  border: "1px solid",
  borderTop: 0,
  borderColor: "$neutral6",

  color: "$neutral9",
})

export const AccessControlEmptyState = styled(Flex, {
  flexDirection: "column",
  alignItems: "center",
  my: "$2",
  fontSize: "$2",
})

export const AccessControlMemberPanel = styled(Flex, {
  alignItems: "center",
  justifyContent: "space-between",
  gap: "$2",
  padding: "$2 $3",
  border: "1px solid",
  borderTop: 0,
  borderColor: "$neutral6",

  [`& ${EllipsisText}`]: {
    maxWidth: "20em",
  },

  [`&:last-child`]: {
    borderBottomLeftRadius: "$2",
    borderBottomRightRadius: "$2",
  },

  variants: {
    allUsersGroup: {
      true: {
        backgroundColor: "$neutral2",
      },
    },
  },
})

export const AccessControlMemberIcon = styled(Flex, {
  borderRadius: "$round",
  alignItems: "center",
  justifyContent: "center",
  height: "$5",
  width: "$5",

  variants: {
    variant: {
      group: {
        backgroundColor: "$primary4",
      },
      allUsers: {
        backgroundColor: "$success4",
      },
    },
  },
})

export const AccessControlGroupName = styled(Text, {
  fontWeight: "$semi",
  color: "$neutral12",
})

export const AccessControlGroupDescription = styled(Text, {
  color: "$neutral9",
  fontSize: "$2",
})
