import React from "react"
import { useWindowWidth } from "@react-hook/window-size"
import { useSelector } from "react-redux"
import { Card, Table } from "antd"
import { ColumnType } from "antd/lib/table"
import { selectLicensedUser } from "src/store/system/selector"
import { formatDate } from "src/utils"
import { isSubscriptionExpired } from "src/domain/desktop/subscription"
import { User } from "../../../../../domain/system"

const LicensorInfo: React.FC = () => {
  const width: number = useWindowWidth()
  const licensedUser = useSelector(selectLicensedUser)
  const subscription = licensedUser.subscription

  const licensedUsersColumnsRevoked: ColumnType<User>[] = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Started Date",
      align: "center",
      width: 150,
      render: (_text, record) => {
        return <span>{formatDate(record.created)}</span>
      },
    },
    {
      title: "Removed on",
      align: "center",
      width: 150,
      render: (_text, record) => {
        return <span>{formatDate(record.removed)}</span>
      },
    },
    {
      title: "Last Plan",
      width: 250,
      render: () => {
        return <span>{subscription?.planName}</span>
      },
    },
  ]

  const licensedUsersColumns: ColumnType<User>[] = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Started Date",
      align: "center",
      width: 150,
      render: (_text, record) => {
        return <span>{formatDate(record.created)}</span>
      },
    },
    {
      title: "Expires on",
      align: "center",
      width: 150,
      render: (_text, record) => {
        return (
          <span>
            {isSubscriptionExpired(record.subscription)
              ? formatDate(record.subscription?.expiryDate)
              : formatDate(
                  record.subscription?.renewalDate ||
                    record.subscription?.expiryDate
                )}
          </span>
        )
      },
    },
    {
      title: "Last Plan",
      width: 250,
      render: () => {
        return <span>{subscription?.planName}</span>
      },
    },
  ]
  return (
    <div className="subscriber-info">
      <Card className="card" title="Your Membership" bordered={false}>
        <Table
          pagination={false}
          columns={
            licensedUser.removed
              ? licensedUsersColumnsRevoked
              : licensedUsersColumns
          }
          dataSource={[licensedUser]}
          rowKey="id"
          scroll={width < 1285 ? { x: "max-content" } : undefined}
        />
      </Card>
    </div>
  )
}

export default LicensorInfo
