import { all, put, select, takeLatest } from "redux-saga/effects"
import { getAdminUsersApi } from "src/services"
import { Action, ActionType } from "src/domain/desktop"
import { orEmpty, parseError, replace } from "src/utils"
import { getAdminsFailure, getAdminsSuccess } from "./actions"
import { setMessage } from "../../../system/actions"
import { ConduktorUserIdPayload } from "../../../../domain/desktop/admin/adminsTypes"
import { AppState } from "../../../config/rootStore"
import { selectAdmins } from "./selector"
import { ConduktorUser, MessageType } from "../../../../domain/system"
import { ClientError } from "../../../../services/ClientError"

function* getAdminUsersSaga() {
  try {
    const users: ConduktorUser[] = yield getAdminUsersApi()

    yield put(getAdminsSuccess(users))
  } catch (err) {
    yield put(getAdminsFailure(parseError(err as ClientError)))
  }
}

function* upsertAdminSaga({ payload }: Action<ConduktorUser>) {
  const state: AppState = yield select()
  const currents = orEmpty(selectAdmins(state))
  const currentIndex = currents.findIndex((a) => a.id === payload.id)

  if (currentIndex === -1) {
    yield put(getAdminsSuccess([payload, ...currents]))
  } else {
    yield put(getAdminsSuccess(replace(currentIndex, payload, currents)))
  }
}

function* removeAdminAccessToUserSaga({
  payload,
}: Action<ConduktorUserIdPayload>) {
  const state: AppState = yield select()
  const currents = orEmpty(selectAdmins(state))
  yield put(getAdminsSuccess(currents.filter((r) => r.id !== payload.id)))
  yield put(
    setMessage({
      type: MessageType.SUCCESS,
      message: "The user is not an admin anymore",
    })
  )
}

export default function* AdminsSagas() {
  yield all([
    takeLatest(ActionType.GET_ADMINS_REQUEST, getAdminUsersSaga),
    takeLatest(ActionType.UPSERT_ADMIN_REQUEST, upsertAdminSaga),
    takeLatest(ActionType.REMOVE_ADMIN_ACCESS, removeAdminAccessToUserSaga),
  ])
}
