import { User } from "../system"

export interface LicenseMembers {
  billingUsers?: Array<User>
  licenseMembers?: Array<User>
  admins?: Array<User>
  licenseManagers?: Array<User>
}

export enum LicenseMembersActionType {
  INIT_LICENSED_MEMBERS = "MEMBERS/INIT_LICENSED_MEMBERS",
  GET_LICENSE_MEMBERS_REQUEST = "MEMBERS/GET_LICENSE_MEMBERS_REQUEST",
  GET_LICENSE_MEMBERS_SUCCESS = "MEMBERS/GET_LICENSE_MEMBERS_SUCCESS",
  GET_LICENSE_MEMBERS_FAILURE = "MEMBERS/GET_LICENSE_MEMBERS_FAILURE",
}
