import { DropdownItem, styled, theme } from "@conduktor/ui-library"
import { Link } from "react-router-dom"
import { EllipsisText } from "src/styles/overflow"
import { disabledStyles } from "./sidebar.styles"

export const AvatarImage = styled("img", {
  objectFit: "cover",
  borderRadius: "50%",
  width: 40,
  height: 40,
})

export const AvatarName = styled(EllipsisText, {
  width: 160,
  fontSize: "$2",
  fontWeight: "$bold",
  lineHeight: `${theme.lineHeights.display}`,
})

export const AvatarEmail = styled(EllipsisText, {
  width: 160,
  fontSize: "$1",
  fontWeight: "$regular",
  color: "$neutral9",
  lineHeight: "$standard",
})

export const AvatarDropdownItem = styled(DropdownItem, {
  cursor: "pointer",

  variants: {
    disabled: {
      true: { ...disabledStyles },
    },
  },
})

export const AvatarMenuStyledLink = styled(Link, {
  display: "block",

  variants: {
    disabled: {
      true: { ...disabledStyles },
    },
  },
})
