import { DomainUsers, RootState } from "src/domain/desktop"

import { DomainDetailsReducerType } from "./reducer"

export const selectDomainDetailsState = (
  state: RootState
): DomainDetailsReducerType => state.domainDetails

export const selectDomainDetailsLoading = (state: RootState): boolean =>
  selectDomainDetailsState(state).loading

export const selectDomainDetailsError = (
  state: RootState
): string | undefined => selectDomainDetailsState(state).error

export const selectDomainDetailsUsers = (
  state: RootState
): DomainUsers | undefined => selectDomainDetailsState(state).domainUsers
