export type Topic =
  | "invitations"
  | "user"
  | "ui.help"
  | "signup.userprofile"
  | "signup.createorganization"
  | "signup.invitations"
  | "signup.organizationUserProfile"
  | "signup.chooseProduct"
  | "signup.redirectDesktop"
  | "subscriptions.invoices"
  | "subscriptions.members"
  | "subscriptions.domain"
  | "subscriptions.owner"

export enum Action {
  Edited = "Edited",
  Added = "Added",
  Deleted = "Deleted",
  Viewed = "Viewed",
  Started = "Started",
  Succeeded = "Succeeded",
  Completed = "Completed",
  Chosen = "Chosen",
  Cancelled = "Cancelled",
  Failed = "Failed",
  Skipped = "Skipped",
  LoggedIn = "LoggedIn",
  Clicked = "Clicked",
  Downloaded = "Downloaded",
  Imported = "Imported",
  Exported = "Exported",
  Revoked = "Revoked",
  Reactivated = "Reactivated",
  Status = "Status",
  Linked = "Linked",
}

export type Path<A extends `${string}.${Action}`> = `cdk.admin.${A}`

export type TrackActionPath = Path<`${Topic}.${Action}`>

export const path = (object: string, action: Action) =>
  `cdk.admin.${object}.${action}`
