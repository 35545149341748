import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { Button, Card, Col, Input, Row, Statistic, Switch, Tag } from "antd"
import Table, { ColumnType, TablePaginationConfig } from "antd/lib/table"
import { Link } from "react-router-dom"
import UserWithAvatar from "src/components/UserWithAvatar"
import { SettingOutlined } from "@ant-design/icons"
import {
  selectShowExpiredSubscriptions,
  selectSubscriptionsList,
  selectSubscriptionsLoading,
  selectSubscriptionsPagination,
  selectSubscriptionsSearchQuery,
} from "src/store/desktop/subscriptions/selector"
import { selectConduktorUser } from "src/store/system/selector"
import {
  getSubscriptionsRequest,
  setShowExpiredSubscriptions,
  setSubscriptionsPagination,
  setSubscriptionsSearchQuery,
} from "src/store/desktop/subscriptions/actions"
import { isValidUser } from "src/domain/user"
import { formatDate } from "src/utils"
import { Subscription } from "src/domain/desktop"
import {
  isSubscriptionExpired,
  isSubscriptionStarted,
} from "src/domain/desktop/subscription"
import { ConduktorUser } from "../../../../domain/system"
import { UserRoleType } from "../../../../domain/desktop/common"

export const SubscriptionsList: React.FC = () => {
  const dispatch = useDispatch()
  const width: number = useWindowWidth()
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const isAdmin: boolean = isValidUser(conduktorUser, [UserRoleType.ADMIN])
  const loading: boolean = useSelector(selectSubscriptionsLoading)
  const subscriptionsList: Subscription[] = useSelector(selectSubscriptionsList)

  const searchQuery: string = useSelector(selectSubscriptionsSearchQuery) || ""
  const pagination: TablePaginationConfig = useSelector(
    selectSubscriptionsPagination
  )
  const showExpiredSubscriptions: boolean = useSelector(
    selectShowExpiredSubscriptions
  )

  const [currentQuery, setCurrentQuery] = useState("")

  useEffect(() => {
    dispatch(getSubscriptionsRequest(Boolean(isAdmin)))
  }, [dispatch, isAdmin])

  useEffect(() => {
    setCurrentQuery(searchQuery)
  }, [searchQuery])

  const handleTableChange = (pagination: TablePaginationConfig) => {
    dispatch(setSubscriptionsPagination(pagination))
  }

  const handleSearch = () => {
    dispatch(setSubscriptionsSearchQuery(currentQuery))
  }

  const handleSwitchShowExpired = (e: boolean) => {
    dispatch(setShowExpiredSubscriptions(e))
  }

  const subscriptionsColumns: ColumnType<Subscription>[] = [
    {
      title: "Subscription",
      width: 300,
      render: (_text, record) => {
        return (
          <span className="cursor-pointer">
            <Link
              to={`/subscriptions/licenses-management/${record.chargeBeeSubscriptionId}`}
            >
              {(record.planName || "???")
                .replaceAll("Conduktor", "")
                .replaceAll("Plan", "")}
            </Link>
            {isSubscriptionExpired(record) && (
              <Tag color="volcano">Expired</Tag>
            )}
            {!isSubscriptionStarted(record) && <Tag color="purple">Future</Tag>}
            <div className="hint-text">{record.chargeBeeSubscriptionId}</div>
          </span>
        )
      },
    },
    {
      title: "Members",
      align: "center",
      width: 100,
      render: (_text, record) => {
        return (
          <Statistic
            value={record.quantity - record.available}
            suffix={record.quantity && <span> / {record.quantity}</span>}
          />
        )
      },
    },
    {
      title: "Started on",
      align: "center",
      width: 150,
      render: (_text, record) => {
        return <span>{formatDate(record.subscriptionDate)}</span>
      },
    },
    {
      title: "Renewal / Expiration",
      align: "center",
      width: 150,
      render: (_text, record) => {
        return (
          <span>{formatDate(record.expiryDate || record.renewalDate)}</span>
        )
      },
    },

    {
      title: "",
      align: "center",
      width: 50,
      render: (_text, record) => {
        return (
          <Link
            to={`/subscriptions/licenses-management/${record.chargeBeeSubscriptionId}`}
          >
            <Button type="primary" icon={<SettingOutlined />} />
          </Link>
        )
      },
    },
  ]

  if (isAdmin) {
    const adminColumn: ColumnType<Subscription> = {
      title: "Owner",
      align: "left",
      width: 300,
      render: (_text, record) => {
        if (!record.billingUser) {
          return null
        }
        return (
          <UserWithAvatar
            pictureUrl={record.billingUser.pictureUrl}
            name={record.billingUser.fullName}
            email={record.billingUser.email}
            company={record.billingUser.company.name}
          />
        )
      },
    }
    subscriptionsColumns.splice(2, 0, adminColumn)
  }

  return (
    <Card loading={loading} className="card" bordered={false}>
      {isAdmin && (
        <Row className="m-b-15">
          <Col className="float-left">
            <Input.Search
              value={currentQuery}
              loading={loading}
              placeholder="Search for ID / Name / Company"
              allowClear={true}
              className="search-box"
              onChange={(e) => {
                setCurrentQuery(e.target.value)
              }}
              onSearch={handleSearch}
            />
            <Switch
              checked={showExpiredSubscriptions}
              className="m-y-5 m-x-10"
              checkedChildren="Expired"
              unCheckedChildren="Expired"
              onChange={handleSwitchShowExpired}
            />
          </Col>
        </Row>
      )}
      <Table
        loading={loading}
        pagination={pagination}
        columns={subscriptionsColumns}
        dataSource={subscriptionsList}
        rowKey="id"
        scroll={width < 800 ? { x: "max-content" } : undefined}
        onChange={handleTableChange}
      />
    </Card>
  )
}
