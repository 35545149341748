import { TablePaginationConfig } from "antd"
import { RootState, Domain } from "src/domain/desktop"
import { FilterValue, SorterResult } from "antd/lib/table/interface"

import { DomainsReducerType } from "./reducer"

const selectDomainsState = (state: RootState): DomainsReducerType =>
  state.domains

export const selectDomainsLoading = (state: RootState): boolean =>
  selectDomainsState(state).loading

export const selectDomainsError = (state: RootState): string | undefined =>
  selectDomainsState(state).error

export const selectDomainsList = (state: RootState): Domain[] =>
  selectDomainsState(state).items

export const selectDomainsPagination = (
  state: RootState
): TablePaginationConfig => selectDomainsState(state).pagination

export const selectDomainsFilters = (
  state: RootState
): Record<string, FilterValue | null> | undefined =>
  selectDomainsState(state).filters

export const selectDomainsSorter = (
  state: RootState
): SorterResult<Domain> | SorterResult<Domain>[] | undefined =>
  selectDomainsState(state).sorter
