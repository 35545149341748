import { Action, ApiStatus, EmptyAction, IActionType } from "src/domain/desktop"
import { LicensesReducerType } from "./desktop/licenses/reducer"
import { SubscriptionDetailsReducerType } from "./desktop/subscriptionDetails/reducer"
import { SubscriptionsReducerType } from "./desktop/subscriptions/reducer"
import { SystemReducerType } from "./system/reducer"
import { AdminDashboardReducerType } from "./desktop/adminDashboard/reducer"
import { DomainsReducerType } from "./desktop/admin/domains/reducer"
import { LicenseMembersReducerType } from "./desktop/licenseMembers/reducer"
import { LogsReducerType } from "./desktop/logs/reducer"
import { PlansReducerType } from "./desktop/plans/reducer"
import { ClustersReducerType } from "./desktop/clusters/reducer"
import { OAuthUserReducerType } from "./desktop/admin/users/reducer"
import { AccessControlReducerType } from "./desktop/accessControl/reducer"

export const initReducer = (
  defaultState:
    | SubscriptionDetailsReducerType
    | SubscriptionsReducerType
    | SystemReducerType
    | LicensesReducerType
    | AdminDashboardReducerType
    | DomainsReducerType
    | OAuthUserReducerType
    | LicenseMembersReducerType
    | LogsReducerType
    | PlansReducerType
    | ClustersReducerType
    | AccessControlReducerType
) => () => {
  return {
    ...defaultState,
  }
}

export const requestReducer = (
  state: ApiStatus,
  { type }: { type: IActionType }
) => {
  return {
    ...state,
    type,
    loading: true,
    loaded: false,
    error: undefined,
  }
}

export const resetRequestReducer = (state: ApiStatus) => {
  return {
    ...state,
    loading: false,
    loaded: false,
    error: undefined,
  }
}

export const successReducer = <S extends ApiStatus>(state: S) => {
  return {
    ...state,

    loading: false,
    loaded: true,
    error: undefined,
  }
}

export const failureReducer = (
  state: ApiStatus,
  { payload }: Action<string>
) => {
  return {
    ...state,
    loading: false,
    loaded: false,
    error: payload,
  }
}

export const requestReducerOn = <ReducerState>(on: keyof ReducerState) => (
  state: ReducerState,
  action: EmptyAction
) => {
  return {
    ...state,
    [on]: requestReducer((state[on] as unknown) as ApiStatus, action),
  }
}

export const failureReducerOn = <ReducerState>(on: keyof ReducerState) => (
  state: ReducerState,
  action: Action<string>
) => {
  return {
    ...state,
    [on]: failureReducer((state[on] as unknown) as ApiStatus, action),
  }
}

export const successReducerOn = <ReducerState>(on: keyof ReducerState) => (
  state: ReducerState
) => {
  return {
    ...state,
    [on]: successReducer((state[on] as unknown) as ApiStatus),
  }
}

export const resetRequestReducerOn = <ReducerState>(on: keyof ReducerState) => (
  state: ReducerState
) => {
  return {
    ...state,
    [on]: resetRequestReducer((state[on] as unknown) as ApiStatus),
  }
}
