import {
  AccessControlMemberId,
  AccessControlMemberType,
  allUsersGroupId,
  allUsersGroupName,
} from "src/domain/desktop"
import { TeamsById } from "src/domain/desktop/common"

const UserOrGroupId = {
  USER: "user",
  GROUP: "group",
  SEPARATOR: "-",
}
export function createGroupId(id: AccessControlMemberId): string {
  return `${UserOrGroupId.GROUP}${UserOrGroupId.SEPARATOR}${id}`
}
export function createUserId(email: AccessControlMemberId): string {
  return `${UserOrGroupId.USER}${UserOrGroupId.SEPARATOR}${email}`
}
export function parseUserOrGroupId(
  userOrGroup?: string
): { type: AccessControlMemberType; id: string } | undefined {
  const separator = userOrGroup && userOrGroup.indexOf(UserOrGroupId.SEPARATOR)
  if (!separator || separator < 1) {
    return
  }

  const type = userOrGroup!.substring(0, separator) as AccessControlMemberType
  const id = userOrGroup!.substring(separator + 1)
  return { type, id }
}

export function groupName(teamsById: TeamsById, id: string | number) {
  if (id === allUsersGroupId) {
    return allUsersGroupName
  }
  return teamsById[Number(id)]?.name || id
}
