import { ActionType, LicenseMembers } from "src/domain/desktop"

export const getLicenseMembersRequest = () => ({
  type: ActionType.GET_LICENSE_MEMBERS_REQUEST,
})

export const getLicenseMembersSuccess = (payload: LicenseMembers) => ({
  type: ActionType.GET_LICENSE_MEMBERS_SUCCESS,
  payload,
})

export const getLicenseMembersFailure = (payload: string) => ({
  type: ActionType.GET_LICENSE_MEMBERS_FAILURE,
  payload,
})

export const initLicensedMembers = () => ({
  type: ActionType.INIT_LICENSED_MEMBERS,
})
