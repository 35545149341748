import { Domain } from "./index"
import { Subscription } from "./subscriptionsTypes"
import { User } from "../system"
import { Team } from "./common"

export enum InvoiceStatus {
  PAYMENT_DUE = "PAYMENT_DUE",
  NOT_PAID = "NOT_PAID",
  PAID = "PAID",
}

export const InvoiceStatusInfo = {
  [InvoiceStatus.PAYMENT_DUE]: {
    color: "red",
    text: "Payment Due",
  },
  [InvoiceStatus.NOT_PAID]: {
    color: "red",
    text: "Not Paid",
  },
  [InvoiceStatus.PAID]: {
    color: "green",
    text: "Paid",
  },
}

export interface Invoice {
  currencyCode?: string
  date?: number
  id?: string
  status: InvoiceStatus
  total?: number
  paidAt?: number
}

export interface RemovedUserCount {
  count?: number
  endDate?: number
  revokingPercentage?: number
  startDate?: number
}

export interface SubscriptionDetails {
  subscription?: Subscription
  licenseManagers?: Array<User>
  billingUsers?: Array<User>
  teams?: Array<Team>
  domainList?: Array<Domain>
  adminsOfSubscription?: Array<User>
  changePaymentMethodURL?: string
  licensedUsers?: Array<User>
  removedUsers?: Array<User>
  removedUserCounts?: Array<RemovedUserCount>
  invoices?: Array<Invoice>
  selectedUser?: User
}

export enum SubscriptionDetailsActionType {
  GET_SUBSCRIPTION_DETAILS_REQUEST = "GET_SUBSCRIPTION_DETAILS_REQUEST",
  GET_SUBSCRIPTION_DETAILS_SUCCESS = "GET_SUBSCRIPTION_DETAILS_SUCCESS",
  GET_SUBSCRIPTION_DETAILS_FAILURE = "GET_SUBSCRIPTION_DETAILS_FAILURE",
  GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST",
  GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS",
  GET_SUBSCRIPTION_FAILURE = "GET_SUBSCRIPTION_FAILURE",
  CLEAR_SUBSCRIPTION_STATE_REQUEST = "CLEAR_SUBSCRIPTION_STATE_REQUEST",
  GET_LICENCED_USERS_REQUEST = "GET_LICENCED_USERS_REQUEST",
  GET_LICENCED_USERS_SUCCESS = "GET_LICENCED_USERS_SUCCESS",
  GET_LICENCED_USERS_FAILURE = "GET_LICENCED_USERS_FAILURE",
  GET_REMOVED_USERS_REQUEST = "GET_REMOVED_USERS_REQUEST",
  GET_REMOVED_USERS_SUCCESS = "GET_REMOVED_USERS_SUCCESS",
  GET_REMOVED_USERS_FAILURE = "GET_REMOVED_USERS_FAILURE",
  GET_REMOVED_USER_COUNTS_REQUEST = "GET_REMOVED_USER_COUNTS_REQUEST",
  GET_REMOVED_USER_COUNTS_SUCCESS = "GET_REMOVED_USER_COUNTS_SUCCESS",
  GET_REMOVED_USER_COUNTS_FAILURE = "GET_REMOVED_USER_COUNTS_FAILURE",
  GET_INVOICES_REQUEST = "GET_INVOICES_REQUEST",
  GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS",
  GET_INVOICES_FAILURE = "GET_INVOICES_FAILURE",
  CREATE_LICENSE_MANAGER_REQUEST = "CREATE_LICENSE_MANAGER_REQUEST",
  CREATE_LICENSE_MANAGER_SUCCESS = "CREATE_LICENSE_MANAGER_SUCCESS",
  CREATE_LICENSE_MANAGER_FAILURE = "CREATE_LICENSE_MANAGER_FAILURE",
  CREATE_BILLING_USER_REQUEST = "CREATE_BILLING_USER_REQUEST",
  CREATE_BILLING_USER_SUCCESS = "CREATE_BILLING_USER_SUCCESS",
  CREATE_BILLING_USER_FAILURE = "CREATE_BILLING_USER_FAILURE",
  ADD_LICENSE_REQUEST = "ADD_LICENSE_REQUEST",
  ADD_LICENSE_SUCCESS = "ADD_LICENSE_SUCCESS",
  ADD_LICENSE_FAILURE = "ADD_LICENSE_FAILURE",
  ADD_LICENSES_CSV_REQUEST = "ADD_LICENSES_CSV_REQUEST",
  ADD_LICENSES_CSV_SUCCESS = "ADD_LICENSES_CSV_SUCCESS",
  ADD_LICENSES_CSV_FAILURE = "ADD_LICENSES_CSV_FAILURE",
  GENERATE_LICENSE_TOKEN_REQUEST = "GENERATE_LICENSE_TOKEN_REQUEST",
  GENERATE_LICENSE_TOKEN_SUCCESS = "GENERATE_LICENSE_TOKEN_SUCCESS",
  GENERATE_LICENSE_TOKEN_FAILURE = "GENERATE_LICENSE_TOKEN_FAILURE",
  DELETE_LICENSE_TOKEN_REQUEST = "DELETE_LICENSE_TOKEN_REQUEST",
  DELETE_LICENSE_TOKEN_SUCCESS = "DELETE_LICENSE_TOKEN_SUCCESS",
  DELETE_LICENSE_TOKEN_FAILURE = "DELETE_LICENSE_TOKEN_FAILURE",
  CREATE_TEAM_REQUEST = "CREATE_TEAM_REQUEST",
  CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS",
  CREATE_TEAM_FAILURE = "CREATE_TEAM_FAILURE",
  DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST",
  DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS",
  DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE",
  UPDATE_TEAM_REQUEST = "UPDATE_TEAM_REQUEST",
  UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS",
  UPDATE_TEAM_FAILURE = "UPDATE_TEAM_FAILURE",
  UPDATE_SUBSCRIPTION_REQUEST = "UPDATE_SUBSCRIPTION_REQUEST",
  UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS",
  UPDATE_SUBSCRIPTION_FAILURE = "UPDATE_SUBSCRIPTION_FAILURE",
  UPDATE_SUBSCRIPTION_REASSIGN_ALL_MEMBERS_REQUEST = "UPDATE_SUBSCRIPTION_REASSIGN_ALL_MEMBERS_REQUEST",
  UPDATE_SUBSCRIPTION_REASSIGN_ALL_MEMBERS_SUCCESS = "UPDATE_SUBSCRIPTION_REASSIGN_ALL_MEMBERS_REQUEST_SUCCESS",
  UPDATE_SUBSCRIPTION_REASSIGN_ALL_MEMBERS_FAILURE = "UPDATE_SUBSCRIPTION_REASSIGN_ALL_MEMBERS_REQUEST_FAILURE",
  UPDATE_LICENSE_REQUEST = "UPDATE_LICENSE_REQUEST",
  UPDATE_LICENSE_SUCCESS = "UPDATE_LICENSE_SUCCESS",
  UPDATE_LICENSE_FAILURE = "UPDATE_LICENSE_FAILURE",
  UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE",
  DELETE_BILLING_USER_REQUEST = "DELETE_BILLING_USER_REQUEST",
  DELETE_BILLING_USER_SUCCESS = "DELETE_BILLING_USER_SUCCESS",
  DELETE_BILLING_USER_FAILURE = "DELETE_BILLING_USER_FAILURE",
  DELETE_ADMIN_OF_SUBSCRIPTION_REQUEST = "DELETE_ADMIN_OF_SUBSCRIPTION_REQUEST",
  DELETE_ADMIN_OF_SUBSCRIPTION_SUCCESS = "DELETE_ADMIN_OF_SUBSCRIPTION_SUCCESS",
  DELETE_ADMIN_OF_SUBSCRIPTION_FAILURE = "DELETE_ADMIN_OF_SUBSCRIPTION_FAILURE",
  DELETE_LICENSE_MANAGER_REQUEST = "DELETE_LICENSE_MANAGER_REQUEST",
  DELETE_LICENSE_MANAGER_SUCCESS = "DELETE_LICENSE_MANAGER_SUCCESS",
  DELETE_LICENSE_MANAGER_FAILURE = "DELETE_LICENSE_MANAGER_FAILURE",
  DELETE_LICENSED_USER_REQUEST = "DELETE_LICENSED_USER_REQUEST",
  DELETE_LICENSED_USER_SUCCESS = "DELETE_LICENSED_USER_SUCCESS",
  DELETE_LICENSED_USER_FAILURE = "DELETE_LICENSED_USER_FAILURE",
  ADD_SUBSCRIPTION_DOMAIN_REQUEST = "ADD_SUBSCRIPTION_DOMAIN_REQUEST",
  ADD_SUBSCRIPTION_DOMAIN_SUCCESS = "ADD_SUBSCRIPTION_DOMAIN_SUCCESS",
  ADD_SUBSCRIPTION_DOMAIN_FAILURE = "ADD_SUBSCRIPTION_DOMAIN_FAILURE",
  DELETE_SUBSCRIPTION_DOMAIN_REQUEST = "DELETE_SUBSCRIPTION_DOMAIN_REQUEST",
  DELETE_SUBSCRIPTION_DOMAIN_SUCCESS = "DELETE_SUBSCRIPTION_DOMAIN_SUCCESS",
  DELETE_SUBSCRIPTION_DOMAIN_FAILURE = "DELETE_SUBSCRIPTION_DOMAIN_FAILURE",
  ADD_SUBSCRIPTION_DOMAIN_EMAIL_REQUEST = "ADD_SUBSCRIPTION_DOMAIN_EMAIL_REQUEST",
  ADD_SUBSCRIPTION_DOMAIN_EMAIL_SUCCESS = "ADD_SUBSCRIPTION_DOMAIN_EMAIL_SUCCESS",
  ADD_SUBSCRIPTION_DOMAIN_EMAIL_FAILURE = "ADD_SUBSCRIPTION_DOMAIN_EMAIL_FAILURE",
  DELETE_SUBSCRIPTION_DOMAIN_EMAIL_REQUEST = "DELETE_SUBSCRIPTION_DOMAIN_EMAIL_REQUEST",
  DELETE_SUBSCRIPTION_DOMAIN_EMAIL_SUCCESS = "DELETE_SUBSCRIPTION_DOMAIN_EMAIL_SUCCESS",
  DELETE_SUBSCRIPTION_DOMAIN_EMAIL_FAILURE = "DELETE_SUBSCRIPTION_DOMAIN_EMAIL_FAILURE",
  SELECT_USER = "SELECT_SUBSCRIPTION_USER",
}
