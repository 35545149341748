import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import AllLicenses from "./components/AllLicenses"
import {
  selectLicensesError,
  selectLicensesLoading,
} from "src/store/desktop/licenses/selector"
import { setMessage } from "src/store/system/actions"
import { RouteKey } from "src/router/routes"
import { usePageView } from "src/core/analytics/hooks"
import { MessageType } from "../../../domain/system"

const LicencesPage = () => {
  usePageView(RouteKey.LICENSES)
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectLicensesLoading)
  const error: string | undefined = useSelector(selectLicensesError)

  useEffect(() => {
    if (error && !loading) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: error,
        })
      )
    }
  }, [dispatch, error, loading])

  return (
    <div className="all-licenses">
      <AllLicenses />
    </div>
  )
}

export default LicencesPage
