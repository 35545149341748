import { all, put, select, takeLatest } from "redux-saga/effects"
import { getAdminSubscriptionsApi, getSubscriptionsApi } from "src/services"
import {
  Action,
  ActionType,
  CreateRolePayload,
  DeleteRolePayload,
  GetAdminSubscriptionsApiPayload,
  Subscription,
  UpdateRolePayload,
} from "src/domain/desktop"
import { TablePaginationConfig } from "antd"
import { parseError, replace } from "src/utils"
import {
  getSubscriptionsFailure,
  getSubscriptionsRequest,
  getSubscriptionsSuccess,
  upsertRoleRequest,
} from "./actions"
import { AppState } from "../../config/rootStore"
import {
  selectShowExpiredSubscriptions,
  selectSubscriptionsPagination,
  selectSubscriptionsSearchQuery,
} from "./selector"
import { selectClustersRoles } from "../clusters/selector"
import { getClustersRolesSuccess } from "../clusters/actions"
import { setMessage } from "../../system/actions"
import { MessageType } from "../../../domain/system"
import { ClientError } from "../../../services/ClientError"

function* getSubscriptionsSaga({ payload: isAdmin }: Action<boolean>) {
  try {
    if (isAdmin) {
      const state: AppState = yield select()

      const searchQuery: string | undefined = selectSubscriptionsSearchQuery(
        state
      )
      const showExpiredSubscriptions:
        | boolean
        | undefined = selectShowExpiredSubscriptions(state)
      const pagination: TablePaginationConfig = selectSubscriptionsPagination(
        state
      )

      const payload: GetAdminSubscriptionsApiPayload = {
        params: {
          page: pagination.current,
          results: pagination.pageSize,
          showSizeChanger: pagination.showSizeChanger,
          showExpiredSubscriptions,
          searchQuery,
        },
      }

      const data: {
        totalCount: number
        subscriptions: Subscription[]
      } = yield getAdminSubscriptionsApi(payload)

      yield put(getSubscriptionsSuccess(data))
    } else {
      const data: Subscription[] = yield getSubscriptionsApi()
      yield put(
        getSubscriptionsSuccess({
          subscriptions: data,
          totalCount: data.length,
        })
      )
    }
  } catch (err) {
    yield put(getSubscriptionsFailure(parseError(err as ClientError)))
  }
}

function* setSubscriptionsPaginationSaga() {
  yield put(getSubscriptionsRequest(true))
}

function* deleteClusterRoleSaga({ payload }: Action<DeleteRolePayload>) {
  const state: AppState = yield select()
  const currentRoles = selectClustersRoles(state) || []
  yield put(
    getClustersRolesSuccess(currentRoles.filter((r) => r.name !== payload.role))
  )
  yield put(
    setMessage({
      type: MessageType.SUCCESS,
      message: `Role "${payload.role}" removed`,
    })
  )
}

function* createClusterRoleSaga({ payload }: Action<CreateRolePayload>) {
  yield put(upsertRoleRequest(payload.subscriptionId, payload.role))
  yield put(
    setMessage({
      type: MessageType.SUCCESS,
      message: `Role "${payload.role.name}" created`,
    })
  )
}

function* upsertClusterRoleSaga({ payload }: Action<UpdateRolePayload>) {
  const state: AppState = yield select()
  const currentRoles = selectClustersRoles(state) || []
  const currentRoleIndex = currentRoles.findIndex(
    (r) => r.name === payload.role.name
  )
  if (currentRoleIndex === -1) {
    yield put(getClustersRolesSuccess([payload.role, ...currentRoles]))
  } else if (payload.newName) {
    yield put(
      getClustersRolesSuccess(
        replace(
          currentRoleIndex,
          { ...payload.role, name: payload.newName },
          currentRoles
        )
      )
    )
  } else {
    yield put(
      getClustersRolesSuccess(
        replace(currentRoleIndex, payload.role, currentRoles)
      )
    )
  }
}

export default function* subscriptionsSagas() {
  yield all([
    takeLatest(ActionType.GET_SUBSCRIPTIONS_REQUEST, getSubscriptionsSaga),
    takeLatest(
      ActionType.SET_SUBSCRIPTIONS_PAGINATION,
      setSubscriptionsPaginationSaga
    ),
    takeLatest(
      ActionType.SET_SUBSCRIPTIONS_SEARCH_QUERY,
      setSubscriptionsPaginationSaga
    ),
    takeLatest(
      ActionType.SET_SHOW_SHOWEXPIRED_SUBSCRIPTIONS,
      setSubscriptionsPaginationSaga
    ),
    takeLatest(ActionType.DELETE_ROLE_REQUEST, deleteClusterRoleSaga),
    takeLatest(ActionType.CREATE_ROLE_REQUEST, createClusterRoleSaga),
    takeLatest(ActionType.UPDATE_ROLE_REQUEST, upsertClusterRoleSaga),
  ])
}
