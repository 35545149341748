import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  selectSubscription,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoaded,
  selectSubscriptionDetailsLoading,
} from "src/store/desktop/subscriptionDetails/selector"
import {
  addLicenseRequest,
  generateLicenseTokenRequest,
} from "src/store/desktop/subscriptionDetails/actions"
import { setMessage } from "src/store/system/actions"
import {
  ActionType,
  AddLicenseModalProps,
  AddLicenseModalFormValues,
  Subscription,
  IActionType,
} from "src/domain/desktop"
import { Modal } from "antd"
import AddLicenseModalFooter from "./components/Footer"
import AddLicenseModalBody from "./components/Body"
import { getLicensePayload, getLicenseTokenPayload } from "./utils"

const AddLicenseModal: React.FC<AddLicenseModalProps> = ({
  visible,
  onClose,
  licenseData,
}) => {
  const dispatch = useDispatch()

  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const apiMode: IActionType | undefined = useSelector(
    selectSubscriptionDetailsApiMode
  )
  const loading: boolean =
    useSelector(selectSubscriptionDetailsLoading) &&
    apiMode === ActionType.ADD_LICENSE_REQUEST
  const loaded: boolean | undefined =
    useSelector(selectSubscriptionDetailsLoaded) &&
    apiMode === ActionType.ADD_LICENSE_REQUEST

  const [values, setValues] = useState<AddLicenseModalFormValues>({})

  useEffect(() => {
    if (visible) {
      setValues(
        licenseData?.id
          ? {
              ...licenseData,
            }
          : {}
      )
    }
  }, [dispatch, visible, licenseData])

  useEffect(() => {
    if (!loading && loaded) {
      setValues({})
    }
  }, [dispatch, loading, loaded])

  const handleChange = (prop: keyof AddLicenseModalFormValues) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setValues({
      ...values,
      [prop]: value,
    })
  }

  const handleAddLicense = () => {
    const payload = getLicensePayload(values, subscription)
    dispatch(
      payload
        ? addLicenseRequest(payload)
        : setMessage({ message: "Please enter valid email" })
    )
  }
  const handleGenerateToken = () => {
    const payload = getLicenseTokenPayload(values, subscription)
    dispatch(
      payload
        ? generateLicenseTokenRequest(payload)
        : setMessage({ message: "Please enter all fields" })
    )
  }

  return (
    <div className="addLicense">
      <Modal
        title={
          licenseData?.id
            ? "Add an Offline Machine for this member"
            : "Add a member"
        }
        visible={visible}
        width={"50%"}
        maskClosable={false}
        closable={false}
        footer={
          <AddLicenseModalFooter
            licenseData={licenseData}
            onGenerateToken={handleGenerateToken}
            onAddLicense={handleAddLicense}
            onClose={() => onClose()}
          />
        }
      >
        <AddLicenseModalBody
          licenseData={licenseData}
          values={values}
          onChange={handleChange}
        />
      </Modal>
    </div>
  )
}

export default AddLicenseModal
