import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Card, Row, Space, Typography } from "antd"
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form"
import { useSubscriptionQuery } from "../../hooks/useSubscriptionQuery"
import { bm, classes } from "../../../bem"
import { isCancelable as isCancelableSubscription } from "src/domain/desktop/subscription"
import { AppConfig } from "../../../core/config"
import { BackToSubscription } from "../../../components/BackToLink"
import "./styles.less"
import { usePageView } from "src/core/analytics/hooks"
import { RouteKey } from "src/router/routes"

const FORM_TARGET_ID = "hubspot-form"
const hubspotPortalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID || "8861897"
const hubspotFormId =
  process.env.REACT_APP_HUBSPOT_FORM_CANCEL_ID ||
  "9d69ee1d-974e-4e37-a1ca-9c0636257544"

const b = "subscriptionCancel"

const SubscriptionCancelPage: React.FC = () => {
  usePageView(RouteKey.SUBSCRIPTION_CANCEL)
  const history = useHistory()
  const { loading, subscription, backToSubscription } = useSubscriptionQuery()
  const [isCancelable, setCancelable] = useState<boolean>(true)
  const [warningContinue, setWarningContinue] = useState<boolean>(false)

  useEffect(() => {
    if (subscription && !isCancelableSubscription(subscription)) {
      setCancelable(false)
    }
  }, [subscription])

  useHubspotForm({
    portalId: hubspotPortalId,
    formId: hubspotFormId,
    target: "#" + FORM_TARGET_ID,
    redirectUrl: `${history.location.pathname}/trigger`,
  })

  return (
    <Card
      className={classes(
        "card",
        bm(b, {
          loading: loading || !warningContinue,
          notCancelable: !isCancelable,
        })
      )}
      bordered={false}
    >
      <Row className="m-b-10">
        <BackToSubscription onClick={backToSubscription} />
      </Row>
      <div id="cancel-form">
        <Typography.Title level={4}>
          We are sad to see you leave, please help us understand how to improve
        </Typography.Title>
        <div id="hubspot-form"></div>
      </div>
      {!isCancelable && (
        <Typography.Text>
          Sorry, your subscription cannot be canceled by yourself. Please
          contact {AppConfig.supportEmail}
        </Typography.Text>
      )}
      {isCancelable && !warningContinue && !loading && (
        <Space direction={"vertical"} size={20}>
          <Typography.Text>
            If you cancel your subscription, you will lose access to all
            professional subscription features
          </Typography.Text>
          <Button
            danger
            type={"primary"}
            onClick={() => setWarningContinue(true)}
          >
            I want to unsubscribe anyway
          </Button>
        </Space>
      )}
    </Card>
  )
}

export default SubscriptionCancelPage
