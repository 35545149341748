import { CircleUserFarIcon, PeopleIcon } from "@conduktor/ui-library"
import { FC } from "react"
import { AccessControlMemberType } from "src/domain/desktop"

export const MemberIcon: FC<{ type: AccessControlMemberType }> = ({ type }) => {
  if (type === "group") {
    return <PeopleIcon fr />
  }
  return <CircleUserFarIcon fr />
}
