import { RootState } from "src/domain/desktop"
import { LicenseMembersReducerType } from "./reducer"
import { User } from "../../../domain/system"

const selectLicenseMembersState = (
  state: RootState
): LicenseMembersReducerType => state.licenseMembers

export const selectLicenseMembersLoading = (state: RootState): boolean =>
  selectLicenseMembersState(state).loading

export const selectLicenseMembersError = (
  state: RootState
): string | undefined => selectLicenseMembersState(state).error

export const selectLicenseMembersBillingUsers = (
  state: RootState
): User[] | undefined => selectLicenseMembersState(state).billingUsers

export const selectLicenseMembersAdmins = (
  state: RootState
): User[] | undefined => selectLicenseMembersState(state).admins

export const selectLicenseMembersManagers = (
  state: RootState
): User[] | undefined => selectLicenseMembersState(state).licenseManagers

export const selectLicenseMembersLicenses = (
  state: RootState
): User[] | undefined => selectLicenseMembersState(state).licenseMembers

export const selectAllLicenseMembers = (state: RootState): User[] => {
  const billingUsers = selectLicenseMembersBillingUsers(state) || []
  const admins = selectLicenseMembersAdmins(state) || []
  const licenseManagers = selectLicenseMembersManagers(state) || []
  const licenseMembers = selectLicenseMembersLicenses(state) || []

  return [...billingUsers, ...admins, ...licenseManagers, ...licenseMembers]
}
