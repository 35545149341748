import { TablePaginationConfig } from "antd"
import { RootState, Log } from "src/domain/desktop"
import { LogsReducerType } from "./reducer"

const selectLogsState = (state: RootState): LogsReducerType => state.logs

export const selectLogsLoading = (state: RootState): boolean =>
  selectLogsState(state).loading

export const selectLogsError = (state: RootState): string | undefined =>
  selectLogsState(state).error

export const selectLogsList = (state: RootState): Log[] =>
  selectLogsState(state).items

export const selectLogsPagination = (state: RootState): TablePaginationConfig =>
  selectLogsState(state).pagination

export const selectLogsSearchQuery = (state: RootState): string | undefined =>
  selectLogsState(state).searchQuery
