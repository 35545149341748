import { useDispatch, useSelector } from "react-redux"
import { Flex } from "@conduktor/ui-library"
import {
  AccessControlMemberIcon,
  AccessControlMemberPanel,
} from "./AccessControl.styled"
import { EllipsisText } from "src/styles/overflow"
import { RoleSelect } from "./RoleSelect"
import {
  AccessControlMemberApiPayload,
  AccessControlMemberId,
  RemoveAccessControlMemberApiPayload,
  RoleName,
} from "src/domain/desktop"
import { selectAccessControlUsers } from "src/store/desktop/accessControl/selector"
import {
  removeAccessControlMemberSuccess,
  updateAccessControlMemberSuccess,
} from "src/store/desktop/accessControl/actions"
import { useSubscriptionQuery } from "src/pages/hooks/useSubscriptionQuery"
import { selectMostSpecificRole } from "./utils"
import React, { useState } from "react"
import {
  removeAccessControlUserApi,
  upsertAccessControlUserApi,
} from "src/services"
import { setErrorMessage, setSuccessMessage } from "src/store/system/actions"
import { RemoveMemberButton } from "./RemoveMemberButton"
import { selectSelectedCluster } from "src/store/desktop/clusters/selector"
import { MemberIcon } from "../MemberIcon"

export const ListUsers = () => {
  const users = useSelector(selectAccessControlUsers)

  return (
    <>
      {Object.entries(users).map(([email, roles]) => (
        <AccessControlUser key={email} email={email} roles={roles} />
      ))}
    </>
  )
}

interface UserProps {
  email: AccessControlMemberId
  roles: RoleName[]
}

const AccessControlUser = ({ email, roles }: UserProps) => {
  const dispatch = useDispatch()
  const { subscription } = useSubscriptionQuery()
  const cluster = useSelector(selectSelectedCluster)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const update = async (role: RoleName) => {
    try {
      const updating: AccessControlMemberApiPayload = {
        id: email,
        clusterId: cluster!.clusterId!,
        subscriptionId: subscription!.id,
        roles: [role],
        type: "user",
      }
      setIsUpdating(true)
      await upsertAccessControlUserApi(updating)
      dispatch(updateAccessControlMemberSuccess(updating))
      dispatch(setSuccessMessage(`Access control assigned`))
    } catch (e) {
      dispatch(setErrorMessage(`Error while assigning this access control`))
    } finally {
      setIsUpdating(false)
    }
  }

  const remove = async () => {
    try {
      const removal: RemoveAccessControlMemberApiPayload = {
        id: email,
        clusterId: cluster!.clusterId!,
        subscriptionId: subscription!.id,
        type: "user",
      }
      setIsUpdating(true)
      await removeAccessControlUserApi(removal)
      dispatch(removeAccessControlMemberSuccess(removal))
      dispatch(setSuccessMessage(`Access control removed`))
    } catch (e) {
      setIsUpdating(false)
      dispatch(setErrorMessage(`Error while removing this access control`))
    }
  }

  return (
    <AccessControlMemberPanel>
      <Flex align={"center"} gap={3}>
        <AccessControlMemberIcon>
          <MemberIcon type={"user"} />
        </AccessControlMemberIcon>
        <EllipsisText>{email}</EllipsisText>
      </Flex>

      <Flex align={"center"} gap={5}>
        <RoleSelect
          disabled={isUpdating}
          value={selectMostSpecificRole(roles)}
          onChange={update}
          options={cluster!.roles}
        />
        <RemoveMemberButton disabled={isUpdating} onClick={remove} />
      </Flex>
    </AccessControlMemberPanel>
  )
}
