import { useSubscriptionQuery } from "../../hooks/useSubscriptionQuery"
import { useSelector } from "react-redux"
import { selectSelectedCluster } from "../../../store/desktop/clusters/selector"
import { RouteKey } from "../../../router/routes"

export const useRoutePath = (key: RouteKey) => {
  const { chargeBeeId } = useSubscriptionQuery()
  const cluster = useSelector(selectSelectedCluster)
  const basePath = `/subscriptions/licenses-management/${chargeBeeId}/clusters`

  if (!cluster) {
    return ""
  }
  if (key === RouteKey.CLUSTERS) {
    return `${basePath}`
  }
  if (key === RouteKey.CLUSTER_CONTROL) {
    return `${basePath}/${cluster!.clusterId}`
  }
  if (key === RouteKey.TOPIC_CONTROL_CREATION) {
    return `${basePath}/${cluster!.clusterId}/topics`
  }
  throw new Error("Unsupported key")
}
