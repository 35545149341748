import React, { useEffect, useState } from "react"
import { Alert, Button, Card, Col, Input, Row, Table } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { useColumns } from "./columns"
import { useDispatch, useSelector } from "react-redux"
import { selectAdminsState } from "../../../../store/desktop/admin/admins/selector"
import {
  deleteAdmin,
  getAdminsRequest,
  upsertAdmin,
} from "../../../../store/desktop/admin/admins/actions"
import { AdminModal, ModalConfig } from "./AdminModal"
import { RouteKey } from "src/router/routes"
import {
  createAdminUserApi,
  deleteAdminUserApi,
  updateAdminUserApi,
} from "../../../../services"
import { ConduktorUserUpdate } from "../../../../domain/desktop/admin/adminsTypes"
import { usePageView } from "src/core/analytics/hooks"
import { ConduktorUser } from "../../../../domain/system"

export const AdminsPage = () => {
  usePageView(RouteKey.ADMIN_ADMINS)
  const dispatch = useDispatch()
  const { error, users, loading } = useSelector(selectAdminsState)
  const [filter, setFilter] = useState<string>()
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    isVisible: false,
    isUpdate: false,
  })

  useEffect(() => {
    dispatch(getAdminsRequest())
  }, [dispatch])

  const triggerUpsert = async (
    user: ConduktorUserUpdate,
    config: ModalConfig
  ) => {
    if (config.isUpdate && config.user?.id) {
      await updateAdminUserApi(config.user.id, user)
      dispatch(upsertAdmin({ ...config.user, ...user }))
    } else {
      await createAdminUserApi(user)
      dispatch(upsertAdmin(user))
    }
  }

  const handleCreate = async () => {
    setModalConfig({
      isVisible: true,
      isUpdate: false,
    })
  }

  const handleEdit = async (user: ConduktorUser) => {
    setModalConfig({
      isVisible: true,
      isUpdate: true,
      user,
    })
  }

  const handleDelete = async (user: ConduktorUser) => {
    if (user.id) {
      await deleteAdminUserApi({ id: user.id })
      dispatch(deleteAdmin(user.id))
    }
  }

  const columns = useColumns({ handleEdit, handleDelete })
  const filteredUsers = !filter
    ? users
    : users?.filter(
        (u) =>
          u.email?.toLowerCase().includes(filter.toLowerCase()) ||
          u.fullName?.toLowerCase().includes(filter.toLowerCase())
      )

  if (error) {
    return <Alert type="info" message={<u>Error</u>} description={error} />
  }

  return (
    <Card loading={loading} className="card" bordered={false}>
      <Row className="m-b-15" justify={"space-between"}>
        <Col>
          <Input.Search
            // defaultValue={filter}
            loading={loading}
            placeholder="Search for Name / Email"
            allowClear={true}
            className="search-box"
            // onChange={(e) => setFilter(e.target.value)}
            onSearch={(e) => setFilter(e)}
          />
        </Col>
        <Col>
          <Button icon={<PlusOutlined />} type="primary" onClick={handleCreate}>
            Create an admin
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredUsers}
        rowKey="email"
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize: 20,
        }}
      />
      {modalConfig.isVisible && (
        <AdminModal
          config={modalConfig}
          onClose={() => setModalConfig({ isVisible: false })}
          onSubmit={triggerUpsert}
        />
      )}
    </Card>
  )
}
