import {
  DomainStatusWithCount,
  LicenseActivityLog,
  LicensesCount,
  RootState,
  SubscriptionActiveExpiredCountWithPlanName,
  SubscriptionAddonsCount,
} from "src/domain/desktop"
import { AdminDashboardReducerType } from "./reducer"

const selectAdminDashboardState = (
  state: RootState
): AdminDashboardReducerType => state.adminDashboard

export const selectAdminDashboardLoading = (state: RootState): boolean =>
  selectAdminDashboardState(state).loading

export const selectAdminDashboardError = (
  state: RootState
): string | undefined => selectAdminDashboardState(state).error

export const selectSubscriptionActiveExpiredCountWithPlanNameList = (
  state: RootState
): Array<SubscriptionActiveExpiredCountWithPlanName> =>
  selectAdminDashboardState(state)
    .subscriptionActiveExpiredCountWithPlanNameList

export const selectSubscriptionAddonsCount = (
  state: RootState
): SubscriptionAddonsCount =>
  selectAdminDashboardState(state).subscriptionAddonsCount

export const selectDomainStatusWithCount = (
  state: RootState
): DomainStatusWithCount =>
  selectAdminDashboardState(state).domainStatusWithCount

export const selectLicensesCount = (state: RootState): LicensesCount =>
  selectAdminDashboardState(state).licensesCount

export const selectLicenseActivityLogsList = (
  state: RootState
): Array<LicenseActivityLog> =>
  selectAdminDashboardState(state).licenseActivityLogsList
