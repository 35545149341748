import {
  Action,
  ActionType,
  AddClusterApiPayload,
  Cluster,
  DeleteClusterApiPayload,
  Role,
  SubscriptionApiPayload,
} from "src/domain/desktop"

export const getClustersRequest = (payload: number) => ({
  type: ActionType.GET_CLUSTERS_REQUEST,
  payload,
})

export const getClustersSuccess = (payload: Cluster[]) => ({
  type: ActionType.GET_CLUSTERS_SUCCESS,
  payload,
})

export const getClustersFailure = (payload: string) => ({
  type: ActionType.GET_CLUSTERS_FAILURE,
  payload,
})

export const checkClusters = (payload: Cluster[]) => ({
  type: ActionType.CHECK_CLUSTERS,
  payload,
})

export const selectCluster = (payload?: Cluster) => ({
  type: ActionType.SELECT_CLUSTER,
  payload,
})

export const addClusterRequest = (payload: AddClusterApiPayload) => ({
  type: ActionType.ADD_CLUSTER_REQUEST,
  payload,
})

export const addClusterSuccess = (payload: Cluster[]) => ({
  type: ActionType.ADD_CLUSTER_SUCCESS,
  payload,
})

export const addClusterFailure = (payload: string) => ({
  type: ActionType.ADD_CLUSTER_FAILURE,
  payload,
})

export const updateClusterRequest = (payload: AddClusterApiPayload) => ({
  type: ActionType.UPDATE_CLUSTER_REQUEST,
  payload,
})

export const updateClusterSuccess = (payload: Cluster[]) => ({
  type: ActionType.UPDATE_CLUSTER_SUCCESS,
  payload,
})

export const updateClusterFailure = (payload: string) => ({
  type: ActionType.UPDATE_CLUSTER_FAILURE,
  payload,
})

export const deleteClusterRequest = (payload: DeleteClusterApiPayload) => ({
  type: ActionType.DELETE_CLUSTER_REQUEST,
  payload,
})

export const deleteClusterSuccess = (payload: Cluster[]) => ({
  type: ActionType.DELETE_CLUSTER_SUCCESS,
  payload,
})

export const deleteClusterFailure = (payload: string) => ({
  type: ActionType.DELETE_CLUSTER_FAILURE,
  payload,
})

export const deleteClustersRequest = (payload: number) => ({
  type: ActionType.DELETE_CLUSTERS_REQUEST,
  payload,
})

export const deleteClustersSuccess = (payload: Cluster[]) => ({
  type: ActionType.DELETE_CLUSTERS_SUCCESS,
  payload,
})

export const deleteClustersFailure = (payload: string) => ({
  type: ActionType.DELETE_CLUSTERS_FAILURE,
  payload,
})

export const getClustersRolesRequest = (
  subscriptionId: number
): Action<SubscriptionApiPayload> => ({
  type: ActionType.GET_CLUSTERS_ROLES_REQUEST,
  payload: { subscriptionId },
})

export const getClustersRolesSuccess = (payload: Role[]) => ({
  type: ActionType.GET_CLUSTERS_ROLES_SUCCESS,
  payload,
})

export const getClustersRolesFailure = (payload: string) => ({
  type: ActionType.GET_CLUSTERS_ROLES_FAILURE,
  payload,
})
