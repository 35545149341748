export const AppConfig = {
  environment:
    process.env.VERCEL_GIT_COMMIT_REF === "master-account"
      ? "production"
      : "development",
  title: "Conduktor",
  supportEmail: "support@conduktor.io",
  website: {
    domain: "www.conduktor.io",
    url: "https://www.conduktor.io/",
    doc: "https://docs.conduktor.io/portal/account-management",
    download: "https://www.conduktor.io/download/",
    privacyPolicy: "https://www.conduktor.io/privacy-policy",
    contact: {
      email: "sales@conduktor.io",
      support: "https://share.hsforms.com/1OZyPYnF6R5Ct_FxEBBbbnQ59xvt",
    },
  },
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || "YOUR_DOMAIN",
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "YOUR_CLIENT_ID",
    redirectUri: window.location.origin + "/callback",
    logoutReturnTo: window.location.origin + "/login",
  },
  segment: {
    key: process.env.REACT_APP_SEGMENT_KEY,
  },
}
