import { Action, ActionType, EmptyAction } from "src/domain/desktop"
import { ConduktorUserIdPayload } from "../../../../domain/desktop/admin/adminsTypes"
import { ConduktorUser } from "../../../../domain/system"

export const getAdminsRequest = (): EmptyAction => ({
  type: ActionType.GET_ADMINS_REQUEST,
})

export const getAdminsSuccess = (
  payload: ConduktorUser[]
): Action<ConduktorUser[]> => ({
  type: ActionType.GET_ADMINS_SUCCESS,
  payload,
})

export const getAdminsFailure = (message: string): Action<string> => ({
  type: ActionType.GET_ADMINS_FAILURE,
  payload: message,
})

export const deleteAdmin = (
  userId: number
): Action<ConduktorUserIdPayload> => ({
  type: ActionType.REMOVE_ADMIN_ACCESS,
  payload: { id: userId },
})

export const upsertAdmin = (user: ConduktorUser): Action<ConduktorUser> => ({
  type: ActionType.UPSERT_ADMIN_REQUEST,
  payload: user,
})
