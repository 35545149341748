import * as yup from "yup"

export type FormValues = { topic: string }

export const schema = yup.object({
  topic: yup
    .string()
    .required("Define a topic pattern")
    .matches(
      /^\*?[a-zA-Z0-9\\._\\-]+\*?$/,
      "Invalid topic definition. A wildcard can only be used as prefix or suffix."
    ),
})
