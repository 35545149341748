import React from "react"
import { useSelector } from "react-redux"
import SubscriberInfo from "./components/SubscriberInfo"
import LicensorInfo from "./components/LicensorInfo"
import AuthUserInfo from "./components/AuthUserInfo"
import {
  selectIsBillingUser,
  selectIsLicensed,
} from "src/store/system/selector"
import { RouteKey } from "src/router/routes"
import { usePageView } from "src/core/analytics/hooks"
import { DownloadAppAlert } from "./components/DownloadAppAlert"

const HomePage: React.FC = () => {
  usePageView(RouteKey.HOME)
  const isLicensed = useSelector(selectIsLicensed)
  const isBillingUser = useSelector(selectIsBillingUser)

  return (
    <>
      <DownloadAppAlert />
      {isBillingUser && <SubscriberInfo />}
      {isLicensed && <LicensorInfo />}
      {!isBillingUser && !isLicensed && <AuthUserInfo />}
    </>
  )
}

export default HomePage
