import { CheckboxInput, Flex } from "@conduktor/ui-library"
import React, { FC } from "react"
import {
  TopicPermission,
  TopicPermissionLabel,
} from "src/domain/desktop/topicPermission"

interface TopicPermissionsProps {
  disabled?: boolean
  permissions: TopicPermission[]
  onChange: (value: TopicPermission[]) => void
}

export const TopicPermissions: FC<TopicPermissionsProps> = ({
  disabled,
  permissions,
  onChange,
}) => {
  const isChecked = (perm: TopicPermission) => permissions.includes(perm)

  const onPermChange = (perm: TopicPermission, checked: boolean) => {
    if (checked && !isChecked(perm)) {
      onChange([...permissions, perm])
    } else if (!checked) {
      onChange(permissions.filter((p) => p !== perm))
    }
  }

  return (
    <Flex gap={2} direction={"column"}>
      {Object.entries(TopicPermission).map(([key, value]) => (
        <CheckboxInput
          key={key}
          disabled={disabled}
          checked={isChecked(value)}
          onCheckedChange={(checked) => onPermChange(value, checked as boolean)}
          label={TopicPermissionLabel[value]}
        />
      ))}
    </Flex>
  )
}
