import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Card, Tabs } from "antd"
import { setMessage } from "src/store/system/actions"
import { getSubscriptionRequest } from "src/store/desktop/subscriptionDetails/actions"
import { ClusterTab } from "src/domain/desktop"
import { RouteKey } from "src/router/routes"
import {
  selectClustersError,
  selectClustersLoading,
} from "src/store/desktop/clusters/selector"
import {
  getClustersRequest,
  getClustersRolesRequest,
} from "src/store/desktop/clusters/actions"
import { RolesTab } from "./components/RolesTab"
import { useQuery } from "../../../router/useQuery"
import { useSubscriptionQuery } from "../../hooks/useSubscriptionQuery"
import { BackToSubscription } from "../../../components/BackToLink"
import { useSubscriptionPageView } from "src/core/analytics/hooks"
import { MessageType } from "../../../domain/system"
import { ClustersTab } from "./components/ClustersTab"
import { Flex, Skeleton } from "@conduktor/ui-library"

const ClustersPage: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()

  const cLoading: boolean = useSelector(selectClustersLoading)
  const cError: string | undefined = useSelector(selectClustersError)
  const {
    error: sError,
    loading: sLoading,
    subscription,
    chargeBeeId,
    backToSubscription,
  } = useSubscriptionQuery()
  useSubscriptionPageView(RouteKey.CLUSTERS, subscription)

  const selectedTab: ClusterTab = (query.get("tab") as ClusterTab) || "clusters"
  const error = sError || cError
  const loading = sLoading || cLoading

  useEffect(() => {
    if (chargeBeeId && !sLoading && !subscription?.id) {
      dispatch(getSubscriptionRequest(chargeBeeId))
    }
  }, [chargeBeeId, dispatch])

  useEffect(() => {
    if (error && !loading) {
      dispatch(
        setMessage({
          type: MessageType.ERROR,
          message: error,
        })
      )
    }
  }, [dispatch, error, loading])

  useEffect(() => {
    if (subscription?.id) {
      dispatch(getClustersRequest(subscription?.id))
      dispatch(getClustersRolesRequest(subscription?.id))
    }
  }, [dispatch, subscription?.id])

  const handleTabChange = (tab: ClusterTab | string) => {
    history.push(`clusters?tab=${tab}`)
  }

  return (
    <Card className="card clusters" bordered={false}>
      <BackToSubscription onClick={backToSubscription} />
      <p className="hint-text m-t-4">
        You can configure what your users can do with Conduktor when they are
        connected on specific Apache Kafka clusters. You need the{" "}
        <b>clusterId</b> to uniquely identify your clusters. It is an unique
        identifier assigned to an Apache Kafka cluster. Your Apache Kafka
        administrators can provide it.
      </p>
      <Tabs
        activeKey={selectedTab}
        onChange={handleTabChange}
        style={{
          /* default overflow doesn't permit the sticky header of the roles table */
          overflow: "initial",
        }}
      >
        <Tabs.TabPane key={"clusters"} tab={"Clusters"}>
          {loading ? (
            <Flex direction="column" gap={2} css={{ flex: 1 }}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Flex>
          ) : (
            <ClustersTab />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane key={"roles"} tab={"Access roles"}>
          <RolesTab />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}

export default ClustersPage
