import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FileAddOutlined, UserOutlined } from "@ant-design/icons"
import { Card, Dropdown, Input, Menu } from "antd"
import {
  selectSelectedUser,
  selectSubscription,
  selectSubscriptionAdmins,
  selectSubscriptionBillingUsers,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoaded,
  selectSubscriptionDetailsLoading,
  selectSubscriptionLicensedUsers,
  selectSubscriptionLicenseManagers,
} from "src/store/desktop/subscriptionDetails/selector"
import { selectConduktorUser, selectModals } from "src/store/system/selector"
import { hideModal, showModal } from "src/store/system/actions"
import { isValidUser } from "src/domain/user"
import { ActionType, IActionType, Subscription } from "src/domain/desktop"
import AddLicenseModal from "src/components/Modals/AddLicenseModal"
import {
  getLicensedUsersRequest,
  selectUser,
} from "src/store/desktop/subscriptionDetails/actions"
import "./styles.less"
import { AddLicensesByCSVModal } from "../../../../../components/Modals/AddLicensesByCSVModal"
import { ExportCSVButton } from "../ExportCSVButton"
import {
  isSubscriptionExpired,
  isSubscriptionStarted,
} from "src/domain/desktop/subscription"
import { bem, classes } from "../../../../../bem"
import { MembersTabs } from "./MembersTabs"
import { ConduktorUser, User } from "../../../../../domain/system"
import { ModalType, UserRoleType } from "../../../../../domain/desktop/common"

const b = "subscribedUsers"

const SubscribedUsers: React.FC = () => {
  const dispatch = useDispatch()

  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const loading: boolean = useSelector(selectSubscriptionDetailsLoading)
  const loaded: boolean = useSelector(selectSubscriptionDetailsLoaded) || false
  const api: IActionType | undefined = useSelector(
    selectSubscriptionDetailsApiMode
  )
  const billingUsers: Array<User> =
    useSelector(selectSubscriptionBillingUsers) || []
  const admins: Array<User> = useSelector(selectSubscriptionAdmins) || []
  const licenseManagers: Array<User> =
    useSelector(selectSubscriptionLicenseManagers) || []
  const licensedUsers: Array<User> =
    useSelector(selectSubscriptionLicensedUsers) || []
  const allMembers: Array<User> = [
    ...billingUsers,
    ...admins,
    ...licenseManagers,
    ...licensedUsers,
  ]

  const isAdmin = isValidUser(conduktorUser, [UserRoleType.ADMIN])
  const availableLicenses = (subscription?.quantity || 0) - licensedUsers.length
  const licenseData = useSelector(selectSelectedUser)
  const {
    [ModalType.ADD_USER]: addLicenseModalVisible,
    [ModalType.ADD_USER_CSV]: addLicensesByCSVModalVisible,
  } = useSelector(selectModals)

  const [searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    if (
      loaded &&
      !loading &&
      api === ActionType.DELETE_ADMIN_OF_SUBSCRIPTION_REQUEST &&
      subscription
    ) {
      dispatch(getLicensedUsersRequest(subscription.id))
    }
  }, [loaded, loading, api])

  const handleAddLicense = () => {
    dispatch(selectUser(undefined))
    dispatch(showModal(ModalType.ADD_USER))
  }

  const handleAddLicensesByCSV = () => {
    dispatch(selectUser(undefined))
    dispatch(showModal(ModalType.ADD_USER_CSV))
  }

  return (
    <div className={b}>
      <Card className="card" bordered={false}>
        <div className={bem(b, "menu")}>
          <h1 className="card-title">Members</h1>
          <ExportCSVButton
            className={bem(b, "exportButton")}
            isAdminUser={isAdmin}
            members={licensedUsers}
            subscription={subscription}
          />
          <Input.Search
            placeholder="Search for a Member"
            className={classes(bem(b, "menuSearch"), "text-left", "m-r-5")}
            value={searchQuery}
            loading={loading}
            allowClear
            onChange={(e) => {
              setSearchQuery(e.target.value)
            }}
          />
          <Dropdown.Button
            disabled={
              availableLicenses < 1 ||
              isSubscriptionExpired(subscription) ||
              !isSubscriptionStarted(subscription)
            }
            className="m-t-0"
            type="primary"
            onClick={handleAddLicense}
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  icon={<FileAddOutlined />}
                  onClick={handleAddLicensesByCSV}
                >
                  Import by CSV
                </Menu.Item>
              </Menu>
            }
          >
            <UserOutlined />
            Add a member
          </Dropdown.Button>
        </div>
        <MembersTabs searchQuery={searchQuery} members={allMembers} />
      </Card>
      <AddLicenseModal
        visible={addLicenseModalVisible}
        licenseData={licenseData}
        onClose={() => dispatch(hideModal(ModalType.ADD_USER))}
      />
      <AddLicensesByCSVModal
        visible={addLicensesByCSVModalVisible}
        onClose={() => dispatch(hideModal(ModalType.ADD_USER_CSV))}
      />
    </div>
  )
}
export default SubscribedUsers
