import { User } from "@auth0/auth0-react"
import { ConduktorUser } from "../../../domain/system"
import { isValidEmail } from "../../../utils"

const getResponseDataFromValidationFunction = (message?: string) => {
  if (!message) {
    return {
      isValidated: true,
      errorMessage: "",
    }
  }
  return {
    isValidated: false,
    errorMessage: message,
  }
}

export const isValidPayloadForAddingOwnerToSubscription = ({
  user,
  conduktorUser,
  currentOwners = [],
}: {
  user: User
  conduktorUser: ConduktorUser
  currentOwners: Array<User>
}) => {
  if (!user.name || !user.email) {
    return getResponseDataFromValidationFunction("Please enter all fields")
  }
  if (!isValidEmail(user.email)) {
    return getResponseDataFromValidationFunction(
      "Please enter valid email address"
    )
  }
  if (conduktorUser.email === user.email) {
    return getResponseDataFromValidationFunction(
      "This user is already a Conduktor Desktop member"
    )
  }
  if (currentOwners.some((o) => o.email === user.email)) {
    return getResponseDataFromValidationFunction(
      "This user is already an owner of this subscription"
    )
  }
  return getResponseDataFromValidationFunction()
}
