import {
  ActionType,
  Action,
  ApiStatus,
  AdminDashboard,
} from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import { failureReducer, initReducer, requestReducer } from "../../reducers"

export type AdminDashboardReducerType = ApiStatus & AdminDashboard

export const defaultState: AdminDashboardReducerType = {
  loading: false,
  error: undefined,

  subscriptionActiveExpiredCountWithPlanNameList: [],
  subscriptionAddonsCount: {},
  domainStatusWithCount: {},
  licensesCount: {},
  licenseActivityLogsList: [],
}

function getAdminDashboardSuccessReducer(
  state: AdminDashboardReducerType,
  { payload }: Action<AdminDashboard>
) {
  return {
    ...state,
    ...payload,
    loading: false,
  }
}

export const adminDashboardReducer = createReducer<AdminDashboardReducerType>(
  defaultState,
  {
    [ActionType.INIT_STORE]: initReducer(defaultState),
    [ActionType.GET_ADMIN_DASHBOARD_DATA_REQUEST]: requestReducer,
    [ActionType.GET_ADMIN_DASHBOARD_DATA_SUCCESS]: getAdminDashboardSuccessReducer,
    [ActionType.GET_ADMIN_DASHBOARD_DATA_FAILURE]: failureReducer,
  }
)
