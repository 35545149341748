import { Cluster, IActionType, RootState } from "src/domain/desktop"
import { ClustersReducerType } from "./reducer"

const selectClustersState = (state: RootState): ClustersReducerType =>
  state.clusters

export const selectClustersLoading = (state: RootState): boolean =>
  selectClustersState(state).loading

export const selectClustersError = (state: RootState): string | undefined =>
  selectClustersState(state).error

export const selectClustersLoaded = (state: RootState): boolean | undefined =>
  selectClustersState(state).loaded

export const selectClustersList = (state: RootState): Cluster[] =>
  selectClustersState(state).items

export const selectCheckedClusters = (state: RootState): Cluster[] =>
  selectClustersState(state).checked

export const selectSelectedCluster = (state: RootState): Cluster | undefined =>
  selectClustersState(state).selected

export const selectClusterApiMode = (
  state: RootState
): IActionType | undefined => selectClustersState(state).type

export const selectClustersRoles = (state: RootState) =>
  selectClustersState(state).roles
