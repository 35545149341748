import { Auth0Stats, RootState } from "src/domain/desktop"

import { LastSignupsReducerType } from "./reducer"

export const selectLastSignupsState = (
  state: RootState
): LastSignupsReducerType => state.lastSignups

export const selectLastSignupsLoading = (state: RootState): boolean =>
  selectLastSignupsState(state).loading

export const selectLastSignupsError = (state: RootState): string | undefined =>
  selectLastSignupsState(state).error

export const selectLastSignupsStats = (
  state: RootState
): Auth0Stats | undefined => selectLastSignupsState(state).stats
