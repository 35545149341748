import {
  AppMetadata,
  LicenseOffline,
  TeamWrapper,
  UserRoleType,
} from "./desktop/common"
import { ApiStatus, Company, Subscription } from "./desktop"

export type Email = string
export type ConduktorUserId = number
export type Timestamp = number

export interface Auth0User {
  email?: string
  email_verified?: boolean
  "https://conduktor.io/subscription_expiry"?: string
  "https://conduktor.io/subscription_type"?: string
  name?: string
  nickname?: string
  picture?: string
  pictureUrl?: string
  sub?: string
  updated_at?: string
  appMetadata?: AppMetadata
}

export interface ConduktorUser {
  id?: ConduktorUserId
  company?: Company
  created?: number
  email?: string
  pictureUrl?: string
  lastUpdatedAt?: number
  modified?: number
  roleId?: UserRoleType
  fullName?: string
  firstName?: string
  lastName?: string
  country?: string
  phoneNumber?: string
}

export interface User {
  autoAssigned?: boolean
  conduktorUser?: ConduktorUser
  created?: number
  email?: string
  id?: number
  modified?: number
  name?: string
  pictureUrl?: string
  removed?: number
  accessLevel?: number
  company?: Company
  fullName?: string
  teams?: Array<TeamWrapper>
  lastUpdatedAt?: number
  licensesOffline?: Array<LicenseOffline>
  subscription?: Subscription
  roleId?: UserRoleType
}

export enum MessageType {
  WARN = "warn",
  INFO = "info",
  SUCCESS = "success",
  ERROR = "error",
}

export interface Message {
  type?: MessageType
  title?: string
  message?: string
}

export interface System {
  conduktorUser: ConduktorUser
  auth0User: Auth0User
  licensedUser: User
  isBillingUser: boolean
  isLicensed: boolean
  message?: Message
  forceLogout?: boolean
  sidebar: { collapsed: boolean }
  modals: Record<string, boolean>
  updateUserDataStatus: ApiStatus
}
