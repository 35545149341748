import { takeLatest, put, select, all } from "redux-saga/effects"

import { getLicensesAllApi } from "src/services"

import { ActionType, GetItemsApiPayload } from "src/domain/desktop"
import { TablePaginationConfig } from "antd"

import { parseError } from "src/utils"

import {
  getLicensesSuccess,
  getLicensesFailure,
  getLicensesRequest,
} from "./actions"
import { AppState } from "../../config/rootStore"
import { selectLicensesPagination, selectLicensesSearchQuery } from "./selector"
import { License } from "../../../domain/desktop/common"
import { ClientError } from "../../../services/ClientError"

function* getLicensesSaga() {
  try {
    const state: AppState = yield select()

    const searchQuery: string | undefined = selectLicensesSearchQuery(state)

    const pagination: TablePaginationConfig = selectLicensesPagination(state)

    const payload: GetItemsApiPayload = {
      params: {
        page: pagination.current,
        results: pagination.pageSize,
        searchQuery,
      },
    }

    const data: {
      totalCount: number
      licenses: License[]
    } = yield getLicensesAllApi(payload)

    yield put(getLicensesSuccess(data))
  } catch (err) {
    yield put(getLicensesFailure(parseError(err as ClientError)))
  }
}

function* setLicensesPaginationSaga() {
  yield put(getLicensesRequest())
}

export default function* licensesSagas() {
  yield all([
    takeLatest(ActionType.GET_LICENSES_REQUEST, getLicensesSaga),
    takeLatest(ActionType.SET_LICENSES_PAGINATION, setLicensesPaginationSaga),
    takeLatest(ActionType.SET_LICENSES_SEARCH_QUERY, setLicensesPaginationSaga),
  ])
}
