import React from "react"
import { ArrowUpRightFromSquareFarIcon, Box, Span } from "@conduktor/ui-library"
import { Sidebar } from "./Sidebar"
import {
  RouteKey,
  SidebarMenuItem,
  SidebarMenuSubItem,
} from "src/router/routes"
import { useSelector } from "react-redux"
import {
  SidebarHeader,
  SidebarHeaderImage,
  SidebarWrapper,
} from "./sidebar.styles"
import { SidebarExternalLinkItem, SidebarLinkItem } from "./SidebarLinkItem"
import { AvatarMenu } from "./AvatarMenu"
import { SidebarSection } from "./SidebarSection"
import { SidebarToggleButton } from "./SidebarToggleButton"
import { ConduktorUser, User } from "../../domain/system"
import {
  selectConduktorUser,
  selectIsBillingUser,
  selectIsLicensed,
  selectLicensedUser,
  selectSidebarCollapsed,
} from "../../store/system/selector"
import { isValidUser } from "../../domain/user"
import { UserRoleType } from "../../domain/desktop/common"
import {
  menuAdminItems,
  menuItems,
  routesDesktop,
} from "../../router/desktop/routesDesktop"
import { AppConfig } from "../../core/config"
import { Scroll } from "../scroll/Scroll"
import { useSegmentContext } from "src/core/analytics/segment"
import { useLocation } from "react-router-dom"

export const SidebarDesktop: React.FC = () => {
  const { pathname } = useLocation()
  const collapsed = useSelector(selectSidebarCollapsed)
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const licensedUser: User = useSelector(selectLicensedUser)
  const isBillingUser: boolean = useSelector(selectIsBillingUser)
  const isLicensedUser: boolean = useSelector(selectIsLicensed)
  const { track } = useSegmentContext()

  const isAdmin = isValidUser(conduktorUser, [UserRoleType.ADMIN])
  const showMemberMenu: boolean = isLicensedUser && !licensedUser.removed
  const showDevicesMenu: boolean = isLicensedUser

  const isShowable = (menuItem: SidebarMenuItem) => {
    if (menuItem.forBillingUserOnly && isAdmin) return false
    if (menuItem.forBillingUser && !isBillingUser && !isAdmin) return false
    if (menuItem.isMembersMenu && !showMemberMenu) return false
    if (menuItem.isMembersMenu && !showDevicesMenu) return false
    return true
  }

  const getRoutePath = (item: SidebarMenuSubItem) => {
    if (item.path) {
      return item.path
    }
    const route = routesDesktop.find((e) => e.key === item.key)
    return route?.path ? route.path : "/"
  }

  const createRoutePathMatch = (menuItem: SidebarMenuItem) => {
    if (menuItem.key === RouteKey.HOME) {
      // "Home" should only be active for exact match
      return undefined
    }
    if (
      menuItem.key === RouteKey.SUBSCRIPTIONS &&
      pathname.includes("/clusters")
    ) {
      // "Subscriptions" shouldn't be active for /clusters page
      return undefined
    }
    if (menuItem.key === RouteKey.CLUSTERS_SHORTCUT) {
      // "My Clusters" should be active for subscription's clusters path
      return "/subscriptions/licenses-management/:id/clusters"
    }

    return getRoutePath(menuItem)
  }

  return (
    <Sidebar>
      <SidebarHeader>
        <SidebarHeaderImage
          src={"/assets/logo/conduktor-icon.svg"}
          alt="Organization"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = `/assets/logo/icon.png`
          }}
        />
        {!collapsed && (
          <Span
            variant="h5"
            lineHeight="display"
            ellipsis
            css={{ maxWidth: "10rem", paddingTop: "12px" }}
          >
            Conduktor Desktop <p>Portal</p>
          </Span>
        )}
      </SidebarHeader>
      <Scroll height="calc(100vh - 12.375rem)" css={{ flex: 1 }}>
        <SidebarWrapper>
          <SidebarSection title="Conduktor">
            {menuItems
              .filter((menuItem) => isShowable(menuItem))
              .map((menuItem) => (
                <SidebarLinkItem
                  key={menuItem.key}
                  icon={menuItem.icon}
                  to={getRoutePath(menuItem)}
                  match={createRoutePathMatch(menuItem)}
                  title={menuItem.name}
                >
                  {menuItem.name}
                </SidebarLinkItem>
              ))}
          </SidebarSection>

          <SidebarSection title="Other">
            <SidebarExternalLinkItem
              icon={<ArrowUpRightFromSquareFarIcon fr />}
              to={AppConfig.website.download}
              title={"Download"}
            >
              Download App
            </SidebarExternalLinkItem>
            <SidebarExternalLinkItem
              icon={<ArrowUpRightFromSquareFarIcon fr />}
              onClick={() => track("cdk.admin.ui.help.Clicked")}
              to={AppConfig.website.doc}
              title={"Help"}
            >
              Help
            </SidebarExternalLinkItem>
          </SidebarSection>

          {isAdmin && (
            <SidebarSection title="Admin ⭐">
              {menuAdminItems.map((menuItem) => (
                <SidebarLinkItem
                  key={menuItem.key}
                  icon={menuItem.icon}
                  to={getRoutePath(menuItem)}
                  title={menuItem.name}
                >
                  {menuItem.name}
                </SidebarLinkItem>
              ))}
            </SidebarSection>
          )}

          <Box css={{ mt: "auto" }}>
            <SidebarToggleButton />
          </Box>
        </SidebarWrapper>
      </Scroll>
      <AvatarMenu collapsed={collapsed} />
    </Sidebar>
  )
}
