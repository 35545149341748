import { Flex } from "@conduktor/ui-library"
import React, { FC } from "react"

import { SidebarSectionTitle } from "./sidebar.styles"
import { useSelector } from "react-redux"
import { selectSidebarCollapsed } from "../../store/system/selector"

interface SidebarSectionProps {
  title: string
}

export const SidebarSection: FC<SidebarSectionProps> = ({
  title,
  children,
}) => {
  const collapsed = useSelector(selectSidebarCollapsed)

  return (
    <Flex direction="column" css={{ gap: 2 }}>
      <SidebarSectionTitle size={1} weight="semi" upper>
        {collapsed ? <>&nbsp;</> : title}
      </SidebarSectionTitle>
      {children}
    </Flex>
  )
}
