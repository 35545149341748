import { all, put, select, takeLatest } from "redux-saga/effects"

import {
  addClusterApi,
  deleteClusterApi,
  deleteClustersApi,
  getClustersApi,
  getSubscriptionRolesApi,
} from "src/services"

import {
  Action,
  ActionType,
  AddClusterApiPayload,
  Cluster,
  DeleteClusterApiPayload,
  Role,
  SubscriptionApiPayload,
} from "src/domain/desktop"

import { parseError } from "src/utils"

import {
  addClusterFailure,
  addClusterSuccess,
  checkClusters,
  deleteClusterFailure,
  deleteClusterSuccess,
  getClustersFailure,
  getClustersRolesFailure,
  getClustersRolesSuccess,
  getClustersSuccess,
  updateClusterSuccess,
} from "./actions"

import { selectCheckedClusters } from "./selector"

import { AppState } from "../../index"
import { hideModal, setMessage } from "../../system/actions"
import { MessageType } from "../../../domain/system"
import { ModalType } from "../../../domain/desktop/common"
import { ClientError } from "../../../services/ClientError"

function* getClustersSaga({ payload }: Action<number>) {
  try {
    const data: Cluster[] = yield getClustersApi(payload)
    yield put(getClustersSuccess(data))
  } catch (err) {
    yield put(getClustersFailure(parseError(err as ClientError)))
  }
}

function* getClustersRolesSaga({ payload }: Action<SubscriptionApiPayload>) {
  try {
    const roles: Role[] = yield getSubscriptionRolesApi(payload)
    yield put(getClustersRolesSuccess(roles))
  } catch (err) {
    yield put(getClustersRolesFailure(parseError(err as ClientError)))
  }
}

function* addClusterSaga({ payload }: Action<AddClusterApiPayload>) {
  try {
    const clusterData: Cluster[] = yield getClustersApi(payload.subscriptionId)
    yield put(addClusterSuccess(clusterData))
    yield put(
      setMessage({
        type: MessageType.SUCCESS,
        message: `Cluster created`,
      })
    )
    yield put(hideModal(ModalType.CLUSTER))
  } catch (err) {
    console.log(err)
    yield put(addClusterFailure(parseError(err as ClientError)))
  }
}

function* deleteClusterSaga({ payload }: Action<DeleteClusterApiPayload>) {
  try {
    yield deleteClusterApi(payload)
    const data: Cluster[] = yield getClustersApi(payload.subscriptionId)
    yield put(deleteClusterSuccess(data))
  } catch (err) {
    yield put(deleteClusterFailure(parseError(err as ClientError)))
  }
}

function* deleteClustersSaga({ payload }: Action<number>) {
  try {
    const state: AppState = yield select()
    const checked = (selectCheckedClusters(state) || [])
      .map((e) => e.clusterId)
      .filter(Boolean)
    yield deleteClustersApi({
      clusterIds: checked,
      subscriptionId: payload,
    })
    const data: Cluster[] = yield getClustersApi(payload)
    yield put(checkClusters([]))
    yield put(deleteClusterSuccess(data))
  } catch (err) {
    yield put(deleteClusterFailure(parseError(err as ClientError)))
  }
}

function* updateClusterSaga({ payload }: Action<AddClusterApiPayload>) {
  try {
    yield addClusterApi(payload)
    const data: Cluster[] = yield getClustersApi(payload.subscriptionId)
    yield put(updateClusterSuccess(data))
    yield put(hideModal(ModalType.CLUSTER))
    yield put(
      setMessage({
        type: MessageType.SUCCESS,
        message: `Cluster updated`,
      })
    )
  } catch (err) {
    console.log(err)
    yield put(addClusterFailure(parseError(err as ClientError)))
  }
}

export default function* clustersSagas() {
  yield all([takeLatest(ActionType.GET_CLUSTERS_REQUEST, getClustersSaga)])
  yield all([takeLatest(ActionType.DELETE_CLUSTER_REQUEST, deleteClusterSaga)])
  yield all([takeLatest(ActionType.UPDATE_CLUSTER_REQUEST, updateClusterSaga)])
  yield all([takeLatest(ActionType.ADD_CLUSTER_REQUEST, addClusterSaga)])
  yield all([
    takeLatest(ActionType.DELETE_CLUSTERS_REQUEST, deleteClustersSaga),
  ])
  yield all([
    takeLatest(ActionType.GET_CLUSTERS_ROLES_REQUEST, getClustersRolesSaga),
  ])
}
