import { takeLatest, put, all } from "redux-saga/effects"
import { getLicenseMembersApi } from "src/services"
import { ActionType, LicenseMembers } from "src/domain/desktop"
import { parseError } from "src/utils"
import { getLicenseMembersSuccess, getLicenseMembersFailure } from "./actions"
import { ClientError } from "../../../services/ClientError"

function* getLicenseMembersSaga() {
  try {
    const data: LicenseMembers = yield getLicenseMembersApi()
    yield put(getLicenseMembersSuccess(data))
  } catch (err) {
    yield put(getLicenseMembersFailure(parseError(err as ClientError)))
  }
}

export default function* licensesSagas() {
  yield all([
    takeLatest(ActionType.GET_LICENSE_MEMBERS_REQUEST, getLicenseMembersSaga),
  ])
}
