import React, { useEffect, useState } from "react"
import { setMessage } from "src/store/system/actions"
import { useDispatch } from "react-redux"
import { Input, Spin } from "antd"
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons"
import "./styles.less"

interface IEditableCellProps {
  text?: string
  cellLoading?: boolean
  canEdit?: boolean
  onChange?: Function
}

const EditableCell: React.FC<IEditableCellProps> = ({
  text,
  cellLoading,
  canEdit,
  onChange,
}) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState<string>()
  const [editable, setEditable] = useState<boolean>(false)

  useEffect(() => {
    setValue(text)
  }, [text])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleCheck = () => {
    if (value && value !== "") {
      setEditable(false)
      if (onChange) {
        onChange(value)
      }
    } else {
      dispatch(
        setMessage({
          message: `Please enter name`,
        })
      )
    }
  }

  const handleCancel = () => {
    setEditable(false)
    setValue(text)
  }

  const handleEdit = () => {
    setEditable(true)
  }

  return (
    <div className="editable-cell">
      {editable ? (
        <div className="editable-cell-input-wrapper">
          <Input
            value={value}
            onChange={handleChange}
            onPressEnter={handleCheck}
          />
          <CheckOutlined
            className="editable-cell-icon-check"
            onClick={handleCheck}
          />
          <CloseOutlined
            className="editable-cell-icon-cross"
            onClick={handleCancel}
          />
        </div>
      ) : (
        <div className="editable-cell-text-wrapper">
          <Spin spinning={cellLoading}>
            <div className="spin-content">
              {value || " "}
              {canEdit && (
                <EditOutlined
                  className="editable-cell-icon-pencil"
                  onClick={handleEdit}
                />
              )}
            </div>
          </Spin>
        </div>
      )}
    </div>
  )
}
export default EditableCell
