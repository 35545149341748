export enum TopicsControlActionType {
  GET_TOPICS_CONTROL_REQUEST = "ACCESS_CONTROL/GET_TOPICS_CONTROL_REQUEST",
  GET_TOPICS_CONTROL_SUCCESS = "ACCESS_CONTROL/GET_TOPICS_CONTROL_SUCCESS",
  GET_TOPICS_CONTROL_FAILURE = "ACCESS_CONTROL/GET_TOPICS_CONTROL_FAILURE",
  REMOVE_TOPIC_CONTROL = "ACCESS_CONTROL/REMOVE_TOPIC_CONTROL",
  UPSERT_TOPIC_CONTROL = "ACCESS_CONTROL/UPSERT_TOPIC_CONTROL",
}

export enum TopicPermission {
  Consumers = "consumers",
  Producers = "producers",
  TopicsConfigUpdate = "topics.config.update",
  TopicsImportdata = "topics.importdata",
  TopicsLeaderElection = "topics.leader.election",
  TopicsPartitionsCleanup = "topics.partitions.cleanup",
  TopicsPartitionsUpdate = "topics.partitions.update",
  TopicsReassignment = "topics.reassignment",
  TopicsReplicationfactorUpdate = "topics.replicationfactor.update",
  TopicsSizeRead = "topics.size.read",
}

export const TopicPermissionLabel: Record<TopicPermission, string> = {
  [TopicPermission.Consumers]: "Consumers",
  [TopicPermission.Producers]: "Producers",
  [TopicPermission.TopicsConfigUpdate]: "Topic config update",
  [TopicPermission.TopicsImportdata]: "Topic import data",
  [TopicPermission.TopicsLeaderElection]: "Topic leader election",
  [TopicPermission.TopicsPartitionsCleanup]: "Topic partitions cleanup",
  [TopicPermission.TopicsPartitionsUpdate]: "Topic partitions update",
  [TopicPermission.TopicsReassignment]: "Topic reassignment",
  [TopicPermission.TopicsReplicationfactorUpdate]:
    "Topic replication factor update",
  [TopicPermission.TopicsSizeRead]: "Topic size read",
}

export type TopicsControl = TopicControl[]
export interface TopicControl {
  target: TopicControlTarget
  membersPermissions: TopicControlMembersPermissions[]
}

export type TopicControlTarget = { topicClusterId: string; topicName: string }
export type TopicGroupMember = { groupName: string }
export type TopicUserMember = { userEmail: string }
export type TopicControlMember = TopicGroupMember | TopicUserMember
export interface TopicControlMembersPermissions {
  members: TopicControlMember[]
  permissions: TopicPermission[]
}
export interface UpdateTopicControl {
  to: TopicControlTarget
  from: TopicControlMembersPermissions[]
}

export const isUserMember = (member: any): member is TopicUserMember => {
  return Boolean(member.userEmail)
}

export const hasSameTarget = (clusterId: string, topicName: string) => {
  return ({ target }: TopicControl): boolean => {
    return target.topicClusterId === clusterId && target.topicName === topicName
  }
}
