import {
  Avatar,
  Button,
  Card,
  Col,
  Descriptions,
  InputNumber,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tag,
} from "antd"
import moment from "moment"
import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  selectOAuthUser,
  selectOAuthUserLoading,
} from "src/store/desktop/admin/users/selector"
import {
  ArrowRightOutlined,
  DeleteOutlined,
  MailOutlined,
} from "@ant-design/icons"
import {
  deleteOAuthUser,
  sendOAuthUserResetPasswordEmail,
  sendOAuthUserVerificationEmail,
  sendUserReactivateTrial,
  setOAuthUserMaxActivations,
} from "../../../../../store/desktop/admin/users/actions"
import { Link } from "react-router-dom"
import { getReactivableStatus } from "src/domain/desktop/subscription"

export const UserSubscription = () => {
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectOAuthUserLoading)
  const user = useSelector(selectOAuthUser)
  const maxActivationsRef = useRef<HTMLInputElement>(null)
  const userDomain = user?.email.split("@")[1]

  if (!user) {
    return null
  }
  if (loading) {
    return <Spin size="large" tip="Loading..." />
  }

  const reactivateTrial = () => dispatch(sendUserReactivateTrial(user.email))
  const sendVerificationEmail = () =>
    dispatch(sendOAuthUserVerificationEmail(user.email))

  const title = (
    <Card.Meta
      avatar={<Avatar src={user.picture} className="avatar" />}
      title={
        <Row>
          <Col>
            {user.email}
            <br />
            {user.identityProviders?.map((p, index) => (
              <Tag key={index}>{p}</Tag>
            ))}
          </Col>
          <Col flex={"auto"}></Col>
          <Col>
            <Space size={20}>
              <a
                target={"_blank"}
                rel={"noreferrer"}
                href={`https://manage.auth0.com/dashboard/eu/conduktor/users/${btoa(
                  user.userId
                )}`}
              >
                <ArrowRightOutlined /> Auth0
              </a>
              <a
                target={"_blank"}
                rel={"noreferrer"}
                href={`https://app.hubspot.com/contacts/8861897/contacts/list/view/all/?query=${user.email}`}
              >
                <ArrowRightOutlined /> HubSpot
              </a>
            </Space>
          </Col>
        </Row>
      }
    />
  )

  const deleteButton = (
    <Popconfirm
      key="delete"
      title={`Do you really want to delete this user?`}
      okText="Yes"
      cancelText="No"
      onConfirm={() => dispatch(deleteOAuthUser(user.email))}
      okType={"danger"}
    >
      <Button danger>
        <DeleteOutlined /> Delete
      </Button>
    </Popconfirm>
  )

  const resetPasswordButton = (
    <Popconfirm
      key="resetPwd"
      title={`Do you really want to send this email?`}
      okText="Yes"
      cancelText="No"
      onConfirm={() => dispatch(sendOAuthUserResetPasswordEmail(user.email))}
      okType={"primary"}
    >
      <Button>
        <MailOutlined /> Send Reset Password Email
      </Button>
    </Popconfirm>
  )

  const expirationRelatedTags = () => {
    const isExpired = user.subscriptionExpired
    const status = getReactivableStatus(user)

    const expiredTag = isExpired && <Tag color="red">Expired</Tag>
    const actionEl =
      status === "impossible" ? (
        <Tag color="orange">Could not be reactivated</Tag>
      ) : (
        <Button type={"link"} size={"small"} onClick={reactivateTrial}>
          <ArrowRightOutlined /> Reactivate trial
        </Button>
      )

    return (
      <Space direction={"horizontal"}>
        {expiredTag}
        {actionEl}
      </Space>
    )
  }

  return (
    <Card actions={[resetPasswordButton, deleteButton]}>
      {title}
      <br />
      <Descriptions size="small" bordered column={1}>
        <Descriptions.Item label="Subscription">
          {user.subscriptionType}
        </Descriptions.Item>
        {user.chargebeeSubscriptionId && (
          <Descriptions.Item label="Subscription ID">
            <Link
              to={`/subscriptions/licenses-management/${user.chargebeeSubscriptionId}`}
            >
              {user.chargebeeSubscriptionId}
            </Link>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Subscription Expiry">
          {moment(user.subscriptionExpiry).format("LL")}{" "}
          {expirationRelatedTags()}
        </Descriptions.Item>
        <Descriptions.Item label="Max Activations">
          <InputNumber
            ref={maxActivationsRef}
            defaultValue={user.maxActivations}
            className="m-r-5"
            size="small"
            type="number"
            min={0}
          />
          <Button
            size="small"
            onClick={() =>
              dispatch(
                setOAuthUserMaxActivations(
                  user.email,
                  Number(maxActivationsRef!.current!.value)
                )
              )
            }
          >
            Update
          </Button>
        </Descriptions.Item>
        <Descriptions.Item label="Registered">
          {moment(user.createdAt).format("LLL")}{" "}
        </Descriptions.Item>
        <Descriptions.Item label="Last activity date">
          {moment(user.lastAuthDate).format("LLL")}
        </Descriptions.Item>
        <Descriptions.Item label="Email verification">
          {user.emailVerified ? (
            <Tag color="green">Email verified</Tag>
          ) : (
            <div>
              <Tag color="red">Not verified email</Tag>
              <Button
                type={"link"}
                size={"small"}
                onClick={sendVerificationEmail}
              >
                <MailOutlined /> Send again
              </Button>
            </div>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Conduktor Version">
          {user.conduktorVersion}
        </Descriptions.Item>
        <Descriptions.Item label="Domain">
          <Link to={`/domains?name=${userDomain}`}>{userDomain}</Link>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}
