export interface DomainUser {
  email: string
  picture: string
  subscriptionType: string
  subscriptionExpiry: string
  subscriptionExpired: boolean
  updatedAt: string
  isSeat: boolean
}

export interface DomainUsers {
  active: number
  total: number
  users: DomainUser[]
}

export interface DomainPayload {
  domainName: string
}

export enum DomainDetailsActionType {
  GET_DOMAIN_USERS_REQUEST = "DOMAIN_DETAILS/GET_DOMAIN_USERS_REQUEST",
  GET_DOMAIN_USERS_SUCCESS = "DOMAIN_DETAILS/GET_DOMAIN_USERS_SUCCESS",
  GET_DOMAIN_USERS_FAILURE = "DOMAIN_DETAILS/GET_DOMAIN_USERS_FAILURE",
  DELETE_DOMAIN_USER_REQUEST = "DOMAIN_DETAILS/DELETE_DOMAIN_USER_REQUEST",
  DELETE_DOMAIN_USER_SUCCESS = "DOMAIN_DETAILS/DELETE_DOMAIN_USER_SUCCESS",
}
