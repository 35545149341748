import React, { CSSProperties } from "react"

interface Props {
  className?: string
  onClick?: () => void
}

export const Flex: React.FC<Props & CSSProperties> = ({
  className,
  onClick,
  children,
  ...props
}) => {
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ display: "flex", ...props }}
    >
      {children}
    </div>
  )
}
