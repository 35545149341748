import { Reducer } from "redux"
import { Action } from "src/domain/desktop"

export default function createReducer<S>(
  initialState: S,
  handlers: Record<string, Function>
): Reducer<S> {
  const r = (state: S = initialState, action: Action<S>): S => {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    }

    return state
  }

  return r as Reducer<S>
}
