import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Table } from "antd"
import { isNil } from "lodash"
import MembersColumns from "./MemebersColumns"
import { useLicenseOfflineTableColumns } from "./LicenseOfflineTableColumns"
import {
  selectSubscription,
  selectSubscriptionDetailsLoading,
} from "src/store/desktop/subscriptionDetails/selector"
import { Subscription } from "src/domain/desktop"
import { selectUser } from "src/store/desktop/subscriptionDetails/actions"
import { showModal } from "src/store/system/actions"
import { User } from "../../../../../../domain/system"
import { ModalType } from "../../../../../../domain/desktop/common"

interface MainTableProps {
  members: Array<User>
}

const MainTable: React.FC<MainTableProps> = ({ members }) => {
  const dispatch = useDispatch()
  const width: number = useWindowWidth()
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const loading: boolean = useSelector(selectSubscriptionDetailsLoading)

  const handleGenerateToken = (license: User) => {
    dispatch(selectUser(license))
    dispatch(showModal(ModalType.ADD_USER))
  }

  const licenseOfflineColumns = useLicenseOfflineTableColumns()
  const isUnlimitedOfflineSub = Boolean(
    subscription?.hasOffline && subscription?.hasUnlimited
  )

  return (
    <Table
      columns={MembersColumns()}
      dataSource={members}
      loading={loading}
      pagination={{ hideOnSinglePage: true, showSizeChanger: true }}
      expandIconColumnIndex={3}
      rowKey={(object) =>
        (object.accessLevel ? "user-" + object.id : object.id) || 0
      }
      rowClassName={(object) => (object.accessLevel ? "hide" : "show")}
      expandable={{
        rowExpandable: (record) =>
          isUnlimitedOfflineSub && isNil(record.accessLevel),
        expandedRowRender: (record) => (
          <div style={{ margin: 0 }}>
            <Table
              rowKey="id"
              style={{ width: 500 }}
              size="small"
              className="m-b-10 float-right"
              showHeader={false}
              pagination={{ hideOnSinglePage: true }}
              columns={licenseOfflineColumns}
              dataSource={record.licensesOffline}
              locale={{
                emptyText: "No Offline Machine Token generated yet",
              }}
            />
            <div className="w-100 float-right text-right">
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => handleGenerateToken(record)}
              >
                Add Offline Machine
              </Button>
            </div>
          </div>
        ),
      }}
      size="small"
      scroll={width < 1400 ? { x: "max-content" } : undefined}
    />
  )
}
export default MainTable
