import { TablePaginationConfig as Pagination } from "antd/lib/table"
import {
  Action,
  ActionType,
  ApiStatus,
  Subscription,
  Subscriptions,
} from "src/domain/desktop"

import createReducer from "src/store/config/createReducer"
import {
  failureReducer,
  initReducer,
  requestReducer,
  successReducer,
} from "../../reducers"

export type SubscriptionsReducerType = ApiStatus & Subscriptions

export const defaultState: SubscriptionsReducerType = {
  loading: false,
  error: undefined,

  items: [],

  pagination: {
    current: 1,
    pageSizeOptions: ["10", "20", "40", "100"],
    pageSize: 10,
    showSizeChanger: true,
    total: 0,
  },

  searchQuery: "",
  showExpiredSubscriptions: false,
}

function getSubscriptionsSuccessReducer(
  state: SubscriptionsReducerType,
  { payload }: Action<{ subscriptions: Subscription[]; totalCount: number }>
) {
  return successReducer({
    ...state,
    items: payload.subscriptions,
    pagination: {
      ...state.pagination,
      total: payload.totalCount,
    },
  })
}

function setSubscriptionsPagination(
  state: SubscriptionsReducerType,
  { payload }: Action<Pagination>
) {
  return {
    ...state,
    pagination: payload,
  }
}

function setSubscriptionsSearchQuery(
  state: SubscriptionsReducerType,
  { payload }: Action<string>
) {
  return {
    ...state,
    pagination: {
      ...state.pagination,
      current: 1,
    },
    searchQuery: payload,
  }
}

function setShowExpiredSubscriptons(
  state: SubscriptionsReducerType,
  { payload }: Action<boolean>
) {
  return {
    ...state,
    pagination: {
      ...state.pagination,
      current: 1,
    },
    showExpiredSubscriptions: payload,
  }
}

export const subscriptionsReducer = createReducer<SubscriptionsReducerType>(
  defaultState,
  {
    [ActionType.INIT_STORE]: initReducer(defaultState),
    [ActionType.GET_SUBSCRIPTIONS_REQUEST]: requestReducer,
    [ActionType.GET_SUBSCRIPTIONS_SUCCESS]: getSubscriptionsSuccessReducer,
    [ActionType.GET_SUBSCRIPTIONS_FAILURE]: failureReducer,
    [ActionType.SET_SUBSCRIPTIONS_PAGINATION]: setSubscriptionsPagination,
    [ActionType.SET_SUBSCRIPTIONS_SEARCH_QUERY]: setSubscriptionsSearchQuery,
    [ActionType.SET_SHOW_SHOWEXPIRED_SUBSCRIPTIONS]: setShowExpiredSubscriptons,
  }
)
