import {
  Button,
  Card,
  Popconfirm,
  Space,
  Spin,
  Tabs,
  Tag,
  Timeline,
} from "antd"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { map, orderBy } from "lodash"
import moment from "moment"
import {
  fingerprintMachinePreview,
  fingerprintUserPreview,
  formatDate,
  or0,
} from "src/utils"
import {
  selectOAuthUserLoading,
  selectOAuthUserState,
} from "src/store/desktop/admin/users/selector"
import { clearOAuthUserFingerprintsAndRefreshTokens } from "src/store/desktop/admin/users/actions"
import {
  CheckOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons"

const { TabPane } = Tabs

export const UserDetails = () => {
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectOAuthUserLoading)
  const oAuthUser = useSelector(selectOAuthUserState)

  if (!oAuthUser?.user) {
    return null
  }
  if (loading) {
    return <Spin size="large" tip="Loading..." />
  }

  const clearFingerprintsButton = (
    <Popconfirm
      key="delete"
      title={`Are you sure?`}
      okText="Yes"
      cancelText="No"
      onConfirm={() =>
        dispatch(
          clearOAuthUserFingerprintsAndRefreshTokens(oAuthUser.user!.email)
        )
      }
      okType={"danger"}
    >
      <Button style={{ padding: 0 }} type="link">
        Clear Fingerprints & Refresh Tokens
      </Button>
    </Popconfirm>
  )

  return (
    <Card>
      <Tabs defaultActiveKey="emails">
        <TabPane tab={`Emails (${or0(oAuthUser.emails?.length)})`} key="emails">
          <Timeline>
            {map(
              orderBy(oAuthUser.emails, "timestamp", "desc"),
              (email, index) => (
                <Timeline.Item key={index} color="blue">
                  <Space
                    size={3}
                    title={[email.event, email.deliveryStatus]
                      .filter(Boolean)
                      .join(": ")}
                  >
                    <span>
                      {formatDate(email.timestamp, "YYYY-MM-DD hh:mm:ss")}
                    </span>
                    <Tag>{email.source}</Tag>
                    {email.event === "accepted" ? (
                      <InfoCircleOutlined style={{ color: "green" }} />
                    ) : email.deliveryStatus === "OK" ? (
                      <CheckOutlined style={{ color: "green" }} />
                    ) : (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    )}
                    <span>{email.subject}</span>
                  </Space>
                </Timeline.Item>
              )
            )}
          </Timeline>
        </TabPane>
        <TabPane
          tab={`Auth0 Activity Logs (${or0(oAuthUser.logs?.length)})`}
          key="logs"
        >
          <Timeline>
            {map(oAuthUser.logs, (log, index) => (
              <Timeline.Item key={index} color="blue">
                <Space size={3} title={log.eventType.description}>
                  <span>{moment(log.at).format("LLL")}</span>
                  <Tag>{log.clientName || "Global"}</Tag>
                  {log.eventType.name}
                </Space>
              </Timeline.Item>
            ))}
          </Timeline>
        </TabPane>
        <TabPane
          tab={`Fingerprints (${or0(oAuthUser.user?.fingerprints?.length)})`}
          key="fingerprints"
        >
          <Space size={10} direction="vertical">
            <Tag>{or0(oAuthUser.refreshTokens?.length)} refresh tokens</Tag>
            {clearFingerprintsButton}
            <Timeline>
              {map(oAuthUser.user.fingerprints, (print, index) => (
                <Timeline.Item key={index} color="green">
                  <Space>
                    <span>
                      {moment(print.firstUsage).format("L")} {" > "}{" "}
                      {moment(print.lastUsage).format("L")}
                      {": "}({print.alg}){": "}
                      User=<b>
                        {fingerprintUserPreview(print.fingerprint)}...
                      </b>{" "}
                      Machine=
                      <b>{fingerprintMachinePreview(print.fingerprint)}...</b> (
                      {print.count}x)
                    </span>
                  </Space>
                </Timeline.Item>
              ))}
            </Timeline>
          </Space>
        </TabPane>
      </Tabs>
    </Card>
  )
}
