import { useDispatch, useSelector } from "react-redux"
import { CopyOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Tooltip } from "antd"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import copy from "copy-to-clipboard"
import { isEmpty } from "lodash"
import { isDefined, truncate } from "src/utils"
import { Subscription } from "src/domain/desktop"
import { selectUser } from "src/store/desktop/subscriptionDetails/actions"
import { setMessage, showModal } from "src/store/system/actions"
import { MessageType, User } from "../../../../../../../../domain/system"
import { ModalType } from "../../../../../../../../domain/desktop/common"

const OfflineTokenColumn = (_text: string, record: User) => {
  const dispatch = useDispatch()

  const subscription: Subscription | undefined = useSelector(selectSubscription)

  const handleCopyToClipBoard = (payload?: User | string) => {
    let token: string | undefined
    if (typeof payload === "string") {
      token = payload
    } else if (
      payload &&
      payload.licensesOffline &&
      payload.licensesOffline[0]
    ) {
      token = payload.licensesOffline[0].token
    }
    if (!token) return
    const res = copy(token)
    if (res) {
      dispatch(
        setMessage({
          type: MessageType.INFO,
          message: `${truncate(token, 20)} Copied to clipboard`,
        })
      )
    }
  }

  const handleGenerateToken = (license: User) => {
    dispatch(selectUser(license))
    dispatch(showModal(ModalType.ADD_USER))
  }

  if (!subscription || isDefined(record.accessLevel)) {
    return <span>-</span>
  }

  return (
    <div>
      {subscription.hasOffline && subscription.hasUnlimited && (
        <span>
          {record?.licensesOffline && record.licensesOffline.length > 0 ? (
            <span>{record.licensesOffline.length}</span>
          ) : (
            <span>-</span>
          )}
        </span>
      )}
      {record.licensesOffline && record.licensesOffline.length === 1 && (
        <span
          className="cursor-pointer"
          onClick={() => handleCopyToClipBoard(record)}
        >
          <Tooltip
            placement="bottom"
            title="Click to copy this offline token for the user"
          >
            {record.licensesOffline &&
              record.licensesOffline.length > 0 &&
              record.licensesOffline[0].token?.substring(0, 14)}
            ...
            <Button icon={<CopyOutlined />} size="small" />
          </Tooltip>
        </span>
      )}

      {!subscription.hasUnlimited && isEmpty(record.licensesOffline) && (
        <span>
          <Tooltip placement="bottom" title="Add Offline Machine">
            <Button
              icon={<PlusOutlined />}
              size="small"
              onClick={() => handleGenerateToken(record)}
            />
          </Tooltip>
        </span>
      )}
    </div>
  )
}

export default OfflineTokenColumn
