import React, { FC } from "react"
import { useRouteMatch } from "react-router-dom"

import { SidebarExternalLink, SidebarLink } from "./sidebar.styles"
import { SidebarItemProps, SidebarLinkItemProps } from "./types"
import { SidebarItem } from "./SidebarItem"

export const SidebarLinkItem: FC<SidebarLinkItemProps & SidebarItemProps> = ({
  children,
  to,
  match,
  disabled,
  ...props
}) => {
  const isActiveTo = useRouteMatch({ path: to, exact: true })
  const isActiveMatch = useRouteMatch({ path: match })
  return (
    <SidebarLink to={to} disabled={disabled}>
      <SidebarItem
        disabled={disabled}
        active={!!isActiveTo || !!isActiveMatch}
        {...props}
      >
        {children}
      </SidebarItem>
    </SidebarLink>
  )
}

export const SidebarExternalLinkItem: FC<
  SidebarLinkItemProps & SidebarItemProps
> = ({ children, to, disabled, ...props }) => {
  return (
    <SidebarExternalLink href={to} disabled={disabled} target={"_blank"}>
      <SidebarItem disabled={disabled} {...props}>
        {children}
      </SidebarItem>
    </SidebarExternalLink>
  )
}
