import { Card, Col, Row, Table } from "antd"
import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { difference, isEmpty, sortBy } from "lodash"
import { selectClustersRoles } from "../../../../store/desktop/clusters/selector"
import { permissionGroups } from "../../../../components/Modals/ClusterModal/constants"
import { ColumnsType } from "antd/lib/table/interface"
import {
  PredefinedRoleName,
  PredefinedRolesName,
  PredefinedRolesPermissionsLevel,
  Role,
} from "../../../../domain/desktop"
import {
  GroupLine,
  GroupValue,
  Line,
  PermLine,
  PermValue,
  RoleCell,
} from "./RoleCell"
import {
  CopyOutlined,
  EditOutlined,
  LockOutlined,
  MoreOutlined,
} from "@ant-design/icons"
import { ModalConfig, RoleCreationModal } from "./RoleCreationModal"
import { bem, classes } from "../../../../bem"
import "./rolesTab.less"
import {
  deleteSubscriptionRoleApi,
  renameSubscriptionRoleApi,
  upsertSubscriptionRoleApi,
} from "../../../../services"
import { selectSubscription } from "../../../../store/desktop/subscriptionDetails/selector"
import {
  createRoleRequest,
  deleteRoleRequest,
  upsertRoleRequest,
} from "../../../../store/desktop/subscriptions/actions"
import { AlignType } from "rc-table/lib/interface"
import { RoleUsageModal, UsageModalConfig } from "./RoleUsageModal"
import {
  Button,
  CircleUserFarIcon,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownSeparator,
  DropdownTrigger,
  PlusFarIcon,
  Text,
  TrashcanIcon,
} from "@conduktor/ui-library"

const b = "rolesTab"
const menuIconClass = bem(b, "roleHeaderMenuIcon")

export const RolesTab: React.FC = () => {
  const dispatch = useDispatch()
  const subscription = useSelector(selectSubscription)
  const roles = useSelector(selectClustersRoles)
  const [creationModalConfig, setCreationModalConfig] = useState<ModalConfig>({
    isVisible: false,
  })
  const [usageModalConfig, setUsageModalConfig] = useState<UsageModalConfig>({
    isVisible: false,
  })
  const permissionGroupsWithChildren: Line[] = useMemo(
    () => permissionsRows(roles),
    [roles]
  )

  const displayRoleUsage = async (role: Role, deleteRoleOnSubmit: boolean) => {
    if (!subscription) {
      return
    }
    setUsageModalConfig({
      isVisible: true,
      deleteRoleOnSubmit: deleteRoleOnSubmit,
      role: role.name,
      subscriptionId: subscription.id,
    })
  }

  const columns: ColumnsType<Line> = [
    {
      title: "",
      key: "name",
      fixed: "left",
      width: 200,
      render: (text: string, line: Line) => {
        if (line.isGroup) {
          return <b>{line.name}</b>
        }
        return line.permission.name
      },
    },
    ...(roles || []).map((role) => {
      const isCustomRole = !PredefinedRolesName.includes(
        role.name as PredefinedRoleName
      )
      return {
        title: (
          <Dropdown>
            <DropdownTrigger asChild>
              <div className={bem(b, "roleHeader")}>
                {!isCustomRole && (
                  <LockOutlined
                    className={bem(b, "roleHeaderLocked")}
                    title={"This is a predefined role"}
                  />
                )}
                {role.name}
                <MoreOutlined className={bem(b, "roleHeaderMore")} />
              </div>
            </DropdownTrigger>
            <DropdownContent>
              <DropdownItem
                hidden={!isCustomRole}
                onClick={() =>
                  setCreationModalConfig({
                    isVisible: true,
                    originalRole: role,
                    role: role,
                  })
                }
              >
                <EditOutlined className={menuIconClass} /> Rename
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  setCreationModalConfig({
                    isVisible: true,
                    role: {
                      ...role,
                      name: `${role.name} (copy)`,
                    },
                  })
                }
              >
                <CopyOutlined className={menuIconClass} /> Duplicate
              </DropdownItem>
              <DropdownItem onClick={() => displayRoleUsage(role, false)}>
                <CircleUserFarIcon className={menuIconClass} fr /> View Usage
              </DropdownItem>
              <DropdownSeparator hidden={!isCustomRole} />
              <DropdownItem
                hidden={!isCustomRole}
                onClick={() => displayRoleUsage(role, true)}
              >
                <TrashcanIcon
                  className={menuIconClass}
                  css={{ color: "$error9" }}
                  fr
                />
                <Text css={{ color: "$error9" }}>Delete</Text>
              </DropdownItem>
            </DropdownContent>
          </Dropdown>
        ),
        width: 150,
        align: "center" as AlignType,
        key: role.name,
        render: (text: string, line: Line) => {
          return <RoleCell line={line} role={role} />
        },
      }
    }),
  ]

  const upsertRole = async (role: Role, originalRole?: Role) => {
    if (originalRole) {
      await renameSubscriptionRoleApi({
        subscriptionId: subscription!.id,
        name: originalRole.name,
        newName: role.name,
      })
      dispatch(upsertRoleRequest(subscription!.id, originalRole, role.name))
    } else {
      await upsertSubscriptionRoleApi({
        subscriptionId: subscription!.id,
        role,
      })
      dispatch(createRoleRequest(subscription!.id, role))
    }
  }

  const handleCreate = async () => {
    setCreationModalConfig({
      isVisible: true,
      role: {
        name: ``,
        permissions: ["clusters.connect"],
      },
    })
  }

  if (!subscription) {
    return null
  }

  return (
    <Card
      className={classes(b, "plans-configurations")}
      bodyStyle={{ padding: 0 }}
      bordered={false}
      loading={!Boolean(roles)}
    >
      <h1 className="card-title">
        <Row gutter={10}>
          <Col>
            <Button variant="primary" onClick={handleCreate}>
              <PlusFarIcon fr /> Create Access Role
            </Button>
          </Col>
        </Row>
      </h1>
      <Table
        sticky
        size={"small"}
        scroll={{ x: 1 }}
        pagination={false}
        columns={sortColumns(columns)}
        indentSize={0}
        dataSource={permissionGroupsWithChildren}
        rowClassName={(line) => {
          return line.isGroup ? "" : "childRow"
        }}
        style={{ maxWidth: "1130px" }}
      />
      {creationModalConfig.isVisible && (
        <RoleCreationModal
          config={creationModalConfig}
          onClose={() => setCreationModalConfig({ isVisible: false })}
          onSubmit={upsertRole}
        />
      )}
      {usageModalConfig.isVisible && (
        <RoleUsageModal
          config={usageModalConfig}
          onClose={() => setUsageModalConfig({ isVisible: false })}
          onSubmit={async ({ deleteRoleOnSubmit, role, subscriptionId }) => {
            if (role && subscriptionId && deleteRoleOnSubmit) {
              deleteSubscriptionRoleApi({
                subscriptionId: subscriptionId,
                role: role,
              }).then(() => dispatch(deleteRoleRequest(subscriptionId, role)))
            }
          }}
        />
      )}
    </Card>
  )
}
function permissionsRows(roles: Role[] = []): Line[] {
  return permissionGroups.map((group) => {
    const children: PermLine[] = group.permissions.map((permission) => {
      const enabledByRole = roles.reduce((rolesEnabled, role) => {
        rolesEnabled[role.name] = isEmpty(
          difference(permission.codes, role.permissions)
        )
        return rolesEnabled
      }, {} as PermValue)

      return {
        key: `${group.group} - ${permission.name}`,
        permission: permission,
        isGroup: false,
        value: enabledByRole,
      }
    })

    const countByRole = roles.reduce((rolesEnabled, role) => {
      rolesEnabled[role.name] = {
        count: children.filter((c) => c.value[role.name]).length,
        max: children.length,
      }
      return rolesEnabled
    }, {} as GroupValue)

    return {
      key: group.group,
      name: group.group,
      isGroup: true,
      value: countByRole,
      children,
    } as GroupLine
  })
}

function sortColumns(columns: ColumnsType<Line>) {
  return sortBy(
    columns,
    // fixed first
    (c) => (c.fixed ? 1 : 2),
    // then by level for predefined roles
    (c) => -PredefinedRolesPermissionsLevel[c.key as PredefinedRoleName],
    // then by name
    (c) => String(c.key).toLowerCase()
  )
}
