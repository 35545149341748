import React, { useState } from "react"
import { Alert, Form, Modal } from "antd"
import { Role } from "../../../../domain/desktop"
import { isClientError, orEmpty, orBlank } from "../../../../utils"
import { useSelector } from "react-redux"
import { selectClustersRoles } from "../../../../store/desktop/clusters/selector"
import { Flex, Input, Text } from "@conduktor/ui-library"

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

interface FormValues {
  name: string
}

export interface ModalConfig {
  role?: Role
  originalRole?: Role
  isVisible: boolean
}

interface Props {
  config: ModalConfig
  onClose: () => void
  onSubmit: (role: Role, originalRole?: Role) => Promise<void>
}

export const RoleCreationModal: React.FC<Props> = ({
  config,
  onSubmit,
  onClose,
}) => {
  const roles = useSelector(selectClustersRoles)
  const [form] = Form.useForm<FormValues>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isConfirmLoading, setIsConfirmationLoading] = useState(false)
  const rolesName = roles?.map((r) => r.name)

  const create = (values: FormValues) => {
    setIsConfirmationLoading(true)
    setErrorMessage(undefined)
    onSubmit(
      {
        name: values.name,
        permissions: orEmpty(config.role?.permissions),
      },
      config.originalRole
    )
      .then(() => {
        setIsConfirmationLoading(false)
        onClose()
      })
      .catch((e) => {
        console.log("Error", e)
        setIsConfirmationLoading(false)
        setErrorMessage(
          isClientError(e)
            ? "The role seems invalid, please retry with other settings"
            : "Sorry, an unexpected error occurred"
        )
      })
  }

  return (
    <Modal
      title="Role name"
      visible={config.isVisible}
      onOk={form.submit}
      confirmLoading={isConfirmLoading}
      onCancel={onClose}
    >
      {config.isVisible && (
        <Form {...layout} form={form} onFinish={create}>
          <Flex direction={"column"} gap={1}>
            <Text variant={"label"}>Name</Text>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^[^-]+$/,
                  message: "Hyphens are not allowed in the role name",
                },
                {
                  validator: (_, value) => {
                    return !rolesName?.includes(value)
                      ? Promise.resolve()
                      : Promise.reject(new Error("This name is already used"))
                  },
                },
              ]}
              initialValue={orBlank(config.role?.name)}
            >
              <Input />
            </Form.Item>
          </Flex>
          {errorMessage && <Alert type="error" message={errorMessage} />}
        </Form>
      )}
    </Modal>
  )
}
