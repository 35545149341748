import { Input } from "antd"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"

interface Props {
  domainName: string | null
}

export const DomainSearchInput: React.FC<Props> = ({ domainName }) => {
  const history = useHistory()
  const [currentQuery, setCurrentQuery] = useState(domainName || "")

  const handleSearch = () => {
    history.push(`/domains?name=${currentQuery}`)
  }

  return (
    <Input.Search
      value={currentQuery}
      placeholder="conduktor.io"
      allowClear={true}
      className="search-box"
      onChange={(e) => {
        setCurrentQuery(e.target.value)
      }}
      onSearch={handleSearch}
    />
  )
}
