import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import { Auth0Provider } from "@auth0/auth0-react"
import * as serviceWorker from "./serviceWorker"
import { AppConfig } from "./core/config"
import { initSentry } from "./core/reporting/sentry"
import { initGTM } from "./core/analytics/gtm"
import { onRedirectCallback } from "./core/auth"
import App from "./App"

initSentry()
initGTM()

ReactDOM.render(
  <Auth0Provider
    domain={AppConfig.auth0.domain}
    clientId={AppConfig.auth0.clientId}
    redirectUri={AppConfig.auth0.redirectUri}
    onRedirectCallback={onRedirectCallback}
    cacheLocation="memory"
    useRefreshTokens={true}
  >
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  </Auth0Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
