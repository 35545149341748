import {
  ActionType,
  ApiStatus,
  createAction,
  createEmptyAction,
} from "src/domain/desktop"
import {
  Auth0User,
  ConduktorUser,
  Message,
  MessageType,
  User,
} from "../../domain/system"
import { ModalType, UpdatableConduktorUser } from "../../domain/desktop/common"

export const initStore = () => ({
  type: ActionType.INIT_STORE,
})

export const setAuth0User = (payload: Auth0User) => ({
  type: ActionType.SET_AUTH0_USER,
  payload,
})

export const setConduktorUser = (payload: ConduktorUser) => ({
  type: ActionType.SET_CONDUKTOR_USER,
  payload,
})
export const updateUserDataRequest = createAction<UpdatableConduktorUser>(
  ActionType.UPDATE_USER_DATA_REQUEST
)
export const updateUserDataFailure = createAction<string>(
  ActionType.UPDATE_USER_DATA_FAILURE
)
export const updateUserDataSuccess = createAction<UpdatableConduktorUser>(
  ActionType.UPDATE_USER_DATA_SUCCESS
)
export const updateUserDataClear = createEmptyAction(
  ActionType.UPDATE_USER_DATA_CLEAR
)

export const setLicensedUser = (payload: User) => ({
  type: ActionType.SET_LICENCED_USER,
  payload,
})

export const setIsBillingUser = (payload: boolean) => ({
  type: ActionType.SET_IS_BILLING_USER,
  payload,
})

export const setIsLicensed = (payload?: boolean) => ({
  type: ActionType.SET_IS_LICENCED,
  payload,
})

export const setApiStatus = (payload: ApiStatus) => ({
  type: ActionType.SET_API_STATE,
  payload,
})

export const fetchAllDesktopUserData = (payload: boolean) => ({
  type: ActionType.FETCH_ALL_DESKTOP_USER_DATA,
  payload,
})

export const fetchAuth0User = () => ({
  type: ActionType.FETCH_AUTH0_USER,
})

export const fetchConduktorUser = () => ({
  type: ActionType.FETCH_CONDUKTOR_USER,
})

export const setMessage = (payload?: Message) => ({
  type: ActionType.SET_MESSAGE,
  payload,
})

export const setSuccessMessage = (message: string) =>
  setMessage({
    type: MessageType.SUCCESS,
    message: message,
  })

export const setErrorMessage = (message: string) =>
  setMessage({
    type: MessageType.ERROR,
    message: message,
  })

export const removeDevice = (payload: string) => ({
  type: ActionType.REMOVE_DEVICE,
  payload,
})

export const setForceLogout = (payload: boolean) => ({
  type: ActionType.SET_FORCE_LOGOUT,
  payload,
})

export const showModal = (payload: ModalType) => ({
  type: ActionType.SHOW_MODAL,
  payload,
})

export const hideModal = (payload: ModalType) => ({
  type: ActionType.HIDE_MODAL,
  payload,
})

export const hideAllModals = () => ({
  type: ActionType.HIDE_ALL_MODALS,
})

export const toggleSidebarCollapsed = () => ({
  type: ActionType.SIDEBAR_TOGGLE_COLLAPSE,
})
