import { useDispatch, useSelector } from "react-redux"
import {
  selectSubscription,
  selectSubscriptionDetailsLoading,
} from "src/store/desktop/subscriptionDetails/selector"
import { deleteDomainRequest } from "src/store/desktop/subscriptionDetails/actions"
import { Button, Popconfirm, Tag, Tooltip } from "antd"
import { ColumnType } from "antd/lib/table"
import {
  Domain,
  DomainStatus,
  DeleteDomainApiPayload,
  Subscription,
} from "src/domain/desktop"
import { formatDate } from "src/utils"

const DomainsColumns = () => {
  const dispatch = useDispatch()
  const loading: boolean = useSelector(selectSubscriptionDetailsLoading)
  const subscription: Subscription | undefined = useSelector(selectSubscription)

  const handleDeleteDomain = (id: number) => {
    const payload: DeleteDomainApiPayload = {
      subscriptionId: subscription?.id,
      domainId: id,
    }
    dispatch(deleteDomainRequest(payload))
  }

  const columns: ColumnType<Domain>[] = [
    {
      key: "domain",
      title: "Domain",
      render: (_text, record) => {
        return <span>{record.domain}</span>
      },
    },
    {
      key: "status",
      title: "Approval State",
      render: (_text, record) => {
        return (
          <span>
            {record.approvalStatus === DomainStatus.ACCEPTED && (
              <Tag color="green">Approved</Tag>
            )}
            {record.approvalStatus === DomainStatus.PENDING && (
              <Tag color="volcano">Pending</Tag>
            )}
            {record.approvalStatus === DomainStatus.REJECTED && (
              <Tag color="red">Rejected</Tag>
            )}
          </span>
        )
      },
      align: "center",
    },
    {
      key: "created",
      title: "Linked at",
      render: (_text, record) => {
        return <span>{formatDate(record.created)}</span>
      },
      align: "center",
    },
    {
      key: "action",
      title: "",
      render: (_text, record) => {
        return (
          <Tooltip
            placement="bottom"
            title={"All of its allowed emails will also be deleted."}
          >
            <template slot="title">
              <span>All of its allowed emails will also be deleted.</span>
            </template>
            <Popconfirm
              disabled={loading}
              title="Are you sure you want to delete this domain?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDeleteDomain(record.id)}
            >
              <Button disabled={loading} type="link">
                Unlink
              </Button>
            </Popconfirm>
          </Tooltip>
        )
      },
      width: 80,
      align: "center",
    },
  ]
  return columns
}

export default DomainsColumns
