import { useSelector } from "react-redux"
import { Tag } from "antd"
import { ColumnType } from "antd/lib/table"
import UserWithAvatar from "src/components/UserWithAvatar"
import { selectConduktorUser } from "src/store/system/selector"
import { formatDate } from "src/utils"
import { isValidUser } from "src/domain/user"
import { Link } from "react-router-dom"
import { isSubscriptionExpired } from "src/domain/desktop/subscription"
import { License, UserRoleType } from "../../../../../../domain/desktop/common"
import { ConduktorUser } from "../../../../../../domain/system"

const LicensesColumns = () => {
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const isAdmin = isValidUser(conduktorUser, [UserRoleType.ADMIN])

  const columns: ColumnType<License>[] = [
    {
      title: "Subscription",
      key: "chargeBeeSubscriptionId",
      sorter: true,
      width: 180,
      render: (_text, record) => {
        return (
          <span>
            {isSubscriptionExpired(record.subscription) && !isAdmin ? (
              <div className="link-disabled">
                {record.subscription.chargeBeeSubscriptionId}
              </div>
            ) : (
              <Link
                to={`/subscriptions/licenses-management/${record.subscription.chargeBeeSubscriptionId}`}
              >
                {record.subscription.chargeBeeSubscriptionId}
              </Link>
            )}
          </span>
        )
      },
    },
    {
      title: "Status",
      align: "center",
      width: 80,
      render: (_text, record) => {
        return (
          <span>
            {isSubscriptionExpired(record.subscription) ? (
              <Tag color="volcano">Expired</Tag>
            ) : (
              <Tag color="green">Active</Tag>
            )}
          </span>
        )
      },
    },
    {
      title: "User",
      dataIndex: "",
      sorter: true,
      render: (_text, record) => {
        return (
          <UserWithAvatar
            teams={record.teams}
            pictureUrl={record.pictureUrl}
            name={record.name}
            email={record.email}
          />
        )
      },
    },
    {
      title: "Assigned on",
      align: "center",
      width: 120,
      render: (_text, record) => {
        return <span>{formatDate(record.created)}</span>
      },
    },
    {
      title: "Until",
      align: "center",
      width: 120,
      render: (_text, record) => {
        return (
          <span>
            {formatDate(
              record.subscription.renewalDate || record.subscription.expiryDate
            )}
          </span>
        )
      },
    },
  ]
  return columns
}
export default LicensesColumns
