import { ConduktorUser } from "../../system"

export enum AdminsActionType {
  GET_ADMINS_REQUEST = "ADMINS/GET_ADMINS_REQUEST",
  GET_ADMINS_SUCCESS = "ADMINS/GET_ADMINS_SUCCESS",
  GET_ADMINS_FAILURE = "ADMINS/GET_ADMINS_FAILURE",
  UPSERT_ADMIN_REQUEST = "ADMINS/UPSERT_ADMIN_REQUEST",
  REMOVE_ADMIN_ACCESS = "ADMINS/REMOVE_ADMIN_ACCESS",
}

export interface ConduktorUserIdPayload {
  id: number
}

export type ConduktorUserUpdate = Pick<
  ConduktorUser,
  "email" | "fullName" | "pictureUrl"
>
