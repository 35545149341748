import { TablePaginationConfig as Pagination } from "antd/lib/table"
import {
  ActionType,
  Action,
  ApiStatus,
  Domains,
  Domain,
} from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import { failureReducer, initReducer, requestReducer } from "../../../reducers"
import { FilterValue, SorterResult } from "antd/lib/table/interface"

export type DomainsReducerType = ApiStatus & Domains

export const defaultState: DomainsReducerType = {
  loading: false,
  error: undefined,
  type: undefined,

  items: [],

  pagination: {
    current: 1,
    pageSizeOptions: ["5", "10", "20", "50"],
    pageSize: 10,
    showSizeChanger: true,
    total: 0,
  },
}

function getDomainsSuccessReducer(
  state: DomainsReducerType,
  { payload }: Action<{ subscriptionDomains: Domain[]; totalCount: number }>
) {
  return {
    ...state,
    items: payload.subscriptionDomains,
    pagination: {
      ...state.pagination,
      total: payload.totalCount,
    },
    loading: false,
  }
}

function setDomainsTableConfig(
  state: DomainsReducerType,
  {
    payload,
  }: Action<{
    pagination: Pagination
    filters: Record<string, FilterValue | null>
    sorter: SorterResult<Domain> | SorterResult<Domain>[]
  }>
) {
  return {
    ...state,
    ...payload,
  }
}

export const domainsReducer = createReducer<DomainsReducerType>(defaultState, {
  [ActionType.INIT_STORE]: initReducer(defaultState),
  [ActionType.GET_DOMAINS_REQUEST]: requestReducer,
  [ActionType.GET_DOMAINS_SUCCESS]: getDomainsSuccessReducer,
  [ActionType.GET_DOMAINS_FAILURE]: failureReducer,
  [ActionType.SET_DOMAINS_TABLE_CONFIG]: setDomainsTableConfig,
})
