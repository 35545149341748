import React from "react"
import { useSelector } from "react-redux"
import { Card, Table } from "antd"
import { ColumnType } from "antd/lib/table"
import { isEmpty } from "lodash"
import {
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoading,
  selectSubscriptionRemovedUserCounts,
} from "src/store/desktop/subscriptionDetails/selector"
import { ActionType, IActionType, RemovedUserCount } from "src/domain/desktop"
import { formatDate } from "src/utils"
import { useWindowWidth } from "@react-hook/window-size"

const RemovedUserCounts: React.FC = () => {
  const width: number = useWindowWidth()
  const mode: IActionType | undefined = useSelector(
    selectSubscriptionDetailsApiMode
  )
  const loading: boolean =
    useSelector(selectSubscriptionDetailsLoading) &&
    mode === ActionType.GET_REMOVED_USER_COUNTS_REQUEST
  const removedUserCount: Array<RemovedUserCount> =
    useSelector(selectSubscriptionRemovedUserCounts) || []

  if (isEmpty(removedUserCount)) {
    return null
  }

  return (
    <div className="removed-users-count">
      <Card className="card" bordered={false}>
        <h1 className="card-title">Removed Members Count</h1>
        <Table
          size="small"
          rowKey="startDate"
          loading={loading}
          columns={removedUserCountsColumns}
          dataSource={removedUserCount}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            pageSize: 20,
          }}
          scroll={width < 1100 ? { x: "max-content" } : undefined}
        />
      </Card>
    </div>
  )
}

export default RemovedUserCounts

const removedUserCountsColumns: ColumnType<RemovedUserCount>[] = [
  {
    title: "Month / Year",
    render: (_text, record) => {
      return <span>{formatDate(record.startDate, "MMM YYYY")}</span>
    },
  },
  {
    title: "Licenses Revoked",
    dataIndex: "count",
    align: "center",
    width: 180,
  },
  {
    title: "Revoking %",
    dataIndex: "revokingPercentage",
    align: "center",
    width: 180,
  },
]
