import { TablePaginationConfig as Pagination } from "antd"
import { ActionType, Log } from "src/domain/desktop"

export const getLogsRequest = () => ({
  type: ActionType.GET_LOGS_REQUEST,
})

export const getLogsSuccess = (payload: {
  licenseLoggingList: Log[]
  totalCount: number
}) => ({
  type: ActionType.GET_LOGS_SUCCESS,
  payload,
})

export const getLogsFailure = (payload: string) => ({
  type: ActionType.GET_LOGS_FAILURE,
  payload,
})

export const setLogsPagination = (payload: Pagination) => ({
  type: ActionType.SET_LOGS_PAGINATION,
  payload,
})

export const setLogsSearchQuery = (payload: string) => ({
  type: ActionType.SET_LOGS_SEARCH_QUERY,
  payload,
})
