import { RootState } from "src/domain/desktop"
import { TopicsControlReducerType } from "./reducer"
import { TopicsControl } from "../../../domain/desktop/topicPermission"

const selectTopicsControlState = (state: RootState): TopicsControlReducerType =>
  state.topicsControl

export const selectTopicsControlLoading = (state: RootState): boolean =>
  selectTopicsControlState(state).loading

export const selectTopicsControlError = (
  state: RootState
): string | undefined => selectTopicsControlState(state).error

export const selectTopicsControlLoaded = (
  state: RootState
): boolean | undefined => selectTopicsControlState(state).loaded

export const selectTopicsControl = (
  state: RootState
): TopicsControl | undefined => selectTopicsControlState(state).control
