import {
  Action,
  ActionType,
  ApiStatus,
  Cluster,
  Clusters,
  Role,
} from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import { failureReducer, initReducer, requestReducer } from "../../reducers"

export type ClustersReducerType = ApiStatus & Clusters

export const defaultState: ClustersReducerType = {
  loading: false,
  loaded: false,
  error: undefined,
  type: undefined,

  items: [],
  checked: [],
  selected: undefined,
  roles: undefined,
}

function getClustersSuccessReducer(
  state: ClustersReducerType,
  { payload }: Action<Cluster[]>
): ClustersReducerType {
  return {
    ...state,
    items: payload,
    loading: false,
    loaded: true,
  }
}

function getClustersRolesSuccessReducer(
  state: ClustersReducerType,
  { payload }: Action<Role[]>
): ClustersReducerType {
  return {
    ...state,
    roles: payload,
  }
}

function checkClustersReducer(
  state: ClustersReducerType,
  { payload }: Action<Cluster[]>
): ClustersReducerType {
  return {
    ...state,
    checked: payload,
  }
}

function selectClusterReducer(
  state: ClustersReducerType,
  { payload }: Action<Cluster | undefined>
): ClustersReducerType {
  return {
    ...state,
    selected: payload,
  }
}

export const clustersReducer = createReducer<ClustersReducerType>(
  defaultState,
  {
    [ActionType.INIT_STORE]: initReducer(defaultState),
    [ActionType.GET_CLUSTERS_REQUEST]: requestReducer,
    [ActionType.GET_CLUSTERS_SUCCESS]: getClustersSuccessReducer,
    [ActionType.GET_CLUSTERS_FAILURE]: failureReducer,
    [ActionType.GET_CLUSTERS_ROLES_SUCCESS]: getClustersRolesSuccessReducer,
    [ActionType.ADD_CLUSTER_REQUEST]: requestReducer,
    [ActionType.ADD_CLUSTER_SUCCESS]: getClustersSuccessReducer,
    [ActionType.ADD_CLUSTER_FAILURE]: failureReducer,
    [ActionType.UPDATE_CLUSTER_REQUEST]: requestReducer,
    [ActionType.UPDATE_CLUSTER_SUCCESS]: getClustersSuccessReducer,
    [ActionType.UPDATE_LICENSE_FAILURE]: failureReducer,
    [ActionType.DELETE_CLUSTER_REQUEST]: requestReducer,
    [ActionType.DELETE_CLUSTER_SUCCESS]: getClustersSuccessReducer,
    [ActionType.DELETE_CLUSTER_FAILURE]: failureReducer,
    [ActionType.DELETE_CLUSTERS_REQUEST]: requestReducer,
    [ActionType.DELETE_CLUSTERS_SUCCESS]: getClustersSuccessReducer,
    [ActionType.DELETE_CLUSTERS_FAILURE]: failureReducer,
    [ActionType.CHECK_CLUSTERS]: checkClustersReducer,
    [ActionType.SELECT_CLUSTER]: selectClusterReducer,
  }
)
