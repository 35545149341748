import React from "react"
import { useSelector } from "react-redux"
import {
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoading,
} from "src/store/desktop/subscriptionDetails/selector"
import { ActionType, AddLicenseModalLicenseData } from "src/domain/desktop"
import { Button } from "antd"
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons"
interface MyProps {
  licenseData?: AddLicenseModalLicenseData
  onGenerateToken: Function
  onAddLicense: Function
  onClose: Function
}

const AddLicenseModalFooter: React.FC<MyProps> = ({
  licenseData,
  onGenerateToken,
  onClose,
  onAddLicense,
}) => {
  const apiMode = useSelector(selectSubscriptionDetailsApiMode)
  const loading: boolean =
    useSelector(selectSubscriptionDetailsLoading) &&
    apiMode === ActionType.ADD_LICENSE_REQUEST

  return (
    <>
      <Button disabled={loading} onClick={() => onClose()}>
        <CloseCircleOutlined />
        Close
      </Button>
      {licenseData?.id ? (
        <Button
          disabled={loading}
          loading={loading}
          onClick={() => onGenerateToken()}
        >
          <PlusOutlined />
          Add an Offline Machine
        </Button>
      ) : (
        <Button
          disabled={loading}
          loading={loading}
          onClick={() => onAddLicense()}
        >
          <PlusOutlined />
          Add a member
        </Button>
      )}
    </>
  )
}

export default AddLicenseModalFooter
