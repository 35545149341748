import { SentEmail, OAuthUser, RootState } from "src/domain/desktop"

import { OAuthUserReducerType } from "./reducer"

export const selectOAuthUserState = (state: RootState): OAuthUserReducerType =>
  state.users

export const selectOAuthUserLoading = (state: RootState): boolean =>
  selectOAuthUserState(state).loading

export const selectOAuthUserError = (state: RootState): string | undefined =>
  selectOAuthUserState(state).error

export const selectOAuthUser = (state: RootState): OAuthUser | undefined =>
  selectOAuthUserState(state).user

export const selectOAuthUserEmails = (
  state: RootState
): SentEmail[] | undefined => selectOAuthUserState(state).emails
