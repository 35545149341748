import {
  AccessControl,
  AccessControlMemberApiPayload,
  Action,
  ActionType,
  ApiStatus,
  RemoveAccessControlMemberApiPayload,
} from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import { initReducer, requestReducer, successReducer } from "src/store/reducers"
import { omit } from "src/utils"

export type AccessControlReducerType = ApiStatus & AccessControl

export const defaultState: AccessControlReducerType = {
  loading: false,
  loaded: false,
  error: undefined,
  type: undefined,

  groups: {},
  users: {},
}

function getAccessControlSuccessReducer(
  state: AccessControlReducerType,
  { payload }: Action<AccessControl>
): AccessControlReducerType {
  return successReducer({
    ...state,
    ...payload,
  })
}

function updateAccessControlMemberSuccessReducer(
  state: AccessControlReducerType,
  { payload }: Action<AccessControlMemberApiPayload>
): AccessControlReducerType {
  const groups = (): Partial<AccessControl> => ({
    groups: {
      ...state.groups,
      [payload.id]: payload.roles,
    },
  })
  const users = (): Partial<AccessControl> => ({
    users: {
      ...state.users,
      [payload.id]: payload.roles,
    },
  })

  return {
    ...state,
    ...(payload.type === "group" ? groups() : users()),
  }
}

function removeAccessControlMemberSuccessReducer(
  state: AccessControlReducerType,
  { payload }: Action<RemoveAccessControlMemberApiPayload>
): AccessControlReducerType {
  const groups = (): Partial<AccessControl> => ({
    groups: omit(payload.id, state.groups),
  })
  const users = (): Partial<AccessControl> => ({
    users: omit(payload.id, state.users),
  })

  return {
    ...state,
    ...(payload.type === "group" ? groups() : users()),
  }
}

export const accessControlReducer = createReducer<AccessControlReducerType>(
  defaultState,
  {
    [ActionType.INIT_STORE]: initReducer(defaultState),
    [ActionType.GET_ACCESS_CONTROL_REQUEST]: requestReducer,
    [ActionType.GET_ACCESS_CONTROL_SUCCESS]: getAccessControlSuccessReducer,
    [ActionType.UPDATE_ACCESS_CONTROL_MEMBER_SUCCESS]: updateAccessControlMemberSuccessReducer,
    [ActionType.REMOVE_ACCESS_CONTROL_MEMBER_SUCCESS]: removeAccessControlMemberSuccessReducer,
  }
)
