import { useDispatch, useSelector } from "react-redux"
import { useWindowWidth } from "@react-hook/window-size"
import { isEmpty } from "lodash"
import {
  selectSubscriptionDetailsLoading,
  selectSubscriptionDomainList,
} from "src/store/desktop/subscriptionDetails/selector"
import { setMessage } from "src/store/system/actions"
import { addDomainEmailRequest } from "src/store/desktop/subscriptionDetails/actions"
import { Input, Table, Tag } from "antd"
import { useDomainEmailsColumns } from "./DomainEmailsColumns"
import { Domain, DomainStatus } from "src/domain/desktop"
import DomainsColumns from "./DomainsColumns"

const DomainModalTable = () => {
  const dispatch = useDispatch()

  const loading: boolean = useSelector(selectSubscriptionDetailsLoading)
  const domainList = useSelector(selectSubscriptionDomainList)
  const domainEmailsColumns = useDomainEmailsColumns()
  const width: number = useWindowWidth()

  const dispatchMsg = (message: string) =>
    dispatch(
      setMessage({
        message,
      })
    )

  const haddleAddDomainEmail = (r: Domain) => (domainEmail: string) => {
    const email = `${domainEmail}@${r.domain}`
    const alreadyExist = () => r.emails?.find((e) => e.email === email)

    if (isEmpty(domainEmail)) {
      dispatchMsg(`Please enter valid email address`)
    } else if (alreadyExist()) {
      dispatchMsg(`This email already exists`)
    } else if (domainEmail.includes("@")) {
      dispatchMsg(`Please don't enter the domain`)
    } else {
      dispatch(
        addDomainEmailRequest({
          email: email,
          subscriptionDomain: r,
        })
      )
    }
  }

  return (
    <Table
      rowKey="id"
      size="small"
      className="domains-table m-t-10"
      pagination={{ hideOnSinglePage: true }}
      loading={loading}
      columns={DomainsColumns()}
      dataSource={domainList}
      scroll={width < 900 ? { x: "max-content" } : undefined}
      expandable={{
        expandedRowRender: (record) => (
          <div>
            {record.approvalStatus === DomainStatus.ACCEPTED &&
              record.emails && (
                <Table
                  rowKey="id"
                  size="small"
                  className="m-b-10"
                  showHeader={false}
                  pagination={{ hideOnSinglePage: true }}
                  columns={domainEmailsColumns}
                  dataSource={record.emails}
                />
              )}
            {record.approvalStatus !== DomainStatus.ACCEPTED && (
              <Tag color="red" className="m-b-5">
                You must wait for domain approval by Conduktor before managing
                your domain
              </Tag>
            )}
            <Input.Search
              suffix={"@" + record.domain}
              placeholder="Allow only specific emails. eg: foo@mycompany.com"
              enterButton="Allow"
              disabled={record.approvalStatus !== DomainStatus.ACCEPTED}
              onSearch={haddleAddDomainEmail(record)}
            />
          </div>
        ),
      }}
    />
  )
}
export default DomainModalTable
