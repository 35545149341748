import { Action, ActionType, ApiStatus } from "src/domain/desktop"
import createReducer from "src/store/config/createReducer"
import {
  failureReducer,
  initReducer,
  requestReducer,
  successReducer,
} from "../../../reducers"
import { ConduktorUser } from "../../../../domain/system"

export type Admins = {
  users?: ConduktorUser[]
}

export type AdminsReducerType = ApiStatus & Admins

export const defaultState: AdminsReducerType = {
  loading: false,
  loaded: false,
  error: undefined,
  type: undefined,
  users: undefined,
}

function getAdminsSuccessReducer(
  state: AdminsReducerType,
  { payload }: Action<ConduktorUser[]>
): AdminsReducerType {
  return {
    ...successReducer(state),
    users: payload,
  }
}

export const adminsReducer = createReducer<AdminsReducerType>(defaultState, {
  [ActionType.INIT_STORE]: initReducer(defaultState),
  [ActionType.GET_ADMINS_REQUEST]: requestReducer,
  [ActionType.GET_ADMINS_SUCCESS]: getAdminsSuccessReducer,
  [ActionType.GET_ADMINS_FAILURE]: failureReducer,
})
