import UserWithAvatar from "src/components/UserWithAvatar"
import { ColumnType } from "antd/lib/table"
import { DomainUser } from "src/domain/desktop"
import { Button, Popconfirm, Space, Tag } from "antd"
import React from "react"
import moment from "moment"
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { deleteDomainUser } from "../../../../../../store/desktop/admin/domainDetails/actions"

export const useDomainDetailsColumns = (): ColumnType<DomainUser>[] => {
  const dispatch = useDispatch()

  const columns: ColumnType<DomainUser>[] = [
    {
      title: "Email",
      dataIndex: "",
      render: (_text, record) => {
        return (
          <Space>
            <UserWithAvatar pictureUrl={record.picture} email={record.email} />{" "}
            <Link to={`/users?email=${encodeURIComponent(record.email)}`}>
              <SearchOutlined />
            </Link>
          </Space>
        )
      },
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
      },
    },
    {
      title: "Subscription",
      dataIndex: "",
      sorter: {
        compare: (a, b) => a.subscriptionType.localeCompare(b.subscriptionType),
      },
      render: (_text, record) => {
        const colors: Record<string, string> = {
          enterprise: "blue",
          trialing: "orange",
          professional: "green",
          free: "grey",
        }
        return (
          <Tag
            color={colors[record.subscriptionType] || "red"}
            className="m-l-3"
          >
            {record.subscriptionType}
          </Tag>
        )
      },
    },
    {
      title: "Last update",
      dataIndex: "updatedAt",
      render: (_text, record) => {
        return <span>{moment(record.updatedAt).format("YYYY-MM-DD")}</span>
      },
      sorter: {
        compare: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      },
    },
    {
      title: "Subscription Expiry",
      dataIndex: "subscriptionExpiry",
      sorter: {
        compare: (a, b) =>
          a.subscriptionExpiry
            ? a.subscriptionExpiry.localeCompare(b.subscriptionExpiry)
            : -1,
      },
    },
    {
      title: "",
      render: (_text, record) => {
        return (
          <Popconfirm
            key="delete"
            title={`Do you really want to delete this user?`}
            okText="Yes"
            cancelText="No"
            onConfirm={() => dispatch(deleteDomainUser(record))}
            okType={"danger"}
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        )
      },
    },
  ]
  return columns
}
