import { Analytics, AnalyticsBrowser } from "@segment/analytics-next"
import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import { AppConfig } from "../config"
import { RouteKey } from "src/router/routes"
import { Properties } from "./EventProperties"
import { Action, path, TrackActionPath } from "./paths"
import { emailDomain } from "../../utils"
import { useAuth0 } from "@auth0/auth0-react"

interface SegmentContext {
  page: (path: RouteKey, properties?: Properties) => void
  track: (path: TrackActionPath, properties?: Properties) => void
}

const Context = React.createContext<SegmentContext | undefined>(undefined)

export const SegmentProvider: FC = ({ children }) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined)
  const { user } = useAuth0()
  const domain = user?.email && emailDomain(user.email)
  const context = useMemo(
    () => ({
      app: {
        name: "desktopCustomerPortal",
      },
      traits: {
        subscription: {
          domain: domain,
        },
      },
    }),
    [domain]
  )

  useEffect(() => {
    const id =
      user?.["https://conduktor.io/userId"] || analytics?.user()?.anonymousId()
    const domain = user?.email && emailDomain(user.email)
    const userTrait = user && {
      name: user.name,
      email: user.email,
    }

    if (id && domain && userTrait) {
      analytics?.identify(id, {
        subscription: {
          domain: domain,
        },
        name: userTrait.name,
        email: userTrait.email,
      })
    }
  }, [user, analytics])

  const page = useCallback(
    (key: RouteKey, properties: Properties = {}) => {
      if (!analytics || !key) return
      analytics
        .page(path(key, Action.Viewed), properties, context)
        .catch((e) => console.log("Could not send page viewed event", e))
    },
    [analytics, context]
  )
  const track = useCallback(
    (path: TrackActionPath, properties?: Properties) => {
      if (!analytics || !path) return
      analytics
        .track(path, properties, context)
        .catch((e) => console.log(`Could not send tracking event: ${path}`, e))
    },
    [analytics, context]
  )

  useEffect(() => {
    if (!AppConfig.segment.key) return

    AnalyticsBrowser.load({
      writeKey: AppConfig.segment.key!,
    }).then(([response]) => setAnalytics(response))
  }, [])

  return <Context.Provider value={{ page, track }}>{children}</Context.Provider>
}

export const useSegmentContext = () => {
  const ctx = React.useContext(Context)
  if (!ctx) {
    throw new Error("Fatal error. Segment context was not initialized")
  }
  return ctx
}
