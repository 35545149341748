import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Modal } from "antd"
import { CloseCircleOutlined } from "@ant-design/icons"
import InvoicesModalTable from "./components/Table"
import {
  selectSubscription,
  selectSubscriptionDetailsApiMode,
  selectSubscriptionDetailsLoading,
} from "src/store/desktop/subscriptionDetails/selector"
import { getInvoicesRequest } from "src/store/desktop/subscriptionDetails/actions"
import {
  ActionType,
  InvoicesModalProps,
  Subscription,
} from "src/domain/desktop"

const InvoicesModal: React.FC<InvoicesModalProps> = ({ visible, onClose }) => {
  const dispatch = useDispatch()
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const apiMode = useSelector(selectSubscriptionDetailsApiMode)
  const loading: boolean =
    useSelector(selectSubscriptionDetailsLoading) &&
    apiMode === ActionType.GET_INVOICES_REQUEST

  useEffect(() => {
    if (visible && subscription?.id) {
      dispatch(getInvoicesRequest(subscription.id))
    }
  }, [dispatch, visible, subscription])

  const handleClose = () => {
    onClose()
  }

  return (
    <div className="invoices">
      <Modal
        title="Invoices"
        visible={visible}
        width={1200}
        maskClosable={false}
        closable={false}
        footer={
          <Button disabled={loading} className="m-l-0" onClick={handleClose}>
            <CloseCircleOutlined />
            Close
          </Button>
        }
      >
        <InvoicesModalTable />
      </Modal>
    </div>
  )
}

export default InvoicesModal
