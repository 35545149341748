import { Flex, styled } from "@conduktor/ui-library"

export const SectionText = styled(Flex, {
  flexDirection: "column",
  gap: "$2",

  variants: {
    small: {
      true: {
        width: "25%",
      },
    },
  },
})
export const SectionPanel = styled(Flex, {
  flexDirection: "column",
  flex: 0.9,
  minHeight: "10em",

  variants: {
    small: {
      true: {
        minHeight: "4em",
      },
    },
  },
})

export const Section = styled(Flex, {
  justifyContent: "space-between",
})
